/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import React, { Component } from 'react';
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Button,

} from 'reactstrap';
import MetaTags from 'react-meta-tags';
import { connect } from 'react-redux';
import Select from 'react-select';
import XlsxPopulate from 'xlsx-populate';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { stockBusinessPoint } from '../../store/business-point/actions';
//Import Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';
import './datatables.scss';
import { buildTitle } from 'Apps';

// Table data

class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      stock: { label: 'All', value: 'all' },
      operator: 'all',
      modal: false,
    };

    this.handleStock = this.handleStock.bind(this);
    this.handleOperator = this.handleOperator.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  handleModal(row) {
    this.toggle();
    this.setState({
      dataPlanogram: [row],
    });
  }
  handleStock = stock => {
    this.setState({ stock });
    const payload = {
      stock: stock,
      operator: this.state.operator,
    };
    this.props.ReadStock(payload);
  };
  handleOperator = operator => {
    this.setState({ operator });
    const payload = {
      operator: operator.value,
    };
    this.props.ReadStock(payload);
  };
  componentDidMount() {
    const payload = {
      operator: this.state.operator,
    };
    this.props.ReadStock(payload);
  }

  renderTableData(stock_data) {
    return stock_data.map((stock, index) => {
      var row_class = 'table-info';
      const { name, qty } = stock; //destructuring

      if (qty <= 3) {
        row_class = 'table-warning';
      } else {
        row_class = 'table-success';
      }

      return (
        <tr className={row_class} key={name}>
          <td>{name}</td>
          <td>{qty}</td>
        </tr>
      );
    });
  }
  getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : 0;
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }
  handleExport = list => {
    const adbj = [
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
      'AA',
      'AB',
      'AC',
      'AD',
      'AE',
      'AF',
      'AG',
      'AH',
      'AI',
      'AJ',
      'AK',
      'AL',
      'AM',
      'AN',
      'AO',
      'AP',
      'AQ',
      'AR',
      'AS',
      'AT',
      'AU',
      'AV',
      'AW',
      'AX',
      'AY',
      'AZ',
      'BB',
    ];
    var data = list.dataSet;
    let header = list.header;
    const { selectedMonthYear, businessPoints, dateRange } = this.state;
    XlsxPopulate.fromBlankAsync().then(async workbook => {
      const sheet1 = workbook.sheet(0);
      sheet1.column('A').width(40);
      sheet1.column('B').width(40);
      let sheetData;
      sheetData = this.getSheetData(data, header);
      sheetData.shift();
      const totalColumns = sheetData[0].length;
      sheet1.cell('A1').value('Business Point').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1.cell('B1').value('Address').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      for (const j in header) {
        if(header[j]){
          sheet1
            .cell(adbj[j] + '1')
            .value(header[j])
            .style({
              horizontalAlignment: 'center',
              verticalAlignment: 'center',
              border: true,
            });
        }
      }
      sheet1.cell('A2').value(sheetData).style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      var report_filename = 'Stock in Business Point '+new Date().toUTCString() +'.xlsx';
      return workbook.outputAsync().then(res => {
        saveAs(res, report_filename)
      })
    });
  };
  format_report = list => {
    const dataSet = [];
    let trx_data = {};
    const header = [];
    if (list.length > 0) {
      for (const el in list) {
        const dataArr = safeDeepGet(list[el], 'product', 0);
        trx_data = {
          business_point: safeDeepGet(list[el], 'bpname', '-'),
          address: safeDeepGet(
            list[el],
            'address',
            safeDeepGet(list[el], 'deviceId', '-')
          ),
        };
        for (const i in dataArr) {
          const qty = safeDeepGet(dataArr, i, 0);
          safeDeepSet(trx_data, i, qty);
          if (!header.includes(i)) {
            header.push(i);
          }
        }
        dataSet.push(trx_data);
      }

      return { dataSet, header };
    }
  };
  render() {
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const report_list = this.format_report(list);
    const fillterOptions = [
      { label: 'All', value: 'all' },
      { label: 'Stock Empty', value: 'empty' },
      { label: 'Stock Warning', value: 'warning' },
      { label: 'Stock Tersedia', value: 'tersedia' },
    ];
    const columns = [
      {
        dataField: 'bpname',
        text: 'Name',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            <p style={{ fontWeight: 'bold' }} className="mb-1">
              {row.bpname}
            </p>
            <p className="text-wrap">{row.address}</p>
          </>
        ),
      },
      {
        dataField: 'stock',
        text: 'Stock',
        headerAlign: (column, colIndex) => 'center',
        align: 'center',
        formatter: (cellContent, row) => (
          <>
            <Table>
              <tbody>
                <React.Fragment>
                  <tr onClick={() => this.handleModal(row)}>
                    <td className="table-danger">
                      Empty {row.conditional.empty}
                    </td>
                    <td className="table-warning">
                      Warning {row.conditional.warning}
                    </td>
                    <td className="table-success">OK {row.conditional.ok}</td>
                  </tr>
                </React.Fragment>
              </tbody>
            </Table>
          </>
        ),
      },
      // {
      //   dataField: 'stock',
      //   text: 'Stock',
      //   formatter: (cellContent, row) => (
      //     <Table id={`stock_business_point_${row.id}`} className="table mb-0">
      //       <tbody>
      //         {row.planogram.map((index, idx) => (
      //           <React.Fragment key={idx}>
      //             <tr key={`${idx}`}>
      //               {index.map((data, innerIdx) => {
      //                 if (!data) {
      //                   return (
      //                     <>
      //                       <td className="table-danger">{'⁣'}</td>
      //                       <td className="table-danger">{'⁣'}</td>
      //                     </>
      //                   );
      //                 }

      //                 const statusClass =
      //                   data.qty === 0
      //                     ? 'table-danger'
      //                     : data.qty <= 3
      //                     ? 'table-warning'
      //                     : 'table-success';

      //                 // Check if the inner index is a multiple of 10
      //                 const isNewRow = (innerIdx + 1) % 10 === 0;

      //                 return (
      //                   <React.Fragment key={`${idx}-${innerIdx}`}>
      //                     <td className={statusClass}>{data.sensor ?? '-'}</td>
      //                     <td className={statusClass}>{data.name ?? '-'}</td>
      //                     <td className={statusClass}>{data.qty ?? '-'}</td>
      //                     {isNewRow && <td rowSpan="1">{'⁣'}</td>}
      //                   </React.Fragment>
      //                 );
      //               })}
      //             </tr>
      //           </React.Fragment>
      //         ))}
      //       </tbody>
      //     </Table>
      //   ),
      // },
    ];
    const modalColumns = [
      {
        dataField: 'stock',
        formatter: (cellContent, row) => (
          <Table id={`stock_business_point_${row.id}`} className="table mb-0">
            <tbody>
              {row.planogram.map((index, idx) => (
                <React.Fragment key={idx}>
                  <tr key={`${idx}`}>
                    {index.map((data, innerIdx) => {
                      if (!data) {
                        return (
                          <>
                            <td className="table-danger">{'⁣'}</td>
                            <td className="table-danger">{'⁣'}</td>
                          </>
                        );
                      }

                      const statusClass =
                        data.qty === 0
                          ? 'table-danger'
                          : data.qty <= 3
                          ? 'table-warning'
                          : 'table-success';

                      // Check if the inner index is a multiple of 10
                      const isNewRow = (innerIdx + 1) % 10 === 0;

                      return (
                        <React.Fragment key={`${idx}-${innerIdx}`}>
                          <td className={statusClass}>{data.sensor ?? '-'}</td>
                          <td className={statusClass}>{data.name ?? '-'}</td>
                          <td className={statusClass}>{data.qty ?? '-'}</td>
                          {isNewRow && <td rowSpan="1">{'⁣'}</td>}
                        </React.Fragment>
                      );
                    })}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        ),
      },
    ];
    const defaultSorted = [
      {
        dataField: 'id',
        order: 'desc',
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: list.length, // replace later with size(customers),
      custom: true,
    };

    const { SearchBar } = Search;
    const { stock, operator, dataPlanogram } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Stock di Business Points')}</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Stock" breadcrumbItem="Business Points" />

            <Row className="mb-2">
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      Stock Product di Business Points{' '}
                    </CardTitle>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={list}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="9">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="2" align="center">
                                  <div className="clearfix mt-4 mt-lg-0">
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="btn btn-nestle w-sm"
                                      disabled={list.length == 0 ? true : false}
                                      onClick={() =>
                                        this.handleExport(report_list)
                                      }
                                    >
                                      <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                                      Download Excel
                                    </Button>
                                  </div>
                                </Col>
                                <Col sm="3">
                                  <FormGroup className="mb-3 ajax-select select2-container">
                                    {/* <Select
                                      defaultValue={fillterOptions[0]}
                                      value={operator}
                                      onChange={this.handleOperator}
                                      options={fillterOptions}
                                      classNamePrefix="select2-selection"
                                      isLoading={false}
                                      placeholder="Stock"
                                    /> */}
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={'bpname'}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        'table align-middle table-nowrap'
                                      }
                                      headerWrapperClasses={'thead-light'}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Modal
              size="xl"
              isOpen={this.state.modal}
              className={this.props.className}
            >
              {dataPlanogram ? (
                <>
                  <div className="modal-header">
                    <h3>Stock Planogam {safeDeepGet(dataPlanogram[0], 'bpname', '-')}</h3>
                    <button
                      onClick={() => this.setState({ modal: false })}
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <ModalBody>
                    <BootstrapTable
                      keyField="id"
                      bootstrap4={true}
                      data={dataPlanogram}
                      columns={modalColumns}
                      bordered={false}
                      ref={this.navRef}
                    />
                  </ModalBody>
                </>
              ) : null}
            </Modal>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['businessPoint', 'stockBusinessPoint'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  ReadStock: stock => dispatch(stockBusinessPoint(stock)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DatatableTables);
