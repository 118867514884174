/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import { call, put, takeEvery } from 'redux-saga/effects';

// Product state
import {
  LIST,
  INIT_CREATE,
  LIST_BUSINESS_POINTS,
  LIST_ALERT_DEVICE,
} from './actionTypes';
import { PROJECT } from '../../../Project';
import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
import {getTasks}from '../../webservice/device'

function* list_business_points(payload) {
  const data = safeDeepGet(payload, ['payload']);
  yield put(loading(LIST_BUSINESS_POINTS));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list, data);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointMap = wsResult.body;
    const bpFilterList = [{ label: 'All points', value: 'all' }];
    if (PROJECT == 'Redboks') {
      for (const deviceId in businessPointMap) {
        let bp_name = safeDeepGet(businessPointMap, [deviceId, 'name']);
        bpFilterList.push({ label: bp_name, value: deviceId });
      }
    } else {
      for (const deviceId in businessPointMap) {
        let bp_name = safeDeepGet(businessPointMap, [deviceId, 'name']);
        let id = safeDeepGet(businessPointMap, [deviceId, 'id']);
        bpFilterList.push({ label: bp_name, value: id });
      }
    }
    yield put(
      result(LIST_BUSINESS_POINTS, { code: Result.SUCCESS, body: bpFilterList })
    );
    return;
  }
  yield put(result(LIST_BUSINESS_POINTS, { result: { code: Result.FAILED } }));
}

function* list() {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(getTasks);
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    const alertList = safeDeepGet(wsResult,'body', []);
    wsResult = yield call(firebaseInstance.businessPoint.list)
    const bpMap = safeDeepGet(wsResult, 'body', {})
    const OnlineType = []
    const MachineType = []
    for(const i in alertList) {
      const alertMap = alertList[i];
      const device_id = safeDeepGet(alertMap, 'id', '')
      const type = safeDeepGet(alertMap, 'type', '')
      if(type == 'machine'){
        MachineType.push(alertMap)
      } else{
        OnlineType.push(alertMap)
      }
      const bpName = safeDeepGet(bpMap, [device_id, 'name'], '')
      safeDeepSet(alertMap, 'name', bpName);
    }
    const MachineList = []
    for(const i in MachineType){
      
      const data = JSON.parse(MachineType[i].description)
      const device_id = safeDeepGet(data, 'device_id', '')
      const bpName = safeDeepGet(bpMap, [device_id, 'name'], '')
      safeDeepSet(data, 'name', bpName);
      MachineList.push(data)
    }

    yield put(result(LIST, { code: Result.SUCCESS, body: alertList, machine: MachineList, online: OnlineType }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}

function* list_alert_device(action) {
  yield put(loading(LIST_ALERT_DEVICE));
  const firebaseInstance = getInstance();
  const payload = safeDeepGet(action, ['payload']);
  let wsResult = yield call(firebaseInstance.alertExplanation.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const explanations = wsResult.body;
    const explanationsMaps = {};
    for (const id in explanations) {
      let expId = safeDeepGet(explanations, [id, 'code']);
      explanationsMaps[expId] = safeDeepGet(explanations, id);
    }
    wsResult = yield call(firebaseInstance.alertdevice.list, payload);
    const alertMap = wsResult.body.alerts;
    const alertList = [];
    for (const id in alertMap) {
      var active = safeDeepGet(alertMap[id], ['active']);
      var time = safeDeepGet(alertMap[id], ['time']);
      safeDeepSet(wsResult.body, ['alerts', id, 'active'], active);
      safeDeepSet(wsResult.body, ['alerts', id, 'id'], id);
      safeDeepSet(wsResult.body, ['alerts', id, 'time'], time);
    }
    const list = wsResult.body.alerts;
    for (const id in list) {
      let listId = safeDeepGet(list[id], ['id']);

      let action = safeDeepGet(explanationsMaps, [listId, 'action_required']);
      let description = safeDeepGet(explanationsMaps, [listId, 'description']);
      safeDeepSet(list, [id, 'action'], action);
      safeDeepSet(list, [id, 'description'], description);

      alertList.push(list[id]);
    }
    if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
      yield put(result(LIST_ALERT_DEVICE, { code: Result.FAILED }));
      return;
    }
    yield put(
      result(LIST_ALERT_DEVICE, {
        code: 0,
        body: alertList,
      })
    );
  }
}

function* initCreate() {
  yield put(result(INIT_CREATE, { code: Result.SUCCESS, body: {} }));
}

function* productSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(LIST_BUSINESS_POINTS, list_business_points);
  yield takeEvery(LIST_ALERT_DEVICE, list_alert_device);
}

export default productSaga;
