/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';

import { Container, Row, Col, FormGroup } from 'reactstrap';
import MetaTags from 'react-meta-tags';
import Select from 'react-select';
import 'flatpickr/dist/themes/material_blue.css';

import { connect } from 'react-redux';

// Iotera
import { safeDeepGet } from 'iotera-base/utility/json';

import {
  list_business_points,
  snapshot,
} from '../../../store/transaction/actions';
 
 
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { APPLICATION_ID } from 'Apps';
//Import Components
import SnapshotReport from './snapshot-report';

class Snapshot extends Component {
  constructor(props) {
    super(props);
    this.refDatePicker = React.createRef();
    var current_month = new Date();
    this.state = {
      bpfilterlist: [],
      businessPoints: 'all',
      reports: [
        {
          icon: 'bx bx-copy-alt',
          title: 'Landlord Profit Share',
          value: 'Rp 0',
          badgeValue: '+ 0.0%',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
        {
          icon: 'bx bx-copy-alt',
          title: 'Average Daily Transactions',
          value: '',
          badgeValue: '',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
        {
          icon: 'bx bx-archive-in',
          title: 'Average Daily Settlement',
          value: '',
          badgeValue: '',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Average Daily Refund',
          value: '',
          badgeValue: '',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
      ],
      selectedMonthYear:
        current_month.getFullYear().toString() +
        '-' +
        (current_month.getMonth() + 1).toString(),
    };
    this.handleBusinessPoints = this.handleBusinessPoints.bind(this);
    this.handleMonthYear = this.handleMonthYear.bind(this);
  }

  handleBusinessPoints = businessPoints => {
    this.setState({ businessPoints });

    const payload = {
      businessPoints: businessPoints.value,
      month: parseInt(this.state.selectedMonthYear.split('-')[1]) - 1,
      year: parseInt(this.state.selectedMonthYear.split('-')[0]),
    };

    this.props.Snapshot(payload);
  };

  handleMonthYear = selectedMonthYear => {
    let val = selectedMonthYear.target.value;
    this.setState({ selectedMonthYear: val });

    const payload = {
      businessPoints: this.state.businessPoints,
      month: parseInt(val.split('-')[1]) - 1,
      year: parseInt(val.split('-')[0]),
    };
    this.props.Snapshot(payload);
  };

  componentDidMount() {
    var current_month = new Date();
    this.props.LoadBpFilterList();
    const payload = {
      businessPoints: 'all',
      dateRange: [null, null],
      month: current_month.getMonth(),
      year: current_month.getFullYear(),
    };

    this.props.Snapshot(payload);
  }

  componentDidUpdate(prevProps) {
    const sum_update = safeDeepGet(this.props, ['summaryresult', 'body'], []);

    if (
      this.props.summaryresult !== prevProps.summaryresult &&
      sum_update.length != 0
    ) {
      var stateCopy = Object.assign({}, this.state);
      stateCopy.reports = stateCopy.reports.slice();

      // Landlord Profit Share
      stateCopy.reports[0] = Object.assign({}, stateCopy.reports[0]);
      stateCopy.reports[0].value =
        'Rp ' + (sum_update.landlord_current / 30.0).toFixed(2);
      stateCopy.reports[0].badgeValue =
        sum_update.landlord_change.toFixed(2) + ' %';
      if (sum_update.landlord_change > 0) {
        stateCopy.reports[0].color = 'success';
        stateCopy.reports[0].chevron = 'mdi mdi-chevron-up ms-1 text-success';
      } else {
        stateCopy.reports[0].color = 'danger';
        stateCopy.reports[0].chevron = 'mdi mdi-chevron-down ms-1 text-danger';
      }

      // Average Daily Transactions
      stateCopy.reports[1] = Object.assign({}, stateCopy.reports[1]);
      stateCopy.reports[1].value =
        'Rp ' + (sum_update.trx_current / 30.0).toFixed(2);
      stateCopy.reports[1].badgeValue = sum_update.trx_change.toFixed(2) + ' %';
      if (sum_update.trx_change > 0) {
        stateCopy.reports[1].color = 'success';
        stateCopy.reports[1].chevron = 'mdi mdi-chevron-up ms-1 text-success';
      } else {
        stateCopy.reports[1].color = 'danger';
        stateCopy.reports[1].chevron = 'mdi mdi-chevron-down ms-1 text-danger';
      }

      // Average Daily Settlement
      stateCopy.reports[2] = Object.assign({}, stateCopy.reports[2]);
      stateCopy.reports[2].value =
        (sum_update.settlement_current / 30.0).toFixed(2) + ' x';
      stateCopy.reports[2].badgeValue =
        sum_update.settlement_change.toFixed(2) + ' %';
      if (sum_update.settlement_change > 0) {
        stateCopy.reports[2].color = 'success';
        stateCopy.reports[2].chevron = 'mdi mdi-chevron-up ms-1 text-success';
      } else {
        stateCopy.reports[2].color = 'danger';
        stateCopy.reports[2].chevron = 'mdi mdi-chevron-down ms-1 text-danger';
      }

      // Average Daily Refund
      stateCopy.reports[3] = Object.assign({}, stateCopy.reports[3]);
      stateCopy.reports[3].value =
        'Rp ' + (sum_update.refund_current / 30.0).toFixed(2);
      stateCopy.reports[3].badgeValue =
        sum_update.refund_change.toFixed(2) + ' %';
      if (sum_update.refund_change >= 0) {
        stateCopy.reports[3].color = 'success';
        stateCopy.reports[3].chevron = 'mdi mdi-chevron-up ms-1 text-success';
      } else {
        stateCopy.reports[3].color = 'danger';
        stateCopy.reports[3].chevron = 'mdi mdi-chevron-down ms-1 text-danger';
      }

      this.setState(stateCopy);
    }
  }

  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };

  format_report = list => {
    const dataSet = [];
    let trx_data = {};
    for (const el in list) {
      trx_data = {
        order_id: safeDeepGet(list[el], ['detail', 'order_id']),
        business_point: list[el].name,
        date: this.handleValidDate(list[el].time.firestore_timestamp),
        sku: safeDeepGet(list[el], ['product', 'sku']),
        total: list[el].payment.amount,
        method: list[el].payment.method,
        payment_status: list[el].detail.transaction_status,
        dispense_status: list[el].detail.dispense_status,
      };
      dataSet.push(trx_data);
    }
    return dataSet;
  };

  render() {
    const bpfilterlist = safeDeepGet(
      this.props,
      ['bpfilterresult', 'body'],
      []
    );

    const snapshot_report = safeDeepGet(
      this.props,
      ['snapshotresult', 'body'],
      []
    );

    const { businessPoints, selectedMonthYear } = this.state;

    const report_list = this.format_report(snapshot_report);

    let bp_name = businessPoints.value ? businessPoints.label : businessPoints;
    let report_filename = 'Snapshot Trx ' + bp_name + ' ' + selectedMonthYear;
    return (
      <React.Fragment>
        <div className="page-content">
        {APPLICATION_ID == '1000000209' ? (
            <MetaTags>
              <title>Transaction Report | Redboks Vending Machine</title>
            </MetaTags>
          ) : APPLICATION_ID == '1000000159' ? (
            <MetaTags>
              <title>Transaction Report | Nestle Vending Machine</title>
            </MetaTags>
          ) : APPLICATION_ID == '1000000211' ? (
            <MetaTags>
              <title>Transaction Snapshot | Franke Vending Machine</title>
            </MetaTags>
          ) : APPLICATION_ID == '1000000229' ? (
            <MetaTags>
              <title>Transaction Snapshot | VendkiosK Machine</title>
            </MetaTags>
          ) : APPLICATION_ID == '1000000233' ? (
            <MetaTags>
              <title>Transaction Snapshot | VendkiosK-TTM Machine</title>
            </MetaTags>
          ) : APPLICATION_ID == '1000000240' ? (
            <MetaTags>
              <title>Transaction Snapshot | Mandarin Sentosa Machine</title>
            </MetaTags>
            ) : APPLICATION_ID == '1000000232' ? (
              <MetaTags>
                <title>Transaction Snapshot | Hamparan Andalworks Machine</title>
              </MetaTags>
              ) : APPLICATION_ID == '1000000246' ? (
                <MetaTags>
                  <title>Transaction Snapshot | Freshtin Machine</title>
                </MetaTags>
            ) : APPLICATION_ID == '1000000245' ? (
              <MetaTags>
                <title>Transaction Snapshot | Wahana Pronatural Machine</title>
              </MetaTags>
          ) : (
            <MetaTags>
              <title>Transaction Snapshot | Smart Vending Machine</title>
            </MetaTags>
          )}
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Transaction" breadcrumbItem="Snapshot" />

            <Row className="clearfix mb-3">
              <Col sm="2">
                <FormGroup className="mb-2 ajax-select select2-container">
                  <Select
                    defaultValue={bpfilterlist[0]}
                    value={businessPoints}
                    onChange={this.handleBusinessPoints}
                    options={bpfilterlist}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </Col>
              <Col sm="6"></Col>

              <Col sm="4" className="align-self-end">
                <Row className="m-auto justify-content-end">
                  <div className="mb-3 row">
                    <label
                      htmlFor="example-month-input"
                      className="col-md-2 col-form-label"
                    >
                      Month
                    </label>
                    <div className="col-md-10">
                      <input
                        className="form-control input"
                        type="month"
                        // value="2019-05"
                        id="example-month-input"
                        value={selectedMonthYear}
                        onChange={this.handleMonthYear}
                      />
                    </div>
                  </div>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18">SNAPSHOT REPORT</h4>
                </div>
              </Col>
            </Row>

            <SnapshotReport
              reports={report_list}
              filename={report_filename}
              date={selectedMonthYear}
            />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  snapshotresult: safeDeepGet(state, ['transaction', 'snapshot'], {}),
  bpfilterresult: safeDeepGet(
    state,
    ['transaction', 'list_business_points'],
    {}
  ),
  summaryresult: safeDeepGet(state, ['transaction', 'summary'], {}),
  netsettlementresult: safeDeepGet(state, ['dashboard', 'nett_settlement'], {}),
  salesanalyticsresult: safeDeepGet(
    state,
    ['transaction', 'get_sales_analytics'],
    {}
  ),
  loading: safeDeepGet(state, ['dashboard', 'loading'], true),
});

const mapDispatchToProps = dispatch => ({
  LoadBpFilterList: () => dispatch(list_business_points()),
  Snapshot: (businessPoints, month, year, dateRange) =>
    dispatch(snapshot(businessPoints, month, year, dateRange)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Snapshot);
