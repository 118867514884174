import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
// Qrcode
import QRcode from 'qrcode';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { QRCodeSVG } from 'qrcode.react';
//Import Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';

// Iotera
import { safeDeepGet } from 'iotera-base/utility/json';
import 'toastr/build/toastr.min.css';
// Redux
import { connect } from 'react-redux';
import { list, update, delete_ } from '../../store/distributor/actions';

class DistributorList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    let id = null;
    const path = this.props.match.path;
    if (path.includes('/qrCode/')) {
      id = safeDeepGet(this.props.match.params, 'id');
    }
    this.state = {
      id,
    };
  }
  render() {
    // TODO Loading
    const { id } = this.state;
    const text = `http://contactlessv2.web.app/?deviceId=` + id;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Business Point Qrcode | Smart Vending Machine</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Business Point" breadcrumbItem="Qr-Code" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <QRCodeSVG value={text} size={200} includeMargin={true} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['distributor', 'list'], {}),
  loading: safeDeepGet(state, ['distributor', 'loading'], true),
  updateResult: safeDeepGet(state, ['refiller', 'update'], {}),
});

const mapDispatchToProps = dispatch => ({
  LoadDistributorList: () => dispatch(list()),
  UpdateDistributor: payload => dispatch(update(payload)),
  DeleteDistributor: payload => dispatch(delete_(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DistributorList);
