export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "DISTRIBUTOR_LIST";
export const LIST_OPTIONS = "DISTRIBUTOR_LIST_OPTIONS";
export const INIT_CREATE = "DISTRIBUTOR_INIT_CREATE";
export const CREATE = "DISTRIBUTOR_CREATE";
export const READ = "DISTRIBUTOR_INFO";
export const UPDATE = "DISTRIBUTOR_UPDATE";
export const STOCK_UPDATE = "STOCK_DISTRIBUTOR_UPDATE";
export const DELETE = "DISTRIBUTOR_DELETE";
