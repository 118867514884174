/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import { call, put, takeEvery } from 'redux-saga/effects';
import { App_ID } from 'Apps';
// Product state
import {
  LIST,
  DETAIL_CHAT,
  CREATE,
  CANCEL,
  DELETE,
  LIST_PRODUCT,
  LIST_BUSINESS_POINTS,
} from './actionTypes';
import { Buffer } from 'buffer';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
import {
  listCoupon,
  CreateDiscount,
  cancelDiscount,
  deleteDiscount,
} from 'Apps/device'
;
function* list() {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.discount.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const discountMap = safeDeepGet(wsResult, 'body', []);
    wsResult = yield call(firebaseInstance.product.list);
    if (safeDeepGet(wsResult, 'code') != Result.SUCCESS) {
      yield put(result(LIST, { result: { code: Result.FAILED } }));
      return;
    }
    const productBody = safeDeepGet(wsResult, 'body', []);
    wsResult = yield call(firebaseInstance.businessPoint.list);
    if (safeDeepGet(wsResult, 'code') != Result.SUCCESS) {
      yield put(result(LIST, { result: { code: Result.FAILED } }));
      return;
    }
    const bpBody = safeDeepGet(wsResult, 'body', []);
    const discountList = [];
    for (const id in discountMap) {
      const sku = safeDeepGet(discountMap[id], 'sku', '');
      const device_id = safeDeepGet(discountMap[id], 'device_id', '');
      const productName = safeDeepGet(productBody, [sku, 'name'], '');
      const bpName = safeDeepGet(bpBody, [device_id, 'name'], '');
      safeDeepSet(discountMap[id], 'productName', productName);
      safeDeepSet(discountMap[id], 'bpName', bpName);
      safeDeepSet(discountMap[id], 'id', id);
      discountList.push(discountMap[id]);
    }
    yield put(result(LIST, { code: Result.SUCCESS, body: discountList }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}
function* list_product() {
  yield put(loading(LIST_PRODUCT));
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.product.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS) {
    const productList = [];
    const productBody = safeDeepGet(wsResult, 'body', []);
    for (const id in productBody) {
      const nameProduct = safeDeepGet(productBody, [id, 'name'], '');
      const sku = safeDeepGet(productBody, [id, 'sku'], '');
      productList.push({ label: nameProduct, value: sku });
    }
    yield put(
      result(LIST_PRODUCT, { code: Result.SUCCESS, body: productList })
    );
    return;
  }
  yield put(result(LIST_PRODUCT, { result: { code } }));
  return;
}
function* list_business_points() {
  yield put(loading(LIST_BUSINESS_POINTS));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointMap = wsResult.body;
    const bpFilterList = [];
    for (const deviceId in businessPointMap) {
      let bp_name = safeDeepGet(businessPointMap, [deviceId, 'name']);
      bpFilterList.push({ label: bp_name, value: deviceId });
    }

    yield put(
      result(LIST_BUSINESS_POINTS, { code: Result.SUCCESS, body: bpFilterList })
    );
    return;
  }
  yield put(result(LIST_BUSINESS_POINTS, { result: { code: Result.FAILED } }));
}
function* create(payload) {
  yield put(loading(CREATE));
  const data = safeDeepGet(payload, 'payload', {});
  const amount = safeDeepGet(data, 'discount', 0);
  const sku = safeDeepGet(data, 'product', '');
  const ts = safeDeepGet(data, 'exp_ts', 0);
  const bp = safeDeepGet(data, 'bp', '');
  if (sku == '' || sku == null || sku == undefined) {
    yield put(result(CREATE, { code: Result.FAILED }));
    return;
  }
  const id = bp + '_' + sku;
  const body = {
    id: id,
    device_id: bp,
    discount: Number(amount),
    sku: sku,
    expired_ts: ts,
    created_ts: new Date().getTime(),
    active: true,
  };
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');
  const firebaseInstance = getInstance();
  let wsResult = yield call(CreateDiscount, btoaBody);
  if (
    safeDeepGet(wsResult, 'code') == Result.SUCCESS ||
    safeDeepGet(wsResult, 'code') == Result.HTTP.OK
  ) {
    yield call(firebaseInstance.discount.create, body);
    yield put(result(CREATE, { code: Result.SUCCESS }));
    return;
  }
  yield put(result(CREATE, { code: Result.FAILED }));
}
function* cancel_(payload) {
  yield put(loading(CANCEL));
  const data = safeDeepGet(payload, 'payload', {});
  const amount = safeDeepGet(data, 'discount', 0);
  const sku = safeDeepGet(data, 'sku', '');
  const ts = safeDeepGet(data, 'expired_ts', 0);
  const bp = safeDeepGet(data, 'device_id', '');
  const id = safeDeepGet(data, 'id', '');
  if (sku == '' || sku == null || sku == undefined) {
    yield put(result(CANCEL, { code: Result.FAILED }));
    return;
  }
  const body = {
    id,
    device_id: bp,
    discount: Number(amount),
    sku: sku,
    expired_ts: ts,
    created_ts: new Date().getTime(),
    active: 'false',
  };
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');
  const firebaseInstance = getInstance();
  let wsResult = yield call(cancelDiscount, btoaBody);
  if (
    safeDeepGet(wsResult, 'code') == Result.SUCCESS ||
    safeDeepGet(wsResult, 'code') == Result.HTTP.OK
  ) {
    yield call(firebaseInstance.discount.update, body);
    yield put(result(CANCEL, { code: Result.SUCCESS }));
    return;
  }
  yield put(result(CANCEL, { code: Result.FAILED }));
}
function* delete_(payload) {
  yield put(loading(DELETE));
  const data = safeDeepGet(payload, 'payload', {});
  const id = safeDeepGet(data, 'id', '');
  const firebaseInstance = getInstance();
  const body = {
    id: id,
  }
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');
  let wsResult = yield call(deleteDiscount, btoaBody);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS || safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    yield call(firebaseInstance.discount.delete_, data);
    yield put(result(DELETE, { code: Result.SUCCESS }));
    return;
  }
  yield put(result(DELETE, { code: Result.FAILED }));
}

function* discountSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(LIST_PRODUCT, list_product);
  yield takeEvery(LIST_BUSINESS_POINTS, list_business_points);
  yield takeEvery(CREATE, create);
  yield takeEvery(CANCEL, cancel_);
  yield takeEvery(DELETE, delete_);
}

export default discountSaga;
