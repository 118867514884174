export default class Product {
  constructor(firebase) {
    this.collection = firebase.firestore().collection('planogram');
    this.FieldValue = firebase.firestore.FieldValue;
  }

  read = id => {
    return new Promise((resolve, reject) => {
      const list = {};

      this.collection
        .where('id', '==', id)
        .get()
        .then(
          querySnapshot => {
            querySnapshot.docs.map(doc => {
              list[doc.id] = doc.data();
            });
            resolve({ code: 0, body: list });
          },
          error => {
            resolve({ code: -1, body: list });
          }
        );
    });
  };

  readproduct = id => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .doc(id)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.data()) {
              code = 0;
              map = { ...querySnapshot.data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };
  upsert = (id, planogram) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {
        device_id: id,
        planogram,
      };

      this.collection
        .doc(id)
        .set(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  addPlanogram = (id, payload) => {
    return new Promise((resolve, reject) => {
      const arr_planogram = payload.payload.value;
      let j = 0;
      for (j = 0; j < arr_planogram.length; j++) {
        var productid = '';
        var idProduct = '';
        const _chars =
          'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890';
        if (arr_planogram[j]['idplanogram'] == null) {
          for (var i = 0; i < 20; i++)
            productid += _chars.charAt(
              Math.floor(Math.random() * _chars.length)
            );
          idProduct = arr_planogram[j]['name'];
          arr_planogram[j]['id'] = id;
          arr_planogram[j]['productId'] = idProduct;
          arr_planogram[j]['active'] = true;
          if (arr_planogram[j]['0']) {
            delete arr_planogram[j]['0'];
          }
          if (arr_planogram[j]['1']) {
            delete arr_planogram[j]['1'];
          }
          if (arr_planogram[j]['2']) {
            delete arr_planogram[j]['2'];
          }
          if (arr_planogram[j]['3']) {
            delete arr_planogram[j]['3'];
          }
          this.collection
            .doc(productid)
            .set(arr_planogram[j])
            .then(
              () => {
                resolve({ code: 0 });
              },
              error => {
                resolve({ code: -1, body: error });
              }
            );
        } else {
          this.collection
            .doc(arr_planogram[j]['idplanogram'])
            .update(arr_planogram[j])
            .then(
              () => {
                resolve({ code: 0 });
              },
              error => {
                resolve({ code: -1, body: error });
              }
            );
        }
      }
    });
  };

  updateProduct = (id, payload, ingredients) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (key === 'displayName' || key === 'price' || key === 'sizeName') {
          updatedPayload[key] = payload[key];
        }
      }
      updatedPayload['ingredients'] = ingredients;
      if (payload.sizeName == 'M') {
        updatedPayload['cupSize'] = '7';
      } else if (payload.sizeName == 'L') {
        updatedPayload['cupSize'] = '11';
      }
      this.collection
        .doc(id)
        .update(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  delete_ = id => {
    this.collection.doc(id).delete();
  };

  setDiscount = (id, discountPrice) => {
    return new Promise((resolve, reject) => {
      this.collection
        .where('productId', '==', id)
        .get()
        .then(snapshot => {
          if (snapshot.size > 0) {
            snapshot.docs.forEach(doc => {
              this.collection
                .doc(doc.id)
                .update({ discountPrice: discountPrice });
            });
          }
        });
    });
  };
  deleteDiscount = id => {
    return new Promise((resolve, reject) => {
      this.collection
        .where('productId', '==', id)
        .get()
        .then(snapshot => {
          if (snapshot.size > 0) {
            snapshot.docs.forEach(doc => {
              this.collection
                .doc(doc.id)
                .update({ discountPrice: this.FieldValue.delete() });
            });
          }
        });
    });
  };

  addCsv = payload => {
    return new Promise((resolve, reject) => {
      for (var i in payload) {
        var id = '';
        const _chars =
          'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890';
        for (var j = 0; j < 20; j++) {
          id += _chars.charAt(Math.floor(Math.random() * _chars.length));
        }
        payload[i]['id'] = payload[i]['DeviceId'];
        payload[i]['name'] = payload[i]['productId'];
        payload[i]['price'] = Number(payload[i]['price']);
        payload[i]['order'] = Number(payload[i]['order']);
        delete payload[i]['DeviceId'];
        delete payload[i]['amount 1'];
        delete payload[i]['amount 2'];
        delete payload[i]['amount 3'];
        delete payload[i]['amount 4'];
        delete payload[i]['ingredients 1'];
        delete payload[i]['ingredients 2'];
        delete payload[i]['ingredients 3'];
        delete payload[i]['ingredients 4'];
        if (payload[i]['id'] != null || payload[i]['id'] != undefined) {
          this.collection
            .doc(id)
            .set(payload[i])
            .then(
              () => {
                resolve({
                  code: 0,
                  Message: 'Berhasil Upload Csv Ke Database',
                });
              },
              error => {
                resolve({ code: -1, body: error });
              }
            );
        }
      }
    });
  };
}
