export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "BUSINESS_POINT_LIST";
export const HISTORY = "BUSINESS_POINT_HISTORY";
export const STOCK_BUSINESS_POINT = "STOCK_BUSINESS_POINT";
export const INIT_CREATE = "BUSINESS_POINT_INIT_CREATE";
export const CREATE = "BUSINESS_POINT_CREATE";
export const READ_INFO = "BUSINESS_POINT_READ_INFO";
export const UPDATE_INFO = "BUSINESS_POINT_UPDATE_INFO";
export const READ_PLANOGRAM = "BUSINESS_POINT_READ_PLANOGRAM";
export const UPDATE_PLANOGRAM = "BUSINESS_POINT_UPDATE_PLANOGRAM";
export const DELETE = "BUSINESS_POINT_DELETE";
export const INPUT_CSV = "BUSINESS_POINT_INPUT_CSV";
export const CONFIG_PLANOGRAM = "BUSINESS_POINT_CONFIG_PLANOGRAM";
export const NOTIFICATIONS = "BUSINESS_POINT_NOTIFICATIONS";
export const CLOSE_NOTIFICATIONS = "BUSINESS_POINT_CLOSE_NOTIFICATIONS";
export const SUMMARY = "ADMIN_DEVICE_SUMMARY";
export const UPDATE_TIME = "ADMIN_DEVICE_UPDATE_TIME";
