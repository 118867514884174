/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  FormGroup,
  Label,
  InputGroup,
} from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import ReactTooltip from 'react-tooltip';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import {
  list,
  list_business_points,
  list_alert_current,
  list_alert_historical,
  list_alert_device,
  list_alert_historical_device,
} from '../../store/alert/actions';
import { buildTitle, getTableAlert } from 'Apps';

class AlertList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.refDatePicker = React.createRef();
    let machine = false;
    let historical = false;
    let historical_device = false;
    let id = null;
    let device_id = null;
    let key = null;
    let byDevice = false;
    const path = this.props.match.path;
    let type = safeDeepGet(this.props.match.params, 'type', 'Device');
    // console.log(type)
    if (path.includes('/history/')) {
      historical = true;
      id = safeDeepGet(this.props.match.params, 'id');
      // machine = true;
    }
    if (path.includes('/device/')) {
      byDevice = true;
      id = safeDeepGet(this.props.match.params, 'id');
    }
    if (path.includes('/device-history/')) {
      historical_device = true;
      device_id = safeDeepGet(this.props.match.params, 'device_id');
      key = safeDeepGet(this.props.match.params, 'key');
    }
    const date2 = new Date();
    const year = date2.getFullYear();
    const month = date2.getMonth();
    const day = date2.getDate();
    let newDate = new Date(year, month, day - 3);
    let endDate = new Date();
    let arrDate = [newDate, endDate];
    const Columns = getTableAlert(historical, historical_device, byDevice, id);
    this.state = {
      bpfilterlist: [],
      type,
      list: [],
      businessPoints: 'all',
      byDevice,
      transactionStatus: 'all',
      dispenseStatus: 'all',
      machine,
      historical_device,
      device_id,
      key,
      historical,
      dateRange: arrDate,
      id,
      Columns,
    };
    this.handleBusinessPoints = this.handleBusinessPoints.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleClearDateRange = this.handleClearDateRange.bind(this);
  }

  handleTimeStamp = time => {
    return new Date(time).toString();
  };

  handleBusinessPoints = businessPoints => {
    this.setState({ businessPoints });
    const payload = {
      businessPoints: businessPoints.value,
      dateRange: this.state.dateRange,
    };
    this.props.LoadAlertList(payload);
  };

  componentDidMount() {
    const payload = {
      businessPoints: 'all',
      dateRange: [null, null],
    };
    this.props.LoadAlertList(payload);
    if (this.state.historical) {
      this.props.LoadHistoricalList(this.state.id);
    } else if (this.state.byDevice) {
      this.props.LoadDeviceList(this.state.id);
    } else if (this.state.historical_device) {
      const { dateRange } = this.state;
      const payload = {
        device_id: this.state.device_id,
        key: this.state.key,
        start: dateRange[0].getTime(),
        end: dateRange[1].getTime(),
      };
      this.props.LoadHistoricalDeviceList(payload);
    } else {
      this.props.LoadCurrentList();
    }
    // this.props.LoadBpFilterList(data);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.historicalResult !== prevProps.historicalResult) {
      // Insert value of VM select
      const code = safeDeepGet(
        this.props,
        ['historicalResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code == Result.SUCCESS) {
        const list = safeDeepGet(this.props, ['historicalResult', 'body'], []);
        this.setState({ list });
      }
    }
    if (this.props.deviceResult !== prevProps.deviceResult) {
      // Insert value of VM select
      const code = safeDeepGet(
        this.props,
        ['deviceResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code == Result.SUCCESS) {
        const list = safeDeepGet(this.props, ['deviceResult', 'body'], []);
        this.setState({ list });
        console.log(list)
      }
    }
    if (
      this.props.historicalDeviceResult !== prevProps.historicalDeviceResult
    ) {
      // Insert value of VM select
      const code = safeDeepGet(
        this.props,
        ['historicalDeviceResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code == Result.SUCCESS) {
        const list = safeDeepGet(
          this.props,
          ['historicalDeviceResult', 'body'],
          []
        );
        console.log(list)
        this.setState({ list });
      }
    }
  }
  /* Handle click */
  handleDateRange = dateRange => {
    if (dateRange.length < 2) {
      console.log('Ignoring.. only start date');
    } else {
      if (this.state.historical_device) {
        const payload = {
          device_id: this.state.device_id,
          key: this.state.key,
          start: dateRange[0].getTime(),
          end: dateRange[1].getTime(),
        };
        this.props.LoadHistoricalDeviceList(payload);
      }
    }
  };

  handleClearDateRange = () => {
    console.log('dateRange is cleared');
    if (!this.refDatePicker.current?.flatpickr) {
      return;
    } else {
      this.setState({ dateRange: [null, null] });
      this.refDatePicker.current.flatpickr.clear();
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: [null, null],
      };
      this.props.LoadAlertList(payload);
    }
  };

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  //

  render() {
    // TODO Loading
    let list = safeDeepGet(this.props, ['result', 'body', 'initList'], []);
    if (this.state.type == 'er') {
      list = safeDeepGet(this.props, ['result', 'body', 'errorList'], []);
    } else if (this.state.type == 'nt') {
      list = safeDeepGet(this.props, ['result', 'body', 'ntList'], []);
    } else if (this.state.type == 'bp') {
      list = safeDeepGet(this.props, ['result', 'body', 'bpList'], []);
    } else if (this.state.type == 'st') {
      list = safeDeepGet(this.props, ['result', 'body', 'onlineList'], []);
    } else if (this.state.type == 'stock') {
      list = safeDeepGet(this.props, ['result', 'body', 'stockList'], []);
    }
    const { dateRange } = this.state;
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };
    const defaultSorted = [
      {
        dataField: 'initial_ts', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];
    const title =
      safeDeepGet(this.state, 'type', 'Device').charAt(0).toUpperCase() +
      safeDeepGet(this.state, 'type', 'Device').slice(1);
    const dataToDisplay =
      this.state.historical ||
      this.state.byDevice ||
      this.state.historical_device
        ? this.state.list
        : list;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Alert List')}</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={title} breadcrumbItem="List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="key"
                      columns={this.state.Columns}
                      data={dataToDisplay}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="key"
                          columns={this.state.Columns}
                          data={dataToDisplay}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              {this.state.historical_device ? (
                                <Row className="mb-2">
                                  <Col sm="8"></Col>
                                  <Col sm="4">
                                    <Row>
                                      <Col sm="4">
                                        <Label className="col-md-12 col-form-label text-sm-end">
                                          Date Range
                                        </Label>
                                      </Col>
                                      <Col sm="8">
                                        <FormGroup className="mb-3 ajax-select select2-container">
                                          <InputGroup>
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="dd M,yyyy"
                                              options={{
                                                maxDate: new Date(),
                                                mode: 'range',
                                                dateFormat: 'Y-m-d',
                                              }}
                                              value={dateRange}
                                              onChange={this.handleDateRange}
                                              ref={this.refDatePicker}
                                            />
                                          </InputGroup>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              ) : null}

                              <div className="table-responsive">
                                <ReactTooltip place="top" effect="solid" />
                                <BootstrapTable
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  defaultSorted={defaultSorted}
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    'table align-middle table-nowrap table-check'
                                  }
                                  headerWrapperClasses={'table-light'}
                                  ref={this.node}
                                />
                              </div>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['alert', 'list_alert_current'], {}),
  historicalResult: safeDeepGet(state, ['alert', 'list_alert_historical'], {}),
  historicalDeviceResult: safeDeepGet(
    state,
    ['alert', 'list_alert_historical_device'],
    {}
  ),
  deviceResult: safeDeepGet(state, ['alert', 'list_alert_device'], {}),
  loading: safeDeepGet(state, ['alert', 'loading'], true),
  bpfilterresult: safeDeepGet(state, ['alert', 'list_business_points'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  LoadAlertList: (businessPoints, dateRange) =>
    dispatch(list(businessPoints, dateRange)),
  LoadBpFilterList: payload => dispatch(list_business_points(payload)),
  LoadCurrentList: payload => dispatch(list_alert_current(payload)),
  LoadHistoricalList: payload => dispatch(list_alert_historical(payload)),
  LoadHistoricalDeviceList: payload =>
    dispatch(list_alert_historical_device(payload)),
  LoadDeviceList: payload => dispatch(list_alert_device(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertList);
