import firebase from 'firebase/app';
import { safeDeepGet } from 'iotera-base/utility/json';

export default class AlertDevice {
  constructor(firebase) {
    this.collection = firebase.firestore().collection('alertDevices');
  }

  list = sku => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .where('device_id', '==', sku)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };
}