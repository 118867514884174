export default class Product {
  constructor(firebase) {
    this.collection = firebase.firestore().collection('product');
    this.FieldValue = firebase.firestore.FieldValue;
  }

  list = () => {
    return new Promise((resolve, reject) => {
      const list = {};
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          resolve({ code: -1, body: list });
        }
      );
    });
  };

  create = (id, payload, ingredients) => {
    return new Promise((resolve, reject) => {
      const insertedPayload = {};
      for (const key in payload) {
        if (
          key === 'displayName' ||
          key === 'price' ||
          key === 'sizeName' ||
          key === 'description' ||
          key === 'imgUrl'
        ) {
          insertedPayload[key] = payload[key];
        }
      }
      insertedPayload['ingredients'] = ingredients;

      if (payload.sizeName == 'M') {
        insertedPayload['cupSize'] = 7;
      } else {
        insertedPayload['cupSize'] = 11;
      }

      // Add sku
      insertedPayload['name'] = id;

      // Add

      this.collection
        .doc(id)
        .set(insertedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  read = sku => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .where('name', '==', sku)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };

  update = (id, payload) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (
          key === 'displayName' ||
          key === 'price' ||
          key === 'sizeName' ||
          key === 'description' ||
          key === 'ingredients' ||
          key === 'imgUrl'
        ) {
          updatedPayload[key] = payload[key];
        }
      }
      if (payload.sizeName == 'M') {
        updatedPayload['cupSize'] = '7';
      } else {
        updatedPayload['cupSize'] = '11';
      }
      this.collection
        .doc(id)
        .update(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  updateStock = (id, payload) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (key === 'name' || key === 'desc' || key === 'warehouse_stock') {
          updatedPayload[key] = payload[key];
        }
      }

      this.collection
        .doc(id)
        .update(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  delete_ = id => {
    this.collection.doc(id).delete();
  };

  setDiscount = (id, discountPrice) => {
    return new Promise((resolve, reject) => {
      this.collection
        .doc(id)
        .update({ discountPrice: discountPrice })
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };
  deleteDiscount = (id) => {
    return new Promise((resolve, reject) => {
      this.collection
        .doc(id)
        .update({'discountPrice': this.FieldValue.delete()})
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

}
