/* eslint-disable react/prop-types */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";

import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import { AvForm, AvField } from "availity-reactstrap-validation";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

//Import Breadcrumb
import Breadcrumbs from "Base/components/Common/Breadcrumb";

// Iotera
import Result from "iotera-base/result";
import { safeDeepGet } from "iotera-base/utility/json";

// Redux
import { connect } from "react-redux";
import { list, stockUpdate } from "../../store/product/actions";
import { useRowSelect } from "react-table";

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      productListColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: "sku",
          text: "SKU",
          sort: true,
          formatter: (cellContent, row) => <>{row.sku}</>,
        },
        {
          text: "Name",
          dataField: "name",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.name}
                </Link>
              </h5>
            </>
          ),
        },
        {
          dataField: "warehouse_stock",
          text: "Stok di Warehouse",
          formatter: (cellContent, row) => <>{row.warehouse_stock}</>,
        },
        {
          dataField: "update_warehouse_stock",
          isDummyField: true,
          text: "Update Stok Warehouse",
          sort: true,
          formatter: (cellContent, product) => (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => this.handleUpdateClick(product)}
            >
              Update Stock
            </Button>
          ),
        },
      ],
      products: [],
      modal: false,
    };
    this.handleDOClick = this.handleDOClick.bind(this);
    this.handleUpdateClick = this.handleUpdateClick.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    // Load business point
    this.props.LoadProductList();
    const { products } = this.props;
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  /* Handle click */
  handleDOClick = arg => {
    this.props.history.push("/redboks/stock/create-do");
  };

  handleUpdateClick = arg => {
    const product = arg;

    this.setState({
      products: {
        id: product.id,
        sku: product.sku,
        name: product.name,
        warehouse_stock: product.warehouse_stock,
      },
    });

    this.toggle();
  };

  /**
   * Handling submit DO / Update on Stock form
   */
   handleValidStockSubmit = (e, values) => {
    const { onUpdateStock } = this.props;
    const { products } = this.state;
    const updateStock = {
      id: products.id,
      sku: products.sku,
      name: products.name,
      warehouse_stock: values.orderId,
    };

    // update Stock
    onUpdateStock(updateStock);
    this.props.LoadProductList();
  
    this.toggle();
  };

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    const code = safeDeepGet(
      this.props,
      ["result", "code"],
      Result.UNKNOWN_ERROR
    );
    const list = safeDeepGet(this.props, ["result", "body"], []);

    const { SearchBar } = Search;
    const { products } = this.props;

    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    const selectRow = {
      mode: "checkbox",
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Product Stock di Warehouse | Smart Vending Machine</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Warehouse" breadcrumbItem="Stock" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.productListColumns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.productListColumns}
                          data={list}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      color="primary"
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={this.handleDOClick}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Buat Delivery Order
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable    
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      selectRow={selectRow}
                                      responsive
                                      ref={this.node}
                                    />
                                  </div>
                                  <Modal
                                    isOpen={this.state.modal}
                                    className={this.props.className}
                                  >
                                    <ModalHeader toggle={this.toggle} tag="h4">
                                      {"Update Stok " + this.state.products.sku}
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm
                                        onValidSubmit={
                                          this.handleValidStockSubmit
                                        }
                                      >
                                        <Row form>
                                          <Col className="col-12">
                                            <div className="mb-3">
                                              <AvField
                                                name="orderId"
                                                label="Stok Terbaru"
                                                type="number"
                                                errorMessage="Invalid amount"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  this.state.products.warehouse_stock ||
                                                  ""
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                Submit
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ProductList.propTypes = {
  products: PropTypes.array,
  onUpdateStock: PropTypes.func,
  className: PropTypes.any
};

const mapStateToProps = state => ({
  result: safeDeepGet(state, ["product", "list"], {}),
  loading: safeDeepGet(state, ["product", "loading"], true),
});

const mapDispatchToProps = dispatch => ({
  LoadProductList: () => dispatch(list()),
  onUpdateStock: order => dispatch(stockUpdate(order)),  
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
