export const LOADING = "LOADING"
export const RESULT = "RESULT"

export const LIST = "PAYMENT_LINK_LIST"
export const LIST_VM = "PAYMENT_LINK_LIST_VM"
export const INIT_CREATE = "PAYMENT_LINK_INIT_CREATE"
export const CREATE = "PAYMENT_LINK_CREATE"
export const READ = "PAYMENT_LINK_INFO"
export const UPDATE = "PAYMENT_LINK_UPDATE"
export const STOCK_UPDATE = "STOCK_PAYMENT_LINK_UPDATE"
export const DELETE = "PAYMENT_LINK_DELETE"
export const DEVICE_LIST = "PAYMENT_LINK_DEVICE_LIST"
