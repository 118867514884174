/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import firebase from 'firebase/compat/app';
import { safeDeepGet } from 'iotera-base/utility/json';
 
export default class Payout {
  constructor(firebase) {
      this.collection = firebase.firestore().collection('payout');
  }
  payout = payload => {
    return new Promise((resolve, reject) => {
      const firestore = firebase.firestore;
      const dataUpdate = safeDeepGet(payload, ['dataUpdate'], {});
      const createdTs = safeDeepGet(payload, ['createdTs'], '');
      const notes = safeDeepGet(payload, ['notes'], '');
      const amount = safeDeepGet(payload, ['amount'], '');
      const transactionFee = safeDeepGet(payload, ['transactionFee'], '');
      const accId = safeDeepGet(payload, ['accId'], '');
      const acc_name = safeDeepGet(payload, ['accName'], '');
      const accNo = safeDeepGet(payload, ['accNo'], '');
      const accBank = safeDeepGet(payload, ['accBank'], '');
      const total = safeDeepGet(payload, ['total'], '');
      const status = safeDeepGet(payload, ['status'], '');
      const createdFirestore = firestore.Timestamp.fromMillis(createdTs);
      const data = {
        fee: {
          transaction_fee: transactionFee,
          amount: amount,
        },
        time: {
          created_firestore_ts: createdFirestore,
          created_ts: createdTs,
        },
        account: {
          acc_id: accId,
          acc_name: acc_name,
          acc_bank: accBank,
          acc_no: accNo,
        },
        total: total,
        status: status,
      };
      this.collection
        .doc(notes)
        .set(data, { merge: true })
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };
  list = payload => {
    return new Promise((resolve, reject) => {
      var filter_name = safeDeepGet(payload, ['payload', 'name']);
      var filter_bank = safeDeepGet(payload, ['payload', 'bank']);
      if (
        safeDeepGet(payload, ['payload', 'dateRange']) != null ||
        safeDeepGet(payload, ['payload', 'dateRange'] != undefined)
      ) {
        var filter_sd = safeDeepGet(payload, ['payload', 'dateRange'])[0];
        var filter_ed = safeDeepGet(payload, ['payload', 'dateRange'])[1];
      }
      if (filter_name == 'all' || filter_name == null) {
        filter_name = null;
      }
      if (filter_bank == 'all' || filter_bank == null) {
        filter_bank = null;
      }
      if (filter_sd == 'all' || filter_sd == null) {
        filter_sd = null;
      }
      if (filter_ed == 'all' || filter_ed == null) {
        filter_ed = null;
      }
      const list = {};
      let query = this.collection;

      if (filter_name) {
        query = query.where('account.acc_id', '==', filter_name);
      }
      if (filter_bank) {
        query = query.where('account.acc_bank', '==', filter_bank);
      }
      if (filter_sd) {
        var startfulldate = firebase.firestore.Timestamp.fromDate(filter_sd);
        query = query.where('time.created_firestore_ts', '>=', startfulldate);
      }

      if (filter_ed) {
        const d = filter_ed;
        d.setDate(d.getDate() + 1);
        var endfulldate = firebase.firestore.Timestamp.fromDate(d);

        query = query.where('time.created_firestore_ts', '<=', endfulldate);
      }

      query.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          resolve({ code: -1, body: list });
        }
      );
    });
  };
}
