import firebase from 'firebase/compat/app';
import { safeDeepGet } from 'iotera-base/utility/json';

export default class balance {
  constructor(firebase) {
    this.collection = firebase.firestore().collection('balances');
  }

  list = () => {
    return new Promise((resolve, reject) => {
        const list = {}
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          resolve({ code: -1, body: list });
        }
      );
    });
  };
}
