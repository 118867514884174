/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'
import { connect } from 'react-redux'
import { safeDeepGet } from 'iotera-base/utility/json'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  FormGroup,
} from 'reactstrap'
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb'
import MetaTags from 'react-meta-tags'
import { read } from '../../store/business-point-fee/actions'
import Select from 'react-select'
const typeOptions = [
  { label: 'Bruto Transaction', value: 'bruto' },
  { label: 'Nett Transaction', value: 'nett' },
  { label: 'Profit', value: 'profit' },
  { label: 'Total Transaction', value: 'total' },
]
class DashedLine extends Component {
  constructor(props) {
    super(props)
    const appId = safeDeepGet(this.props.match.params, 'appId')
    const device_id = safeDeepGet(this.props.match.params, 'device_id')
    this.state = {
      device_id,
      appId,
      Type: 'bruto',
      options: {
        chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
        colors: ['#556ee6', '#34c38f'],
        dataLabels: { enabled: !1 },
        stroke: { width: [3, 4, 3], curve: 'straight', dashArray: [0, 8, 5] },
        // title: { text: 'Daa', align: 'left' },
        markers: { size: 0, hover: { sizeOffset: 6 } },
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'Mei',
            'Jun',
            'Jul',
            'Agt',
            'Sep',
            'Oct',
            'Nov',
            'Des',
          ],
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (e) {
                  return e
                },
              },
            },
            {
              title: {
                formatter: function (e) {
                  return e
                },
              },
            },
          ],
        },
        grid: { borderColor: '#f1f1f1' },
      },
    }
    this.handleType = this.handleType.bind(this)
  }
  componentDidMount() {
    // Load business point
    const payload = {
      appId: this.state.appId,
      device_id: this.state.device_id,
      type: this.state.Type,
    }
    this.props.LoadBusinessPoint(payload)
  }
  handleType(Type) {
    this.setState({ Type: Type.value })
    const payload = {
      appId: this.state.appId,
      device_id: this.state.device_id,
      type: Type.value,
    }
    this.props.LoadBusinessPoint(payload)
  }
  render() {
    const chart_data = safeDeepGet(this.props, ['result', 'body'], [])
    const { Type } = this.state
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Chart Fee | Admin</title>
          </MetaTags>

          <Container fluid>
            <Breadcrumbs title="Chart" breadcrumbItem="Fee" />
            <Row className="mb-2">
              <Col sm="4">
                <FormGroup className="mb-3 ajax-select select2-container"></FormGroup>
              </Col>
              <Col sm="2"></Col>
              <Col sm="2" className="d-none d-lg-block" align="center"></Col>

              <Col sm="4">
                <FormGroup className="mb-3 ajax-select select2-container">
                  <Select
                    defaultValue={typeOptions[0]}
                    value={typeOptions.filter(function (option) {
                      return option.value === Type
                    })}
                    onChange={this.handleType}
                    options={typeOptions}
                    classNamePrefix="select2-selection"
                    isLoading={false}
                    placeholder="Type"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <ReactApexChart
                      options={this.state.options}
                      series={chart_data}
                      type="line"
                      height="380"
                      className="apex-charts"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['BpFee', 'read'], {}),
})

const mapDispatchToProps = dispatch => ({
  LoadBusinessPoint: payload => dispatch(read(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashedLine)
