/* eslint-disable semi */
import { call, put, takeEvery } from 'redux-saga/effects'

import {
  LIST,
  CREATE,
  DELETE,
} from './actionTypes'

import { loading, result } from './actions'
import { getInstance } from '../../firebase/helper'
import { safeDeepGet, safeDeepSet, safeDel } from 'iotera-base/utility/json'
import Result from 'iotera-base/result'
import { deleteTags,getTags, createTags, tags_serverless, delete_Tags, create_Tags } from '../../webservice/device'


function* delete_(action) {
  yield put(loading(DELETE));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body');
    const app_id = safeDeepGet(action, ['payload', 'appId'], '');
    const url = safeDeepGet(mapBody, [app_id, 'url_delete_tags'], '');
    const tag_id = safeDeepGet(action, ['payload', 'id'], '');
    const body = {
      id: tag_id,
    };
    wsResult = yield call(delete_Tags, app_id, body);
    const code = safeDeepGet(wsResult, 'code', Result.FAILED);

    if (code == Result.SUCCESS || code == Result.HTTP.OK) {
      yield put(result(DELETE, { code: Result.SUCCESS }));
      return;
    }
    yield put(result(DELETE, { code: Result.FAILED }));
  }
  yield put(result(DELETE, { code: Result.FAILED }));
}
function* list(payload) {
  yield put(loading(LIST))
  const firebaseInstance = getInstance()
  let wsResult = yield call(firebaseInstance.mapping.list)
  const code = safeDeepGet(wsResult, 'code', Result.FAILED)
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult,'body', [])
    const app_id = safeDeepGet(payload, 'payload');
    const url = safeDeepGet(mapBody, [app_id, 'url_get_tag'], '');
    // wsResult = yield call(getTags, url)
    wsResult = yield call(tags_serverless, app_id)
    if(safeDeepGet(wsResult, 'code', Result.FAILED) == Result.HTTP.OK){
      const body = safeDeepGet(wsResult, 'body',[])
      yield put(result(LIST, { code: Result.SUCCESS, body: body }))
    }
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }))
}

function* create(action) {
  yield put(loading(CREATE))
  const firebaseInstance = getInstance()
  let wsResult = yield call(firebaseInstance.mapping.list)
  const code = safeDeepGet(wsResult, 'code', Result.FAILED)
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const payload = safeDeepGet(action, ['payload'],{})
    safeDeepSet(payload, 'type', 'Single, CheckBox')
    const mapBody = safeDeepGet(wsResult,'body', [])
    const app_id = safeDeepGet(action, ['payload', 'appId'],{});
    safeDel(payload, 'appId')
    const url = safeDeepGet(mapBody, [app_id, 'url_create_tag'], '');
    // wsResult = yield call(createTags, url,payload)
    wsResult = yield call(create_Tags, app_id, payload)
    const code = safeDeepGet(wsResult, 'code')
    if (code === Result.SUCCESS || code === Result.HTTP.OK) {
      yield put(result(CREATE, { code: Result.SUCCESS }))
    } else {
      yield put(result(CREATE, { code: Result.FAILED }))
    }
  }
}
function* productSaga() {
  yield takeEvery(LIST, list)
  yield takeEvery(DELETE, delete_)
  yield takeEvery(CREATE, create)

}

export default productSaga
