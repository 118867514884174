import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Card, CardBody } from 'reactstrap';

import { APPLICATION_ID } from 'Apps';

class TransactionSummary extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.reports.map((report, key) => (
          <Col sm="4" key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs me-3">
                    {APPLICATION_ID == '1000000209' ? (
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <i className={report.icon} />
                      </span>
                    ) : (
                      <span className="avatar-title-nestle rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <i className={report.icon} />
                      </span>
                    )}
                  </div>
                  <h5 className="font-size-14 mb-0">{report.title}</h5>
                </div>
                <div className="text-muted mt-4">
                  <h4>
                    {report.value} <i className={report.chevron} />
                  </h4>
                  <div className="d-flex">
                    <span
                      className={
                        'badge badge-soft-' + report.color + ' font-size-12'
                      }
                    >
                      {' '}
                      {report.badgeValue}{' '}
                    </span>{' '}
                    <span className="ms-2 text-truncate">{report.desc}</span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

TransactionSummary.propTypes = {
  reports: PropTypes.array,
};

export default TransactionSummary;
