/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import firebase from 'firebase/compat/app';
import { safeDeepGet } from 'iotera-base/utility/json';
import Base from './base';

export default class BusinessPoint extends Base {
  constructor(firebase, applicationId) {
    super(firebase, applicationId, 'navigation');
  }


  list = () => {
    return new Promise((resolve, reject) => {
      const map = {};
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            map[doc.id] = doc.data();
          });
          resolve({ code: 0, body: map });
        },
        error => {
          resolve({ code: -1, body: error });
        }
      );
    });
  };
}
