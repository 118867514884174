export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "REFILLER_LIST";
export const LIST_VM = "REFILLER_LIST_VM";
export const INIT_CREATE = "REFILLER_INIT_CREATE";
export const CREATE = "REFILLER_CREATE";
export const READ = "REFILLER_INFO";
export const UPDATE = "REFILLER_UPDATE";
export const STOCK_UPDATE = "STOCK_REFILLER_UPDATE";
export const DELETE = "REFILLER_DELETE";
