/* eslint-disable semi */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/compat/app';
import { doc, getDoc } from 'firebase/firestore';
import {
  forceDeepSet,
  has,
  safeDeepGet,
  safeDeepSet,
  safeDel,
} from 'iotera-base/utility/json';
export default class BusinessPoint {
  constructor(firebase) {
    this.collection = firebase.firestore().collection('business_point');
  }

  list = payload => {
    return new Promise((resolve, reject) => {
      var code = safeDeepGet(payload, 'code');
      var type = safeDeepGet(payload, 'type');
      // var code = 'pt_cahaya_inti_putra_sejahtera'
      // var type = 'distributor';
      if (code == 'all' || code == null) {
        code = null;
      }
      if (type == 'all' || type == null) {
        type = null;
      }
      let query = this.collection;
      if (type || code) {
        query = query.where(type, '==', code);
      }
      const map = {};
      query.orderBy("name").get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            map[doc.id] = doc.data();
          });
          resolve({ code: 0, body: map });
        },
        error => {
          resolve({ code: -1, body: error });
        }
      );
    });
  };

  map = () => {
    return this.list();
  };

  create = (id, payload) => {
    return new Promise((resolve, reject) => {
      const insertedPayload = {};
      for (const key in payload) {
        if (
          key === 'address' ||
          key === 'cp' ||
          key === 'id' ||
          key === 'email' ||
          key === 'name' ||
          key === 'pic' ||
          key === 'milanosn' ||
          key === 'sn'
        ) {
          insertedPayload[key] = payload[key];
        }
      }
      const date = new Date();
      insertedPayload['created_date'] = date.getTime();
      insertedPayload['active_date'] = date.getTime();

      // Add location
      if ('latitude' in payload && 'longitude' in payload) {
        insertedPayload['loc'] = new firebase.firestore.GeoPoint(
          payload['latitude'],
          payload['longitude']
        );
      }

      // Add device id
      insertedPayload['id'] = id;
      insertedPayload['beanCalibration'] = 0;
      insertedPayload['can1Calibration'] = 0;
      insertedPayload['can2Calibration'] = 0;
      insertedPayload['can3Calibration'] = 0;
      insertedPayload['can4Calibration'] = 0;
      this.collection
        .doc(id)
        .set(insertedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };
  updatecalibration = (id, payload) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (
          key === 'beanCalibration' ||
          key === 'can1Calibration' ||
          key === 'can2Calibration' ||
          key === 'can3Calibration' ||
          key === 'can4Calibration' ||
          key === 'canOrder1' ||
          key === 'canOrder2' ||
          key === 'canOrder3' ||
          key === 'canOrder4' ||
          key === 'channel' ||
          key === 'distributor' ||
          key === 'distributormargin' ||
          key === 'partnerfee' ||
          key === 'refiller'
        ) {
          updatedPayload[key] = payload[key];
        }
      }

      this.collection
        .doc(id)
        .set(updatedPayload, { merge: true })
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  read = deviceId => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .where('__name__', '==', deviceId)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };

  update = (id, payload) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (
          key === 'address' ||
          key === 'cp' ||
          key === 'id' ||
          key === 'email' ||
          // key === 'name' ||
          key === 'pic' ||
          key === 'milanosn' ||
          key === 'sn'
        ) {
          updatedPayload[key] = payload[key];
        }
      }
    
      // Add location
      if ('latitude' in payload && 'longitude' in payload) {
        updatedPayload['loc'] = new firebase.firestore.GeoPoint(
          payload['latitude'],
          payload['longitude']
        );
      }

      this.collection
        .doc(id)
        .update(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  delete_ = deviceId => {
    this.collection.doc(deviceId).delete();
  };

  duplicate = (id, newId) => {};
}
