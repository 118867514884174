export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "LANDLORD_LIST";
export const LIST_VM = "LANDLORD_LIST_VM";
export const INIT_CREATE = "LANDLORD_INIT_CREATE";
export const CREATE = "LANDLORD_CREATE";
export const READ = "LANDLORD_INFO";
export const UPDATE = "LANDLORD_UPDATE";
export const STOCK_UPDATE = "STOCK_LANDLORD_UPDATE";
export const DELETE = "LANDLORD_DELETE";
