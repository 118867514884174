/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable no-self-assign */
/* eslint-disable no-redeclare */
/* eslint-disable react/prop-types */
const momenttz = require('moment-timezone');
const moment = require('moment');
import Result from 'iotera-base/result';
import { BarLoader } from 'react-spinners';
import React, { Component } from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import MetaTags from 'react-meta-tags';
import 'flatpickr/dist/themes/material_blue.css';
//Import Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';
import Transaction from '../../../Component/transactionsTable';
import { isEmpty, size } from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import ReactExport from 'react-data-export';
import PropTypes from 'prop-types';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import ReactTooltip from 'react-tooltip';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Table,
  InputGroup,
} from 'reactstrap';
import Select from 'react-select';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import XlsxPopulate from 'xlsx-populate';
import { saveAs } from 'file-saver';
//Import Breadcrumb
// Iotera
import { safeDeepGet } from 'iotera-base/utility/json';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { Link } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import {
  list_business_points,
  list,
  read,
  updateRefund,
  refunded,
  get_snapshot_monthly,
} from '../../store/transaction/actions';
import ButtonDownload, { APPLICATION_ID, buildTitle, PROJECT_HANDLE, REFUND_PERIOD, getTableTransaction, getFormatReport, } from 'Apps';

const paymentOptions = [
  { label: 'All transactions', value: 'all' },
  { label: 'Settlement', value: 'settlement' },
  { label: 'Refund Pending', value: 'refund_pending' },
  { label: 'Timeout', value: 'timeout' },
  { label: 'Cancel', value: 'cancel' },
  { label: 'Failure', value: 'failure' },
  { label: 'Refunded', value: 'refunded' },
  { label: 'Outstanding', value: 'outstanding' },
  { label: 'Credit', value: 'credit' },
  { label: 'Debt', value: 'debt' },
];

const dispenseOptions = [
  { label: 'All dispenses', value: 'all' },
  { label: 'No Dispense', value: 'no_dispense' },
  { label: 'Success', value: 'success' },
  { label: 'Forced Dispense', value: 'forced_dispense' },
  { label: 'No Dispense VM TimeOut', value: 'no_dispense_vm_timeout' },
  {
    label: 'No Dispense Column Unresponsive',
    value: 'no_dispense_column_unresponsive',
  },
  { label: 'No Dispense VM error', value: 'no_dispense_vm_error' },
  { label: '-', value: '-' },
];
const refundOptions = [
  { label: 'No Refund', value: 'all' },
  { label: 'Refund', value: 'refund' },
];
const bankOptions = [
  { label: 'All', value: 'all' },
  { label: 'CASH', value: 'CASH' },
  { label: 'ECR', value: 'ECR' },
  { label: 'RFID', value: 'RFID' },
  { label: 'QRIS-MIDTRANS', value: 'QRIS-MIDTRANS' },
  { label: 'QRIS-MIDTRANS-PARTNER', value: 'QRIS-MIDTRANS-PARTNER' },
  { label: 'QRIS-BANKNOBU', value: 'QRIS-BANKNOBU' },
];
let role = '';
const obj = JSON.parse(localStorage.getItem('role'));
if (obj != null) {
  role = obj;
}
import Pages403 from '../pages-403';

import{ NAVBAR, PERMISION } from 'Apps'
const navbar = NAVBAR
const permision = PERMISION
class TransactionList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.refDatePicker = React.createRef();

    const params = new URLSearchParams(window.location.href.split('?')[1]);
    let name = params.get('device_id');
    let trx = params.get('trx_status');
    let dsp = params.get('dsp_status');
    let bank = params.get('bank_status');
    let start = params.get('dateStart');
    let end = params.get('dateEnd');
    let dateStart = new Date(Number(start));
    let dateEnd = new Date(Number(end));

    let businessPoints = { label: 'All points', value: 'all' };
    let transactionStatus = { value: 'all' };
    let dispenseStatus = { value: 'all' };
    let payment = { value: 'all' };
    if (name != undefined || name != null) {
      businessPoints = { value: name };
    }
    if (trx != undefined || trx != null) {
      transactionStatus = { value: trx };
    }
    if (dsp != undefined || dsp != null) {
      dispenseStatus = { value: dsp };
    }
    if (bank != undefined || bank != null) {
      payment = { value: bank };
    }
    const date = new Date();
    const twoDay = new Date(date.setDate(date.getDate() - REFUND_PERIOD));
    var one_day = 1000 * 60;

    const date2 = new Date();
    const year = date2.getFullYear();
    const month = date2.getMonth();
    const day = date2.getDate();
    let newDate = new Date(year, month, day - REFUND_PERIOD);
    let endDate = new Date();
    let arrDate = [newDate, endDate];
    if (start != undefined || start != null) {
      arrDate = [dateStart, dateEnd];
    } else {
      arrDate = [newDate, endDate];
    }
    const trxColumn = getTableTransaction(this.handleRefundedMidTrans, this.handleDetail)

    this.state = {
      trxColumn,
      list: [],
      loadingData: false,
      modalLoading: false,
      modal: false,
      modalDetail: false,
      products: [],
      trx: [],
      confirm_msg: false,
      // lenghtAllData:0,
      confirm_alert: false,
      bpfilterlist: [],
      dateRange: arrDate,
      role: '',
      anchor: false,
      businessPoints: businessPoints,
      payment: payment,
      transactionStatus: transactionStatus,
      dispenseStatus: dispenseStatus,
    };

    this.handleBusinessPoints = this.handleBusinessPoints.bind(this);
    this.handleTransactionStatus = this.handleTransactionStatus.bind(this);
    this.handlePaymentMethod = this.handlePaymentMethod.bind(this);
    this.handleDispenseStatus = this.handleDispenseStatus.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleRefund = this.handleRefund.bind(this);
    this.handleRefunded = this.handleRefunded.bind(this);
    this.handleRefundedMidTrans = this.handleRefundedMidTrans.bind(this);
    this.handleRefundDate = this.handleRefundDate.bind(this);

    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleDetail = this.handleDetail.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.showToast = this.showToast.bind(this);
    this.toggleDetail = this.toggleDetail.bind(this);
    this.dataChange = this.dataChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }


  dataChange() {
    setTimeout(() => {
      let dataTable = this.node.current.table.props.data;
      const lenghtTable = dataTable.length;
      this.setState({ lenghtTable });
    }, 1000);
  }

  timeConvert(n) {
    var num = n;
    var day = num / 60 / 24;
    var rday = Math.floor(day);
    var hours = (day - rday) * 24;
    var rhours = Math.round(hours);
    return rday + ' Days(s) and ' + rhours + ' hours(s) ';
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  toggleDetail() {
    this.setState(prevState => ({
      modalDetail: !prevState.modalDetail,
    }));
  }
  // untuk ke Payment Server
  handleRefundedMidTrans = arg => {
    const product = arg;
    this.setState({
      products: {
        id: product.detail.order_id,
        amount: product.payment.amount,
        device_id: product.product.device_id,
        name: product.name,
        method: product.payment.method,
      },
    });
    this.toggle();
  };
  handleDetail = arg => {
    const trx = arg;
    // this.toggleDetail();
    this.props.history.push(
      `/transaction/detail/${safeDeepGet(
        trx,
        ['detail', 'order_id'],
        'default'
      )}`
    );
  };
  handleClick() {
    this.setState({
      drp_secondary_sm: !this.state.drp_secondary_sm,
    });
  }
  handleClose = () => {
    this.setState(prevState => ({
      anchor: !prevState.anchor,
    }));
  };
  handleRefundDate = date => {
    if (date) {
      const d = new Date(0, 0, 0, 0, 0, 0, date).toLocaleDateString('en-GB');
      return d;
    }
  };
  showToast() {
    const { isEdit } = this.state;
    var toastType = 'info';
    var message = 'Refund sedang di Proses';
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '10000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  // untuk ke Payment Server
  handleValidRefundSubmit = (e, values) => {
    // this.dataChange();
    const { onRefund } = this.props;
    const { products } = this.state;
    const refunded = {
      ...values,
      id: products.id,
      amount: products.amount,
      device_id: products.device_id,
      name: products.name,
    };
    if (
      products.method == 'QRIS-MIDTRANS' ||
      products.method == 'QRIS-BANKNOBU' ||
      products.method == 'QRIS-MIDTRANS-PARTNER'
    ) {
      onRefund(refunded);
    } else {
      this.handleRefunded(products);
    }
    this.toggle();
    this.componentDidMount();
    this.showToast();
    const payload1 = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      bank: this.state.payment.value,
    };
    this.props.LoadTransactionList(payload1);
  };

  //Untuk Ke  Payemnt Server
  handleRefund = row => {
    // this.dataChange();
    const payload = {
      order_id: safeDeepGet(row, 'id', ''),
      transaction_status: 'refund_pending',
    };
    this.props.RefundTransaction(payload);
      const payload1 = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        bank: this.state.payment.value,
      };
      this.props.LoadTransactionList(payload1);
  };

  // Untuk Ke Firebase
  handleRefunded = row => {
    // this.dataChange();
    if (confirm('Are you sure you want to complete the refund process?')) {
      var time = Date.now();
      const payload = {
        order_id: safeDeepGet(row, 'id', ' '),
        transaction_status: 'refunded',
        refund_time: time,
      };
      this.props.RefundTransaction(payload);
    } else {
      const payload1 = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        bank: this.state.payment.value,
      };
      this.props.LoadTransactionList(payload1);
    }
    const payload1 = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      bank: this.state.payment.value,
    };
    this.props.LoadTransactionList(payload1);
  };

  handleBusinessPoints = businessPoints => {
    // this.dataChange();
    this.setState({ businessPoints, loadingData: true, modalLoading: true });
   
      const payload = {
        businessPoints: businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        bank: this.state.payment.value,
      };
      this.props.LoadTransactionList(payload);
      var searchParams = new URLSearchParams(window.location.search);
      if (businessPoints.value == 'all') {
        searchParams.delete('device_id');
        this.props.history.push({
          pathname: '/transaction/list',
          search: searchParams.toString(),
        });
      } else {
        searchParams.set('device_id', businessPoints.value);
        this.props.history.push({
          pathname: '/transaction/list',
          search: searchParams.toString(),
        });
      }
  };
  handlePaymentMethod = payment => {
    // this.dataChange();
    this.setState({ payment, loadingData: true, modalLoading: true });
   
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        bank: payment.value,
      };
      this.props.LoadTransactionList(payload);
      var searchParams = new URLSearchParams(window.location.search);
      if (payment.value == 'all') {
        searchParams.delete('bank_status');
        this.props.history.push({
          pathname: '/transaction/list',
          search: searchParams.toString(),
        });
      } else {
        searchParams.set('bank_status', payment.value);
        this.props.history.push({
          pathname: '/transaction/list',
          search: searchParams.toString(),
        });
      }
  };

  handleTransactionStatus = transactionStatus => {
    // this.dataChange();
    this.setState({ transactionStatus, loadingData: true, modalLoading: true });
    
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        bank: this.state.payment.value,
      };
      this.props.LoadTransactionList(payload);

      var searchParams = new URLSearchParams(window.location.search);
      if (transactionStatus.value == 'all') {
        searchParams.delete('trx_status');
        this.props.history.push({
          pathname: '/transaction/list',
          search: searchParams.toString(),
        });
      } else {
        searchParams.set('trx_status', transactionStatus.value);
        this.props.history.push({
          pathname: '/transaction/list',
          search: searchParams.toString(),
        });
      }
  };

  handleDispenseStatus = dispenseStatus => {
    this.setState({ dispenseStatus, loadingData: true, modalLoading: true });
    // this.dataChange();

      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: dispenseStatus.value,
        bank: this.state.payment.value,
      };
      this.props.LoadTransactionList(payload);
    

    var searchParams = new URLSearchParams(window.location.search);
    if (dispenseStatus.value == 'all') {
      searchParams.delete('dsp_status');
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('dsp_status', dispenseStatus.value);
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    }
  };

  handleDateRange = (dateRange, dateStr, instance) => {
    // this.dataChange();
    if (dateRange.length < 2) {
      console.log('Ignoring.. only start date');
    } else {
      this.setState({ dateRange, loadingData: true, modalLoading: true });
      this.setState({ loading: true });
      this.setState({ currentPage: 1 });

      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
      };
      this.props.LoadTransactionList(payload);
      var searchParams = new URLSearchParams(window.location.search);
      if (dateRange[0] == null || dateRange[0] == undefined) {
        searchParams.delete('dateStart');
        searchParams.delete('dateEnd');
        this.props.history.push({
          pathname: '/transaction/list',
          search: searchParams.toString(),
        });
      } else {
        searchParams.set('dateStart', dateRange[0].getTime());
        searchParams.set('dateEnd', dateRange[1].getTime());
        this.props.history.push({
          pathname: '/transaction/list',
          search: searchParams.toString(),
        });
      }
    }
  };

  handleSwitch = e => {
    // this.dataChange();
    const value = e.value;
    this.setState({ refundStatus: e });
    if (value == 'refund') {
      if (this.state.SvFranketransactionColumns.length < 6) {
        const date = new Date();
        const twoDay = new Date(date.setDate(date.getDate() - REFUND_PERIOD));
        var one_day = 1000 * 60;
        this.state.SvFranketransactionColumns.push({
          dataField: 'refund',
          isDummyField: true,
          text: 'Refund',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
              'settlement' ? (
                row.time.timestamp >= twoDay ? (
                  <Tippy
                    content={this.timeConvert(
                      Math.ceil((row.time.timestamp - twoDay) / one_day)
                    )}
                  >
                    <button
                      type="button"
                      color="success"
                      className="btn btn-sm btn-rounded btn-success"
                      onClick={() => this.handleRefundedMidTrans(row)}
                    >
                      Refund
                    </button>
                  </Tippy>
                ) : (
                  ' '
                )
              ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
                'refunded' ? (
                <>
                  <p className="mb-0">
                    {this.handleValidRefundDate(row.detail.refund_time)}
                  </p>
                  <p className="mb-1">
                    {this.handleValidTime(row.detail.refund_time)}
                  </p>
                </>
              ) : (
                ' '
              )}
            </>
          ),
        });
      }
      // this.setState({
      //   toggleSwitch: true,
      // });
      // this.state.FranketransactionColumns.pop();
    } else {
      if (this.state.SvFranketransactionColumns.length > 5) {
        this.state.SvFranketransactionColumns.pop();
      }
    }

    //  setTimeout(window.location.reload(false), 5000)
    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      bank: this.state.payment.value,
    };
    this.props.LoadTransactionList(payload);
  };

  componentDidMount() {
    this.setState({ loadingData: true, modalLoading: true });
      this.props.LoadBpFilterList();
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        bank: this.state.payment.value,
      };
      this.props.LoadTransactionList(payload);
      // setTimeout(
      //   function () {
      //     //Start the timer
      //     this.dataChange();
      //   }.bind(this),
      //   1500
      // );
      // setInterval(async () => {
      //   this.props.LoadTransactionList(payload);
      // }, 3000);
    // Load transactions
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    if (list.length < 25) {
      this.setState({ lengthData: list.length });
    } else {
      this.setState({ lengthData: 25 });
    }
  }

  // eslint-daadisable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { orders } = this.props;
    if (!isEmpty(orders) && size(prevProps.orders) !== size(orders)) {
      this.setState({ orders: {}, isEdit: false });
    }

    if (this.props.result !== prevProps.result) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['result', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        this.setState({
          loadingData: false,
          modalLoading: false,
        });
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { orders } = this.props;
    this.setState({
      orders: orders.filter(order =>
        Object.keys(order).some(
          key =>
            typeof order[key] === 'string' &&
            order[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    });
  };

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }));
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  handleDeleteOrder = order => {
    const { onDeleteOrder } = this.props;
    if (order.id !== undefined) {
      onDeleteOrder(order);
      this.onPaginationPageChange(1);
    }
  };

  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };

  handleValidTime = date => {
    const time1 = new Date(date).toLocaleTimeString('en-GB');
    return time1;
  };
  handleValidRefundDate = date => {
    const date1 = new Date(date).toLocaleDateString('en-GB');
    return date1;
  };





  render() {
    // TODO Loading
    const date = new Date();
    const twoDay = new Date(date.setDate(date.getDate() - REFUND_PERIOD));
    var one_day = 1000 * 60;
    const bpfilterlist = safeDeepGet(
      this.props,
      ['bpfilterresult', 'body'],
      []
    );
    const length = localStorage.getItem('lenghtAllData');
    const { role, refundStatus, lenghtAllData } = this.state;

    let {
      dateRange,
      businessPoints,
      transactionStatus,
      dispenseStatus,
      payment,
      loadingData,
    } = this.state;
    let list = [];
    {
      APPLICATION_ID == '1000000159' || APPLICATION_ID == '1000000211' || APPLICATION_ID == '1000000226' 
        ? role == 'refiller'
          ? []
          : (list = safeDeepGet(this.props, ['result', 'body'], []))
        : (list = safeDeepGet(this.props, ['result', 'body'], []));
    }
    // const list = safeDeepGet(this.props, ['result', 'body'], []);

    //pagination customization
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(Order),
      custom: true,

      onPageChange: (page, sizePerPage) => {
        this.dataChange();
      },
    };

    const lengthData = list.length;
    // setTimeout(() => {
    //   if (list.length != 0) {
    //     let dataTable = this.node.current.table.props.data;
    //     lenghtTable = dataTable.length;
    //     if (this.state.lenghtTable == undefined) {
    //       this.setState({ lenghtTable });
    //       console.log('test');
    //     }
    //   }
    // }, 1500);
    const defaultSorted = [
      {
        dataField: 'id',
        order: 'desc',
      },
    ];
    const txtRole = localStorage.getItem('role');
    const report_list = getFormatReport(list);
    let report_filename = '';
    let bp_name = businessPoints.value ? businessPoints.label : businessPoints;
    var endDate = new Date();
    var startDate = new Date();
    if (dateRange[1] == null) {
     
      startDate.setDate(1);
      report_filename =
        PROJECT_HANDLE +
        '_Trx ' +
        bp_name +
        ' ' +
        startDate.toLocaleDateString('en-GB') +
        ' ke ' +
        endDate.toLocaleDateString('en-GB') + 
        ' ' + txtRole
    } else {
      report_filename =
        PROJECT_HANDLE +
        '_Trx ' +
        bp_name +
        ' ' +
        dateRange[0].toLocaleDateString('en-GB') +
        ' ke ' +
        endDate.toLocaleDateString('en-GB') + 
        ' ' + txtRole
    }
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    let name = params.get('device_id');
    let trx = params.get('trx_status');
    if (name != undefined || name != null) {
      businessPoints = name;
    } else {
      businessPoints = businessPoints.value;
    }
    if (trx != undefined || trx != null) {
      transactionStatus = trx;
    } else {
      transactionStatus = transactionStatus.value;
    }
    let dsp = params.get('dsp_status');
    let bank = params.get('bank_status');
    if (dsp != undefined || dsp != null) {
      dispenseStatus = dsp;
    } else {
      dispenseStatus = dispenseStatus.value;
    }
    if (bank != undefined || bank != null) {
      payment = bank;
    } else {
      payment = payment.value;
    }
    let start = params.get('dateStart');
    let end = params.get('dateEnd');
    let dateStart = new Date(Number(start));
    let dateEnd = new Date(Number(end));
    if (start != undefined || start != null) {
      dateRange = [dateStart, dateEnd];
    } else {
      dateRange = dateRange;
    }

    const obj = JSON.parse(localStorage.getItem('role'));
    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          
            <MetaTags>
            <title>{buildTitle("Transaction")}</title>
            </MetaTags>
       
            <Container fluid wait={3000}>
              <Breadcrumbs title="Transaction" breadcrumbItem="List" />
              <Row>
                <Col xs="12">
                  {/* {this.state.code === 'rfid' ? (
                <TransactionRFID />
              ) : (
                <Transaction />
              )} */}
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions || [])}
                        keyField="id"
                        columns={
                          this.state.trxColumn
                        }
                        data={list}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={list}
                            columns={
                              this.state.trxColumn
                            }
                            bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="2">
                                    <FormGroup className="mb-3 ajax-select select2-container">
                                      <Select
                                        defaultValue={bpfilterlist[0]}
                                        value={bpfilterlist.filter(function (
                                          option
                                        ) {
                                          return (
                                            option.value === businessPoints
                                          );
                                        })}
                                        onChange={this.handleBusinessPoints}
                                        options={bpfilterlist}
                                        classNamePrefix="select2-selection"
                                        isLoading={false}
                                        placeholder="Business Point"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col sm="2">
                                    <FormGroup className="mb-3 ajax-select select2-container">
                                      <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={paymentOptions[0]}
                                        isDisabled={safeDeepGet(navbar, ['Transaction', 'settlement'], 'off') == 'on'}
                                        isSearchable={false}
                                        onChange={this.handleTransactionStatus}
                                        value={paymentOptions.filter(function (
                                          option
                                        ) {
                                          return (
                                            option.value === transactionStatus
                                          );
                                        })}
                                        name="color"
                                        options={paymentOptions}
                                        placeholder="Payment Status"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col sm="2">
                                    <FormGroup className="mb-3 ajax-select select2-container">
                                      <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={dispenseOptions[0]}
                                        isSearchable={false}
                                        onChange={this.handleDispenseStatus}
                                        value={dispenseOptions.filter(function (
                                          option
                                        ) {
                                          return (
                                            option.value === dispenseStatus
                                          );
                                        })}
                                        name="color"
                                        options={dispenseOptions}
                                        placeholder="Dispense Status"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col sm="4">
                                    <Row>
                                      <Col sm="4">
                                        <Label className="col-md-12 col-form-label text-sm-end">
                                          Date Range
                                        </Label>
                                      </Col>
                                      <Col sm="8">
                                        <FormGroup className="mb-3 ajax-select select2-container">
                                          <InputGroup>
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="dd M,yyyy"
                                              options={{
                                                maxDate: new Date(),
                                                mode: 'range',
                                                dateFormat: 'Y-m-d',
                                              }}
                                              value={dateRange}
                                              onChange={this.handleDateRange}
                                              ref={this.refDatePicker}
                                            />
                                            {/* <Button
                                type="reset"
                                color="secondary"
                                onClick={this.handleClearDateRange}
                              >
                                Clear
                              </Button> */}
                                          </InputGroup>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col sm="2" align="center">
                                    <ButtonDownload list={list} report_list={report_list} report_filename={report_filename} txtRole={txtRole}
                                    selectedMonthYear={this.state.selectedMonthYear} 
                                    businessPoints={businessPoints} 
                                    dateRange={dateRange}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-2">
                                  <Col sm="2">
                                    <FormGroup className="mb-3 ajax-select select2-container">
                                      <Select
                                        defaultValue={bankOptions[0]}
                                        value={bankOptions.filter(function (
                                          option
                                        ) {
                                          return option.value === payment;
                                        })}
                                        onChange={this.handlePaymentMethod}
                                        options={bankOptions}
                                        classNamePrefix="select2-selection"
                                        isLoading={false}
                                        placeholder="Payment Method"
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col sm="3">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          // placeholder="Order ID"
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="3"></Col>
                                  <Col sm="4" className="mt-2" align="end">
                                    <p style={{ fontSize: 13 }}>
                                      Menampilkan{' '}
                                      {safeDeepGet(
                                        this.state,
                                        'lenghtTable',
                                        list.length < 25 ? list.length : 25
                                      )}{' '}
                                      dari {lengthData} transaksi
                                      {/* Menampilkan {dataTable} dari {lengthData}{' '}
                                      transaksi */}
                                      {/* Menampilkan {lengthData} dari {this.state.list}{' '}
                                      transaksi */}
                                    </p>
                                  </Col>
                                </Row>
                                <div className="table-responsive">
                                  <ReactTooltip place="top" effect="solid" />

                                  <BootstrapTable
                                    // onDataSizeChange={this.dataChange}
                                    // onTableChange={props =>this.dataChange(props)}
                                    // onDataSizeChange= {props => this.dataChange(props.dataSize)}
                                    // onTableChange={(newState)=> this.dataChange(newState)}
                                    onTableChange={this.handleTableChange}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    responsive
                                    defaultSorted={defaultSorted}
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      'table align-middle table-nowrap table-check'
                                    }
                                    headerWrapperClasses={'table-light'}
                                    ref={this.node}
                                  />
                                </div>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                      <Modal
                        size="xl"
                        isOpen={this.state.modalDetail}
                        className={this.props.className}
                        toggle={this.toggleDetail}
                      >
                        <div className="modal-header">
                          <h4
                            className="modal-title mt-0"
                            id="exampleModalFullscreenLabel"
                          >
                            {this.state.trx.detail != undefined
                              ? 'Order ID ' + this.state.trx.detail.order_id
                              : null}
                          </h4>
                          <button
                            onClick={() =>
                              this.setState({ modalDetail: false })
                            }
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        {this.state.trx.detail != undefined ? (
                          <ModalBody>
                            {/* <h5 className="mb-2">
                  Product SKU:{' '}
                  <span className="text-primary">
                    {this.state.trx.product.sku}
                  </span>
                </h5> */}
                            <h5 className="mb-4">
                              Business Point :{' '}
                              <span className="text-primary">
                                {this.state.trx.name}
                              </span>
                            </h5>
                            <h5 className="mb-4">
                              Date :{' '}
                              <span className="text-primary">
                                {' '}
                                {this.handleValidDate(
                                  this.state.trx.time.firestore_timestamp
                                )}
                              </span>
                            </h5>

                            <div className="table-responsive mb-2">
                              <Table className="table align-middle table-nowrap">
                                <thead>
                                  <tr>
                                    <th scope="col">Product</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Payment Status</th>
                                    <th scope="col">Dispense Status</th>
                                    <th scope="col">Payment Method</th>
                                    <th scope="col">
                                      {' '}
                                      {this.state.trx.payment.method == 'RFID'
                                        ? 'RFID'
                                        : 'Issuer'}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">
                                      <div>
                                        <h5 className="text-truncate font-size-14">
                                          {APPLICATION_ID == '1000000209'
                                            ? this.state.trx.nameProduct
                                            : this.state.trx.product.sku}
                                        </h5>
                                      </div>
                                    </th>
                                    <td>
                                      <div>
                                        <h5 className="text-truncate font-size-14">
                                          {safeDeepGet(
                                            this.state.trx,
                                            ['detail', 'transaction_status'],
                                            '-'
                                          ) === 'refunded' ? (
                                            <span
                                              style={{ color: 'red' }}
                                              className="mt-1"
                                            >
                                              {'-' +
                                                safeDeepGet(
                                                  this.state.trx,
                                                  ['payment', 'amount'],
                                                  '-'
                                                )}
                                            </span>
                                          ) : (
                                            <span className="mt-1">
                                              {safeDeepGet(
                                                this.state.trx,
                                                ['payment', 'amount'],
                                                '-'
                                              )}
                                            </span>
                                          )}
                                        </h5>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <span
                                          style={{ border: 'none' }}
                                          className={
                                            'font-size-12 badge badge-pill badge-soft-' +
                                            this.state.trx.badgeclass
                                          }
                                          color={this.state.trx.badgeclass}
                                        >
                                          {
                                            this.state.trx.detail
                                              .transaction_status
                                          }
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <span
                                          style={{ border: 'none' }}
                                          className={
                                            'font-size-12 badge badge-pill badge-soft-' +
                                            this.state.trx.badge
                                          }
                                          color={this.state.trx.badge}
                                        >
                                          {
                                            this.state.trx.detail
                                              .dispense_status
                                          }
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <h5 className="text-truncate font-size-14">
                                          {this.state.trx.payment.method}
                                        </h5>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <h5 className="text-truncate font-size-14">
                                          {this.state.trx.payment.method ==
                                          'RFID'
                                            ? this.state.trx.payment.detail.rfid
                                            : this.state.trx.payment.detail
                                                .issuer}
                                        </h5>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                            <div className="mb-4">
                              <Row>
                                <Col sm={1}>
                                  <div className="text-center">
                                    {' '}
                                    {this.state.trx.detail.transaction_status ==
                                    'settlement' ? (
                                      this.state.trx.time.timestamp >=
                                      twoDay ? (
                                        <Button
                                          type="button"
                                          color="success"
                                          className="btn-sm btn-rounded btn-success"
                                          onClick={() =>
                                            this.handleRefundedMidTrans(
                                              this.state.trx
                                            )
                                          }
                                        >
                                          Refund
                                        </Button>
                                      ) : (
                                        ' '
                                      )
                                    ) : this.state.trx.detail
                                        .transaction_status == 'refunded' ? (
                                      <p className="mb-0">Refunded on</p>
                                    ) : (
                                      ' '
                                    )}
                                  </div>
                                </Col>
                                <Col sm={11}>
                                  <div className="text-start">
                                    <p
                                      style={{ fontWeight: 'bold' }}
                                      className="mt-1"
                                    >
                                      {this.state.trx.detail
                                        .transaction_status == 'settlement' ? (
                                        this.state.trx.time.timestamp >=
                                        twoDay ? (
                                          'Time remaining for Refund ' +
                                          this.timeConvert(
                                            Math.ceil(
                                              (this.state.trx.time.timestamp -
                                                twoDay) /
                                                one_day
                                            )
                                          )
                                        ) : (
                                          ' '
                                        )
                                      ) : this.state.trx.detail
                                          .transaction_status == 'refunded' ? (
                                        <>
                                          <p className="mb-0">
                                            {this.handleValidRefundDate(
                                              this.state.trx.detail.refund_time
                                            ) + ' '}{' '}
                                            {this.handleValidTime(
                                              this.state.trx.detail.refund_time
                                            )}
                                          </p>
                                          <p className="mb-1"></p>
                                        </>
                                      ) : (
                                        ' '
                                      )}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </ModalBody>
                        ) : null}
                      </Modal>
                      <Modal
                        size="lg"
                        isOpen={this.state.modal}
                        className={this.props.className}
                      >
                        <ModalHeader toggle={this.toggle} tag="h4">
                          {'Refund Order ID ' + this.state.products.id}
                        </ModalHeader>
                        <ModalBody>
                          <AvForm onValidSubmit={this.handleValidRefundSubmit}>
                            <Row form>
                              <Col className="col-12">
                                <div className="mb-3">
                                  <AvField
                                    name="amount"
                                    label="Jumlah Refund"
                                    type="number"
                                    errorMessage="Invalid amount"
                                    validate={{
                                      required: { value: true },
                                      min: { value: 0.01 },
                                      max: {
                                        value: this.state.products.amount,
                                      },
                                    }}
                                    disabled={true}
                                    value={this.state.products.amount || ''}
                                  />
                                </div>
                                <p>
                                  {'*Batas minimum refund Rp. 0.01.- dan Max Rp. ' +
                                    this.state.products.amount +
                                    '.-'}
                                </p>
                                <p>
                                  {
                                    '**Jika metode pembayaran CASH pastikan sudah melakukan Refund manual ke pelanggan'
                                  }
                                </p>
                                <div className="mb-3">
                                  <AvField
                                    name="reason"
                                    label="Masukan alasan refund.."
                                    type="text"
                                    disabled={false}
                                    value={''}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-end">
                                  <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                    disabled={this.state.products.button}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        </ModalBody>
                      </Modal>
                      <Modal
                        size="md"
                        centered={true}
                        isOpen={this.state.modalLoading11}
                        className={this.props.className}
                        backdrop="static"
                      >
                        <ModalBody>
                          {loadingData ? (
                            <div>
                              <Row
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <h4
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign:'center'
                                  }}
                                >
                                  {'Loading Data....'}
                                </h4>
                                <BarLoader
                                  color="#123abc"
                                  loading={loadingData}
                                  size={150}
                                />
                              </Row>
                            </div>
                          ) : (
                            <></>
                          )}
                        </ModalBody>
                      </Modal>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
        
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  bpfilterresult: safeDeepGet(
    state,
    ['transaction', 'list_business_points'],
    {}
  ),
  result: safeDeepGet(state, ['transaction', 'list'], {}),
  loading: safeDeepGet(state, ['transaction', 'loading'], true),
});
Transaction.propTypes = {
  onRefund: PropTypes.func,
};
const mapDispatchToProps = dispatch => ({
  LoadBpFilterList: () => dispatch(list_business_points()),
  LoadBusinessPointFillter: payload => dispatch(list_business_points(payload)),
  LoadTransactionList: (
    businessPoints,
    transactionStatus,
    dispenseStatus,
    dateRange,
    data
  ) =>
    dispatch(
      list(businessPoints, transactionStatus, dispenseStatus, dateRange, data)
    ),
  ReadTransaction: transactionId => dispatch(read(transactionId)),
  RefundTransaction: payload => dispatch(updateRefund(payload)),
  onRefund: refund => dispatch(refunded(refund)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);
