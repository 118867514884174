/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import MetaTags from 'react-meta-tags'
import 'flatpickr/dist/themes/material_blue.css'
import Flatpickr from 'react-flatpickr'
import { Link } from 'react-router-dom'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  FormGroup,
  Label,
  InputGroup,
} from 'reactstrap'
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator'

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'

import ReactTooltip from 'react-tooltip'
//Import Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb'
// Iotera
import Result from 'iotera-base/result'
import { safeDeepGet } from 'iotera-base/utility/json'

// Redux
import { connect } from 'react-redux'
import { list, list_business_points } from '../../store/alert/actions'
import Select from 'react-select'

import ReactExport from 'react-data-export'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class AlertList extends Component {
  constructor(props) {
    super(props)
    this.node = React.createRef()
    this.refDatePicker = React.createRef()
    let machine = false
    const path = this.props.match.path
    if (path.includes('/machine/')) {
      machine = true
    }
    this.state = {
      bpfilterlist: [],
      businessPoints: 'all',
      dateRange: [null, null],
      transactionStatus: 'all',
      dispenseStatus: 'all',
      machine,
      alertColumn: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => (
            <>{this.handleTimeStamp(row.open_time)}</>
          ),
        },
        {
          text: 'Type',
          dataField: 'type',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p>{row.type}</p>
            </>
          ),
        },
        {
          dataField: 'open_time',
          text: 'Time',
          formatter: (cellContent, row) => (
            <>
              <p>{row.open_time}</p>
            </>
          ),
        },
        {
          dataField: 'notificaion',
          text: 'Notification',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">
                {row.type != 'online'
                  ? row.desc
                  : row.notification_open[0].detail.content.replace(
                      /(<([^>]+)>)/gi,
                      ''
                    )}
                {/* {} */}
                {/* {this.escapeHtml(safeDeepGet(row, ['notification_open',0,'detail','content' ], '-'))} */}
                {/* {} */}
              </p>
            </>
          ),
        },
        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          text: 'Action',
          formatter: (cellContent, row) => (
            <>
              {row.type === 'complaint' ? (
                <div className="d-flex gap-3">
                  <Button
                    className="btn btn-primary"
                    id="edittooltip"
                    onClick={() => this.handleCompleteClick(row)}
                  >
                    Solve
                  </Button>
                </div>
              ) : (
                <p>Automatic Solve</p>
              )}
            </>
          ),
        },
      ],
      machineColumn: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },

        {
          text: 'Business Point',
          dataField: 'name',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.name}
                </Link>
              </h5>
            </>
          ),
        },
        {
          dataField: 'code',
          text: 'Code',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap font-size-14 mb-1">{row.code}</p>
            </>
          ),
        },
        {
          dataField: 'action',
          text: 'Action',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap font-size-14 mb-1">{row.action}</p>
            </>
          ),
        },
        {
          dataField: 'desc',
          text: 'Descriptions',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap font-size-14 mb-1">{row.description}</p>
            </>
          ),
        },
        {
          dataField: 'active',
          text: 'Active',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap font-size-14 mb-1">
                <button
                  style={{ border: 'none' }}
                  className={
                    'font-size-12 badge-soft-' +
                    (row.active ? 'danger' : 'success')
                  }
                  color={row.active ? 'danger' : 'success'}
                >
                  {row.active ? 'Active' : 'Solve'}
                </button>
              </p>
            </>
          ),
        },
        {
          dataField: 'time',
          text: 'Time',
          sort:true,
          formatter: (cellContent, row) => (
            <>
              {/* <p className="text-wrap font-size-14 mb-1">{row.time}</p> */}
              <p className="text-wrap font-size-14 mb-1">{this.handleValidDate(row.time)}</p>
            </>
          ),
        },
      ],
    }
    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleEditClick = this.handleEditClick.bind(this)
    this.handleBusinessPoints = this.handleBusinessPoints.bind(this)
    this.handleDateRange = this.handleDateRange.bind(this)
    this.handleClearDateRange = this.handleClearDateRange.bind(this)
  }
  handleBusinessPoints = businessPoints => {
    this.setState({ businessPoints })
    const payload = {
      businessPoints: businessPoints.value,
      dateRange: this.state.dateRange,
    }
    this.props.LoadAlertList(payload)
  };
  handleValidDate = date => {
    const date1 = new Date(date).toLocaleDateString('en-GB')
    const time1 = new Date(date).toLocaleTimeString('en-GB')
    return date1 + ' ' + time1
    // return date
  };
  componentDidMount() {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    let newDate = new Date(year, month, day - 1)
    let endDate = new Date()
    this.setState({ dateRange: [null, null] })
    // Load business point
    const payload = {
      businessPoints: 'all',
      dateRange: [null, null],
    }
    this.props.LoadAlertList(payload)
    let code = ''
    let type = ''
    const obj = JSON.parse(localStorage.getItem('role'))
    if (obj == null) {
      code = 'all'
      type = 'all'
    } else {
      if (obj.role == 'admin') {
        code = null
        type = null
      } else {
        code = obj.code
        type = obj.role
      }
    }
    const data = { code: code, type: type }

    this.props.LoadBpFilterList(data)
  }

  /* Handle click */
  handleAddClick = arg => {
    this.props.history.push('/product/create')
  };
  handleDateRange = dateRange => {
    if (dateRange.length < 2) {
      console.log('Ignoring.. only start date')
    } else {
      this.setState({ dateRange })
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: dateRange,
      }
      this.props.LoadAlertList(payload)
    }
  };

  handleClearDateRange = () => {
    console.log('dateRange is cleared')
    if (!this.refDatePicker.current?.flatpickr) {
      return
    } else {
      this.setState({ dateRange: [null, null] })
      this.refDatePicker.current.flatpickr.clear()
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: [null, null],
      }
      this.props.LoadAlertList(payload)
    }
  };
  handleEditClick = row => {
    this.props.history.push(
      `/product/edit/${safeDeepGet(row, 'name', 'default')}`
    )
  };

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page)
    }
  };
  format_report = list => {
    const dataSet = []
    let trx_data = {}
    if (list.length > 0) {
      for (const el in list) {
        trx_data = {
          actions: list[el].action,
          active: list[el].active,
          code: list[el].code,
          description: list[el].description,
          device_id: list[el].device_id,
          business_point: list[el].name,
          date: this.handleValidDate(list[el].time),
        }
        dataSet.push(trx_data)
      }
      return dataSet
    }
  };

  //

  render() {
    // TODO Loading
    const bpfilterlist = safeDeepGet(
      this.props,
      ['bpfilterresult', 'body'],
      []
    )
    const code = safeDeepGet(
      this.props,
      ['result', 'code'],
      Result.UNKNOWN_ERROR
    )
    let list = []
    if (this.state.machine) {
      list = safeDeepGet(this.props, ['result', 'machine'], [])
    } else {
      list = safeDeepGet(this.props, ['result', 'online'], [])
    }
    const report_list = this.format_report(list)
    const { businessPoints, dateRange } = this.state
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    }

    const defaultSorted = [
      {
        dataField: 'time', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ]

    let report_filename = ''
    let bp_name = businessPoints.value ? businessPoints.label : businessPoints
    var startDate = new Date()
    report_filename = 'Alert ' + bp_name + ' Sampai ' + startDate.getDate() + '-' + (startDate.getMonth()+1) + '-' + startDate.getFullYear()  

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Alert List | Nestle Milano Machine</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Alert" breadcrumbItem="List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={
                        this.state.machine
                          ? this.state.machineColumn
                          : this.state.alertColumn
                      }
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={
                            this.state.machine
                              ? this.state.machineColumn
                              : this.state.alertColumn
                          }
                          data={list}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="2">
                                  <FormGroup className="mb-3 ajax-select select2-container"></FormGroup>
                                </Col>

                                <Col sm="8">
                                  <Row className="m-auto text-sm-end"></Row>
                                </Col>
                                <Col
                                  sm="2"
                                  // className="d-none d-lg-block"
                                  align="center"
                                >
                                  {this.state.machine ? (
                                    <div className="clearfix mt-4 mt-lg-0">
                                      <ExcelFile
                                        filename={report_filename}
                                        element={
                                          <button
                                            type="button"
                                            className="btn btn-nestle white w-sm"
                                          >
                                            <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                                            Download Excel
                                          </button>
                                        }
                                      >
                                        <ExcelSheet
                                          data={report_list}
                                          name="Alert List"
                                        >
                                          <ExcelColumn
                                            label="Device Id"
                                            value="device_id"
                                          />
                                          <ExcelColumn
                                            label="Business Point"
                                            value="business_point"
                                          />
                                          <ExcelColumn
                                            label="Date"
                                            value="date"
                                          />
                                          <ExcelColumn
                                            label="Code Error"
                                            value="code"
                                          />
                                          <ExcelColumn
                                            label="Description"
                                            value="description"
                                          />
                                          <ExcelColumn
                                            label="Actions"
                                            value="actions"
                                          />
                                          <ExcelColumn
                                            label="Active"
                                            value="active"
                                          />
                                        </ExcelSheet>
                                      </ExcelFile>
                                    </div>
                                  ) : null}
                                </Col>
                              </Row>
                              <div className="table-responsive">
                                <ReactTooltip place="top" effect="solid" />
                                <BootstrapTable    
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  defaultSorted={defaultSorted}
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    'table align-middle table-nowrap table-check'
                                  }
                                  headerWrapperClasses={'table-light'}
                                  ref={this.node}
                                />
                              </div>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['alert', 'list'], {}),
  loading: safeDeepGet(state, ['alert', 'loading'], true),
  bpfilterresult: safeDeepGet(state, ['alert', 'list_business_points'], {}),
})

const mapDispatchToProps = dispatch => ({
  LoadAlertList: (businessPoints, dateRange) =>
    dispatch(list(businessPoints, dateRange)),
  LoadBpFilterList: payload => dispatch(list_business_points(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AlertList)
