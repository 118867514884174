/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  Label,
  ModalBody,
} from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Tippy
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
//Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { list, update, delete_ } from '../../store/refiller/actions';
import { buildTitle } from 'Apps';

class RefillerList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      modal: false,
      refiller: [],
      RefillerListColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          text: 'Name',
          dataField: 'name',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.name}
                </Link>
              </h5>
            </>
          ),
        },
        {
          dataField: 'code',
          text: 'Code',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">{row.code}</p>
            </>
          ),
        },
        {
          dataField: 'telegram',
          text: 'Telegram Group',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">{row.telegram_group}</p>
            </>
          ),
        },
        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          text: 'Action',
          formatter: (cellContent, refiller) => (
            <div className="d-flex gap-3">
              <Tippy content={'Edit'}>
                <Link className="text-success" to="#">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={() => this.handleEditClick(refiller)}
                  ></i>
                </Link>
              </Tippy>
              <Tippy content={'Delete'}>
                <Link className="text-danger" to="#">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={() => this.handleDeleteClick(refiller)}
                  ></i>
                </Link>
              </Tippy>
            </div>
          ),
        },
      ],
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.showToast = this.showToast.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  componentDidMount() {
    // Load business point
    this.props.LoadRefillerList();
  }
  handleValidSubmit = (e, values) => {
    const { UpdateRefiller } = this.props;
    const { refiller } = this.state;
    const data = {
      id: refiller.id,
      name: values.name,
      code: values.code,
      telegram_group: values.telegram_group,
    };
    UpdateRefiller(data);
    this.props.LoadRefillerList();

    this.toggle();
  };
  /* Handle click */
  handleAddClick = arg => {
    this.props.history.push('/refill/create');
  };

  handleEditClick = row => {
    const refill = row;

    this.setState({
      refiller: {
        id: refill.id,
        code: refill.code,
        name: refill.name,
        telegram_group: refill.telegram_group,
      },
    });
    this.toggle();
  };
  handleDeleteClick = row => {
    this.props.DeleteRefiller(row);
    this.props.LoadRefillerList();
    this.showToast();
  };
  showToast() {
    var toastType = 'success';
    var message = 'Berhasil Delete Refiller';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    const code = safeDeepGet(
      this.props,
      ['result', 'code'],
      Result.UNKNOWN_ERROR
    );
    const list = safeDeepGet(this.props, ['result', 'body'], []);

    const { SearchBar } = Search;

    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];

    const selectRow = {
      mode: 'checkbox',
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Refiller List')}</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Refiller" breadcrumbItem="List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.RefillerListColumns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.RefillerListColumns}
                          data={list}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={this.handleAddClick}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create New Refiller
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable    
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        'table align-middle table-nowrap table-hover'
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Modal
                      size="lg"
                      isOpen={this.state.modal}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle} tag="h4">
                        {'Ubah Data Refiller ' + this.state.refiller.name}
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleValidSubmit}>
                          <Row form>
                            <Col className="col-12">
                              <div className="mb-3">
                                <Label className="mt-1" htmlFor="code">
                                  Code Refiller:
                                </Label>
                                <AvField
                                  type="text"
                                  id="code"
                                  name="code"
                                  value={this.state.refiller.code || ''}
                                  placeholder="Masukan Code..."
                                  className="form-control"
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </div>
                              <div className="mb-3">
                                <Label htmlFor="name">Name : </Label>
                                <AvField
                                  value={this.state.refiller.name || ''}
                                  type="text"
                                  id="name"
                                  name="name"
                                  placeholder="Masukan Nama..."
                                  className="form-control"
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </div>

                              <div className="mb-3">
                                <Label
                                  className="mt-1"
                                  htmlFor="telegram_group"
                                >
                                  Telegram Group:
                                </Label>
                                <AvField
                                  type="text"
                                  id="telegram_group"
                                  value={
                                    this.state.refiller.telegram_group || ''
                                  }
                                  name="telegram_group"
                                  placeholder="Masukan Nomor Telegram Group..."
                                  errorMessage="Masukkan Angka saja"
                                  className="form-control"
                                  min="0"
                                  validate={{
                                    required: { value: true },
                                    pattern: {
                                      value: '^[0-9]+$',
                                    },
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-success save-user"
                                >
                                  Submit
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['refiller', 'list'], {}),
  loading: safeDeepGet(state, ['refiller', 'loading'], true),
  updateResult: safeDeepGet(state, ['refiller', 'update'], {}),
});

const mapDispatchToProps = dispatch => ({
  LoadRefillerList: () => dispatch(list()),
  UpdateRefiller: payload => dispatch(update(payload)),
  DeleteRefiller: payload => dispatch(delete_(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefillerList);
