import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Tippy
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { list, delete_, discount_product, delete_discount } from '../../store/product/actions';

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      productListColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: 'img_url',
          text: '',
          formatter: (cellContent, row) => (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img className="avatar-md" src={row.imgUrl} />
                </div>
              </div>
            </>
          ),
        },
        // {
        //   dataField: 'sku',
        //   text: 'SKU',
        //   sort: true,
        //   formatter: (cellContent, row) => <>{row.name}</>,
        // },
        {
          text: 'Name',
          dataField: 'name',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.displayName}
                </Link>
              </h5>
            </>
          ),
        },
        {
          dataField: 'desc',
          text: 'Description',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">{row.description}</p>
            </>
          ),
        },
        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          text: 'Action',
          formatter: (cellContent, product) => (
            <div className="d-flex gap-3">
              <Tippy content={'Discount'}>
                <Link className="text-info" to="#">
                  <i
                    className="mdi mdi-cash font-size-18"
                    id="edittooltip"
                    onClick={() => this.handleDiscountClick(product)}
                  ></i>
                </Link>
              </Tippy>
              <Tippy content={'Edit'}>
                <Link className="text-success" to="#">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={() => this.handleEditClick(product)}
                  ></i>
                </Link>
              </Tippy>
              <Tippy content={'Delete'}>
                <Link className="text-danger" to="#">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={() => this.handleDeleteClick(product.name)}
                  ></i>
                </Link>
              </Tippy>
            </div>
          ),
        },
      ],
      products: [],
      modal: false,
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleDiscountClick = this.handleDiscountClick.bind(this);
    this.handleDeleteDiscount = this.handleDeleteDiscount.bind(this);
    this.toggle = this.toggle.bind(this);
    this.showToast = this.showToast.bind(this);
    this.showToastDiscount = this.showToastDiscount.bind(this);
    this.showDeleteDiscount = this.showDeleteDiscount.bind(this);
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  handleDiscountClick = arg => {
    const product = arg;
    this.setState({
      products: {
        id: product.id,
        displayName: product.displayName,
        sku: product.sku,
        name: product.name,
        discountPrice: product.discountPrice,
      },
    });

    this.toggle();
  };
  handleValidStockSubmit = (e, values) => {
    const { onDiscount,LoadProductList } = this.props;
    const { products } = this.state;
    const discount = {
      name: products.name,
      discountPrice: values.discountPrice,
    };

    // update Stock
    onDiscount(discount);
    LoadProductList();
    this.showToastDiscount();
    this.toggle();
  };
  handleDeleteDiscount = (e, values) => {
    const { DeleteDiscount, LoadProductList } = this.props;
    const { products } = this.state;
    const discount = {
      name: products.name,
    };

    // update Stock
    DeleteDiscount(discount);
    LoadProductList();
    this.showDeleteDiscount()
    this.toggle();
  };

  componentDidMount() {
    // Load business point
    this.props.LoadProductList();
  }
  showToast() {
    var toastType = 'success';
    var message = 'Berhasil Delete Product';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastDiscount() {
    var toastType = 'success';
    var message = 'Berhasil Menambahkan Discount';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showDeleteDiscount() {
    var toastType = 'success';
    var message = 'Berhasil Menghapus Discount';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  handleDeleteClick = row => {
    this.props.DeleteProduct(row);
    this.props.LoadProductList();
    this.showToast();
  };
  /* Handle click */
  handleAddClick = arg => {
    this.props.history.push('/product/create');
  };

  handleEditClick = row => {
    this.props.history.push(
      `/product/edit/${safeDeepGet(row, 'name', 'default')}`
    );
  };

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    const code = safeDeepGet(
      this.props,
      ['result', 'code'],
      Result.UNKNOWN_ERROR
    );
    const list = safeDeepGet(this.props, ['result', 'body'], []);

    const { SearchBar } = Search;

    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];

    const selectRow = {
      mode: 'checkbox',
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Product List | Nestle Franke Vending Machine</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Product" breadcrumbItem="List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.productListColumns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.productListColumns}
                          data={list}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      className="font-16 btn-block btn btn-franke"
                                      onClick={this.handleAddClick}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create New Product
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable    
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        'table align-middle table-nowrap table-hover'
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                  </div>
                                  <div>
                                    <Modal
                                      isOpen={this.state.modal}
                                      className={this.props.className}
                                    >
                                      <ModalHeader
                                        toggle={this.toggle}
                                        tag="h4"
                                      >
                                        {'Discount Product ' +
                                          this.state.products.displayName}
                                      </ModalHeader>
                                      <ModalBody>
                                        <AvForm
                                          onValidSubmit={
                                            this.handleValidStockSubmit
                                          }
                                        >
                                          <Row form>
                                            <Col className="col-12">
                                              <div className="mb-3">
                                                <AvField
                                                  name="discountPrice"
                                                  label="Harga Discount"
                                                  type="number"
                                                  errorMessage="Invalid amount"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    this.state.products
                                                      .discountPrice || ''
                                                  }
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <div className="text-end">
                                                <Button
                                                  className="btn btn-danger"
                                                  onClick={this.handleDeleteDiscount}
                                                >
                                                  Delete Discount
                                                </Button> {' '}
                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user"
                                                >
                                                  Submit
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </AvForm>
                                      </ModalBody>
                                    </Modal>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['product', 'list'], {}),
  loading: safeDeepGet(state, ['product', 'loading'], true),
});

const mapDispatchToProps = dispatch => ({
  LoadProductList: () => dispatch(list()),
  DeleteProduct: payload => dispatch(delete_(payload)),
  DeleteDiscount: payload => dispatch(delete_discount(payload)),
  onDiscount: payload => dispatch(discount_product(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
