export const LOADING = "LOADING";
export const RESULT = "RESULT";
export const HISTORY = "BUSINESS_POINT_HISTORY";

export const LIST = "BUSINESS_POINT_LIST";
export const LIST_VM = "BUSINESS_POINT_LIST_VM";
export const SERI_VM = "BUSINESS_POINT_SERI_VM";
export const LIST_PRODUCT = "BUSINESS_POINT_LIST_PRODUCT";
export const INIT_CREATE = "BUSINESS_POINT_INIT_CREATE";
export const CREATE = "BUSINESS_POINT_CREATE";
export const READ_INFO = "BUSINESS_POINT_READ_INFO";
export const UPDATE_INFO = "BUSINESS_POINT_UPDATE_INFO";
export const UPDATE_CALIBRATION = "BUSINESS_POINT_UPDATE_CALIBRATION";
export const READ_PLANOGRAM = "BUSINESS_POINT_READ_PLANOGRAM";
export const ADD_PLANOGRAM = "BUSINESS_POINT_ADD_PLANOGRAM";
export const READ_PRODUCT = "BUSINESS_POINT_READ_PRODUCT";
export const UPDATE_PLANOGRAM = "BUSINESS_POINT_UPDATE_PLANOGRAM";
export const DELETE = "BUSINESS_POINT_DELETE";
export const DELETE_PLANOGRAM = "BUSINESS_POINT_PLANOGRAM_DELETE";
export const TESTCSV = "TESTCSV";
export const INPUT_CSV = "BUSINESS_POINT_INPUT_CSV";
