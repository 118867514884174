import firebase from 'firebase/compat/app';
import { safeDeepGet } from 'iotera-base/utility/json';

export default class Alert {
  constructor(firebase) {
    this.collection = firebase.firestore().collection('alert2');
  }

  list = (payload) => {
    return new Promise((resolve, reject) => {
      var filter_bp = safeDeepGet(payload, "businessPoints");
      var filter_sd = safeDeepGet(payload, "dateRange")[0];
      var filter_ed = safeDeepGet(payload, "dateRange")[1];
      if (filter_bp == "all" || filter_bp == null) {
        filter_bp = null;
      }
      if (filter_sd == "all" || filter_sd == null) {
        filter_sd = null;
      }
      if (filter_ed == "all" || filter_ed == null) {
        filter_ed = null;
      }
      let query = this.collection;
      if (filter_bp) {
        query = query.where("device_id", "==", filter_bp);
      }

      if (filter_sd) {
        var startfulldate = firebase.firestore.Timestamp.fromDate(filter_sd);
        query = query.where(
          "firestoreTimestamp",
          ">=",
          startfulldate
        );
      }

      if (filter_ed) {
        const d = filter_ed;
        d.setDate(d.getDate() + 1);
        var endfulldate = firebase.firestore.Timestamp.fromDate(d);
        query = query.where(
          "firestoreTimestamp",
          "<=",
          endfulldate
        );
      }
      const map = {};
      query.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            map[doc.id] = doc.data();
          });
          resolve({ code: 0, body: map });
        },
        error => {
          resolve({ code: -1, body: error });
        }
      );
    });
  };
}