/* eslint-disable no-case-declarations */
/* eslint-disable semi */
import {
  LOADING,
  RESULT,
  LIST_BUSINESS_POINTS,
  LIST,
  NEXT_LIST,
  UPDATE_REFUND,
  READ,
  REFUND,
  SUMMARY,
  SNAPSHOT,
  DETAIL,
  GET_SALES_ANALYTICS,
  REFUNDED,
  REFUND_LIST,
  SNAPSHOT_DAILY,
  SNAPSHOT_MONTHLY,
  GET_REPORT_GROUP,
  DOWNLOAD_DATA,
  SEARCH_DATA
} from './actionTypes';

const INIT_STATE = {
  loading: true,
};

const Transaction = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case RESULT:
      const newState = {
        ...state,
        loading: false,
      };
      switch (action.payload.actionType) {
        case LIST_BUSINESS_POINTS:
          newState['list_business_points'] = action.payload.result;
          break;
        case SEARCH_DATA:
          newState['search_data'] = action.payload.result;
          break;
        case LIST:
          newState['list'] = action.payload.result;
          break;
        case NEXT_LIST:
          newState['nextList'] = action.payload.result;
          break;
        case GET_REPORT_GROUP:
          newState['get_report_group'] = action.payload.result;
          break;
        case REFUND_LIST:
          newState['refund_list'] = action.payload.result;
          break;
        case DOWNLOAD_DATA:
          newState['download_data'] = action.payload.result;
          break;
        case DETAIL:
          newState['transaction_detail'] = action.payload.result;
          break;
        case READ:
          newState['read'] = action.payload.result;
          break;
        case REFUND:
          newState['refund'] = action.payload.result;
          break;
        case UPDATE_REFUND:
          newState['updateRefund'] = action.payload.result;
          break;
        case REFUNDED:
          newState['refunded'] = action.payload.result;
          break;
        case SUMMARY:
          newState['summary'] = action.payload.result;
          break;
        case SNAPSHOT:
          newState['snapshot'] = action.payload.result;
          break;
        case GET_SALES_ANALYTICS:
          newState['get_sales_analytics'] = action.payload.result;
          break;
        case SNAPSHOT_DAILY:
          newState['get_snapshot_daily'] = action.payload.result;
          break;
        case SNAPSHOT_MONTHLY:
          newState['get_snapshot_monthly'] = action.payload.result;
          break;
      }
      return newState;

    default:
      return state;
  }
};

export default Transaction;
