/* eslint-disable no-self-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Table,
  InputGroup,
} from 'reactstrap';
import Select from 'react-select';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
//Import Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';
// Tippy
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
// Redux
import { connect } from 'react-redux';
import { list } from '../../store/chat/actions';
import { list_business_points } from '../../store/transaction/actions';
import { REFUND_PERIOD, PROJECT_HANDLE, NAME_PROJECT } from 'Apps';
const refundOptions = [
  { label: 'All dispenses', value: 'all' },
  { label: 'Not refunded', value: true },
  { label: 'Refunded', value: false },
];
import Pages403 from '../pages-403';

import{ NAVBAR, PERMISION } from 'Apps';
const navbar = NAVBAR
const permision = PERMISION
class ChatList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.refDatePicker = React.createRef();
    const date2 = new Date();
    const year = date2.getFullYear();
    const month = date2.getMonth();
    const day = date2.getDate();
    let newDate = new Date(year, month, day - REFUND_PERIOD);
    let endDate = new Date();
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    let name = params.get('device_id');
    let refund = params.get('refund_status');
    let start = params.get('dateStart');
    let end = params.get('dateEnd');
    let dateStart = new Date(Number(start));
    let dateEnd = new Date(Number(end));
    let businessPoints = { label: 'All points' };
    let refundStatus = { value: 'all' };
    let arrDate = [newDate, endDate];
    if (name != undefined || name != null) {
      businessPoints = { label: name };
    }
    if (refund != undefined || refund != null) {
      refundStatus = { value: refund };
    }
    if (start != undefined || start != null) {
      arrDate = [dateStart, dateEnd];
    } else {
      arrDate = [newDate, endDate];
    }
    this.state = {
      businessPoints: businessPoints,
      payment: 'all',
      refundStatus: refundStatus,
      dispenseStatus: 'all',
      bpfilterlist: [],
      dateRange: arrDate,

      chatListColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          searchable: false,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: 'order_id',
          text: 'Order Id',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <Link
                to={
                  '/transaction/chat/detail/' +
                  safeDeepGet(row, 'order_id')
                }
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                }}
                className="mb-2"
              >
                {safeDeepGet(row,  'order_id')}
              </Link>
              
            </>
          ),
        },
        {
          dataField: 'date',
          text: 'Request Date',
          sort: true,
          formatter: (cellContent, row) => (
            <p className="mb-1">{this.handleValidDate(row.created_date)}</p>
          ),
        },
        {
          text: 'Business Point',
          dataField: 'business_point',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.business_point}
                </Link>
              </p>
            </>
          ),
        },
        {
          dataField: 'mobile_number',
          text: 'Mobile Phone',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p
                style={{ textDecorationLine: 'underline' }}
                className="mb-1"
                onClick={() => this.handleSendWA(row)}
              >
                {row.mobile_number}
              </p>
            </>
          ),
        },
        {
          dataField: 'opeimg_url',
          text: 'Open Image',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <a
                href={row.img_url}
                target="_blank" rel="noreferrer"
              >
                <img className="avatar-md" src={row.img_url} />
              </a>
            </>
          ),
        },

        {
          dataField: 'active',
          text: 'Status Refund',
          searchable: false,
          formatter: (cellContent, row) => (
            <Badge
              className={
                'font-size-12 badge-' + (row.active ? 'danger' : 'success')
              }
              color={row.active ? 'danger' : 'success'}
              pill
            >
              {row.active ? 'Not refunded' : 'Refunded'}
            </Badge>
          ),
        },
      ],
    };
    this.showToast = this.showToast.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleBusinessPoints = this.handleBusinessPoints.bind(this);
    this.handleRefundStatus = this.handleRefundStatus.bind(this);
  }
  handleSendWA = arg => {
    const trx = arg;
    const mobile_number = safeDeepGet(trx, 'mobile_number', 0);
    const txt = mobile_number.toString();
    const sbtr =  txt.substring(1, txt.length);
    window.open('https://wa.me/'+sbtr);
  };
  handleBusinessPoints = businessPoints => {
    this.setState({ businessPoints });
    const payload = {
      businessPoints: businessPoints.label,
      dateRange: this.state.dateRange,
      refundStatus: this.state.refundStatus.value,
    };
    this.props.LoadChatList(payload);
    var searchParams = new URLSearchParams(window.location.search);
    if (businessPoints.value == 'all') {
      searchParams.delete('device_id');
      this.props.history.push({
        pathname: '/transaction/chat',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('device_id', businessPoints.label);
      this.props.history.push({
        pathname: '/transaction/chat',
        search: searchParams.toString(),
      });
    }
  };
  handleRefundStatus = refundStatus => {
    this.setState({ refundStatus });
    const payload = {
      businessPoints: this.state.businessPoints.label,
      dateRange: this.state.dateRange,
      refundStatus: refundStatus.value,
    };
    this.props.LoadChatList(payload);
    var searchParams = new URLSearchParams(window.location.search);
    if (refundStatus.value == 'all') {
      searchParams.delete('refund_status');
      this.props.history.push({
        pathname: '/transaction/chat',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('refund_status', refundStatus.value);
      this.props.history.push({
        pathname: '/transaction/chat',
        search: searchParams.toString(),
      });
    }
  };
  componentDidMount() {
    // Load business point
    this.props.LoadBpFilterList();
    const payload = {
      businessPoints: this.state.businessPoints.label,
      refundStatus: this.state.refundStatus.value,
      dateRange: this.state.dateRange,
    };
    this.props.LoadChatList(payload);
  }
  handleDateRange = dateRange => {
    if (dateRange.length < 2) {
      console.log('Ignoring.. only start date');
    } else {
      this.setState({ dateRange });
      const payload = {
        businessPoints: this.state.businessPoints.label,
        refundStatus: this.state.refundStatus.value,
        dateRange: dateRange,
      };
      this.props.LoadChatList(payload);
      var searchParams = new URLSearchParams(window.location.search);
      if (dateRange[0] == null || dateRange[0] == undefined) {
        searchParams.delete('dateStart');
        searchParams.delete('dateEnd');
        this.props.history.push({
          pathname: '/transaction/chat',
          search: searchParams.toString(),
        });
      } else {
        searchParams.set('dateStart', dateRange[0].getTime());
        searchParams.set('dateEnd', (dateRange[1].getTime() - 1000));
        this.props.history.push({
          pathname: '/transaction/chat',
          search: searchParams.toString(),
        });
      }
    }
  };
  handleImage = arg => {
    const trx = arg;
    this.setState({
      trx,
    });
    // this.toggleDetail();
    window.open(safeDeepGet(trx, 'img_url', 'default'));
  };
  handleDetail = arg => {
    const trx = arg;
    this.setState({
      trx,
    });
    // this.toggleDetail();
    this.props.history.push(
      `/refund/detail/${safeDeepGet(trx, 'order_id', 'default')}`
    );
  };
  showToast() {
    var toastType = 'success';
    var message = 'Berhasil Delete Product';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
    window.location.reload();
  }

  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    let { dateRange, businessPoints, refundStatus } = this.state;

    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const { SearchBar } = Search;
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'order_id', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];

    const bpfilterlist = safeDeepGet(
      this.props,
      ['bpfilterresult', 'body'],
      []
    );

    const params = new URLSearchParams(window.location.href.split('?')[1]);
    let name = params.get('device_id');
    let refund = params.get('refund_status');
    let start = params.get('dateStart');
    let end = params.get('dateEnd');
    let dateStart = new Date(Number(start));
    let dateEnd = new Date(Number(end));
    if (start != undefined || start != null) {
      dateRange = [dateStart, dateEnd];
    } else {
      dateRange = dateRange;
    }
    if (name != undefined || name != null) {
      businessPoints = name;
    } else {
      businessPoints = businessPoints.label;
    }
    if (refund != undefined || refund != null) {
      refundStatus = refund;
    } else {
      refundStatus = refundStatus.value;
    }
    return (
      <React.Fragment>
        <div className="page-content">
      
            <MetaTags>
              <title>Chat List | {NAME_PROJECT} Vending Machine</title>
            </MetaTags>
              {!permision || safeDeepGet(navbar, ['Transaction', 'view'], 'off') == 'off' ? (
            <Pages403 />
          ) : (
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Chat" breadcrumbItem="List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="order_id"
                      columns={this.state.chatListColumns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="order_id"
                          columns={this.state.chatListColumns}
                          data={list}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="2">
                                  <FormGroup className="mb-3 ajax-select select2-container">
                                    <Select
                                      defaultValue={bpfilterlist[0]}
                                      value={bpfilterlist.filter(function (
                                        option
                                      ) {
                                        return option.label === businessPoints;
                                      })}
                                      onChange={this.handleBusinessPoints}
                                      options={bpfilterlist}
                                      classNamePrefix="select2-selection"
                                      isLoading={false}
                                      placeholder="Business Point"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm="2">
                                  <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    defaultValue={refundOptions[0]}
                                    isSearchable={false}
                                    onChange={this.handleRefundStatus}
                                    value={refundOptions.filter(function (
                                      option
                                    ) {
                                      return option.value === refundStatus;
                                    })}
                                    name="color"
                                    options={refundOptions}
                                    placeholder="Refund Status"
                                  />
                                </Col>
                                <Col sm="2"></Col>
                                <Col sm="2"></Col>
                                <Col sm="4">
                                  <Row>
                                    <Col sm="4">
                                      <Label className="col-md-12 col-form-label text-sm-end">
                                        Date Range
                                      </Label>
                                    </Col>
                                    <Col sm="8">
                                      <InputGroup>
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="dd M,yyyy"
                                          options={{
                                            maxDate: new Date(),
                                            mode: 'range',
                                            dateFormat: 'Y-m-d',
                                          }}
                                          value={dateRange}
                                          onChange={this.handleDateRange}
                                          ref={this.refDatePicker}
                                        />
                                        {/* <Button
                                  type="reset"
                                  color="secondary"
                                  onClick={this.handleClearDateRange}
                                >
                                  Clear
                                </Button> */}
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable    
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        'table align-middle table-nowrap table-hover'
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['chat', 'list'], {}),
  loading: safeDeepGet(state, ['chat', 'loading'], true),
  bpfilterresult: safeDeepGet(
    state,
    ['transaction', 'list_business_points'],
    {}
  ),
});

const mapDispatchToProps = dispatch => ({
  LoadChatList: payload => dispatch(list(payload)),
  LoadBpFilterList: () => dispatch(list_business_points()),
  // DeleteProduct: payload => dispatch(delete_(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatList);
