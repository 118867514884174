import { call, put, takeEvery } from 'redux-saga/effects';
import { App_ID } from 'Apps';
// Product state
import {
  LIST,DETAIL_CHAT
} from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import { JGET, JPOST } from 'iotera-base/webservice/wsrequest';
import Result from 'iotera-base/result';
function* list(actions) {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.chat.list, actions);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const chatBody = safeDeepGet(wsResult, 'body', {});
    for (const id in chatBody) {
      safeDeepSet(chatBody, [id, 'order_id'], id);
    }
    const chatList = []
    for(const id in chatBody){
        chatList.push(chatBody[id])
    }    
    
    yield put(result(LIST, { code: Result.SUCCESS, body: chatList }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}

function* detailChat(actions) {
  yield put(loading(DETAIL_CHAT));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.chat.detail, actions);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const chatBody = safeDeepGet(wsResult, 'body', {});
    for (const id in chatBody) {
      safeDeepSet(chatBody, [id, 'order_id'], id);
    }
    const chatList = []
    for(const id in chatBody){
        chatList.push(chatBody[id])
    }    
    
    yield put(result(DETAIL_CHAT, { code: Result.SUCCESS, body: chatList }));
    return;
  }
  yield put(result(DETAIL_CHAT, { result: { code: Result.FAILED } }));
}
function* payoutSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(DETAIL_CHAT, detailChat);
}

export default payoutSaga;
