/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Availity
import { AvField, AvForm } from 'availity-reactstrap-validation';

// Select
import Select from 'react-select';

// Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, isEmpty } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import {
  initCreate,
  create,
  readInfo,
  updatecalibration,
} from '../../store/business-point/actions';
import { list_vm } from '../../store/refiller/actions';
import { list_distributor } from '../../store/distributor/actions';
import { list_options } from '../../store/ingredient/actions';
 
import QRCode from 'qrcode.react';
import { PROJECT_HANDLE } from 'Apps';

class BusinessPointInfo extends Component {
  constructor(props) {
    super(props);

    let isEdit = false;
    let deviceId = null;

    const path = this.props.match.path;
    if (path.includes('/options/')) {
      isEdit = true;
      deviceId = safeDeepGet(this.props.match.params, 'id');
    }

    this.state = {
      isEdit,
      deviceId,
    };

    this.handelChangecanOrder1 = this.handelChangecanOrder1.bind(this);
    this.handelChangecanOrder2 = this.handelChangecanOrder2.bind(this);
    this.handelChangecanOrder3 = this.handelChangecanOrder3.bind(this);
    this.handelChangecanOrder4 = this.handelChangecanOrder4.bind(this);
    this.handelChangeRefiller = this.handelChangeRefiller.bind(this);
    this.handelChangeDistributor = this.handelChangeDistributor.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleCancleClick = this.handleCancleClick.bind(this);
    this.showToast = this.showToast.bind(this);
    this.showWarning = this.showWarning.bind(this);
  }

  componentDidMount() {
    const { isEdit } = this.state;
    // Load info
    if (isEdit) {
      this.props.ReadBusinessPointInfo(this.state.deviceId);
      this.props.ListRefiller();
      this.props.ListDistributor();
      this.props.ListIngredient();
    } else {
      this.props.InitBusinessPointInfoCreate();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isEdit } = this.state;

    if (!isEdit) {
      if (this.props.initCreateResult !== prevProps.initCreateResult) {
        // Insert value of VM select
        const code = safeDeepGet(
          this.props,
          ['initCreateResult', 'code'],
          Result.UNKNOWN_ERROR
        );
        if (code == Result.SUCCESS) {
          const vmOptions = safeDeepGet(
            this.props,
            ['initCreateResult', 'body', 'unregistered_devices'],
            []
          );

          if (vmOptions.length > 0) {
            const selectedVM = vmOptions[0];
            this.setState({ selectedVM });
          }
        }
      } else if (this.props.createResult !== prevProps.createResult) {
        // Success create
        const code = safeDeepGet(
          this.props,
          ['createResult', 'code'],
          Result.UNKNOWN_ERROR
        );

        if (code === Result.SUCCESS) {
          this.props.history.replace('/business-point/list');
        }
      }
    } else {
      if (this.props.readInfoResult !== prevProps.readInfoResult) {
        // Insert value of VM select
        const code = safeDeepGet(
          this.props,
          ['readInfoResult', 'code'],
          Result.UNKNOWN_ERROR
        );
        if (code == Result.SUCCESS) {
          const vmOptions = safeDeepGet(
            this.props,
            ['readInfoResult', 'body', 'registered_devices'],
            []
          );
          let deviceId = null;
          const newState = {};
          {
            PROJECT_HANDLE == 'Nestle' ||
            PROJECT_HANDLE == 'Franke' ||
            PROJECT_HANDLE == 'Svfranke' ||
            PROJECT_HANDLE == 'Svnestle'
              ? (deviceId = safeDeepGet(this.props, [
                  'readInfoResult',
                  'body',
                  'info',
                  'id',
                ]))
              : (deviceId = safeDeepGet(this.props, [
                  'readInfoResult',
                  'body',
                  'info',
                  'device_id',
                ]));
          }

          const beanCalibration = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'beanCalibration',
          ]);
          newState['beanCalibration'] = beanCalibration;
          const can1Calibration = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'can1Calibration',
          ]);
          newState['can1Calibration'] = can1Calibration;
          const can2Calibration = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'can2Calibration',
          ]);
          newState['can2Calibration'] = can2Calibration;
          const can4Calibration = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'can4Calibration',
          ]);
          newState['can4Calibration'] = can4Calibration;
          const can3Calibration = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'can3Calibration',
          ]);
          newState['can3Calibration'] = can3Calibration;

          const Vm = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'distributor',
          ]);
          newState['distributor'] = Vm;
          const refiller = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'refiller',
          ]);
          newState['refiller'] = refiller;
          const canOrder1 = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'canOrder1',
          ]);
          newState['canOrder1'] = canOrder1;
          const canOrder2 = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'canOrder2',
          ]);
          newState['canOrder2'] = canOrder2;
          const canOrder3 = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'canOrder3',
          ]);
          newState['canOrder3'] = canOrder3;
          const canOrder4 = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'canOrder4',
          ]);
          newState['canOrder4'] = canOrder4;
          const distributorMargin = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'distributormargin',
          ]);
          newState['distributorMargin'] = distributorMargin;
          const channel = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'channel',
          ]);
          newState['channel'] = channel;
          const partnerFee = safeDeepGet(this.props, [
            'readInfoResult',
            'body',
            'info',
            'partnerfee',
          ]);
          newState['partnerFee'] = partnerFee;

          if (!isEmpty(newState)) {
            this.setState(newState);
          }
        }
      } else if (this.props.updateInfoResult !== prevProps.updateInfoResult) {
        // Success update info
        const code = safeDeepGet(
          this.props,
          ['updateInfoResult', 'code'],
          Result.UNKNOWN_ERROR
        );

        if (code === Result.SUCCESS) {
          this.showToast();
          this.props.history.replace('/business-point/list');
        } else if (code === Result.FAILED) {
          this.showFailedToast();
        }
      }
    }
  }
  handleCancleClick() {
    this.props.history.push(`/business-point/list/`);
  }
  handelChangecanOrder1(value) {
    const { canOrder2, canOrder3, canOrder4, isEdit } = this.state;
    if (!isEdit) {
      if (canOrder2 == value) {
        this.setState({ canOrder1: null });
        this.showWarning();
      } else {
        if (canOrder4 == value) {
          this.setState({ canOrder1: null });
          this.showWarning();
        } else {
          if (canOrder3 == value) {
            this.setState({ canOrder1: null });
            this.showWarning();
          } else {
            this.setState({ canOrder1: value });
          }
        }
      }
    } else {
      if (canOrder2 == value) {
        this.setState({ canOrder1: null });
        this.showWarning();
      } else {
        if (canOrder4 == value) {
          this.setState({ canOrder1: null });
          this.showWarning();
        } else {
          if (canOrder3 == value) {
            this.setState({ canOrder1: null });
            this.showWarning();
          } else {
            this.setState({ canOrder1: value.value });
          }
        }
      }
    }
  }
  handelChangecanOrder2(value) {
    const { canOrder1, canOrder3, canOrder4, isEdit } = this.state;
    if (!isEdit) {
      if (canOrder1 == value) {
        this.setState({ canOrder2: null });
        this.showWarning();
      } else {
        if (canOrder4 == value) {
          this.setState({ canOrder2: null });
          this.showWarning();
        } else {
          if (canOrder3 == value) {
            this.setState({ canOrder2: null });
            this.showWarning();
          } else {
            this.setState({ canOrder2: value });
          }
        }
      }
    } else {
      if (canOrder1 == value.value) {
        this.setState({ canOrder2: null });
        this.showWarning();
      } else {
        if (canOrder4 == value.value) {
          this.setState({ canOrder2: null });
          this.showWarning();
        } else {
          if (canOrder3 == value.value) {
            this.setState({ canOrder2: null });
            this.showWarning();
          } else {
            this.setState({ canOrder2: value.value });
          }
        }
      }
    }
  }
  handelChangecanOrder3(value) {
    const { canOrder1, canOrder2, canOrder4, isEdit } = this.state;
    if (!isEdit) {
      if (canOrder1 == value) {
        this.setState({ canOrder3: null });
        this.showWarning();
      } else {
        if (canOrder4 == value) {
          this.setState({ canOrder3: null });
          this.showWarning();
        } else {
          if (canOrder2 == value) {
            this.setState({ canOrder3: null });
            this.showWarning();
          } else {
            this.setState({ canOrder3: value });
          }
        }
      }
    } else {
      if (canOrder1 == value.value) {
        this.setState({ canOrder3: null });
        this.showWarning();
      } else {
        if (canOrder4 == value.value) {
          this.setState({ canOrder3: null });
          this.showWarning();
        } else {
          if (canOrder2 == value.value) {
            this.setState({ canOrder3: null });
            this.showWarning();
          } else {
            this.setState({ canOrder3: value.value });
          }
        }
      }
    }
  }
  handelChangecanOrder4(value) {
    const { canOrder1, canOrder2, canOrder3, isEdit } = this.state;
    if (!isEdit) {
      if (canOrder1 == value) {
        this.setState({ canOrder4: null });
        this.showWarning();
      } else {
        if (canOrder3 == value) {
          this.setState({ canOrder4: null });
          this.showWarning();
        } else {
          if (canOrder2 == value) {
            this.setState({ canOrder4: null });
            this.showWarning();
          } else {
            this.setState({ canOrder4: value.value });
          }
        }
      }
    } else {
      if (canOrder1 == value.value) {
        this.setState({ canOrder4: null });
        this.showWarning();
      } else {
        if (canOrder3 == value.value) {
          this.setState({ canOrder4: null });
          this.showWarning();
        } else {
          if (canOrder2 == value.value) {
            this.setState({ canOrder4: null });
            this.showWarning();
          } else {
            this.setState({ canOrder4: value.value });
          }
        }
      }
    }
  }
  handelChangeRefiller(value) {
    const { isEdit } = this.state;
    if (!isEdit) {
      this.setState({ refiller: value.value });
    } else {
      this.setState({ refiller: value.value });
    }
  }
  handelChangeDistributor(value) {
    const { isEdit } = this.state;
    if (!isEdit) {
      this.setState({ distributor: value.value });
    } else {
      this.setState({ distributor: value.value });
    }
  }
  showWarning() {
    var toastType = 'error';
    var message = 'Bahan sudah Digunakan!';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };
    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  handleValidSubmit(event, values) {
    if (
      PROJECT_HANDLE == 'Nestle' ||
      PROJECT_HANDLE == 'Franke' ||
      PROJECT_HANDLE == 'Svfranke' ||
      PROJECT_HANDLE == 'Svnestle'
    ) {
      const submittedValues = {
        ...values,
        canOrder1: this.state.canOrder1,
        canOrder2: this.state.canOrder2,
        canOrder3: this.state.canOrder3,
        canOrder4: this.state.canOrder4,
        refiller: this.state.refiller,
        distributor: this.state.distributor,
        docId: this.state.deviceId,
      };
      this.props.UpdateBusinessPointInfo(submittedValues);
    }
  }

  showFailedToast() {
    var toastType = 'error';
    var message = 'Gagal Update Business Point';
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToast() {
    const { isEdit } = this.state;

    if (isEdit) {
      var toastType = 'success';
      var message = 'Berhasil Update Bussiness Point!';
    } else {
      var toastType = 'success';
      var message = 'Berhasil Tambah Bussiness Point!';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  render() {
    const refillerOptions = safeDeepGet(this.props, ['refillerVm', 'body'], []);
    const ingredientOptions = safeDeepGet(
      this.props,
      ['ingredientOptions', 'body'],
      []
    );

    const distributorOptions = safeDeepGet(
      this.props,
      ['distributorVm', 'body'],
      []
    );
    // TODO Loading
    const loading = safeDeepGet(this.props, 'loading', true);
    const {
      deviceId,
      isEdit,
      beanCalibration,
      can1Calibration,
      can2Calibration,
      can3Calibration,
      can4Calibration,
      milanosn,
      distributor,
      refiller,
      canOrder1,
      canOrder2,
      canOrder3,
      canOrder4,
      distributorMargin,
      channel,
      partnerFee,
    } = this.state;
    let code, vmOptions;
    if (!isEdit) {
      code = safeDeepGet(
        this.props,
        ['initCreateResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      {
        PROJECT_HANDLE == 'Nestle' ||
        PROJECT_HANDLE == 'Franke' ||
        PROJECT_HANDLE == 'Svfranke' ||
        PROJECT_HANDLE == 'Svnestle'
          ? (vmOptions = safeDeepGet(
              this.props,
              ['initCreateResult', 'body', 'options'],
              []
            ))
          : (vmOptions = safeDeepGet(
              this.props,
              ['initCreateResult', 'body', 'unregistered_devices'],
              []
            ));
      }
    } else {
      code = safeDeepGet(
        this.props,
        ['readInfoResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      vmOptions = safeDeepGet(
        this.props,
        ['readInfoResult', 'body', 'registered_devices'],
        []
      );
    }
    const id = safeDeepGet(this.props, [
      'readInfoResult',
      'body',
      'info',
      'id',
    ]);
    const QrCodeText = `http://contactlessv2.web.app/?deviceId=` + id;

    const sn = safeDeepGet(this.props, [
      'readInfoResult',
      'body',
      'info',
      'sn',
    ]);
    const downloadQR = () => {
      const canvas = document.getElementById('qrcode');
      const pngUrl = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
      let downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = sn + '.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
    return (
      <React.Fragment>
        <div className="page-content">
            <MetaTags>
              <title>Business Point | Smart Vending Machine</title>
            </MetaTags>

          <Container fluid={true}>
            <Breadcrumbs
              title="Business Point"
              breadcrumbItem={isEdit ? 'Edit' : 'Add'}
            />
            <AvForm onValidSubmit={this.handleValidSubmit}>
              <Row>
                <Col lg="12">
                  {/* Calibrations */}
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Calibration</CardTitle>
                      <p className="card-title-desc">Calibration for VM</p>
                      <Row>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <AvField
                              value={beanCalibration}
                              name="beanCalibration"
                              label="Bean Calibration"
                              placeholder="Kalibrasi bean"
                              type="text"
                              errorMessage="Masukkan Kalibrasi Bean"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <AvField
                              value={can1Calibration}
                              name="can1Calibration"
                              label="Canister 1 Calibration"
                              placeholder="Kalibrasi Canister 1"
                              type="text"
                              errorMessage="Masukkan Kalibrasi Canister"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <AvField
                              value={can2Calibration}
                              name="can2Calibration"
                              label="Canister 2 Calibration"
                              placeholder="Kalibrasi Canister"
                              type="text"
                              errorMessage="Masukkan Kalibrasi Canister"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <AvField
                              value={can3Calibration}
                              name="can3Calibration"
                              label="Canister 3 Calibration"
                              placeholder="Kalibrasi Canister"
                              type="text"
                              errorMessage="Masukkan Kalibrasi Canister"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <AvField
                              value={can4Calibration}
                              name="can4Calibration"
                              label="Canister 4 Calibration"
                              placeholder="Kalibrasi Canister"
                              type="text"
                              errorMessage="Masukkan Kalibrasi Canister"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  {/* Canister */}
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Canister Order</CardTitle>
                      <p className="card-title-desc">Canister for VM</p>
                      <Row>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label>Canister Order #1</Label>

                            <Select
                              onChange={this.handelChangecanOrder1}
                              value={
                                isEdit
                                  ? ingredientOptions.filter(function (option) {
                                      return option.value === canOrder1;
                                    })
                                  : this.state.canOrder1
                              }
                              options={ingredientOptions}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label>Canister Order #2</Label>

                            <Select
                              onChange={this.handelChangecanOrder2}
                              value={
                                isEdit
                                  ? ingredientOptions.filter(function (option) {
                                      return option.value === canOrder2;
                                    })
                                  : this.state.canOrder2
                              }
                              options={ingredientOptions}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label>Canister Order #3</Label>

                            <Select
                              onChange={this.handelChangecanOrder3}
                              value={
                                isEdit
                                  ? ingredientOptions.filter(function (option) {
                                      return option.value === canOrder3;
                                    })
                                  : this.state.canOrder3
                              }
                              options={ingredientOptions}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label>Canister Order #4</Label>

                            <Select
                              onChange={this.handelChangecanOrder4}
                              value={
                                isEdit
                                  ? ingredientOptions.filter(function (option) {
                                      return option.value === canOrder4;
                                    })
                                  : this.state.canOrder4
                              }
                              options={ingredientOptions}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  {/* Distributor & Refiller */}
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">
                        Distributor & Refiller
                      </CardTitle>
                      <p className="card-title-desc">
                        Distributor & Refiller for VM
                      </p>
                      <Row>
                        <Col md={6}>
                          <FormGroup className="mb-3 select2-container">
                            <Label>Distributor</Label>
                            <Select
                              value={
                                isEdit
                                  ? distributorOptions.filter(function (
                                      option
                                    ) {
                                      return option.value === distributor;
                                    })
                                  : this.state.distributor
                              }
                              // value={vmOptions.filter(function(option) {
                              //   return option.value === selectedVM;
                              // })}
                              onChange={this.handelChangeDistributor}
                              classNamePrefix="select2-selection"
                              options={distributorOptions}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3 select2-container">
                            <Label>Refiller</Label>
                            <Select
                              onChange={this.handelChangeRefiller}
                              value={
                                isEdit
                                  ? refillerOptions.filter(function (option) {
                                      return option.value === refiller;
                                    })
                                  : this.state.refiller
                              }
                              options={refillerOptions}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  {/* Report */}
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Report</CardTitle>
                      <p className="card-title-desc">Margin and Fee</p>
                      <Row>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <AvField
                              value={distributorMargin}
                              name="distributormargin"
                              label="Distributor Margin (%)"
                              placeholder="0"
                              type="number"
                              errorMessage="Masukkan Angka"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <AvField
                              value={partnerFee}
                              name="partnerfee"
                              label="Partner Fee (%)"
                              placeholder="0"
                              type="number"
                              errorMessage="Masukkan Angka"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <AvField
                              value={channel}
                              name="channel"
                              label="Channel"
                              placeholder="Channel"
                              type="text"
                              errorMessage="Channel"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <div className="d-flex flex-wrap gap-2 float-end mb-4">
                    <Button onClick={this.handleCancleClick} color="secondary">
                      Cancel
                    </Button>{' '}
                    <Button type="submit" color="primary">
                      Simpan
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  initCreateResult: safeDeepGet(state, ['businessPoint', 'initCreate'], {}),
  readInfoResult: safeDeepGet(state, ['businessPoint', 'readInfo'], {}),
  createResult: safeDeepGet(state, ['businessPoint', 'create'], {}),
  updateInfoResult: safeDeepGet(state, ['businessPoint', 'updatecalibration'], {}),
  loading: safeDeepGet(state, ['businessPoint', 'loading'], true),
  refillerVm: safeDeepGet(state, ['refiller', 'list_vm'], true),
  distributorVm: safeDeepGet(state, ['distributor', 'list_distributor'], true),
  ingredientOptions: safeDeepGet(state, ['ingredient', 'list_options'], true),
});

const mapDispatchToProps = dispatch => ({
  InitBusinessPointInfoCreate: () => dispatch(initCreate()),
  ListRefiller: () => dispatch(list_vm()),
  ListDistributor: () => dispatch(list_distributor()),
  ListIngredient: () => dispatch(list_options()),
  ReadBusinessPointInfo: id => dispatch(readInfo(id)),
  CreateBusinessPoint: payload => dispatch(create(payload)),
  UpdateBusinessPointInfo: payload => dispatch(updatecalibration(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPointInfo);
