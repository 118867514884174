/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Container } from 'reactstrap';
import 'flatpickr/dist/themes/material_blue.css';

import 'tippy.js/dist/tippy.css';
//Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

//Import Breadcrumb

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { payout_create } from '../../store/payout/actions';
import LoadingAnimation from 'Base/assets/LoadingAnimation';
import SuccessAnimation from 'Base/assets/SuccessAnimation';
import FailedAnimation from 'Base/assets/FailedAnimation';
//Import Images
let role = '';
const obj = JSON.parse(localStorage.getItem('role'));
if (obj != null) {
  role = obj.role;
}
class PageRedirect extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    const payoutId = safeDeepGet(this.props.match.params, 'id');
    this.state = {
      code: 20,
      payoutId
    };
    this.showToast = this.showToast.bind(this);
    this.showToastFailed = this.showToastFailed.bind(this);
  }
  componentDidMount() {
    const { CreatePayout } = this.props;
    setTimeout(
      function () {
        const payload = {};
        //Start the timer
        const {payoutId} = this.state
        safeDeepSet(payload, 'idPayout', payoutId);
        CreatePayout(payload);
      }.bind(this),
      3000
    );
  }
  showToast() {
    var toastType = 'success';
    var message = 'Berhasil Payout';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastFailed() {
    var toastType = 'error';
    var message = 'Gagal Payout';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        this.setState({ code });
        this.showToast();

        setTimeout(
          function () {
            //Start the timer
            this.props.history.push(`/payout/list`);
          }.bind(this),
          3000
        );
      } else if (code === Result.FAILED) {
        this.showToastFailed();
        setTimeout(
          function () {
            //Start the timer
            this.props.history.push(`/payout/list`);
          }.bind(this),
          3000
        );
        this.setState({ code });
      }
    }
  }

  render() {
    const { code } = this.state;
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-5">
          <MetaTags>
            <title>Redirecting....</title>
          </MetaTags>
          <Container>
            <div className=" text-left">
              {code == Result.SUCCESS ? (
                <>
                  <SuccessAnimation />
                  <h3 className="text-center">
                    Data Payout Telah diterima, tunggu Dana Masuk kedalam
                    rekening
                  </h3>
                </>
              ) : code == Result.FAILED ? (
                <>
                  <FailedAnimation />
                  <h3 className="text-center">
                    Gagal Payout, Silahkan Hubungi Tim Support
                  </h3>
                </>
              ) : (
                <>
                  <LoadingAnimation />
                  <h3 className="text-center">
                    Sedang memproses data, mohon tunggu sebentar...
                  </h3>
                </>
              )}
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: safeDeepGet(state, ['payout', 'loading'], true),
  createResult: safeDeepGet(state, ['payout', 'payout_create']),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  CreatePayout: payload => dispatch(payout_create(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageRedirect);
