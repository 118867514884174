/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import 'flatpickr/dist/themes/material_blue.css';
import { Card, CardBody, Col, Container, Row, Badge } from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import ReactTooltip from 'react-tooltip';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// Iotera
import { safeDeepGet } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { list_alert_device } from '../../store/alert/actions';
import { buildTitle } from 'Apps';

class AlertDeviceList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    let deviceId = null;
    const path = this.props.match.path;
    if (path.includes('/alert/')) {
      deviceId = safeDeepGet(this.props.match.params, 'id');
    }
    this.state = {
      deviceId,
      bpfilterlist: [],
      businessPoints: 'all',
      dateRange: [null, null],
      transactionStatus: 'all',
      dispenseStatus: 'all',
      productListColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },

        {
          dataField: 'code',
          text: 'Code',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">{row.id}</p>
            </>
          ),
        },
        {
          dataField: 'action',
          text: 'Action',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">{row.action}</p>
            </>
          ),
        },
        {
          dataField: 'desc',
          text: 'Description',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">{row.description}</p>
            </>
          ),
        },
        {
          dataField: 'active',
          text: 'Active',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">
                <Badge
                  className={
                    'font-size-12 badge-' + (row.active ? 'danger' : 'success')
                  }
                  color={row.active ? 'danger' : 'success'}
                  pill
                >
                  {row.active ? 'Active' : 'Solve'}
                </Badge>
              </p>
            </>
          ),
        },
        {
          dataField: 'time',
          text: 'Time',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">{this.handleValidDate(row.time)}</p>
            </>
          ),
        },
      ],
    };
  }
  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };
  componentDidMount() {
    this.props.LoadAlertList(this.state.deviceId);
  }

  /* Handle click */
  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title> {buildTitle('Alert List')}</title>
          </MetaTags>

          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Alert" breadcrumbItem="Device" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.productListColumns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.productListColumns}
                          data={list}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <div className="table-responsive">
                                <ReactTooltip place="top" effect="solid" />
                                <BootstrapTable
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  defaultSorted={defaultSorted}
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    'table align-middle table-nowrap table-check'
                                  }
                                  headerWrapperClasses={'table-light'}
                                  ref={this.node}
                                />
                              </div>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['alert', 'list_alert_device'], {}),
  loading: safeDeepGet(state, ['alert', 'loading'], true),
});

const mapDispatchToProps = dispatch => ({
  LoadAlertList: payload => dispatch(list_alert_device(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertDeviceList);
