/* eslint-disable semi */
import { call, put, takeEvery } from 'redux-saga/effects';

import { LIST, CREATE, DEVICE_LIST } from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet } from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
import {
  getDeviceTags,
  createPaymentLink,
  getToken,
} from '../../webservice/device';

function* list() {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body', []);
    const AppList = [];
    for (const i in mapBody) {
      const app_id = '100000' + i;
      const projectName = safeDeepGet(mapBody, [i, 'name'], '');
      AppList.push({
        label: projectName + '( ' + app_id + ' )',
        value: app_id,
      });
    }
    yield put(result(LIST, { code: Result.SUCCESS, body: AppList }));
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}
function* deviceList(payload) {
  yield put(loading(DEVICE_LIST));
  const appId = safeDeepGet(payload, 'app_id', '');

  let wsResult = yield call(getDeviceTags, appId);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.HTTP.OK) {
    const devices = safeDeepGet(wsResult, ['body', 'devices'], []);
    const deviceList = [];
    for (const i in devices) {
      const deviceName = safeDeepGet(devices, [i, 'label'], '');
      deviceList.push({ label: deviceName, value: i });
    }
    yield put(result(DEVICE_LIST, { code: Result.SUCCESS, body: deviceList }));
    return;
  }
  yield put(result(DEVICE_LIST, { result: { code: Result.FAILED } }));
}

// function formatPhoneNumber(phoneNumber) {
//   if (phoneNumber.startsWith('0')) {
//     return '+62' + phoneNumber.slice(1);
//   }
//   return phoneNumber;
// }
const formatCurrency = amount => {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
  }).format(amount);
};

function* create(action) {
  yield put(loading(CREATE));
  const email = safeDeepGet(action, ['payload', 'email'], '');
  const price = safeDeepGet(action, ['payload', 'price'], '');
  const appId = safeDeepGet(action, ['payload', 'selectedAppId'], '');
  const products = [];

  products.push({
    id: 'SV-subs-' + price.toString(),
    name: 'SV-subs-' + price.toString(),
    price: Number(price),
    quantity: 1,
  });

  const currentDate = new Date();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11, so add 1
  const year = currentDate.getFullYear();

  const reference_no = `Subs-${month}-${year}`;
  const payload = {
    method: 'MIDTRANS',
    total: Number(price),
    // total: totalPrice,
    detail: {
      // name: "Iotera",
      // last_name: "Smartvending",
      // phone: "+62",
      // email: email,
      // notes: notes,
    },
    products: products,
    plugin: {
      callback_url: 'https://pay.iotera.io/page/success-payment',
      reference_no: reference_no,
    },
  };
  const device_id = 'internalized-payment-device';
  let wsResult = yield call(getToken, appId, device_id);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.HTTP.OK) {
    const body = safeDeepGet(wsResult, 'body', {});
    const deviceToken = safeDeepGet(body, 'token', '');
    wsResult = yield call(
      createPaymentLink,
      payload,
      appId,
      device_id,
      deviceToken
    );
    const code = safeDeepGet(wsResult, 'code', Result.FAILED);
    if (code == Result.HTTP.OK) {
      const te = safeDeepGet(wsResult, 'body', {});
      const url = safeDeepGet(te, 'url', '');
      const formattedAmountDue = formatCurrency(price);
      const body = `
        Yth. Bapak/Ibu,<br><br>

        Semoga Anda dalam keadaan baik.<br><br>

        Kami ingin menginformasikan bahwa tagihan untuk langganan mesin vending Anda sudah tersedia. Berdasarkan catatan kami, jumlah yang perlu dibayarkan adalah sebesar Rp ${formattedAmountDue}.<br><br>

        Detail Tagihan:<br>
        Jumlah yang Harus Dibayar: Rp ${formattedAmountDue}<br><br>

        Kami mohon kesediaan Anda untuk melakukan pembayaran melalui <a href="${url}">Link pembayaran ini</a>.<br><br>

        Jika Anda telah melakukan pembayaran, mohon abaikan email ini. Apabila ada pertanyaan atau memerlukan informasi lebih lanjut, silakan hubungi kami di <a href="www.smartvending.cloud">Website Ini</a>.<br><br>

        Terima kasih atas perhatian dan kerjasamanya.<br><br>

        Salam hormat,<br><br>

        [Nama Anda]<br>
        [Posisi]<br>
        Iotera<br>
        +62 22 4263040, Jl. Aria Jipang No.9, Citarum, Kec. Bandung Wetan, Kota Bandung, Jawa Barat 40115
      `;

      console.log(body)
      // window.Email.send({
      //   Host: 'smtp.yourisp.com',
      //   Username: 'username',
      //   Password: 'password',
      //   To: email + ', info@iotera.io', // Multiple recipients
      //   From: 'iotera@iotera.com',
      //   Subject: 'Informasi Pembayaran Langganan Mesin Smart Vending',
      //   Body: body,
      // }).then(message =>
        // console.log(     message === 'OK'
        //   ? 'Message Sent Successfully!'
        //   : 'Failed to send message, please try again)
      // );
      yield put(result(CREATE, { code: Result.SUCCESS, body: te }));
      return;
    }
    yield put(result(CREATE, { result: { code: Result.FAILED } }));
  }
  yield put(result(CREATE, { result: { code: Result.FAILED } }));
}
function* productSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(CREATE, create);
  yield takeEvery(DEVICE_LIST, deviceList);
}

export default productSaga;
