import {
  LOADING,
  RESULT,
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  PAYOUT_LIST,
  PAYOUT_CREATE,
  ACCOUNT_LIST,
  DETAIL_ACCOUNT,
  GET_BALANCE,
  GET_PAYOUT_BALANCE,
} from "./actionTypes";

export const loading = actionType => ({
  type: LOADING,
  payload: { actionType },
});

export const result = (actionType, result) => ({
  type: RESULT,
  payload: { actionType, result },
});

export const list = () => ({
  type: LIST,
});
export const get_Payout_Balance = () => ({
  type: GET_PAYOUT_BALANCE,
});
export const get_Balance = () => ({
  type: GET_BALANCE,
});
export const payoutlist = payload => ({
  type: PAYOUT_LIST,
  payload,
});

export const initCreate = () => ({
    type: INIT_CREATE,
  });
export const accountList = () => ({
    type: ACCOUNT_LIST,
  });

export const create = payload => ({
  type: CREATE,
  payload,
});
export const detailAccount = payload => ({
  type: DETAIL_ACCOUNT,
  payload,
});
export const payout_create = payload => ({
  type: PAYOUT_CREATE,
  payload,
});

export const read = sku => ({
  type: READ,
  payload: { sku },
});

export const update = payload => ({
  type: UPDATE,
  payload,
});

export const stockUpdate = payload => ({
  type: STOCK_UPDATE,
  payload,
});

export const delete_ = account_id => ({
  type: DELETE,
  payload: { account_id },
});
