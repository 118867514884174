import firebase from 'firebase/compat/app';
import { collection, query, where } from 'firebase/firestore';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import { safeDeepGet } from 'iotera-base/utility/json';
 
export default class Transaction {
  constructor(firebase) {
    const obj = JSON.parse(localStorage.getItem('role'));

    const role = safeDeepGet(obj, 'role', 'User');
    this.collection = firebase.firestore().collection('transaction_admin');
  }

  list = payload => {
    return new Promise((resolve, reject) => {

      var filter_bp = safeDeepGet(payload, 'businessPoints');
      var filter_ts = safeDeepGet(payload, 'transactionStatus');
      var filter_pm = safeDeepGet(payload, 'bank');
      var filter_ds = safeDeepGet(payload, 'dispenseStatus');
      var filter_sd = safeDeepGet(payload, 'dateRange')[0];
      var filter_ed = safeDeepGet(payload, 'dateRange')[1];
      if (filter_bp == 'all' || filter_bp == null) {
        filter_bp = null;
      }
      if (filter_pm == 'all' || filter_pm == null) {
        filter_pm = null;
      }
      if (filter_ts == 'all' || filter_ts == null) {
        filter_ts = null;
      }
      if (filter_ds == 'all' || filter_ds == null) {
        filter_ds = null;
      }
      if (filter_sd == 'all' || filter_sd == null) {
        filter_sd = null;
      }
      if (filter_ed == 'all' || filter_ed == null) {
        filter_ed = null;
      }

      let query = this.collection;

      if (filter_bp) {
        query = query.where('detail.application_id', '==', filter_bp);
      }
      if (filter_pm) {
        query = query.where('payment.method', '==', filter_pm);
      }

      if (filter_ts) {
        query = query.where('detail.transaction_status', '==', filter_ts);
      }

      if (filter_ds) {
        query = query.where('detail.dispense_status', '==', filter_ds);
      }

      // if (filter_sd) {
      //   const time = filter_sd.getTime();
      //   var startfulldate = firebase.firestore.Timestamp.fromDate(filter_sd);
      //   query = query.where('time.timestamp', '>=', Number(time));
      // }

      // if (filter_ed) {
      //   const d = filter_ed;
      //   d.setDate(d.getDate() + 1);
      //   var endfulldate = firebase.firestore.Timestamp.fromDate(d);
      //   const time = d.getTime();
      //   query = query.where('time.timestamp', '<=', Number(time));
      // }
      if (filter_sd) {
        var startfulldate = filter_sd.getTime();
        query = query.where(
          "time.timestamp",
          ">=",
          startfulldate
        );
      }

      if (filter_ed) {
        const d = filter_ed;
        d.setDate(d.getDate() + 1);
        var endfulldate = d.getTime();
        query = query.where(
          "time.timestamp",
          "<=",
          endfulldate
        );
      }
      const list = {};
      query.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          console.log(error);
          resolve({ code: -1, body: list });
        }
      );
    });
  };
  listChart = () => {
    return new Promise((resolve, reject) => {
      const list = {};
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          console.log(error);
          resolve({ code: -1, body: list });
        }
      );
    });
  };

  nett_settlement = payload => {
    return new Promise((resolve, reject) => {
      var filter_month = safeDeepGet(payload, 'month');
      var filter_year = safeDeepGet(payload, 'year');
      var filter_bp = safeDeepGet(payload, 'businessPoints');
      var filter_sd = safeDeepGet(payload, 'date')[0];
      var filter_ed = safeDeepGet(payload, 'date')[1];
      if (filter_bp == 'all' || filter_bp == null) {
        filter_bp = null;
      }
      if (filter_sd == 'all' || filter_sd == null) {
        filter_sd = null;
      }
      if (filter_ed == 'all' || filter_ed == null) {
        filter_ed = null;
      }
      var startDate = new Date(filter_year, filter_month, 1);
      var endDate = new Date(filter_year, filter_month + 1, 0);
      let query = this.collection;
      if (filter_bp) {
        query = query.where('product.device_id', '==', filter_bp);
      }

      // if (filter_sd) {
      //   var startfulldate = firebase.firestore.Timestamp.fromDate(filter_sd);
      //   query = query.where('time.firestore_timestamp', '>=', startfulldate);
      // } else {
      //   if (startDate) {
      //     var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      //     query = query.where('time.firestore_timestamp', '>=', startfulldate);
      //   }
      //   if (endDate) {
      //     var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      //     query = query.where('time.firestore_timestamp', '<=', endfulldate);
      //   }
      // }

      // if (filter_ed) {
      //   const d = filter_ed;
      //   d.setDate(d.getDate() + 1);
      //   var endfulldate = firebase.firestore.Timestamp.fromDate(d);
      //   query = query.where('time.firestore_timestamp', '<=', endfulldate);
      // } else {
      //   if (startDate) {
      //     var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      //     query = query.where('time.firestore_timestamp', '>=', startfulldate);
      //   }
      //   if (endDate) {
      //     var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      //     query = query.where('time.firestore_timestamp', '<=', endfulldate);
      //   }
      // }
      if (filter_sd) {
        var startfulldate = filter_sd.getTime();
        query = query.where(
          "time.timestamp",
          ">=",
          startfulldate
        );
      } else {
        if (startDate) {
          var startfulldate = startDate.getTime();
          query = query.where( "time.timestamp", '>=', startfulldate);
        }
        if (endDate) {
          var endfulldate = endDate.getTime();
          query = query.where( "time.timestamp", '<=', endfulldate);
        }
      }

      if (filter_ed) {
        const d = filter_ed;
        d.setDate(d.getDate() + 1);
        var endfulldate = d.getTime();
        query = query.where(
          "time.timestamp",
          "<=",
          endfulldate
        );
      } else {
        if (startDate) {
          var startfulldate = startDate.getTime();
          query = query.where( "time.timestamp", '>=', startfulldate);
        }
        if (endDate) {
          var endfulldate = endDate.getTime();
          query = query.where( "time.timestamp", '<=', endfulldate);
        }
      }
      const list = {};
      query.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          console.log(error);
          resolve({ code: -1, body: list });
        }
      );
    });
  };
  detail = orderID => {
    return new Promise((resolve, reject) => {
      // var orderId = safeDeepGet(orderID, 'orderId');
      const list = {};
      this.collection
        .where('detail.order_id', '==', orderID)
        .get()
        .then(
          snapshot => {
            snapshot.docs.map(doc => {
              list[doc.id] = doc.data();
            });
            resolve({ code: 0, body: list });
          },
          error => {
            console.log(error);
            resolve({ code: -1, body: list });
          }
        );
    });
  };

  read = deviceId => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .where('product.device_id', '==', deviceId)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };

  updateRefund = (id, payload) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (
          key === 'order_id' ||
          key === 'transaction_status' ||
          key === 'refund_time'
        ) {
          updatedPayload[key] = payload[key];
        }
      }

      this.collection
        .doc(id)
        .set(
          {
            detail: updatedPayload,
          },
          { merge: true }
        )
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  list_graph = payload => {
    return new Promise((resolve, reject) => {
      var filter_app = safeDeepGet(payload, ['payload','appId']);
      if (filter_app == 'all' || filter_app == null) {
        filter_app = null;
      }
      let query = this.collection;

      if (filter_app) {
        query = query.where('detail.application_id', '==', '100000'+filter_app);
      }
      
      const list = {};
      query.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          console.log(error);
          resolve({ code: -1, body: list });
        }
      );
    });
  };
}
