import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Availity
import { AvField, AvForm } from 'availity-reactstrap-validation';

// Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, isEmpty } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { initCreate, create, read, update } from '../../store/refiller/actions';
class CreateRefiller extends Component {
  constructor(props) {
    super(props);

    let isEdit = false;
    let sku = null;
    const path = this.props.match.path;
    if (path.includes('/edit/')) {
      isEdit = true;
      sku = safeDeepGet(this.props.match.params, 'id');
    }
    this.state = {
      isEdit,
      sku,
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleCancleClick = this.handleCancleClick.bind(this);
    this.showToast = this.showToast.bind(this);
  }
  handleCancleClick() {
    this.props.history.push(`/refill/list`);
  }
  handleValidSubmit(event, values) {
    const { isEdit } = this.state;

    const _chars =
      'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890';
    var id = '';
    for (var i = 0; i < 20; i++)
      id += _chars.charAt(Math.floor(Math.random() * _chars.length));
    // const { isEdit, imageUpdated, imageFile, imageExt } = this.state;
    const submittedValues = { ...values, id, role: 'refiller' };
    this.props.CreateRefiller(submittedValues);
  }
  componentDidMount() {
    const { isEdit, sku } = this.state;
    // Load Refiller
    if (!isEdit) {
      this.props.InitCreateRefiller();
    } else {
      this.props.ReadRefiller(this.state.sku);
    }
  }
  showToast() {
    const { isEdit } = this.state;

    if (isEdit) {
      var toastType = 'success';
      var message = 'Berhasil Update Refiller';
    } else {
      var toastType = 'success';
      var message = 'Berhasil Tambah Refiller';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastFailed() {
    const { isEdit } = this.state;

    if (isEdit) {
      var toastType = 'error';
      var message = "Gagal membuat akun Refiller";
    } else {
      var toastType = 'error';
      var message = "Gagal membuat akun Refiller";
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isEdit } = this.state;

    if (!isEdit) {
      if (this.props.createResult !== prevProps.createResult) {
        // Success create
        const code = safeDeepGet(
          this.props,
          ['createResult', 'code'],
          Result.UNKNOWN_ERROR
        );
        const body = safeDeepGet(
          this.props,
          ['createResult', 'body'],
          Result.UNKNOWN_ERROR
        );
        if (code === Result.SUCCESS || code === Result.HTTP.OK) {
          this.showToast();
          this.props.history.replace('/refill/list');
        } else if (code === Result.UNKNOWN_ERROR) {
          console.log('s')
        } else {
          this.showToastFailed();
        }
      }
    } else {
      if (this.props.readResult !== prevProps.readResult) {
        const code = safeDeepGet(
          this.props,
          ['readResult', 'code'],
          Result.UNKNOWN_ERROR
        );
        if (code == Result.SUCCESS) {
          const newState = {};
          const name = safeDeepGet(this.props, ['readResult', 'body', 'name']);
          newState['name'] = name;
          const code = safeDeepGet(this.props, ['readResult', 'body', 'code']);
          newState['code'] = code;
          const telegram_group = safeDeepGet(this.props, [
            'readResult',
            'body',
            'telegram_group',
          ]);
          newState['telegram_group'] = telegram_group;

          if (!isEmpty(newState)) {
            this.setState(newState);
          }
        }
      } else if (this.props.updateResult !== prevProps.updateResult) {
        // Success update info
        const code = safeDeepGet(
          this.props,
          ['updateResult', 'code'],
          Result.UNKNOWN_ERROR
        );

        if (code === Result.SUCCESS) {
          this.props.history.replace('/product/list');
        }
      }
    }
  }
  render() {
    // const loading = safeDeepGet(this.props, "loading", true);
    const { isEdit, name, code, telegram_group, email, pass } = this.state;
    return (
      <React.Fragment>
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <div className="page-content">
            <MetaTags>
              <title>Refiller | Smart Vending Machine</title>
            </MetaTags>
            <Container fluid={true}>
              <Breadcrumbs
                title="Refiller"
                breadcrumbItem={isEdit ? 'Edit' : 'Add'}
              />
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Refiller Information</CardTitle>
                      <p className="card-title-desc">
                        Fill all information below
                      </p>
                      <Row>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <Label className="mt-1" htmlFor="code">
                              Code Refiller :
                            </Label>
                            <AvField
                              type="text"
                              id="code"
                              name="code"
                              value={code}
                              placeholder="Masukan Code..."
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Please enter a Code',
                                },
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="name">Name : </Label>
                            <AvField
                              type="text"
                              id="name"
                              name="name"
                              value={name}
                              placeholder="Masukan Nama..."
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Please enter a Name',
                                },
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label className="mt-1" htmlFor="telegram_group">
                              Telegram Group:
                            </Label>
                            <AvField
                              type="text"
                              id="telegram_group"
                              value={telegram_group}
                              name="telegram_group"
                              placeholder="Masukan Nomor Telegram Group..."
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Please enter a Telegram Group',
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <Label className="mt-1" htmlFor="email">
                              Email :
                            </Label>
                            <AvField
                              type="email"
                              id="email"
                              name="email"
                              value={email}
                              placeholder="Masukan Email..."
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Please enter a Email',
                                },
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="pass">Password : </Label>
                            <AvField
                              type="password"
                              id="pass"
                              name="pass"
                              value={pass}
                              placeholder="Masukan Password..."
                              className="form-control"
                              validate={{
                                minLength: {
                                  value: 6,
                                  errorMessage:
                                    'Your name must be between 6 and 16 characters',
                                },
                                maxLength: {
                                  value: 16,
                                  errorMessage:
                                    'Your name must be between 6 and 16 characters',
                                },
                                required: {
                                  value: true,
                                  errorMessage: 'Please enter a Password',
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <div className="d-flex flex-wrap gap-2 float-end mb-4">
                    <Link
                      className="btn btn-secondary"
                      role="button"
                      to="/refill/list"
                    >
                      Batal
                    </Link>{' '}
                    <Button type="submit" color="primary">
                      Simpan
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </AvForm>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  initCreateResult: safeDeepGet(state, ['refiller', 'initCreate'], {}),
  createResult: safeDeepGet(state, ['refiller', 'create'], {}),
  loading: safeDeepGet(state, ['refiller', 'loading'], true),
  readResult: safeDeepGet(state, ['refiller', 'read'], {}),
  updateResult: safeDeepGet(state, ['refiller', 'update'], {}),
});

const mapDispatchToProps = dispatch => ({
  InitCreateRefiller: () => dispatch(initCreate()),
  CreateRefiller: payload => dispatch(create(payload)),
  ReadRefiller: code => dispatch(read(code)),
  UpdateRefiller: payload => dispatch(update(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateRefiller);
