import React from 'react'
import { Redirect } from 'react-router-dom'

// User profile
import UserProfile from '../../Base/pages/Authentication/UserProfile'

// Refiller page
import Csv from '../pages/Csv/csv'
import AdminUser from '../pages/User/index'
import AdminProject from '../pages/Project/index'
// Payout Page

// import Page404 from '../../Base/pages/pages-404'

import BPFee from '../pages/BusinessPoint/index'
import Tags from '../pages/Tags/index'
import Chart from '../pages/BusinessPoint/chartLine'
import ChartProject from '../pages/Project/chartLine'
import SummaryAPP from '../pages/Project/summary'
import SummaryDevice from '../pages/BusinessPoint/summary'
import AdminInfoBp from '../pages/BusinessPoint/info'
import TransactionAdmin from '../pages/Transaction-Admin/index'
import TransactionDetailAdmin from '../pages/Transaction-Admin/detail'
import BankAccountAdmin from '../pages/Payout/bankAccount'
import PayoutReportAdmin from '../pages/Payout/index'
import InfoBankAdmin from '../pages/Payout/info'
import DashboardAdmin from '../pages/Dashboard-Admin/index'
import PaymentLink from "../pages/Transaction-Admin/payment-link"


import Login from "../../Base/pages/Authentication/Login"
import Logout from "../../Base/pages/Authentication/Logout"
import ForgetPwd from "../../Base/pages/Authentication/ForgetPassword"
import page404 from "../../Base/pages/pages-subscription"
import components from 'Admin/components'
const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/payment", component: page404},
]
const superRoutes = [
  // Demo routes
  { path: '/admin/dashboard', component: DashboardAdmin },
  { path: '/csv', component: Csv },
  { path: '/admin/user/:appId', component: AdminUser },
  { path: '/admin/project', component: AdminProject },
  { path: '/admin/business-point/:appId', component: BPFee },
  { path: '/admin/tags/:appId', component: Tags },
  { path: '/admin/business-point/:appId/:device_id', component: Chart },
  { path: '/admin/summary/:appId/:device_id', component: SummaryDevice },
  { path: '/admin/transaction', component: TransactionAdmin },
  { path: '/admin/business-point/create', component: AdminInfoBp },
  { path: '/admin/payout/bankaccount', component: BankAccountAdmin },
  { path: '/admin/payout/create/bankaccount', component: InfoBankAdmin },
  { path: '/admin/payout/edit/:id', component: InfoBankAdmin },
  { path: '/admin/payout/list', component: PayoutReportAdmin },
  { path: '/admin/transaction/detail/:id', component: TransactionDetailAdmin },
  { path: '/admin/graph/:appId', component: ChartProject },
  { path: '/admin/summary/:appId', component: SummaryAPP },
  { path: '/admin/create/payment-link', component: PaymentLink},

  //profile
  { path: '/profile', component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: '/',
    exact: true,
    component: () =>
        <Redirect to="/admin/dashboard" />
  },
  {
    path: '/*',
    exact: true,
    component: () =>
        <Redirect to="/admin/dashboard" />
  },
]

export { superRoutes, publicRoutes }
