/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import MetaTags from 'react-meta-tags'
import Select from 'react-select'
import { Link } from 'react-router-dom'
// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'

// Availity
import { AvField, AvForm } from 'availity-reactstrap-validation'

// Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb'
import {
  initCreate,
  create,
  list_ingredients,
} from '../../../SvNestleMilano/store/product/actions'
import {
  readproduct,
  updatePlanogram,
} from '../../store/business-point/actions'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
// Iotera
import Result from 'iotera-base/result'
import { safeDeepGet, isEmpty } from 'iotera-base/utility/json'

// Redux
import { connect } from 'react-redux'
import { NAME_PROJECT, buildTitle, } from 'Apps'

class InfoPlanogram extends Component {
  constructor(props) {
    super(props)
    let isEdit = false
    let id = null
    const path = this.props.match.path
    if (path.includes('/edit/')) {
      isEdit = true
      id = safeDeepGet(this.props.match.params, 'id')
    }
    this.state = {
      isEdit,
      id,
      image: null,
      imageUpdated: false,
      values: [],
      ingregientList: [],
    }

    this.handleImageFile = this.handleImageFile.bind(this)
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
    this.handleChangeingredient1 = this.handleChangeingredient1.bind(this)
    this.handleChangeingredient2 = this.handleChangeingredient2.bind(this)
    this.handleChangeingredient3 = this.handleChangeingredient3.bind(this)
    this.handleChangeingredient4 = this.handleChangeingredient4.bind(this)
    this.handleChangeAmount1 = this.handleChangeAmount1.bind(this)
    this.handleChangeAmount2 = this.handleChangeAmount2.bind(this)
    this.handleChangeAmount3 = this.handleChangeAmount3.bind(this)
    this.handleChangeAmount4 = this.handleChangeAmount4.bind(this)
    this.showToast = this.showToast.bind(this)
    this.showToastAdd = this.showToastAdd.bind(this)
  }

  componentDidMount() {
    const { isEdit } = this.state
    this.props.ListIngress()
    if (!isEdit) {
      this.props.InitCreateProduct()
    } else {
      this.props.ReadProduct(this.state.id)
    }
    // const id = String.fromCharCode
    // Load product
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isEdit } = this.state
    const device_id = safeDeepGet(this.props, ['readResult', 'body', 'id'])

    if (!isEdit) {
      if (this.props.createResult !== prevProps.createResult) {
        // Success create
        const code = safeDeepGet(
          this.props,
          ['createResult', 'code'],
          Result.UNKNOWN_ERROR
        )

        if (code === Result.SUCCESS) {
          this.props.history.replace(`/business-point/planogram/${device_id}`)
          this.showToastAdd()
        } else if(code === Result.FAILED) {
          this.showToastFailed()
        }
      }
    } else {
      if (this.props.readResult !== prevProps.readResult) {
        const code = safeDeepGet(
          this.props,
          ['readResult', 'code'],
          Result.UNKNOWN_ERROR
        )
        if (code == Result.SUCCESS) {
          const newState = {}
          const display_name = safeDeepGet(this.props, [
            'readResult',
            'body',
            'display_name',
          ])
          newState['display_name'] = display_name
          const cup_size = safeDeepGet(this.props, [
            'readResult',
            'body',
            'cup_size',
          ])
          newState['cup_size'] = cup_size
          const size_name = safeDeepGet(this.props, [
            'readResult',
            'body',
            'size_name',
          ])
          newState['size_name'] = size_name
          const price = safeDeepGet(this.props, [
            'readResult',
            'body',
            'price',
          ])
          newState['Intprice'] = price
          const ingredient1 = safeDeepGet(this.props, [
            'readResult',
            'body',
            '0',
            'type',
          ])
          newState['selectedVM'] = ingredient1
          const ingredient2 = safeDeepGet(this.props, [
            'readResult',
            'body',
            '1',
            'type',
          ])
          newState['selectedVM2'] = ingredient2
          const ingredient3 = safeDeepGet(this.props, [
            'readResult',
            'body',
            '2',
            'type',
          ])
          newState['selectedVM3'] = ingredient3
          const ingredient4 = safeDeepGet(this.props, [
            'readResult',
            'body',
            '3',
            'type',
          ])
          newState['selectedVM4'] = ingredient4
          const amount1 = safeDeepGet(this.props, [
            'readResult',
            'body',
            '0',
            'amount',
          ])
          newState['amount'] = amount1
          const amount2 = safeDeepGet(this.props, [
            'readResult',
            'body',
            '1',
            'amount',
          ])
          newState['amount2'] = amount2
          const amount3 = safeDeepGet(this.props, [
            'readResult',
            'body',
            '2',
            'amount',
          ])
          newState['amount3'] = amount3
          const amount4 = safeDeepGet(this.props, [
            'readResult',
            'body',
            '3',
            'amount',
          ])
          newState['amount4'] = amount4
          const image = safeDeepGet(this.props, [
            'readResult',
            'body',
            'img_url',
          ])
          newState['image'] = image

          const iddevice = safeDeepGet(this.props, [
            'readResult',
            'body',
            'id',
          ])
          newState['iddevice'] = iddevice

          if (!isEmpty(newState)) {
            this.setState(newState)
          }
        }
      } else if (this.props.updateResult !== prevProps.updateResult) {
        // Success update info
        const code = safeDeepGet(
          this.props,
          ['updateResult', 'code'],
          Result.UNKNOWN_ERROR
        )
        if (code === Result.SUCCESS) { 
        this.props.history.replace(`/business-point/planogram/${device_id}`)
        this.showToastAdd()
        }  else if(code === Result.FAILED) { 
          this.showToastFailed()
        }
      }
    }
  }
  showToastFailed() {
    var toastType = 'error'
    var message = 'Gagal Update'

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    }
    if (toastType === 'info') toastr.info(message)
    else if (toastType === 'warning') toastr.warning(message)
    else if (toastType === 'error') toastr.error(message)
    else toastr.success(message)
  }
  showToast() {
    var toastType = 'error'
    var message = 'Bahan sudah Digunakan!'

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    }
    if (toastType === 'info') toastr.info(message)
    else if (toastType === 'warning') toastr.warning(message)
    else if (toastType === 'error') toastr.error(message)
    else toastr.success(message)
  }
  showToastAdd() {
    const { isEdit } = this.state

    if (isEdit) {
      var toastType = 'success'
      var message = 'Berhasil Update Product'
    } else {
      var toastType = 'success'
      var message = 'Berhasil Tambah Product'
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    }
    if (toastType === 'info') toastr.info(message)
    else if (toastType === 'warning') toastr.warning(message)
    else if (toastType === 'error') toastr.error(message)
    else toastr.success(message)
  }

  handleChangeingredient1(value) {
    const { selectedVM2, selectedVM3, selectedVM4, isEdit } = this.state
    if (!isEdit) {
      if (selectedVM2 == value) {
        this.setState({ selectedVM: null })
        this.showToast()
      } else {
        if (selectedVM4 == value) {
          this.setState({ selectedVM: null })
          this.showToast()
        } else {
          if (selectedVM3 == value) {
            this.setState({ selectedVM: null })
            this.showToast()
          } else {
            this.setState({ selectedVM: value })
          }
        }
      }
    } else {
      if (selectedVM2 == value) {
        this.setState({ selectedVM: null })
        this.showToast()
      } else {
        if (selectedVM4 == value) {
          this.setState({ selectedVM: null })
          this.showToast()
        } else {
          if (selectedVM3 == value) {
            this.setState({ selectedVM: null })
            this.showToast()
          } else {
            this.setState({ selectedVM: value.value })
          }
        }
      }
    }
  }
  handleChangeingredient2(value) {
    const { selectedVM, selectedVM3, selectedVM4, isEdit } = this.state
    if (!isEdit) {
      if (selectedVM == value) {
        this.setState({ selectedVM2: null })
        this.showToast()
      } else {
        if (selectedVM4 == value) {
          this.setState({ selectedVM2: null })
          this.showToast()
        } else {
          if (selectedVM3 == value) {
            this.setState({ selectedVM2: null })
            this.showToast()
          } else {
            this.setState({ selectedVM2: value })
          }
        }
      }
    } else {
      if (selectedVM == value.value) {
        this.setState({ selectedVM2: null })
        this.showToast()
      } else {
        if (selectedVM4 == value.value) {
          this.setState({ selectedVM2: null })
          this.showToast()
        } else {
          if (selectedVM3 == value.value) {
            this.setState({ selectedVM2: null })
            this.showToast()
          } else {
            this.setState({ selectedVM2: value.value })
          }
        }
      }
    }
  }
  handleChangeingredient3(value) {
    const { selectedVM, selectedVM2, selectedVM4, isEdit } = this.state
    if (!isEdit) {
      if (selectedVM == value) {
        this.setState({ selectedVM3: null })
        this.showToast()
      } else {
        if (selectedVM4 == value) {
          this.setState({ selectedVM3: null })
          this.showToast()
        } else {
          if (selectedVM2 == value) {
            this.setState({ selectedVM3: null })
            this.showToast()
          } else {
            this.setState({ selectedVM3: value })
          }
        }
      }
    } else {
      if (selectedVM == value.value) {
        this.setState({ selectedVM3: null })
        this.showToast()
      } else {
        if (selectedVM4 == value.value) {
          this.setState({ selectedVM3: null })
          this.showToast()
        } else {
          if (selectedVM2 == value.value) {
            this.setState({ selectedVM3: null })
            this.showToast()
          } else {
            this.setState({ selectedVM3: value.value })
          }
        }
      }
    }
  }
  handleChangeingredient4(value) {
    const { selectedVM, selectedVM2, selectedVM3, isEdit } = this.state
    if (!isEdit) {
      if (selectedVM == value) {
        this.setState({ selectedVM4: null })
        this.showToast()
      } else {
        if (selectedVM3 == value) {
          this.setState({ selectedVM4: null })
          this.showToast()
        } else {
          if (selectedVM2 == value) {
            this.setState({ selectedVM4: null })
            this.showToast()
          } else {
            this.setState({ selectedVM4: value.value })
          }
        }
      }
    } else {
      if (selectedVM == value.value) {
        this.setState({ selectedVM4: null })
        this.showToast()
      } else {
        if (selectedVM3 == value.value) {
          this.setState({ selectedVM4: null })
          this.showToast()
        } else {
          if (selectedVM2 == value.value) {
            this.setState({ selectedVM4: null })
            this.showToast()
          } else {
            this.setState({ selectedVM4: value.value })
          }
        }
      }
    }
  }
  handleChangeAmount1(event) {
    this.setState({ amount: event.target.value })
  }
  handleChangeAmount2(event) {
    this.setState({ amount2: event.target.value })
  }
  handleChangeAmount3(event) {
    this.setState({ amount3: event.target.value })
  }
  handleChangeAmount4(event) {
    this.setState({ amount4: event.target.value })
  }
  handleImageFile(event) {
    const fakepath = event.target.value
    const imageExt = fakepath.slice(
      ((fakepath.lastIndexOf('.') - 1) >>> 0) + 2
    )

    if (imageExt !== 'jpg' && imageExt !== 'png') {
      event.target.value = null
      return
    }

    this.setState({
      imageUpdated: true,
      image: URL.createObjectURL(event.target.files[0]),
      imageFile: event.target.files[0],
      imageExt,
    })
  }

  handleValidSubmit(event, value) {
    const _chars =
      'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890'
    var id = ''
    for (var i = 0; i < 20; i++)
      id += _chars.charAt(Math.floor(Math.random() * _chars.length))

    const idedit = this.state.id
    const {
      isEdit,
      imageUpdated,
      imageFile,
      imageExt,
      selectedVM,
      selectedVM2,
      selectedVM3,
      selectedVM4,
      amount,
      amount2,
      amount3,
      amount4,
    } = this.state
    if (!isEdit) {
      const submittedValues = { ...value, name: id }
      if (selectedVM) {
        submittedValues['selectedVM'] = selectedVM.value
        submittedValues['amount'] = Number(amount)
      }
      if (selectedVM2) {
        submittedValues['selectedVM2'] = selectedVM2.value
        submittedValues['amount2'] = Number(amount2)
      }
      if (selectedVM3) {
        submittedValues['selectedVM3'] = selectedVM3.value
        submittedValues['amount3'] = Number(amount3)
      }
      if (selectedVM4) {
        submittedValues['selectedVM4'] = selectedVM4.value
        submittedValues['amount4'] = Number(amount4)
      }
      submittedValues['price'] = Number(value.Intprice)
      if (imageUpdated) {
        submittedValues['imageFile'] = imageFile
        submittedValues['imageExt'] = imageExt
      }
      // this.props.history.replace('/product/list');
      // this.showToastAdd();
    } else {
      const submittedValues = { ...value, name: idedit }
      if (selectedVM) {
        submittedValues['selectedVM'] = selectedVM
        submittedValues['amount'] = Number(amount)
      }
      if (selectedVM2) {
        submittedValues['selectedVM2'] = selectedVM2
        submittedValues['amount2'] = Number(amount2)
      }
      if (selectedVM3) {
        submittedValues['selectedVM3'] = selectedVM3
        submittedValues['amount3'] = Number(amount3)
      }
      if (selectedVM4) {
        submittedValues['selectedVM4'] = selectedVM4
        submittedValues['amount4'] = Number(amount4)
      }
      submittedValues['price'] = Number(value.Intprice)
      const payload = {
        value: submittedValues,
        id: this.state.id,
      }
      this.props.UpdateProduct(payload)
     
    }
  }

  render() {
    const ingregientList = safeDeepGet(
      this.props,
      ['ingfilterresult', 'body'],
      []
    )
    // const loading = safeDeepGet(this.props, "loading", true);
    const {
      isEdit,
      display_name,
      Intprice,
      cup_size,
      image,
      size_name,
      selectedVM,
      selectedVM2,
      selectedVM3,
      selectedVM4,
      amount,
      amount2,
      amount3,
      amount4,
      iddevice,
    } = this.state
    return (
      <React.Fragment>
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <div className="page-content">
            <MetaTags>
              <title>{buildTitle("Product Planogram")}</title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs
                title="Product Planogram"
                breadcrumbItem={isEdit ? 'Edit' : 'Add'}
              />
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Product Information</CardTitle>
                      <p className="card-title-desc">
                        Fill all information below
                      </p>
                      <Row>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="display_name">Display Name</Label>
                            <AvField
                              id="display_name"
                              name="display_name"
                              value={display_name}
                              placeholder="Nama"
                              type="text"
                              errorMessage="Please enter some text"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="size_name">Size</Label>
                            <AvField
                              id="size_name"
                              name="size_name"
                              value={size_name}
                              placeholder="Size"
                              type="text"
                              errorMessage="Must be M or L"
                              className="form-control"
                              validate={{
                                required: { value: true },
                                pattern: {
                                  value: '/([L-M])/',
                                },
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="Intprice">Price</Label>
                            <AvField
                              id="Intprice"
                              name="Intprice"
                              value={Intprice}
                              placeholder="Price"
                              type="number"
                              errorMessage="Please enter some number"
                              className="form-control"
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="cup_size">Cup Size</Label>
                            <AvField
                              id="cup_size"
                              name="cup_size"
                              value={cup_size}
                              rows="5"
                              type="number"
                              errorMessage="Please enter some number"
                              className="form-control"
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <Label htmlFor="image" className="form-label">
                            Display Image
                          </Label>
                          <div className="dropzone">
                            {image != null ? (
                              <img
                                className="mx-auto d-block"
                                height={225}
                                src={image}
                              />
                            ) : (
                              <div className="dz-message needsclick">
                                <div className="mb-2">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Upload your image</h4>
                              </div>
                            )}
                          </div>
                          <p></p>
                          <p>
                            Format gambar .jpg atau .png dengan background
                            Transparant dan ukuran minimum 400 x 400px (Untuk
                            gambar optimal gunakan ukuran maksimal 600 x 600
                            px).{' '}
                          </p>
                          <Input
                            className="form-control mt-2"
                            type="file"
                            id="image"
                            onChange={this.handleImageFile}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">
                        Ingredient Information
                      </CardTitle>
                      <p className="card-title-desc">
                        Fill all information below
                      </p>
                      <Label>Bahan 1:</Label>
                      <Row className="inner mb-3">
                        <Col sm="6" className="mb-3">
                          <Select
                            className="single-select"
                            classNamePrefix="react-select"
                            menuPlacement="auto"
                            maxMenuHeight={200}
                            placeholder={'Pilih Bahan'}
                            onChange={this.handleChangeingredient1}
                            value={
                              isEdit
                                ? ingregientList.filter(function (option) {
                                    return option.value === selectedVM
                                  })
                                : this.state.selectedVM
                            }
                            options={ingregientList}
                            isDisabled={isEdit}
                          />
                        </Col>
                        <Col sm="6" className="mb-3">
                          <Input
                            name="amount"
                            type="number"
                            id="amount"
                            placeholder="Masukan Berat..."
                            className="form-control"
                            onChange={this.handleChangeAmount1}
                            value={amount}
                            // value={ || ''}
                          />
                        </Col>
                      </Row>
                      <Label>Bahan 2:</Label>
                      <Row className="inner mb-3">
                        <Col sm="6" className="mb-3">
                          <Select
                            className="single-select"
                            classNamePrefix="react-select"
                            menuPlacement="auto"
                            maxMenuHeight={200}
                            placeholder={'Pilih Bahan'}
                            onChange={this.handleChangeingredient2}
                            value={
                              isEdit
                                ? ingregientList.filter(function (option) {
                                    return option.value === selectedVM2
                                  })
                                : this.state.selectedVM2
                            }
                            options={ingregientList}
                            isDisabled={isEdit}
                          />
                        </Col>
                        <Col sm="6" className="mb-3">
                          <Input
                            name="amount2"
                            type="number"
                            id="amount2"
                            placeholder="Masukan Berat..."
                            className="form-control"
                            onChange={this.handleChangeAmount2}
                            value={amount2}

                            // value={ || ''}
                          />
                        </Col>
                      </Row>
                      <Label>Bahan 3:</Label>
                      <Row className="inner mb-3">
                        <Col sm="6" className="mb-3">
                          <Select
                            className="single-select"
                            classNamePrefix="react-select"
                            menuPlacement="auto"
                            maxMenuHeight={200}
                            placeholder={'Pilih Bahan'}
                            onChange={this.handleChangeingredient3}
                            value={
                              isEdit
                                ? ingregientList.filter(function (option) {
                                    return option.value === selectedVM3
                                  })
                                : this.state.selectedVM3
                            }
                            options={ingregientList}
                            isDisabled={isEdit}
                          />
                        </Col>
                        <Col sm="6" className="mb-3">
                          <Input
                            name="amount3"
                            type="number"
                            id="amount3"
                            placeholder="Masukan Berat..."
                            className="form-control"
                            onChange={this.handleChangeAmount3}
                            value={amount3}

                            // value={ || ''}
                          />
                        </Col>
                      </Row>
                      <Label>Bahan 4:</Label>
                      <Row className="inner mb-3">
                        <Col sm="6" className="mb-3">
                          <Select
                            className="single-select"
                            classNamePrefix="react-select"
                            menuPlacement="auto"
                            maxMenuHeight={200}
                            placeholder={'Pilih Bahan'}
                            onChange={this.handleChangeingredient4}
                            value={
                              isEdit
                                ? ingregientList.filter(function (option) {
                                    return option.value === selectedVM4
                                  })
                                : this.state.selectedVM4
                            }
                            options={ingregientList}
                            isDisabled={isEdit}
                          />
                        </Col>
                        <Col sm="6" className="mb-3">
                          <Input
                            name="amount4"
                            type="number"
                            id="amount4"
                            placeholder="Masukan Berat..."
                            className="form-control"
                            onChange={this.handleChangeAmount4}
                            value={amount4}

                            // value={ || ''}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <div className="d-flex flex-wrap gap-2 float-end mb-4">
                    <Link
                      className="btn btn-secondary"
                      role="button"
                      to={`/business-point/planogram/${iddevice}`}
                    >
                      Batal
                    </Link>{' '}
                    <Button type="submit" color="primary">
                      Simpan
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </AvForm>
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => ({
  initCreateResult: safeDeepGet(state, ['product', 'initCreate'], {}),
  readResult: safeDeepGet(state, ['businessPoint', 'readproduct'], {}),
  createResult: safeDeepGet(state, ['product', 'create'], {}),
  updateResult: safeDeepGet(state, ['businessPoint', 'updatePlanogram'], {}),
  ingfilterresult: safeDeepGet(state, ['product', 'list_ingredients'], {}),
  loading: safeDeepGet(state, ['product', 'loading'], true),
  test:state
})

const mapDispatchToProps = dispatch => ({
  InitCreateProduct: () => dispatch(initCreate()),
  ListIngress: () => dispatch(list_ingredients()),
  ReadProduct: payload => dispatch(readproduct(payload)),
  CreateProduct: payload => dispatch(create(payload)),
  UpdateProduct: payload => dispatch(updatePlanogram(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InfoPlanogram)
