/* eslint-disable no-unused-vars */
/* eslint-disable semi */import firebase from 'firebase/compat/app';
import { safeDeepGet } from 'iotera-base/utility/json';
import Base from './base';

export default class Config extends Base {
  constructor(firebase, applicationId) {
    super(firebase, applicationId, 'mapping_project');
  }

  list = () => {
    return new Promise((resolve, reject) => {
      const map = {};
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            map[doc.id] = doc.data();
          });
          resolve({ code: 0, body: map });
        },
        error => {
          resolve({ code: -1, body: error });
        }
      );
    });
  };

  create = (data) => {
    return new Promise((resolve, reject) => {
      const id = safeDeepGet(data, 'id', '-');
      this.collection
      .doc(id)
        .set(data)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    })
  }
}
