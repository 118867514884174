import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';

const SalesAnalytics = props => {
  const { series } = props;

  if (series.length > 0) {
    var chart_label = [];
    var chart_data = [];

    for (const product in series) {
      chart_label.push(series[product].name);
      chart_data.push(series[product].value);
    }

    const options = {
      maintainAspectRatio: false,
      labels: chart_label,
      legend: {
        show: 1,
        position: 'right',
        horizontalAlign: 'center',
        offsetX: -40,
      },
      plotOptions: {
        donut: {
          size: '60%',
        },
      },
    };

    return (
      <React.Fragment>
        <Col xl="12" className="d-flex align-self-stretch ">
          <Card className="flex-fill">
            <CardBody>
              <h4 className="card-title mb-4">Sales Analytics</h4>
              <p>Rasio penjualan (Item) per produk</p>
              <Col xl="7" className="offset-md-2">
                <div>
                  <div id="donut-chart" className="apex-charts">
                    <ReactApexChart
                      options={options}
                      series={chart_data}
                      type="donut"
                      labels={chart_label}
                    />
                  </div>
                </div>
              </Col>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
};

export default SalesAnalytics;
