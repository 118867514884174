/* eslint-disable no-unused-vars */

/* eslint-disable semi */

/* Import */
import { APPLICATION_ID } from 'Apps';
import firebase from 'firebase/compat/app';

import Base from './base';
import { safeDeepDel, safeGet } from 'iotera-base/utility/json';

export default class BusinessPoint extends Base {
  constructor(firebase, applicationId) {
    super(firebase, applicationId, 'business_point_fee');
  }
  updateFee = data => {
    return new Promise((resolve, reject) => {
      const id = safeGet(data, 'id', '');
      const insertedPayload = {};
      for (const key in data) {
        if (
          key === 'id' ||
          key === 'share_amount' ||
          key === 'type' 
        ) {
          insertedPayload[`fee.${key}`] = data[key];
        }
      }
      this.collection
        .doc(id)
        .update(insertedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };
  readFee = id => {
    return new Promise((resolve, reject) => {
      let  map = {}
      this.collection
        .doc(id)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };
  list = () => {
    return new Promise((resolve, reject) => {
      const map = {};

      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            map[doc.id] = doc.data();
          });
          resolve({ code: 0, body: map });
        },
        error => {
          resolve({ code: -1, body: error });
        }
      );
    });
  };

  map = () => {
    return this.list();
  };

  create = (id, payload) => {
    return new Promise((resolve, reject) => {
      const insertedPayload = {};
      for (const key in payload) {
        if (
          key === 'address' ||
          key === 'cp' ||
          key === 'device_id' ||
          key === 'email' ||
          key === 'name' ||
          key === 'pic' ||
          key === 'refiller' ||
          key === 'sn'
        ) {
          insertedPayload[key] = payload[key];
        }
      }
      const sn = payload['sn']; 
      const end = sn.length;
      const start = end - 4;
      const codesn = sn.substring(start, end);
      const endapp = APPLICATION_ID.length;
      const startapp = endapp - 4;
      const appSn = APPLICATION_ID.substring(startapp, endapp);
      const vmcode = appSn + '-' + codesn;
      insertedPayload['vmcode'] = vmcode;
      // Add location
      if ('latitude' in payload && 'longitude' in payload) {
        insertedPayload['loc'] = new firebase.firestore.GeoPoint(
          payload['latitude'],
          payload['longitude']
        );
      }

      // Add device id
      insertedPayload['device_id'] = id;

      this.collection
        .doc(id)
        .set(insertedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  read = deviceId => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .where('device_id', '==', deviceId)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };

  update = (id, name) => {
    return new Promise((resolve, reject) => {
      this.collection.where('device_id', '==', id)
      .get()
      .then(
        querySnapshot => {
          let code = -1;
          if (querySnapshot.size > 0) {
            code = 0;
            const data = querySnapshot.docs[0].data()
            if(data.business_point != name){
              this.collection.doc(id).update({business_point: name})
              .then(
                () => {
                  resolve({ code: 0 });
                },
                error => {
                  resolve({ code: -1, body: error });
                }
              );
            }
            resolve({ code: 0 });
          }
        },
        error => {
          resolve({ code: -1 });
        }
      );
    });
  };

  delete_ = deviceId => {
    this.collection.doc(deviceId).delete();
  };

  duplicate = (id, newId) => {};
}
