/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import no_image from '../../../Base/assets/images/no_image.png';
export default class Storage {
  constructor(firebase) {
    this.storage = firebase.storage();
  }

  replace = (file, sku, ext) => {
    return new Promise((resolve, reject) => {
      let metadata;
      if (ext === 'png') {
        metadata = {
          contentType: 'image/png',
        };
      }
      else if (ext === 'jpg') {
        metadata = {
          contentType: 'image/jpeg',
        };
      } else if (ext == 'xlsx') {
        metadata = {
          contentType:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        };
      
      } else if (ext == 'word') {
        metadata = {
          contentType:
            'application/msword',
        };
      }

      const filename = sku + '.' + ext;

      this.storage
        .ref(`/bukti_transfer/${filename}`)
        .put(file, metadata)
        .then(
          snapshot => {
            snapshot.ref.getDownloadURL().then(
              url => {
                resolve({ code: 0, body: {url} });
              }
            )
          },
          error => {
            resolve({ code: -1 });
          }
        );
    });
  };
  defaultImage = (file) => {
    return new Promise((resolve, reject) => {
      const metadata = {
        contentType: "image/png",
      };
      console.log(file);
      this.storage
      .ref(`/product/no_image.png`)
      .put(file, metadata)
      .then(
        snapshot => {
          snapshot.ref.getDownloadURL().then(
            url => {
              resolve({ code: 0, body: {url} });
            }
          )
        },
        error => {
          resolve({ code: -1 });
        }
      );
    })
  }
  delete_ = () => {
    return new Promise((resolve, reject) => {
      const list = {};
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          resolve({ code: -1, body: list });
        }
      );
    });
  };
}
