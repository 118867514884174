/* eslint-disable semi */
export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "DISCOUNT_LIST";
export const LIST_BUSINESS_POINTS = "DISCOUNT_LIST_BUSINESS_POINTS";
export const LIST_PRODUCT= "DISCOUNT_LIST_PRODUCT";
export const INIT_CREATE = "DISCOUNT_INIT_CREATE";
export const CREATE = "DISCOUNT_CREATE";
export const CANCEL = "DISCOUNT_CANCEL";
export const READ = "DISCOUNT_INFO";
export const UPDATE = "DISCOUNT_UPDATE";
export const STOCK_UPDATE = "STOCK_DISCOUNT_UPDATE";
export const DELETE = "DISCOUNT_DELETE";
export const DETAIL_CHAT = "DETAIL_DISCOUNT";
