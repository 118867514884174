/* eslint-disable semi */
export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "SUBSCRIPTION_LIST";
export const UNTAG_DEVICE = "SUBSCRIPTION_UNTAG_DEVICE";
export const EXTEND_SUBS = "SUBSCRIPTION_EXTEND_SUBS";
export const LIST_SUBS = "SUBSCRIPTION_LIST_SUBS";
export const LIST_DEVICE_SUBS = "SUBSCRIPTION_LIST_DEVICE_SUBS";
export const INIT_CREATE = "SUBSCRIPTION_INIT_CREATE";
export const CREATE = "SUBSCRIPTION_CREATE";
export const EDIT = "SUBSCRIPTION_EDIT";
export const CANCEL = "SUBSCRIPTION_CANCEL";
export const READ = "SUBSCRIPTION_INFO";
export const UPDATE = "SUBSCRIPTION_UPDATE";
export const STOCK_UPDATE = "STOCK_SUBSCRIPTION_UPDATE";
export const DELETE = "SUBSCRIPTION_DELETE";
export const DETAIL_CHAT = "DETAIL_SUBSCRIPTION";
export const CHECK_PAYMENT = "CHECK_PAYMENT_SUBSCRIPTION";
