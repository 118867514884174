/* eslint-disable semi */
/* eslint-disable no-unused-vars */
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  LIST_VM,
} from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
import {
  adminListUser,
  deletedUser,
  createdUser,
} from '../../webservice/device';

function* list(payload) {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body');
    const app_id = safeDeepGet(payload, 'appId');
    const getUser = safeDeepGet(mapBody, [app_id, 'url_get_user'], '');
    wsResult = yield call(adminListUser, getUser);
    const code = safeDeepGet(wsResult, 'code', Result.FAILED);

    if (code == Result.SUCCESS || code == Result.HTTP.OK) {
      const userBody = safeDeepGet(wsResult, ['body', 'users'], []);
      yield put(result(LIST, { code: Result.SUCCESS, body: userBody }));
      return;
    }
    yield put(result(LIST, { result: { code: Result.FAILED } }));
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}

function* delete_(payload) {
  yield put(loading(DELETE));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body');
    const app_id = safeDeepGet(payload, 'appId');
    const urlDelete = safeDeepGet(mapBody, [app_id, 'url_deleted_user'], '');
    const userUid = safeDeepGet(payload, ['payload', 'uid'], '');
    if ((urlDelete != null, urlDelete != undefined)) {
      wsResult = yield call(deletedUser, userUid, urlDelete);
      const code = safeDeepGet(wsResult, 'code', Result.FAILED);
      if (code === Result.SUCCESS || code === Result.HTTP.OK) {
        yield put(result(DELETE, { code: Result.SUCCESS }));

        return;
      } else {
        yield put(result(DELETE, { code: Result.FAILED }));
      }
    }
  }
  yield put(result(DELETE, { code: Result.FAILED }));
}
function* create(action) {
  yield put(loading(CREATE));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body');
    const app_id = safeDeepGet(action, 'appId');
    const urlCreated = safeDeepGet(mapBody, [app_id, 'url_created_user'], '');
    const email = safeDeepGet(action, ['payload', 'email']);
    const pass = safeDeepGet(action, ['payload', 'password']);
    wsResult = yield call(createdUser, email, pass, urlCreated);
    const code = safeDeepGet(wsResult, 'code', Result.FAILED);

    if (code === Result.SUCCESS || code === Result.HTTP.OK) {
      yield put(result(CREATE, { code: Result.SUCCESS }));
      return;
    } else {
      yield put(result(CREATE, { code: Result.FAILED }));
    }
  }
  yield put(result(CREATE, { code: Result.FAILED }));
}
function* productSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(DELETE, delete_);
  yield takeEvery(CREATE, create);
}

export default productSaga;
