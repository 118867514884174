/* eslint-disable no-unused-vars */
/* eslint-disable semi */import firebase from 'firebase/compat/app';
export default class Config {
  constructor(firebase) {
      this.collection = firebase.firestore().collection('config');
  }
  list = () => {
    return new Promise((resolve, reject) => {
      const map = {};
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            map[doc.id] = doc.data();
          });
          resolve({ code: 0, body: map });
        },
        error => {
          resolve({ code: -1, body: error });
        }
      );
    });
  };
}
