/* eslint-disable semi */
/* eslint-disable no-unused-vars */
import {
  forceDeepSet,
  has,
  safeDeepGet,
  safeDeepSet,
  safeDel,
} from 'iotera-base/utility/json';
export default class Product {
  constructor(firebase) {
    this.collection = firebase.firestore().collection('planogram_activity');
    this.FieldValue = firebase.firestore.FieldValue;
  }

  read = id => {
    return new Promise((resolve, reject) => {
      const list = {};

      this.collection
        .where('id', '==', id)
        .get()
        .then(
          querySnapshot => {
            querySnapshot.docs.map(doc => {
              list[doc.id] = doc.data();
            });
            resolve({ code: 0, body: list });
          },
          error => {
            resolve({ code: -1, body: list });
          }
        );
    });
  };
  // list = () => {
  //   return new Promise((resolve, reject) => {
  //     const list = {};
  //     this.collection.get().then(
  //       querySnapshot => {
  //         querySnapshot.docs.map(doc => {
  //           list[doc.id] = doc.data();
  //         });
  //         resolve({ code: 0, body: list });
  //       },
  //       error => {
  //         resolve({ code: -1, body: list });
  //       }
  //     );
  //   });
  // };
  list = (device_id, payload) => {
    return new Promise((resolve, reject) => {
      let planogramMap = {};
     
      let query = this.collection;
      if(device_id != null || device_id != undefined) {
        query = query.where('id', '==', device_id).orderBy("__name__", "desc").limit(1);
      } else {
        var filter_bp = safeDeepGet(payload, 'businessPoints');
        var filter_sd = safeDeepGet(payload, 'dateRange')[0];
        var filter_ed = safeDeepGet(payload, 'dateRange')[1];
        if (filter_bp == "all" || filter_bp == null || filter_bp == undefined) {
          filter_bp = null;
        }
        if (filter_sd == "all" || filter_sd == null || filter_sd == undefined) {
          filter_sd = null;
        }
        if (filter_ed == "all" || filter_ed == null || filter_ed == undefined) {
          filter_ed = null;
        }
        if (filter_bp) {
          query = query.where("id", "==", filter_bp);
        }
        if (filter_sd) {
          var startfulldate = filter_sd.getTime();
          query = query.where(
            "time_stamp",
            ">=",
            startfulldate
          );
        }
  
        if (filter_ed) {
          const d = filter_ed;
          d.setDate(d.getDate() + 1);
          var endfulldate = d.getTime();
          query = query.where(
            "time_stamp",
            "<=",
            endfulldate
          );
        }
      }
      
      query.get().then(
        querySnapshot =>  {
          querySnapshot.docs.map(doc => {
            planogramMap[doc.id] = doc.data();
          });
          resolve({ code: 0, body: planogramMap });
        },
        error => {
          console.log(error);
          resolve({ code: -1, body: error });
        }
      );
    });
  };
  create = (id, payload, name) => {
    return new Promise((resolve, reject) => {
      const storage = localStorage.getItem('authUser');
      const jsonStorage = JSON.parse(storage);
      const authUser = safeDeepGet(jsonStorage, 'email', '-');
      const insertedPayload = {};
      // for (const key in payload) {
      //     insertedPayload[key] = payload[key];
      // }
      insertedPayload['planogram'] = payload;
      insertedPayload['user'] = authUser;
      insertedPayload['id'] = id;
      insertedPayload['name'] = name;
      const timeStamp = new Date().getTime();
      insertedPayload['time_stamp'] = timeStamp;
      this.collection
        .doc(timeStamp.toString())
        .set(insertedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            console.log(error);
            resolve({ code: -1, body: error });
          }
        );
    });
  };
  addCsv = payload => {
    return new Promise((resolve, reject) => {
      for (var i in payload) {
        const device_id = payload[i]['id']
        const selection = payload[i]['selection']
        var id = device_id+ "_" + selection;
        // eslint-disable-next-line no-self-assign
        payload[i]['sku'] = payload[i]['sku'];
        payload[i]['price'] = Number(payload[i]['price']);
        payload[i]['order'] = Number(payload[i]['order']);
        delete payload[i]['DeviceId'];
        delete payload[i]['amount 1'];
        delete payload[i]['amount 2'];
        delete payload[i]['amount 3'];
        delete payload[i]['amount 4'];
        delete payload[i]['ingredients 1'];
        delete payload[i]['ingredients 2'];
        delete payload[i]['ingredients 3'];
        delete payload[i]['ingredients 4'];
        if (id !== undefined) {
          this.collection
            .doc(id)
            .set(payload[i])
            .then(
              () => {
                resolve({
                  code: 0,
                  Message: 'Berhasil Upload Csv Ke Database',
                });
              },
              error => {
                resolve({ code: -1, body: error });
              }
            );
        }
      }
    });
  };
  readproduct = id => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .doc(id)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.data()) {
              code = 0;
              map = { ...querySnapshot.data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };
  upsert = (id, planogram) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {
        device_id: id,
        planogram,
      };

      this.collection
        .doc(id)
        .set(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  addPlanogram = (id, payload) => {
    return new Promise((resolve, reject) => {
      const arr_planogram = payload.payload.value;
      let j = 0;
      for (j = 0; j < arr_planogram.length; j++) {
        var productid = '';
        var idProduct = '';
        const _chars =
          'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890';
        if (arr_planogram[j]['id_planogram'] == null) {
          const selection = arr_planogram[j]['selection'];
          if(selection == null ||  selection == undefined || selection == " ") {
            continue;
          }
          productid = id+ "_" + selection;
          idProduct = arr_planogram[j]['sku'];
          arr_planogram[j]['id'] = id;
          arr_planogram[j]['product_id'] = idProduct;
          arr_planogram[j]['active'] = true;
          if(arr_planogram[j]['0']){
            delete arr_planogram[j]['0'];
          }
          if(arr_planogram[j]['1']){
            delete arr_planogram[j]['1'];
          }
          if(arr_planogram[j]['2']){
            delete arr_planogram[j]['2'];
          }
          if(arr_planogram[j]['3']){
            delete arr_planogram[j]['3'];
          }
          this.collection
            .doc(productid)
            .set(arr_planogram[j])
            .then(
              () => {
                resolve({ code: 0 });
              },
              error => {
                resolve({ code: -1, body: error });
              }
            );
        } else {
          this.collection
            .doc(arr_planogram[j]['idplanogram'])
            .update(arr_planogram[j])
            .then(
              () => {
                resolve({ code: 0 });
              },
              error => {
                resolve({ code: -1, body: error });
              }
            );
        }
      }
    });
  };

  updateProduct = (id, payload, ingredients) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (key === 'display_name' || key === 'price' || key === 'size_name') {
          updatedPayload[key] = payload[key];
        }
      }
      updatedPayload['ingredients'] = ingredients;
      if (payload.sizeName == 'M') {
        updatedPayload['cup_size'] = '7';
      } else if (payload.sizeName == 'L') {
        updatedPayload['cup_size'] = '11';
      }
      this.collection
        .doc(id)
        .update(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  delete_ = id => {
    this.collection.doc(id).delete();
  };

  setDiscount = (id, discountPrice) => {
    return new Promise((resolve, reject) => {
      this.collection
        .where('productId', '==', id)
        .get()
        .then(snapshot => {
          if (snapshot.size > 0) {
            snapshot.docs.forEach(doc => {
              this.collection
                .doc(doc.id)
                .update({ discountPrice: discountPrice });
            });
          }
        });
    });
  };
  deleteDiscount = (id) => {
    return new Promise((resolve, reject) => {
      this.collection
        .where('productId', '==', id)
        .get()
        .then(snapshot => {
          if (snapshot.size > 0) {
            snapshot.docs.forEach(doc => {
              this.collection
                .doc(doc.id)
                .update({ 'discountPrice': this.FieldValue.delete() });
            });
          }
        });
    });
  };
}
