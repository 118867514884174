/* eslint-disable semi */
export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "MAP_LIST";
export const LIST_BALANCE = "MAP_LIST_BALANCE";
export const LIST_VM = "MAP_LIST_VM";
export const INIT_CREATE = "MAP_INIT_CREATE";
export const CREATE = "MAP_CREATE";
export const READ = "MAP_INFO";
export const UPDATE = "MAP_UPDATE";
export const STOCK_UPDATE = "STOCK_MAP_UPDATE";
export const DELETE = "MAP_DELETE";
export const MANUAL_UPDATE = "MAP_MANUAL_UPDATE";
export const TOGGLE_REFUND = "MAP_TOGGLE_REFUND";
export const ADD_PROJECT_HANDLE = "MAP_PROJECT_HANDLE";

