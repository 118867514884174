/* eslint-disable semi */
import { call, put, takeEvery, delay } from 'redux-saga/effects';

// Business-point state
import {
  LIST,
  INIT_CREATE,
  CREATE,
  READ_INFO,
  UPDATE_INFO,
  READ_PLANOGRAM,
  UPDATE_PLANOGRAM,
  DELETE,
  STOCK_BUSINESS_POINT,
  INPUT_CSV,
  CONFIG_PLANOGRAM,
  HISTORY, NOTIFICATIONS,
  CLOSE_NOTIFICATIONS,
  SUMMARY,
  UPDATE_TIME
} from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import {
  device_map as deviceMap,
  sensors,
  setConfig3,
  stockPlanogram,
  stockEmpty, get_notifications,
  close_notification
} from '../../webservice/device';
import {
  forceDeepSet,
  has,
  safeDeepGet,
  safeDeepSet,
  safeDel,
} from 'iotera-base/utility/json';
import { JPOST } from 'iotera-base/webservice/wsrequest';
import { isEmpty } from 'iotera-base/utility/text';
import Result from 'iotera-base/result';
import { APP_ID } from '../../../Project';

import {getSummaryDevice,getBussinessPoint, update_time} from '../../webservice/device'

function* inputCsv(action) {
  yield put(loading(INPUT_CSV));
  const payload = safeDeepGet(action, ['payload']);
  for (const i in payload) {
    const ingredient = safeDeepGet(payload[i], ['ingredients 1']);
    const ingredient2 = safeDeepGet(payload[i], ['ingredients 1']);
    const ingredient3 = safeDeepGet(payload[i], ['ingredients 2']);
    const ingredient4 = safeDeepGet(payload[i], ['ingredients 3']);
    const amount = safeDeepGet(payload[i], ['amount 1']);
    const amount2 = safeDeepGet(payload[i], ['amount 2']);
    const amount3 = safeDeepGet(payload[i], ['amount 3']);
    const amount4 = safeDeepGet(payload[i], ['amount 4']);
    const ingredients = [];
    if (ingredient) {
      if (amount) {
        ingredients.push({ type: ingredient, amount: Number(amount) });
      }
    }
    if (ingredient2) {
      if (amount2) {
        ingredients.push({ type: ingredient2, amount: Number(amount2) });
      }
    }
    if (ingredient3) {
      if (amount3) {
        ingredients.push({ type: ingredient3, amount: Number(amount3) });
      }
    }
    if (ingredient4) {
      if (amount4) {
        ingredients.push({ type: ingredient4, amount: Number(amount4) });
      }
    }
    payload[i].ingredients = ingredients;
  }
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.csv.addCsv, payload);
  yield put(result(INIT_CREATE, { code: wsResult }));
}

function* list(payload) {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  const data = safeDeepGet(payload, ['payload']);
  let wsResult = yield call(firebaseInstance.businessPoint.list, data);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointBody = wsResult.body;

    const businessPointMap = {};
    for (const id in businessPointBody) {
      const deviceId = safeDeepGet(businessPointBody, [id, 'device_id'], '');
      businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
    }

    wsResult = yield call(deviceMap);
    if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
      const devices = safeDeepGet(wsResult, ['body', 'devices']);
      const businessPointList = [];
      let i = 0;
      for (const deviceId in businessPointMap) {
        const online = safeDeepGet(
          devices,
          [deviceId, 'online', 'is_online'],
          false
        );
        const serial_number = safeDeepGet(
          devices,
          [deviceId, 'serial_number'],
          '-'
        );
        const latest_online =  safeDeepGet(devices, [deviceId, 'online', 'last_online_ts'], 0)
        const latest_offline = safeDeepGet(
          devices,
          [deviceId, 'online', 'last_offline_ts'],
          0
        );
        safeDeepSet(businessPointMap, [deviceId, 'last_online'], latest_online);
        safeDeepSet(
          businessPointMap,
          [deviceId, 'last_offline'],
          latest_offline
        );
        safeDeepSet(businessPointMap, [deviceId, 'id'], i++);
        safeDeepSet(businessPointMap, [deviceId, 'online'], online);
        safeDeepSet(businessPointMap, [deviceId, 'serial_number'], serial_number);
        businessPointList.push(businessPointMap[deviceId]);
      }
      yield put(
        result(LIST, { code: Result.SUCCESS, body: businessPointList })
      );
      return;
    }
  }
  yield put(result(LIST, { code: Result.FAILED }));
}
function* summary(payload){
  yield put(loading(SUMMARY))
  const firebaseInstance = getInstance()
  let wsResult = yield call(firebaseInstance.mapping.list)
  const code = safeDeepGet(wsResult, 'code', Result.FAILED)
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body')
    const app_id = safeDeepGet(payload, ['payload','appId'])
    const deviceId = safeDeepGet(payload, ['payload','device_id'])
    const url = safeDeepGet(mapBody, [app_id, 'url_summary_device'], '')
    wsResult = yield call(getSummaryDevice, url, deviceId)
    const code =safeDeepGet(wsResult, 'code', Result.FAILED)
    if(code == Result.SUCCESS || code == Result.HTTP.OK){
      const sumBody = safeDeepGet(wsResult, 'body', {})
      yield put(result(SUMMARY, {result: { code: Result.SUCCESS, body: sumBody}}))
      return;
    }
    yield put(result(SUMMARY, { result: { code: Result.FAILED } }))
  }
  yield put(result(SUMMARY, { result: { code: Result.FAILED } }))
}
function* stockBusinessPoint(payload) {
  yield put(loading(STOCK_BUSINESS_POINT));
  const firebaseInstance = getInstance();
  // const value = safeDeepGet(payload, ['payload', 'stock'], '');
  const operator = safeDeepGet(payload, ['payload', 'operator'], '');
  if (operator == 'all') {
    let wsResult = yield call(firebaseInstance.businessPoint.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const businessPointBody = wsResult.body;
      const stockList = [];
      const businessPointMap = {};
      for (const id in businessPointBody) {
        const deviceId = safeDeepGet(businessPointBody, [id, 'device_id'], '');
        const name = safeDeepGet(businessPointBody, [id, 'name'], '');
        const address = safeDeepGet(businessPointBody, [id, 'address'], '');
        businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
        wsResult = yield call(stockPlanogram, deviceId);
        if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
          yield put(result(STOCK_BUSINESS_POINT, { code: Result.FAILED }));
          return;
        }
        let planogramResult = {};
        const productResult = {};
        const sensorsList = safeDeepGet(wsResult, ['body', 'sensors']);
        const PlannogramMap = {};
        forceDeepSet(productResult, ['bpname'], name);
        forceDeepSet(productResult, ['address'], address);
        forceDeepSet(productResult, ['planogram'], planogramResult);
        const data_filter = sensorsList.filter(
          element => element.device_id == deviceId
        );
        for (const i in data_filter) {
          const sensors = data_filter[i];
          // const sensors = sensorsList[i];
          const deviceId = safeDeepGet(sensors, ['device_id']);
          if (has(businessPointMap, deviceId)) {
            const sensor = safeDeepGet(sensors, ['sensor']);
            PlannogramMap[deviceId] = sensorsList;
            const configtype = safeDeepGet(sensors, ['configtype']);
            const param = safeDeepGet(sensors, ['param']);
            if ('config' === configtype && 'name' === param) {
              const value = safeDeepGet(sensors, ['latest_data', 'value'], '');
              forceDeepSet(planogramResult, [sensor, 'name'], value);
            } else if ('data' === configtype && 'stock' === param) {
              const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
              forceDeepSet(planogramResult, [sensor, 'qty'], value);
            } else if ('cdata' === configtype && 'stock' === param) {
              const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
              forceDeepSet(planogramResult, [sensor, 'qty'], value);
            }
          }
          // forceDeepSet(productResult, ['bpname'], name);
          // forceDeepSet(productResult, ['address'], address);
          forceDeepSet(productResult, ['planogram'], planogramResult);
        }
        // const stock = [];
        // const planogramMaps = safeDeepGet(productResult, ['planogram']);
        // for (const i in planogramMaps) {
        //   stock.push(planogramMaps[i]);
        // }
        // forceDeepSet(productResult, ['stock'], stock);
        stockList.push(productResult);
      }
      yield put(
        result(STOCK_BUSINESS_POINT, {
          code: Result.SUCCESS,
          body: stockList,
        })
      );
    }
  } else {
    let wsResult = yield call(firebaseInstance.businessPoint.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      let number = null;
      let operatorw = '=';
      if (operator == 'tersedia') {
        number = 3;
        operatorw = '>';
      } else if (operator == 'empty') {
        number = 1;
        operatorw = '<';
      } else if (operator == 'warning') {
        number = 3;
        operatorw = '<=';
      }

      const businessPointBody = wsResult.body;
      const stockList = [];
      const businessPointMap = {};
      for (const id in businessPointBody) {
        const deviceId = safeDeepGet(businessPointBody, [id, 'device_id'], '');
        const name = safeDeepGet(businessPointBody, [id, 'name'], '');
        const address = safeDeepGet(businessPointBody, [id, 'address'], '');
        businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
        wsResult = yield call(stockEmpty, number, operatorw);
        if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
          yield put(result(STOCK_BUSINESS_POINT, { code: Result.FAILED }));
          return;
        }
        const planogramResult = {};
        const productResult = {};
        const sensorsList = safeDeepGet(wsResult, ['body', 'sensors']);
        const PlannogramMap = {};
        forceDeepSet(productResult, ['bpname'], name);
        forceDeepSet(productResult, ['address'], address);
        forceDeepSet(productResult, ['planogram'], planogramResult);
        const data_filter = sensorsList.filter(
          element => element.device_id == deviceId
        );
        for (const i in data_filter) {
          const sensors = data_filter[i];
          const deviceId = safeDeepGet(sensors, ['device_id']);
          if (has(businessPointMap, deviceId)) {
            const sensor = safeDeepGet(sensors, ['sensor']);
            PlannogramMap[deviceId] = data_filter;
            const configtype = safeDeepGet(sensors, ['configtype']);
            const param = safeDeepGet(sensors, ['param']);
            if ('config' === configtype && 'name' === param) {
              const value = safeDeepGet(sensors, ['latest_data', 'value'], '');
              forceDeepSet(planogramResult, [sensor, 'name'], value);
            } else if ('data' === configtype && 'stock' === param) {
              const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
              forceDeepSet(planogramResult, [sensor, 'qty'], value);
            } else if ('cdata' === configtype && 'stock' === param) {
              const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
              forceDeepSet(planogramResult, [sensor, 'qty'], value);
            }
          }
          forceDeepSet(productResult, ['planogram'], planogramResult);
        }
        const planogramMaps = safeDeepGet(productResult, ['planogram']);
        if (operator == 'warning') {
          let filterWarning = {};
          for (const i in planogramMaps) {
            if (planogramMaps[i].qty > 0) {
              filterWarning[i] = planogramMaps[i];
            }
          }
          forceDeepSet(productResult, ['planogram'], filterWarning);
        }
        stockList.push(productResult);
      }
      yield put(
        result(STOCK_BUSINESS_POINT, {
          code: Result.SUCCESS,
          body: stockList,
        })
      );
    }
  }
}
function* initCreate() {
  yield put(loading(INIT_CREATE));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  if (safeDeepGet(wsResult, 'code') === Result.SUCCESS) {
    const businessPointBody = wsResult.body;

    const businessPointMap = {};
    for (const id in businessPointBody) {
      const device_id = safeDeepGet(businessPointBody, [id, 'device_id'], '');
      businessPointMap[device_id] = safeDeepGet(businessPointBody, id);
    }

    wsResult = yield call(deviceMap);
    if (safeDeepGet(wsResult, 'code') === Result.HTTP.OK) {
      const devices = safeDeepGet(wsResult, ['body', 'devices']);
      const unregisteredDevices = [];
      for (const deviceId in devices) {
        if (!has(businessPointMap, deviceId)) {
          unregisteredDevices.push({
            label: safeDeepGet(devices, [deviceId, 'serial_number'], '-'),
            value: deviceId,
          });
        }
      }
      yield put(
        result(INIT_CREATE, {
          code: 0,
          body: { unregistered_devices: unregisteredDevices },
        })
      );
      return;
    }
  }
  yield put(result(INIT_CREATE, { code: Result.FAILED }));
}

function createBp(id, name) {
  var result = JPOST({
    url: 'https://pay.iotera.io/web/device/update',
    headers: {
      'Iotera-Payment-Application-Id': APP_ID,
      'Iotera-Payment-Admin-Token':
        '6bf868df24a4357dee20e6d3d6ef1d1944249ad44cb29687446d346f60fc0215',
    },
    body: {
      device_id: id,
      name: name,
    },
  })
    .then(function (response) {
      return response;
    })
    .then(data => data);
  return result;
}

function* create(action) {
  yield put(loading(CREATE));
  const id = safeDeepGet(action, ['payload', 'id']);
  const name = safeDeepGet(action, ['payload', 'name']);
  const payload = safeDeepGet(action, 'payload');
  safeDel(payload, 'id');
  const firebaseInstance = getInstance();
  yield call(createBp, id, name);
  const wsResult = yield call(
    firebaseInstance.businessPoint.create,
    id,
    payload
  );
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(CREATE, { code }));
}

function* updateTime(action) {
  yield put(loading(UPDATE_TIME));
  const firebaseInstance = getInstance()
  let wsResult = yield call(firebaseInstance.mapping.list)
  const code = safeDeepGet(wsResult, 'code', Result.FAILED)
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body')
    const app_id = safeDeepGet(action, ['payload','id'])
    const deviceId = safeDeepGet(action, ['payload','device_id'])
    const date = safeDeepGet(action, ['payload','date'])
    const start_date = safeDeepGet(action, ['payload','startDate'], 0)
    const end_date = safeDeepGet(action, ['payload','endDate'], 0)
    const url = safeDeepGet(mapBody, [app_id, 'url_update_time'], '')
    
    wsResult = yield call(update_time, url, deviceId, date, start_date, end_date)
    const code =safeDeepGet(wsResult, 'code', Result.FAILED)
    if(code == Result.SUCCESS || code == Result.HTTP.OK){
      yield put(result(UPDATE_TIME, {result: { code: Result.SUCCESS}}))
      return;
    }
    yield put(result(UPDATE_TIME, { result: { code: Result.FAILED } }))
  }
  yield put(result(UPDATE_TIME, { result: { code: Result.FAILED } }))
}
function* readInfo(action) {
  yield put(loading(READ_INFO));
  const firebaseInstance = getInstance()
  let wsResult = yield call(firebaseInstance.mapping.list)
  const code = safeDeepGet(wsResult, 'code', Result.FAILED)
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body')
    const app_id = safeDeepGet(action, ['payload','id'])
    // const deviceId = safeDeepGet(action, ['payload','device_id'])
    const url = safeDeepGet(mapBody, [app_id, 'url_business_point'], '')

    wsResult = yield call(getBussinessPoint, url)
    const code =safeDeepGet(wsResult, 'code', Result.FAILED)
    if(code == Result.SUCCESS || code == Result.HTTP.OK){
      const sumBody = safeDeepGet(wsResult, 'body', {})
      const maps = {}
      for(const i in sumBody){
        const id = safeDeepGet(sumBody[i], 'device_id', safeDeepGet(sumBody[i], 'id', ''))
        maps[id] = sumBody[i]
      }
      yield put(result(READ_INFO, {result: { code: Result.SUCCESS, body: maps}}))
      return;
    }
    yield put(result(READ_INFO, { result: { code: Result.FAILED } }))
  }
  yield put(result(READ_INFO, { result: { code: Result.FAILED } }))
}

function* updateInfo(action) {
  yield put(loading(UPDATE_INFO));
  const id = safeDeepGet(action, ['payload', 'id']);
  const name = safeDeepGet(action, ['payload', 'name']);
  const payload = safeDeepGet(action, 'payload');
  safeDel(payload, 'id');
  const firebaseInstance = getInstance();
  yield call(createBp, id, name);
  const wsResult = yield call(
    firebaseInstance.businessPoint.update,
    id,
    payload
  );
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(UPDATE_INFO, { code }));
  yield call(firebaseInstance.bpfee.update, id ,name)
}

function* readPlanogram(action) {
  yield put(loading(READ_PLANOGRAM));
  const id = safeDeepGet(action, ['payload', 'id']);
  const firebaseInstance = getInstance();
  const data = yield call(firebaseInstance.planogram.list, id);
  const historyPlanogram = [];
  const historyBody = safeDeepGet(data, 'body');
  var planogramBody = {};
  const body = {};

  for (const i in historyBody) {
    planogramBody = safeDeepGet(historyBody, [i, 'planogram']);
    body['name'] = safeDeepGet(historyBody, [i, 'name']);
    body['id'] = safeDeepGet(historyBody, [i, 'id']);
    body['user'] = safeDeepGet(historyBody, [i, 'user']);
    body['time_stamp'] = Number(i);
  }

  for (const i in planogramBody) {
    planogramBody[i]['key'] = i;
    historyPlanogram.push(planogramBody[i]);
  }
  body['planogram'] = historyPlanogram;
  // Result
  const planogramResult = {};
  const productResult = {};

  // Retrieve product
  let wsResult = yield call(firebaseInstance.product.list);

  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ_PLANOGRAM, { code: Result.FAILED }));
    return;
  }
  const productMap = safeDeepGet(wsResult, ['body']);
  for (const sku in productMap) {
    productResult[sku] = safeDeepGet(productMap, [sku, 'name'], '');
  }

  // Retrieve sensors from platform
  wsResult = yield call(sensors, id);
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    yield put(result(READ_PLANOGRAM, { code: Result.FAILED }));
    return;
  }
  const sensorsList = safeDeepGet(wsResult, ['body', 'sensors']);
  for (const i in sensorsList) {
    const sensors = sensorsList[i];
    const sensor = safeDeepGet(sensors, ['sensor']);
    const configtype = safeDeepGet(sensors, ['configtype']);
    const param = safeDeepGet(sensors, ['param']);
    if ('config' === configtype && 'id' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], '');
      safeDeepSet(planogramResult, [sensor, 'product'], value);
      const nameP = safeDeepGet(productMap, [value, 'name'], '')
      safeDeepSet(planogramResult, [sensor, 'nameProduct'], nameP);
    } else if ('config' === configtype && 'price' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      safeDeepSet(planogramResult, [sensor, 'price'], value);
    } else if ('config' === configtype && 'active' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], false);
      safeDeepSet(planogramResult, [sensor, 'active'], value);
    } else if ('cdata' === configtype && 'stock' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      safeDeepSet(planogramResult, [sensor, 'stock'], value);
    } else if ('config' === configtype && 'column' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      safeDeepSet(planogramResult, [sensor, 'column'], value);
    }
  }
  wsResult = yield call(firebaseInstance.businessPoint.read, id);
  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ_PLANOGRAM, { code: Result.FAILED }));
    return;
  }
  const type = safeDeepGet(wsResult, ['body', 'type'], 'nagomi')
  wsResult = yield call(firebaseInstance.config.list);
  let columnPlanogram = [];
  let buttonPlanogram = [];
  if(type == "nagomi"){
    columnPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_nagomi', 'column'],
      []
    );
    buttonPlanogram = safeDeepGet(
     wsResult,
     ['body', 'planogram_nagomi', 'button'],
     []
   );
  } else if (type == "slant") {
    columnPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram', 'column'],
      []
    );
    buttonPlanogram = safeDeepGet(
     wsResult,
     ['body', 'planogram', 'button'],
     []
   );
  } else if (type == "kubota") {
    columnPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_kubota', 'column'],
      []
    );
    buttonPlanogram = safeDeepGet(
     wsResult,
     ['body', 'planogram_kubota', 'button'],
     []
   );
  } else {
    columnPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_big_nagomi', 'column'],
      []
    );
    buttonPlanogram = safeDeepGet(
     wsResult,
     ['body', 'planogram_bignagomi', 'button'],
     []
   );
  }
  const buttonList = [];
  for (const i in buttonPlanogram) {
    const value = buttonPlanogram[i];
    buttonList.push({ label: value, value: value });
  }
  yield put(
    result(READ_PLANOGRAM, {
      code: Result.SUCCESS,
      column: columnPlanogram,
      button: buttonPlanogram,
      type:type,
      body: {
        planogram: planogramResult,
        products: productResult,
        history: body,
      },
    })
  );
}

function* updatePlanogram(action) {
  yield put(loading(UPDATE_PLANOGRAM));
  const id = safeDeepGet(action, ['payload', 'id']);
  const planogram = safeDeepGet(action, ['payload', 'planogram'], null);
  if (planogram == null || planogram == undefined) {
    yield put(result(UPDATE_PLANOGRAM, { code: -1, message: wsResult }));
    return;
  }
  const stockMap = {};
  const idMap = {};
  const priceMap = {};
  const nameMap = {};
  const activeMap = {};
  const columnMap = {};
  // const stockst = {};
  for (const selection in planogram) {
    if (selection.slice(0, 1) == 'K') {
      const stock = safeDeepGet(planogram, [selection, 'stock'], 0);
      const product = safeDeepGet(planogram, [selection, 'product'], '');
      const name = safeDeepGet(planogram, [selection, 'name'], '');
      const price = safeDeepGet(planogram, [selection, 'price'], 0);
      const active = safeDeepGet(planogram, [selection, 'active'], false);
      if (!isEmpty(product)) {
        idMap[selection] = product;
        nameMap[selection] = name;
      }
      priceMap[selection] = price;
      activeMap[selection] = active;
      stockMap[selection] = stock;
    } else {
      const column = safeDeepGet(planogram, [selection, 'column']);
      columnMap[selection] = column;
    }
  }
  if (
    idMap == null ||
    nameMap == null ||
    priceMap == null ||
    idMap == undefined ||
    nameMap == undefined ||
    priceMap == undefined
  ) {
    yield put(result(UPDATE_PLANOGRAM, { code: -2, message: wsResult }));
    return;
  }
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  const businessPointBody = wsResult.body;
  const businessPointMap = {};
  for (const id in businessPointBody) {
    const deviceId = safeDeepGet(businessPointBody, [id, 'device_id'], '');
    businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
  }

  const nameBp = safeDeepGet(businessPointMap, [id, 'name'], '-');
  const planogramFb = {};
  for (const selection in planogram) {
    if (selection.slice(0, 1) == 'K') {
      planogramFb[selection] = safeDeepGet(planogram, selection);
    }
  }
  wsResult = yield call(
    setConfig3,
    id,
    idMap,
    nameMap,
    priceMap,
    activeMap,
    columnMap,
    stockMap
  );
  // Save stock to platform
  // let wsResult = yield call(setStock, id, stockMap);
  // if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
  //   yield put(
  //     result(UPDATE_PLANOGRAM, { code: Result.FAILED, message: wsResult })
  //   );
  //   return;
  // }

  // Delay
  yield delay(1000);

  // Save price to platform

  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    yield put(result(UPDATE_PLANOGRAM, { code: -3, message: wsResult }));
    return;
  }

  yield call(firebaseInstance.planogram.create, id, planogramFb, nameBp);
  yield put(
    result(UPDATE_PLANOGRAM, { code: Result.SUCCESS, message: wsResult })
  );
}

function* delete_(payload) {
  yield put(loading(DELETE));

  const firebaseInstance = getInstance();
  const deviceId = safeDeepGet(payload, ['payload', 'deviceId'], '');
  const wsResult = yield call(firebaseInstance.businessPoint.delete_, deviceId);
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(DELETE, { code }));
}

function* configList() {
  yield put(loading(CONFIG_PLANOGRAM));
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.config.list);
  const code = safeDeepGet(wsResult, 'code');
  const columnPlanogram = safeDeepGet(
    wsResult,
    ['body', 'planogram', 'column'],
    []
  );
  const buttonPlanogram = safeDeepGet(
    wsResult,
    ['body', 'planogram', 'button'],
    []
  );
  const buttonList = [];
  for (const i in buttonPlanogram) {
    const value = buttonPlanogram[i];
    buttonList.push({ label: value, value: value });
  }

  if (code !== Result.SUCCESS) {
    yield put(result(CONFIG_PLANOGRAM, { code: Result.FAILED }));
    return;
  }
  yield put(
    result(CONFIG_PLANOGRAM, {
      code: Result.SUCCESS,
      column: columnPlanogram,
      button: buttonPlanogram,
    })
  );
}
function* list_history(action) {
  const payload = safeDeepGet(action, 'payload')
    yield put(loading(HISTORY));
  const device_id = safeDeepGet(payload, 'device_id', null);
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.planogram.list,device_id, payload);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const historyBody = safeDeepGet(wsResult, 'body');
    const historyList = [];
    for (const id in historyBody) {
      const body = {};
      const planogramBody = safeDeepGet(historyBody, [id, 'planogram']);
      const historyPlanogram = [];
      for (const i in planogramBody) {
        planogramBody[i]['key'] = i;
        historyPlanogram.push(planogramBody[i]);
      }
      body['planogram'] = historyPlanogram;
      body['name'] = safeDeepGet(historyBody, [id, 'name']);
      body['id'] = safeDeepGet(historyBody, [id, 'id']);
      body['user'] = safeDeepGet(historyBody, [id, 'user']);
      body['time_stamp'] = Number(id);
      historyList.push(body);
    }
    yield put(result(HISTORY, { code: Result.SUCCESS, body: historyList }));
  } else {
    yield put(result(HISTORY, { code: Result.FAILED }));
  }
}
function* notification() {
  yield put(loading(NOTIFICATIONS));
  const wsResult = yield call(get_notifications);
  const code = safeDeepGet(wsResult, 'code');
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const notificationBody = safeDeepGet(wsResult, 'body');
    yield put(
      result(NOTIFICATIONS, { code: Result.SUCCESS, body: notificationBody })
    );
  } else {
    yield put(result(NOTIFICATIONS, { code: Result.FAILED }));
  }
}
function* closeNotification(payload) {
  yield put(loading(CLOSE_NOTIFICATIONS));
  const task_id = safeDeepGet(payload, ['payload','id'], '')
  const wsResult = yield call(close_notification, task_id);
  const code = safeDeepGet(wsResult, 'code');
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    yield put(
      result(CLOSE_NOTIFICATIONS, { code: Result.SUCCESS })
    );
  } else {
    yield put(result(CLOSE_NOTIFICATIONS, { code: Result.FAILED }));
  }
}
function* businessPointSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(INIT_CREATE, initCreate);
  yield takeEvery(CREATE, create);
  yield takeEvery(READ_INFO, readInfo);
  yield takeEvery(UPDATE_INFO, updateInfo);
  yield takeEvery(READ_PLANOGRAM, readPlanogram);
  yield takeEvery(UPDATE_PLANOGRAM, updatePlanogram);
  yield takeEvery(DELETE, delete_);
  yield takeEvery(STOCK_BUSINESS_POINT, stockBusinessPoint);
  yield takeEvery(INPUT_CSV, inputCsv);
  yield takeEvery(CONFIG_PLANOGRAM, configList);
  yield takeEvery(HISTORY, list_history);
  yield takeEvery(NOTIFICATIONS, notification);
  yield takeEvery(SUMMARY, summary);
  yield takeEvery(CLOSE_NOTIFICATIONS, closeNotification);
  yield takeEvery(UPDATE_TIME, updateTime);
}

export default businessPointSaga;
