/* eslint-disable semi */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import FitText from 'react-fittext';
// Iotera
import { safeDeepGet } from 'iotera-base/utility/json';

import { get_snapshot } from '../../store/dashboard/actions';
import ReactApexChart from 'react-apexcharts';
import {
  formatedNumber,
  getButtonNettTheme,
} from 'Apps';

const monthNames = [
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
];

class Earning extends Component {
  constructor(props) {
    super(props);
    var current_month = new Date();

    this.state = {
      months: monthNames.slice(0, current_month.getMonth() + 1),
      series: [],
      options: {
        chart: {
          toolbar: 'false',
          dropShadow: {
            enabled: !0,
            color: '#000',
            top: 18,
            left: 7,
            blur: 8,
            opacity: 0.2,
          },
        },
        xaxis: {
          tickAmount: 3,
        },
        dataLabels: {
          enabled: !1,
        },
        colors: ['#556ee6'],
        stroke: {
          curve: 'smooth',
          width: 3,
        },
      },
      selectedMonth: monthNames[current_month.getMonth()],
    };
    this.handleMonthYear = this.handleMonthYear.bind(this);
  }

  handleMonthYear = selectedMonth => {
    this.setState({ selectedMonth });
    var current_month = new Date();

    const payload = {
      month: selectedMonth.value,
      year: current_month.getFullYear(),
      dateRange: [null, null],
    };
    // this.props.GetNettSettlement(payload)
    this.props.GetSnapshot(payload);
  };

  componentDidMount() {
    var current_month = new Date();

    const payload = {
      businessPoints: 'all',
      month: this.state.selectedMonth.value,
      year: current_month.getFullYear(),
      // dateRange: [null, null],
    };
    this.props.GetSnapshot(payload);
  }

  render() {
    const nett_settlement = safeDeepGet(
      this.props,
      ['netsettlementresult', 'body'],
      []
    );
    const { selectedMonth } = this.state;

    const card_values = nett_settlement.card_values;
    const series = [
      {
        name: 'Revenue',
        data: nett_settlement.chart_data,
      },
    ];

    let card_change = safeDeepGet(card_values, ['card_change'], 0).toFixed(0);
    let card_sum_current = safeDeepGet(card_values, ['card_sum_current'], 0);
    let card_sum_prev = safeDeepGet(card_values, ['card_sum_prev'], 0);
    let card_mdr_current = safeDeepGet(card_values, ['card_mdr_current'], 0);
    let badge_change = 'badge-soft-danger';
    if (card_change >= 0) {
      badge_change = 'badge-soft-success';
    }
    return (
      <React.Fragment>
        <Col xl="7" className="d-flex align-self-stretch">
          <Card className="flex-fill">
            <CardBody>
              <Row className="mb-2">
                <Col sm="8">
                  <Label className="col-md-12 col-form-label">
                    <h4 className="card-title mb-4">Total Settlement</h4>
                  </Label>
                </Col>
                <Col sm="4">
                  <FormGroup className="mb-3 ajax-select select2-container">
                    <Select
                      value={selectedMonth}
                      onChange={this.handleMonthYear}
                      options={monthNames}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="4">
                  <div className="text-muted">
                    <div className="mb-4">
                      <p>Bulan ini</p>
                      <FitText maxFontSize={"24px"}>
                        <h4 className='max-w-48'>
                          Rp{' '}
                        {formatedNumber(card_sum_current.toFixed(0).toString())}</h4>
                      </FitText>
                      {/* <h4>
                        Rp{' '}
                        {formatedNumber(card_sum_current.toFixed(0).toString())}
                      </h4> */}
                      {card_sum_prev > 0 ? (
                        <div>
                          <span
                            className={`badge ${badge_change} font-size-12 me-1`}
                          >
                            {' '}
                            {card_change}%{' '}
                          </span>{' '}
                          dari bulan lalu
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="text-muted">
                      <div className="mb-4">
                        <p>MDR Bulan ini</p>
                          <FitText maxFontSize={"20px"}>
                            <h4 className='max-w-48'>
                              Rp{' '}
                              {formatedNumber(
                                card_mdr_current.toFixed(0).toString()
                              )}
                            </h4>
                        </FitText>

                      </div>
                    </div>
                    <div>
                      <Link
                        to="/transaction/report"
                        className={getButtonNettTheme()}
                      >
                        View Details{' '}
                        <i className="mdi mdi-chevron-right ms-1"></i>
                      </Link>
                    </div>

                    {card_sum_prev > 0 ? (
                      <div className="mt-4">
                        <p className="mb-2">Bulan lalu</p>
                        <FitText maxFontSize={"20px"}>
                          <h5>
                            Rp{' '}
                            {formatedNumber(card_sum_prev.toFixed(0).toString())}
                          </h5>
                        </FitText>

                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>

                <Col lg="8">
                  <div id="line-chart" className="apex-charts" dir="ltr">
                    <ReactApexChart
                      series={series}
                      options={this.state.options}
                      type="line"
                      height={320}
                      className="apex-charts"
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  netsettlementresult: safeDeepGet(state, ['dashboard', 'get_snapshot'], {}),
  loading: safeDeepGet(state, ['dashboard', 'loading'], true),
  // get_Balance: safeDeepGet(state, ['payout', 'get_Balance'], []),
});

const mapDispatchToProps = dispatch => ({
  GetSnapshot: (month, year) => dispatch(get_snapshot(month, year)),
  // GetBalance: () => dispatch(get_Balance()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Earning);
