/* eslint-disable react/prop-types */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  Label,
  Table,
  ModalBody,
} from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Tippy
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
//Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import {
  notification,
  close_notification,
} from '../../store/business-point/actions';
import { PROJECT_HANDLE, buildTitle } from 'Apps';
class NotificationList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    let type = safeDeepGet(this.props.match.params, 'type');
    this.state = {
      modalComplaint: false,
      refiller: [],
      type,
      complaint: {},
      NotificationColoumn: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => (
            <>{this.handleTimeStamp(row.open_time)}</>
          ),
        },
        {
          text: 'Type',
          dataField: 'type',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p>{row.type}</p>
            </>
          ),
        },
        {
          dataField: 'open_time',
          text: 'Time',
          formatter: (cellContent, row) => (
            <>
              <p>{row.open_time}</p>
            </>
          ),
        },
        {
          dataField: 'desc',
          text: 'Notification',
          formatter: (cellContent, row) => (
            <>
              <textarea 
                id="myTextArea"
                cols={ 90}
                rows={this.state.type =='complaint' ? 8 :14}
                disabled={true}
                value={
                  safeDeepGet(row, 'desc', '') == ''
                    ? this.escapeHtml(
                        safeDeepGet(
                          row,
                          ['notification_open', 0, 'detail', 'content'],
                          '-'
                        )
                      )
                    : this.escapeHtml(safeDeepGet(row, 'desc', '-'))
                }
                style={{
                  fontSize: 13,
                  border: 'none',
                  outline: 'none',
                  resize: 'none',
                }}
              ></textarea>
            </>
          ),
        },
        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          text: 'Action',
          formatter: (cellContent, row) => (
            <>
              {row.type === 'complaint' ? (
                <div className="d-flex gap-3">
                  <Button
                    className="btn btn-primary"
                    id="edittooltip"
                    onClick={() => this.handleCompleteClick(row)}
                  >
                    Solve
                  </Button>
                </div>
              ) : (
                <p>Automatic Solve</p>
              )}
            </>
          ),
        },
      ],
    };
    this.toggleComplaint = this.toggleComplaint.bind(this);
    this.showToast = this.showToast.bind(this);
    this.handleCompleteClick = this.handleCompleteClick.bind(this);
    this.handleOpenComplaint = this.handleOpenComplaint.bind(this);
    this.handleValidDate = this.handleValidDate.bind(this);
    this.handleSendWA = this.handleSendWA.bind(this);
  }
  handleCompleteClick(row) {
    this.props.CloseNotifications(row);
    this.toggleComplaint();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.closeResult !== prevProps.closeResult) {
      const code = safeDeepGet(
        this.props,
        ['closeResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code != Result.UNKNOWN_ERROR) {
        if (code == Result.SUCCESS || code == Result.HTTP.OK) {
          this.props.GetNotifications();
          this.showToast('Success');
        } else {
          this.showToast('Failed');
        }
      }
    }
  }
  showToast(sts) {
    const { isEdit } = this.state;
    var toastType = 'success';
    var message = sts;
    if (sts == 'Failed') {
       toastType = 'danger';
       message = sts;
    } 

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  escapeHtml(str) {
    var div = document.createElement('div');
    div.innerHTML = str;
    return div.innerText;
  }
  handleTimeStamp(timeStamp) {
    const d = new Date(timeStamp).getTime();
    return d;
  }
  toggleComplaint() {
    this.setState(prevState => ({
      modalComplaint: !prevState.modalComplaint,
    }));
  }
  handleSendWA = arg => {
    const trx = arg;
    const mobile_number = safeDeepGet(trx, 'mobile_number', 0);
    const txt = mobile_number.toString();
    const sbtr =  txt.substring(1, txt.length);
    window.open('https://wa.me/'+sbtr);
  };
  componentDidMount() {
    // Load business point
    this.props.GetNotifications();
  }
  handleOpenComplaint(row) {
    this.toggleComplaint();
    this.setState({ complaint: row });
  }

  handleValidDate(ts) {
    const date = new Date(ts).toLocaleDateString();
    const time = new Date(ts).toLocaleTimeString();
    return date + ' ' + time;
  }
  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    const code = safeDeepGet(
      this.props,
      ['result', 'code'],
      Result.UNKNOWN_ERROR
    );
    let list = safeDeepGet(this.props, ['result', 'body'], []);
    if( this.state.type == 'refund'){
      list = safeDeepGet(this.props,['result', 'body', 'refundList'], []);
    } else if( this.state.type == 'complaint'){
      list = safeDeepGet(this.props,['result', 'body', 'complaintList'], []);
    }
      // console.log(list)
    const { SearchBar } = Search;

    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'open_time', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        const type = safeDeepGet(row, 'type', '');
        if (type == 'complaint') {
          this.handleOpenComplaint(row);
        }
      },
    };
    const title = (this.state.type).charAt(0).toUpperCase() + (this.state.type).slice(1);
    
    const { complaint } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          
            <MetaTags>
              <title>{buildTitle('Notification List')}</title>
            </MetaTags>
        
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={title} breadcrumbItem="List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.NotificationColoumn}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.NotificationColoumn}
                          data={list}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8"></Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable    
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        'table align-middle table-nowrap table-hover'
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      rowEvents={rowEvents}
                                      hover
                                      ref={this.node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>

                    <Modal
                      size="xl"
                      isOpen={this.state.modalComplaint}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggleComplaint} tag="h4">
                        {complaint.info == undefined || complaint.info == null
                          ? null
                          : 'Complaint ' + complaint.info.topic}
                      </ModalHeader>
                      <ModalBody>
                        {complaint.info == undefined ||
                        complaint.info == null ? null : (
                          <>
                            <Row>
                              <Col lg={6}>
                                <div className="table-responsive mb-2">
                                  <Table className="table align-middle table-nowrap">
                                    <thead>
                                      <tr>
                                        <th scope="col">Topic</th>
                                        <th scope="row">
                                          <div>
                                            <h5 className="text-truncate mt-1 font-size-14">
                                              {complaint.info.topic}
                                            </h5>
                                          </div>
                                        </th>
                                      </tr>
                                      <tr>
                                        <th scope="col">Description</th>
                                        <th scope="row">
                                          <div>
                                            <h5 className="text-truncate mt-1 font-size-14">
                                              {complaint.info.description}
                                            </h5>
                                          </div>
                                        </th>
                                      </tr>

                                      <tr>
                                        <th scope="col">VM Code</th>
                                        <th scope="row">
                                          <div>
                                            <h5 className="text-truncate mt-1 font-size-14">
                                              {complaint.info.vm_code}
                                            </h5>
                                          </div>
                                        </th>
                                      </tr>
                                      <tr>
                                        <th scope="col">Name</th>
                                        <td>
                                          <div>
                                            <h5 className="text-truncate mt-1 font-size-14">
                                              {complaint.info.name}
                                            </h5>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="col">Mobile Phone</th>
                                        <td>
                                          <div>
                                            <h5 className="text-truncate mt-1 font-size-14"
                                              onClick={() => this.handleSendWA(complaint.info.mobile_phone)}
                                              >
                                              {complaint.info.mobile_phone}
                                            </h5>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="col">Date</th>
                                        <td>
                                          <div>
                                            <span className="text-primary">
                                              {this.handleValidDate(
                                                complaint.info.ts
                                              )}
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    </thead>
                                  </Table>
                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0">
                                    <img
                                      src={complaint.info.img_url}
                                      style={{
                                        display: 'block',
                                        maxWidth: '400px',
                                        maxHeight: '400px',
                                        width: 'auto',
                                        height: 'auto',
                                      }}
                                      // onClick={() => this.handleImage(list)}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <div className="mb-4">
                              <Row>
                                <Col sm={1}>
                                  <Button
                                    className="btn btn-primary"
                                    id="edittooltip"
                                    onClick={() =>
                                      this.handleCompleteClick(complaint)
                                    }
                                  >
                                    Solve
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </>
                        )}
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  test: state,
  result: safeDeepGet(state, ['businessPoint', 'notification'], {}),
  closeResult: safeDeepGet(state, ['businessPoint', 'close_notification'], {}),
  loading: safeDeepGet(state, ['businessPoint', 'loading'], true),
});

const mapDispatchToProps = dispatch => ({
  GetNotifications: () => dispatch(notification()),
  CloseNotifications: payload => dispatch(close_notification(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
