import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';

// // Iotera
// import Result from 'iotera-base/result'
// import { safeDeepGet } from 'iotera-base/utility/json'

// Redux
// import { list } from '../../store/product/actions'

class StockList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {};
  }
  render() {
    const products = [
      {
        id: 1,
        sku: 'KP-TB-ORI-200',
        name: 'Robusta',
        stock: 100,
        categories: 'Biji',
      },
      {
        id: 3,
        sku: 'GL-TB-ORI-200',
        name: 'Gula',
        stock: 100,
        categories: 'Bubuk',
      },
      {
        id: 4,
        sku: 'AQ-TB-ORI-200',
        name: 'Aqua',
        stock: 19,
        categories: 'Air',
      },
    ];
    const columns = [
      {
        text: 'id',
        dataField: 'id',
        sort: true,
        hidden: true,
        formatter: (cellContent, row) => <>{row.id}</>,
      },
      {
        dataField: 'sku',
        text: 'SKU',
        sort: true,
        formatter: (cellContent, row) => <>{row.sku}</>,
      },
      {
        text: 'Name',
        dataField: 'name',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {row.name}
              </Link>
            </h5>
          </>
        ),
      },
      {
        dataField: 'categories',
        text: 'Categories',
        formatter: (cellContent, row) => <>{row.categories}</>,
      },
    ];
    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>List Bahan | Nestle Franke Vending Machine</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Minuman" breadcrumbItem="List Bahan" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable    
                            keyField="id"
                            data={products}
                            columns={columns}
                            bordered={false}
                            striped={false}
                            responsive
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default StockList;
