/* eslint-disable react/no-unescaped-entities */
/* eslint-disable semi */
import React, { Component } from "react"
import MetaTags from 'react-meta-tags'
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

//Import Images
import error from "../assets/images/error-img.png"
let role = '';
const obj = JSON.parse(localStorage.getItem('role'));
if (obj != null) {
  role = obj.role;
}
class Pages403 extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-5">
        <MetaTags>
            <title>403 Forbidden Page </title>
          </MetaTags>
          <Container>
            <Row className="mt-4">
              <Col lg="12">
                <div className="text-center mb-5">
                  <h1 className="display-2 fw-medium">
                    4
                    <i className="bx bx-buoy bx-spin text-primary display-3"/>
                    3
                  </h1>
                  <h4 className="text-uppercase">Sorry, You don't Have permission to view this page</h4>
                  <div className="mt-5 text-center">
                    <Link
                      className="btn btn-primary"
                      to=
                        "/dashboard"
                      
                    >
                      Back to Dashboard
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Pages403
