import firebase from 'firebase/compat/app';

// Add the Firebase products that you want to use
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { safeDeepGet } from 'iotera-base/utility/json';
import { getStorage, ref, listAll } from 'firebase/storage';

export default class Transaction {
    constructor(firebase) {
      this.collection = firebase.firestore().collection('transaction_rfid');
    }
    //TODO Untuk List snapshot
    list = payload => {
      return new Promise((resolve, reject) => {
        var filter_bp = safeDeepGet(payload, 'businessPoints');
        var filter_ts = safeDeepGet(payload, 'transactionStatus');
        var filter_ds = safeDeepGet(payload, 'dispenseStatus');
        var filter_sd = safeDeepGet(payload, 'dateRange')[0];
        var filter_ed = safeDeepGet(payload, 'dateRange')[1];
  
        if (filter_bp == 'all' || filter_bp == null) {
          filter_bp = null;
        }
        if (filter_ts == 'all' || filter_ts == null) {
          filter_ts = null;
        }
        if (filter_ds == 'all' || filter_ds == null) {
          filter_ds = null;
        }
        if (filter_sd == 'all' || filter_sd == null) {
          filter_sd = null;
        }
        if (filter_ed == 'all' || filter_ed == null) {
          filter_ed = null;
        }
  
        let query = this.collection;
        if (filter_bp) {
          query = query.where('product.device_id', '==', filter_bp);
        }
  
        if (filter_ts) {
          query = query.where(
            'detail.transaction_status',
            '==',
            filter_ts
          );
        }
        if (filter_ds) {
          query = query.where(
            "detail.dispense_status",
            "==",
            filter_ds
          );
        }
        if (filter_sd) {
          var startfulldate = firebase.firestore.Timestamp.fromDate(filter_sd);
          query = query.where(
            "time.firestore_timestamp",
            '>=',
            startfulldate
          );
        }
  
        if (filter_ed) {
          const d = filter_ed;
          d.setDate(d.getDate() + 1);
          var endfulldate = firebase.firestore.Timestamp.fromDate(d);
  
          query = query.where(
            "time.firestore_timestamp",
            '<=',
            endfulldate
          );
        }
  
        const list = {};
        query.get().then(
          querySnapshot => {
            querySnapshot.docs.map(doc => {
              list[doc.id] = doc.data();
            });
            resolve({ code: 0, body: list });
          },
          error => {
            console.log(error);
            resolve({ code: -1, body: list });
          }
        );
      });
    };
    download = (payload) => {
      return new Promise((resolve, reject) => {
        var filter_bp = safeDeepGet(payload, 'businessPoints');
        var filter_ts = safeDeepGet(payload, 'transactionStatus');
        var filter_ds = safeDeepGet(payload, 'dispenseStatus');
        var filter_month = safeDeepGet(payload, 'month');
        var filter_year = safeDeepGet(payload, 'year');
  
        if (filter_bp == 'all' || filter_bp == null) {
          filter_bp = null;
        }
        if (filter_ts == 'all' || filter_ts == null) {
          filter_ts = null;
        }
        if (filter_month == 'all' || filter_month == null) {
          filter_month = null;
        } else {
          var startDate = new Date(filter_year, filter_month, 1);
          var endDate = new Date(filter_year, filter_month + 1, 0);
        }
        if (filter_year == 'all' || filter_year == null) {
          filter_year = null;
        } else {
          var startDate = new Date(filter_year, filter_month, 1);
          var endDate = new Date(filter_year, filter_month + 1, 0);
        }
        if (filter_ds == 'all' || filter_ds == null) {
          filter_ds = null;
        }
        let query = this.collection;
        const list = {};
        if (filter_bp) {
          query = query.where('product.device_id', '==', filter_bp);
        }
        if (startDate) {
          var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
          query = query.where(
            'time.firestore_timestamp',
            '>=',
            startfulldate
          );
        }
        if (endDate) {
          const d = endDate;
          d.setDate(d.getDate() + 1);
          var endfulldate = firebase.firestore.Timestamp.fromDate(d);
  
          query = query.where(
            "time.firestore_timestamp",
            '<=',
            endfulldate
          );
        }
        query.get().then(
          querySnapshot => {
            querySnapshot.docs.map(doc => {
              list[doc.id] = doc.data();
            });
            resolve({ code: 0, body: list });
          },
          error => {
            resolve({ code: -1, body: list });
          }
        );
      });
    };
  
    nett_settlement = payload => {
      return new Promise((resolve, reject) => {
        var filter_month = safeDeepGet(payload, 'month');
        var filter_year = safeDeepGet(payload, 'year');
        var filter_bp = safeDeepGet(payload, 'businessPoints');
        var filter_sd = safeDeepGet(payload, 'date')[0];
        var filter_ed = safeDeepGet(payload, 'date')[1];
  
        if (filter_bp == 'all' || filter_bp == null) {
          filter_bp = null;
        }
        var startDate = new Date(filter_year, filter_month, 1);
        var endDate = new Date(filter_year, filter_month + 1, 0);
  
        let query = this.collection;
  
        if (filter_bp) {
          query = query.where('product.device_id', '==', filter_bp);
        }
        if (filter_sd) {
          var startfulldate = firebase.firestore.Timestamp.fromDate(filter_sd);
          query = query.where(
            'time.firestore_timestamp',
            '>=',
            startfulldate
          );
        } else {
          if (startDate) {
            var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            query = query.where(
              'time.firestore_timestamp',
              '>=',
              startfulldate
            );
          }
          if (endDate) {
            var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            query = query.where(
              'time.firestore_timestamp',
              '<=',
              endfulldate
            );
          }
        }
  
        if (filter_ed) {
          const d = filter_ed;
          d.setDate(d.getDate() + 1);
          var endfulldate = firebase.firestore.Timestamp.fromDate(d);
  
          query = query.where(
            "time.firestore_timestamp",
            '<=',
            endfulldate
          );
        } else {
          if (startDate) {
            var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            query = query.where(
              "time.firestore_timestamp",
              '>=',
              startfulldate
            );
          }
          if (endDate) {
            var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            query = query.where(
              "time.firestore_timestamp",
              '<=',
              endfulldate
            );
          }
        }
        const list = {};
        query.get().then(
          querySnapshot => {
            querySnapshot.docs.map(doc => {
              list[doc.id] = doc.data();
            });
            resolve({ code: 0, body: list });
          },
          error => {
            resolve({ code: -1, body: list });
          }
        );
      });
    };
  
    read = deviceId => {
      return new Promise((resolve, reject) => {
        let map = {};
        this.collection
          .where('product.device_id', '==', deviceId)
          .get()
          .then(
            querySnapshot => {
              let code = -1;
              if (querySnapshot.size > 0) {
                code = 0;
                map = { ...querySnapshot.docs[0].data() };
              }
              resolve({ code: code, body: map });
            },
            error => {
              resolve({ code: -1, body: map });
            }
          );
      });
    };
  
  
  }
  