/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Select from 'react-select';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  Input,
  Label,
  ModalBody,
  FormGroup,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import 'flatpickr/dist/themes/material_blue.css';
//Tippy
import 'tippy.js/dist/tippy.css';
//Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';
import klikHere from 'Base/assets/images/klikhere.png';

// Redux
import { connect } from 'react-redux';
import {
  list,
  create,
  delete_,
  checkPayment,
  cancel_,
} from '../../store/subs/actions';
import SweetAlert from 'react-bootstrap-sweetalert';
import { buildTitle } from 'Apps';
const timeOptions = [
  { label: '1 Bulan', value: 1 },
  { label: '2 Bulan', value: 2 },
  { label: '3 Bulan', value: 3 },
  { label: '4 Bulan', value: 4 },
  { label: '5 Bulan', value: 5 },
  { label: '6 Bulan', value: 6 },
  { label: '7 Bulan', value: 7 },
  { label: '8 Bulan', value: 8 },
  { label: '9 Bulan', value: 9 },
  { label: '10 Bulan', value: 10 },
  { label: '11 Bulan', value: 11 },
  { label: '12 Bulan', value: 12 },
];
class subsDeviceList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      modal: false,
      modalNav: false,
      Role: [],
      columnDevice: [
        {
          dataField: 'active',
          text: 'Active',

          editor: {
            type: Type.CHECKBOX,
            // value: 'On:Off'
          },
          headerStyle: () => {
            return { width: '80px' };
          },
          // align: 'center',
          formatter: (cell, row, rowIndex) => {
            return (
              <>
                <Input
                  type="checkbox"
                  defaultChecked={safeDeepGet(row, ['active'], 'off') === 'on'}
                />
              </>
            );
          },
        },
        {
          dataField: 'name',
          text: 'Name',
          editable: false,

          formatter: (cell, row, rowIndex) => {
            return (
              <>
                <>{row.name}</>
              </>
            );
          },
        },
        {
          dataField: 'id',
          text: 'Device ID',
          editable: false,
          // hidden: 'true',
          formatter: (cell, row, rowIndex) => {
            return <>{row.id}</>;
          },
        },
      ],
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleLocalDate = this.handleLocalDate.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.cancel = this.cancel.bind(this);
  }
  handleTimeChange = values => {
    const date = new Date();
    const localDate = new Date(
      date.setMonth(date.getMonth() + values.value)
    );
    const day = localDate.getDate().toString();
    const month = localDate.getMonth()
    const year = localDate.getFullYear().toString()
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const stringDate = day + ' ' + monthNames[month] + ' ' + year
    this.setState({ localDate: stringDate})
    this.setState({ duration: values.value });
  };
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  handleConfirmCancle = (id, name) => {
    this.setState({
      confirm_alert: false,
      success_dlg: true,
      dynamic_title: 'Pembayaran',
      dynamic_description: 'Pembayaran di batalkan',
    });
    this.toggle();
    this.props.CancelPayment(this.state.order_id);
  };
  cancel() {
    this.setState({
      confirm_alert: true,
    });
    // this.showToast()
  }
  componentDidMount() {
    // Load business point
    this.props.LoadDeviceList();
  }
  componentDidUpdate(prevProps) {
    if (this.props.createResult !== prevProps.createResult) {
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.FAILED
      );
      if (code == Result.SUCCESS) {
        const url = safeDeepGet(
          this.props,
          ['createResult', 'body', 'url'],
          ''
        );
        const order_id = safeDeepGet(
          this.props,
          ['createResult', 'body', 'order_id'],
          ''
        );
        const amount = safeDeepGet(
          this.props,
          ['createResult', 'body', 'amount'],
          ''
        );
        this.setState({ url, order_id, amount });
        this.props.history.replace(`/subs/list`);
        // this.toggle();
      }
    }
  }
  handleLocalDate() {
    let localDate = '';
    var myDate = new Date();
    const day = myDate.getDate().toString();
    const month = (myDate.getMonth() + 1).toString();
    const year = myDate.getFullYear().toString();
    const date = year + month + day;

    const hours = myDate.getHours().toString();
    const minutes = myDate.getMinutes().toString();
    const seconds = myDate.getSeconds().toString();
    const time = hours + minutes + seconds;
    localDate = date + time;
    return localDate;
  }
  handleValidSubmit = (e, values) => {
    const nav = this.node.current.props.data;
    const { duration } = this.state;
    const submittedValues = { ...values, nav, duration };
    this.props.CreateTags(submittedValues);
    this.props.LoadDeviceList();
  };
  /* Handle click */
  handleAddClick = arg => {
    this.props.history.push('/role/create');
  };
  showToast(msg) {
    var toastType = 'success';
    var message = msg;
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  handleEndDate = end_date => {
    this.setState({ end_date: end_date[0] });
  };
  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    const list = safeDeepGet(this.props, ['result', 'body'], [[], []]);
    const { SearchBar } = Search;
    const { url, end_date, amount } = this.state;
    const date = this.handleLocalDate();
    const PO = 'SUBS' + date;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Subscription Device List')}</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            {this.state.success_dlg ? (
              <SweetAlert
                // success
                error
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}
            {this.state.confirm_alert ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmButtonText="Yes, Edit it!"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => this.handleConfirmCancle()}
                onCancel={() =>
                  this.setState({
                    confirm_alert: false,
                  })
                }
              >
                You won&apos;t be able to revert this!
              </SweetAlert>
            ) : null}
            <Breadcrumbs title="Subscription" breadcrumbItem="Create" />
            <AvForm onValidSubmit={this.handleValidSubmit}>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField="id"
                              classes={
                                'table align-middle table-nowrap table-hover'
                              }
                              data={list}
                              columns={this.state.columnDevice}
                              cellEdit={cellEditFactory({
                                mode: 'click',
                                blurToSave: true,
                              })}
                              bordered={false}
                              striped={false}
                              responsive
                              ref={this.node}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="5">
                          <Label htmlFor="name">No PO : </Label>
                          <AvField
                            type="text"
                            id="po"
                            name="po"
                            placeholder="Masukan name..."
                            className="form-control"
                            value={PO}
                            validate={{
                              required: { value: true },
                            }}
                            disabled={true}
                          />
                        </Col>
                        <Col sm="6">
                          <Label htmlFor="name">Name : </Label>
                          <AvField
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Masukan name..."
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="5">
                          <Label htmlFor="phone">No Handphone : </Label>
                          <AvField
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder="Masukan No Hp..."
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                        <Col sm="6">
                          <FormGroup className="mb-3 select2-container">
                            <Label>Duration</Label>
                            <Select
                              className="mb-3"
                              options={timeOptions}
                              onChange={this.handleTimeChange}
                            ></Select>
                          </FormGroup>
                        </Col>
                        <p>Durasi Subscription Hingga {this.state.localDate}</p>
                        <Col sm="1">
                          <div className="d-flex flex-wrap gap-2 float-end mt-4">
                            <Button type="submit" color="primary">
                              Simpan
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
            <Modal
              size="md"
              isOpen={this.state.modal}
              className={this.props.className}
              centered={true}
            >
              <ModalHeader toggle={this.cancel} tag="h5"></ModalHeader>
              <ModalBody
                className="mt-2"
                style={
                  {
                    // backgroundPosition: 'center',
                    // backgroundSize: 'cover',
                    // backgroundRepeat: 'no-repeat',
                    // backgroundImage: 'url(' + template + ')',
                  }
                }
              >
                <div style={{ height: '100%' }}>
                  <h5 className="text-center mb-2">
                    Jumlah yang harus dibayar{' '}
                    {Number(amount)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
                    , Klik Link di bawah ini
                  </h5>
                  <a
                    className="text-center mb-2"
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="center mb-2"
                      style={{
                        display: 'flex',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '50%',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      src={klikHere}
                    />
                  </a>
                  {/* <QRCode
                    className="text-center"
                    size={100}
                    style={{
                      height: 'auto',
                      maxWidth: '100%',
                      width: '50%',
                      marginLeft: '25%',
                    }}
                    value={url}
                    viewBox={`0 0 100 100`}
                  /> */}
                </div>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['subs', 'list'], {}),
  loading: safeDeepGet(state, ['subs', 'loading'], true),
  createResult: safeDeepGet(state, ['subs', 'create'], {}),
  checkPaymentResult: safeDeepGet(state, ['subs', 'checkPayment'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  LoadDeviceList: () => dispatch(list()),
  CreateTags: payload => dispatch(create(payload)),
  CancelPayment: payload => dispatch(cancel_(payload)),
  DeleteRole: payload => dispatch(delete_(payload)),
  CheckPayment: payload => dispatch(checkPayment(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(subsDeviceList);
