/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import { call, put, takeEvery, delay } from 'redux-saga/effects';

// Business-point state
import {
  LIST,
  LIST_VM,
  INIT_CREATE,
  CREATE,
  READ_INFO,
  UPDATE_INFO,
  UPDATE_CALIBRATION,
  READ_PLANOGRAM,
  UPDATE_PLANOGRAM,
  ADD_PLANOGRAM,
  DELETE,
  READ_PRODUCT,
  LIST_PRODUCT,
  DELETE_PLANOGRAM,
  TESTCSV,
  SERI_VM,
  INPUT_CSV,
  HISTORY,
} from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import {
  device_map as deviceMap,
  setPlanogram_Milano,
  getPlanogram,
  post_task_bp,
  update_business_point
} from '../../webservice/device';
import {
  forceDeepSet,
  has,
  safeDeepGet,
  safeDeepSet,
  safeDel,
} from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
import { APP_ID } from '../../../Project';
import { JPOST } from 'iotera-base/webservice/wsrequest';
function* getSeriVM(payload) {
  const device_id = safeDeepGet(payload, ['payload'], '');
  let wsResult = yield call(deviceMap);
  if (safeDeepGet(wsResult, 'code') === Result.HTTP.OK) {
    const devices = safeDeepGet(wsResult, ['body', 'devices']);
    const getVM = {};
    let q = 0;
    for (const id in devices) {
      if (safeDeepGet(devices, [id, 'id']) === device_id) {
        getVM[q++] = devices[id];
      }
    }
    yield put(
      result(SERI_VM, {
        code: Result.SUCCESS,
        body: getVM,
      })
    );
    return;
  }
  yield put(result(SERI_VM, { result: { code: Result.FAILED } }));
}
function* list_history(action) {
  const payload = safeDeepGet(action, 'payload');
    yield put(loading(HISTORY));
  const device_id = safeDeepGet(payload, 'device_id', null);
  const firebaseInstance = getInstance();
  const wsResult = yield call(
    firebaseInstance.planogram.list,
    device_id,
    payload
  );
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const historyBody = safeDeepGet(wsResult, 'body');
    const historyList = [];
    for (const id in historyBody) {
      const body = {};
      const planogramBody = safeDeepGet(historyBody, [id, 'planogram']);
      const historyPlanogram = [];
      for (const i in planogramBody) {
        planogramBody[i]['key'] = i;
        historyPlanogram.push(planogramBody[i]);
      }
      body['planogram'] = historyPlanogram;
      body['name'] = safeDeepGet(historyBody, [id, 'name']);
      body['id'] = safeDeepGet(historyBody, [id, 'id']);
      body['user'] = safeDeepGet(historyBody, [id, 'user']);
      body['time_stamp'] = Number(id);
      historyList.push(body);
    }
    yield put(result(HISTORY, { code: Result.SUCCESS, body: historyList }));
  } else {
    yield put(result(HISTORY, { code: Result.FAILED }));
  }
}
function* inputCsv(action) {
  yield put(loading(INPUT_CSV));
  const payload = safeDeepGet(action, ['payload']);
  for (const i in payload) {
    const ingredient = safeDeepGet(payload[i], ['ingredients 1']);
    const ingredient2 = safeDeepGet(payload[i], ['ingredients 1']);
    const ingredient3 = safeDeepGet(payload[i], ['ingredients 2']);
    const ingredient4 = safeDeepGet(payload[i], ['ingredients 3']);
    const amount = safeDeepGet(payload[i], ['amount 1']);
    const amount2 = safeDeepGet(payload[i], ['amount 2']);
    const amount3 = safeDeepGet(payload[i], ['amount 3']);
    const amount4 = safeDeepGet(payload[i], ['amount 4']);
    const ingredients = [];
    if (ingredient) {
      if (amount) {
        ingredients.push({ type: ingredient, amount: Number(amount) });
      }
    }
    if (ingredient2) {
      if (amount2) {
        ingredients.push({ type: ingredient2, amount: Number(amount2) });
      }
    }
    if (ingredient3) {
      if (amount3) {
        ingredients.push({ type: ingredient3, amount: Number(amount3) });
      }
    }
    if (ingredient4) {
      if (amount4) {
        ingredients.push({ type: ingredient4, amount: Number(amount4) });
      }
    }
    payload[i].ingredients = ingredients;
  }
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.planogram.addCsv, payload);
  yield put(result(INIT_CREATE, { code: wsResult }));
}

function* list_product() {
  yield put(loading(LIST_PRODUCT));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.product.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const productMap = wsResult.body;
    const productList = [];
    for (const id in productMap) {
      let productName = safeDeepGet(productMap, [id, 'display_name']);
      let productID = id;
      productList.push({ label: productName, value: productID });
    }
    yield put(
      result(LIST_PRODUCT, { code: Result.SUCCESS, body: productList })
    );
    return;
  }
  yield put(result(LIST_PRODUCT, { result: { code: Result.FAILED } }));
}

function* list_vm() {
  yield put(loading(LIST_VM));
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.serialnumber.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const distributorMap = wsResult.body;
    const distributorList = [];
    let i = 0;
    for (const sku in distributorMap) {
      distributorMap[sku].id = sku;
      safeDeepSet(distributorMap, [sku, 'id'], i++);
      distributorList.push(distributorMap[sku]);
    }
    yield put(result(LIST_VM, { code: Result.SUCCESS, body: distributorList }));
    return;
  }
  yield put(result(LIST_VM, { result: { code: Result.FAILED } }));
}
function* list(payload) {
  const data = safeDeepGet(payload, ['payload']);
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list, data);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointBody = wsResult.body;
    const businessPointMap = {};
    for (const id in businessPointBody) {
      const deviceId = safeDeepGet(businessPointBody, [id, 'id'], '');
      businessPointBody[id].iddevice = id;
      businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
    }
    yield delay(2000);
    wsResult = yield call(deviceMap);
    if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
      const devices = safeDeepGet(wsResult, ['body', 'devices']);
      const devicesMaps = {};
      for (const e in devices) {
        const deviceId = safeDeepGet(devices[e], ['id'], '');
        // devicesMaps[deviceId] = safeDeepGet(devices[e], ['online'], "");
        devicesMaps[deviceId] = devices[e];
      }
      const businessPointList = [];
      let i = 0;
      for (const deviceId in businessPointMap) {
        const online = safeDeepGet(
          devicesMaps,
          [deviceId, 'online', 'is_online'],
          false
        );
        const latest_online = safeDeepGet(
          devicesMaps,
          [deviceId, 'online', 'last_online_ts'],
          0
        );
        const latest_offline = safeDeepGet(
          devices,
          [deviceId, 'online', 'last_offline_ts'],
          0
        );
        safeDeepSet(businessPointMap, [deviceId, 'last_online'], latest_online);
        safeDeepSet(
          businessPointMap,
          [deviceId, 'last_offline'],
          latest_offline
        );
        safeDeepSet(businessPointMap, [deviceId, 'id'], i++);
        safeDeepSet(businessPointMap, [deviceId, 'online'], online);
        businessPointList.push(businessPointMap[deviceId]);
      }

      yield put(
        result(LIST, { code: Result.SUCCESS, body: businessPointList })
      );
      return;
    }
  }
  yield put(result(LIST, { code: Result.FAILED }));
}
//  Device List Baru

function* initCreate() {
  yield put(loading(INIT_CREATE));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  if (safeDeepGet(wsResult, 'code') === Result.SUCCESS) {
    const businessPointBody = wsResult.body;

    const businessPointMap = {};
    for (const id in businessPointBody) {
      const device_id = safeDeepGet(businessPointBody, [id, 'device_id'], '');
      businessPointMap[device_id] = safeDeepGet(businessPointBody, id);
    }
    wsResult = yield call(deviceMap);
    if (safeDeepGet(wsResult, 'code') === Result.HTTP.OK) {
      const devices = safeDeepGet(wsResult, ['body', 'devices']);
      const unregisteredDevices = [];
      for (const deviceId in devices) {
        if (!has(businessPointMap, deviceId)) {
          unregisteredDevices.push({
            label: safeDeepGet(devices[deviceId], 'label', '-'),
            value: safeDeepGet(devices[deviceId], 'id', '-'),
          });
        }
      }
      yield put(
        result(INIT_CREATE, {
          code: 0,
          body: { unregistered_devices: unregisteredDevices },
        })
      );
      return;
    }
  }
  yield put(result(INIT_CREATE, { code: Result.FAILED }));
}

// function* initCreate() {
//   yield put(loading(INIT_CREATE));
//   const firebaseInstance = getInstance();
//   let wsResult = yield call(firebaseInstance.businessPoint.list);
//   if (safeDeepGet(wsResult, 'code') === Result.SUCCESS) {
//     const businessPointBody = wsResult.body;

//     const businessPointMap = {};
//     for (const id in businessPointBody) {
//       const device_id = safeDeepGet(businessPointBody, [id, 'device_id'], '');
//       businessPointMap[device_id] = safeDeepGet(businessPointBody, id);
//     }

//     wsResult = yield call(deviceMap);
//     if (safeDeepGet(wsResult, 'code') === Result.HTTP.OK) {
//       const devices = safeDeepGet(wsResult, ['body', 'devices']);
//       const unregisteredDevices = [];
//       for (const deviceId in devices) {
//         if (!has(businessPointMap, deviceId)) {
//           unregisteredDevices.push({
//             label: safeDeepGet(devices, [deviceId, 'label'], '-'),
//             value: deviceId,
//           });
//         }
//       }
//       yield put(
//         result(INIT_CREATE, {
//           code: 0,
//           body: { unregistered_devices: unregisteredDevices },
//         })
//       );
//       return;
//     }
//   }
//   yield put(result(INIT_CREATE, { code: Result.FAILED }));
// }

function createBp(id, name) {
  var result = JPOST({
    url: 'https://pay.iotera.io/web/device/update',
    headers: {
      'Iotera-Payment-Application-Id': APP_ID,
      'Iotera-Payment-Admin-Token':
        '6bf868df24a4357dee20e6d3d6ef1d1944249ad44cb29687446d346f60fc0215',
    },
    body: {
      device_id: id,
      name: name,
    },
  })
    .then(function (response) {
      return response;
    })
    .then(data => data);
  return result;
}

function* create(action) {
  yield put(loading(CREATE));
  const id = safeDeepGet(action, ['payload', 'id']);
  const name = safeDeepGet(action, ['payload', 'name']);
  const payload = safeDeepGet(action, 'payload');
  const data = {
    device_id: id,
    device_name: name,
  }
  yield call(update_business_point, data)
  yield call(post_task_bp, payload, id)
  safeDel(payload, 'id');
  const firebaseInstance = getInstance();
  yield call(createBp, id, name);
  const wsResult = yield call(
    firebaseInstance.businessPoint.create,
    id,
    payload
  );
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(CREATE, { code }));
}

function* readInfo(action) {
  yield put(loading(READ_INFO));
  const firebaseInstance = getInstance();
  let idMap = null;
  let wsResult = yield call(deviceMap);
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    yield put(result(READ_INFO, { code: Result.FAILED }));
    return;
  }
  const devices = safeDeepGet(wsResult, ['body', 'devices']);
  let i = 0;
  const registeredDevices = [];
  // wsResult = yield call(firebaseInstance.serialnumber.list);
  // if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
  //   const distributorMap = wsResult.body;
  //   for (const sku in distributorMap) {
  //     const VMList = distributorMap[sku];
  //     for (const id in VMList) {
  //       registeredDevices.push({
  //         value: safeDeepGet(VMList, [id], '-'),
  //         label: id,
  //       });
  //     }
  //   }
  // }
  wsResult = yield call(deviceMap);
  if (safeDeepGet(wsResult, 'code') === Result.HTTP.OK) {
    const devices = safeDeepGet(wsResult, ['body', 'devices']);
    const unregisteredDevices = [];
    for (const deviceId in devices) {
      registeredDevices.push({
        label: safeDeepGet(devices[deviceId], 'label', '-'),
        value: safeDeepGet(devices[deviceId], 'id', '-'),
      });
    }
  }
  const deviceId = safeDeepGet(action, ['payload', 'id']);
  wsResult = yield call(firebaseInstance.businessPoint.read, deviceId);
  const info = wsResult.body;
  for (const id in info) {
    // info.iddevice = id;
  }

  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ_INFO, { code: Result.FAILED }));
    return;
  }

  yield put(
    result(READ_INFO, {
      code: 0,
      body: { info: wsResult.body, registered_devices: registeredDevices },
    })
  );
}

function* updateInfo(action) {
  yield put(loading(UPDATE_INFO));
  const id = safeDeepGet(action, ['payload', 'id']);
  const name = safeDeepGet(action, ['payload', 'name']);
  const payload = safeDeepGet(action, 'payload');
  yield call(post_task_bp, payload, id);
  const data = {
    device_id: id,
    device_name: name,
  }
  yield call(update_business_point, data)
  safeDel(payload, 'id');
  const firebaseInstance = getInstance();
  yield call(createBp, id, name);
  const wsResult = yield call(
    firebaseInstance.businessPoint.update,
    id,
    payload
  );
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(UPDATE_INFO, { code }));
  yield call(firebaseInstance.bpfee.update, id, name);
}
function* updatecalibration(action) {
  yield put(loading(UPDATE_CALIBRATION));
  const id = safeDeepGet(action, ['payload', 'docId']);
  const payload = safeDeepGet(action, 'payload');
  safeDel(payload, 'docId');
  const firebaseInstance = getInstance();
  const wsResult = yield call(
    firebaseInstance.businessPoint.updatecalibration,
    id,
    payload
  );
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(UPDATE_CALIBRATION, { code }));
}

function* readPlanogram(action) {
  yield put(loading(READ_PLANOGRAM));
  const id = safeDeepGet(action, ['payload', 'id']);
  const firebaseInstance = getInstance();
  // Result
  const planogramResult = {};
  const productList = {};
  const productResult = {};
  let wsResult = yield call(firebaseInstance.product.list);

  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ_PLANOGRAM, { code: Result.FAILED }));
    return;
  }
  const productMap = safeDeepGet(wsResult, ['body']);
  // wsResult = yield call(firebaseInstance.planogram.read, id);
  // const planogramMap = safeDeepGet(wsResult, ['body']);
  for (const sku in productMap) {
    productResult[sku] = safeDeepGet(productMap, [sku, 'display_name'], '');
  }
  const historyPlanogram = [];
  const data = yield call(firebaseInstance.planogram.list, id);
  const historyBody = safeDeepGet(data, 'body');
  var planogramBody = {};
  const body = {};
  for (const i in historyBody) {
    planogramBody = safeDeepGet(historyBody, [i, 'planogram']);
    body['name'] = safeDeepGet(historyBody, [i, 'name']);
    body['id'] = safeDeepGet(historyBody, [i, 'id']);
    body['user'] = safeDeepGet(historyBody, [i, 'user']);
    body['time_stamp'] = Number(i);
  }
  for (const i in planogramBody) {
    planogramBody[i]['key'] = i;
    historyPlanogram.push(planogramBody[i]);
  }
  body['planogram'] = historyPlanogram;
  // Retrieve product
  wsResult = yield call(getPlanogram, id);
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    yield put(result(READ_PLANOGRAM, { code: Result.FAILED }));
    return;
  }

  const sensorsList = safeDeepGet(wsResult, ['body', 'table_data']);
  for (const i in sensorsList) {
    const number = Number(i) + 1;
    const sensors = sensorsList[i];
    const sensor = safeDeepGet(sensors, ['selection']);
    const selection = safeDeepGet(sensors, ['SELECTION', 'value'], '');
    forceDeepSet(
      planogramResult,
      [Number(selection).toString(), 'selection'],
      selection
    );
    const sku = safeDeepGet(sensors, ['SKU', 'value'], 0);
    forceDeepSet(planogramResult, [Number(selection).toString(), 'sku'], sku);
    const name = safeDeepGet(sensors, ['NAME', 'value'], 0);
    forceDeepSet(
      planogramResult,
      [Number(selection).toString(), 'display_name'],
      name
    );
    const harga = safeDeepGet(sensors, ['HARGA', 'value'], 0);
    forceDeepSet(
      planogramResult,
      [Number(selection).toString(), 'price'],
      harga
    );
    const img_url = safeDeepGet(productMap, [sku, 'img_url'], '');
    forceDeepSet(
      planogramResult,
      [Number(selection).toString(), 'img_url'],
      img_url
    );
  }
  const planogramList = [];
  for (const id in planogramResult) {
    const name = safeDeepGet(planogramResult, [id, 'display_name']);
    planogramList.push(planogramResult[id]);
  }
  yield put(
    result(READ_PLANOGRAM, {
      code: Result.SUCCESS,
      body: {
        planogram: planogramList,
        products: productResult,
        history: body,
      },
    })
  );
}
function* updatePlanogram(payload) {
  yield put(loading(UPDATE_PLANOGRAM));
  const firebaseInstance = getInstance();
  const id = safeDeepGet(payload, ['payload', 'id'], '');
  const planogram = safeDeepGet(payload, ['payload', 'planogram'], '');
  const idMap = {};
  const priceMap = {};
  const nameMap = {};
  for (const id in planogram) {
    const code = Number(id);
    const number = ('0' + code).slice(-2);
    const name = safeDeepGet(planogram, [id, 'name'], '');
    const price = safeDeepGet(planogram, [id, 'price'], '');
    const sku = safeDeepGet(planogram, [id, 'sku'], '');
    if (id != 'null') {
      idMap[number] = sku;
      nameMap[number] = name;
      priceMap[number] = price;
    }
  }
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  const businessPointBody = wsResult.body;
  const businessPointMap = {};
  for (const id in businessPointBody) {
    const deviceId = safeDeepGet(businessPointBody, [id, 'id'], '');
    businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
  }
  const nameBp = safeDeepGet(businessPointMap, [id, 'name'], '-');
  const planogramFb = {};
  for (const selection in planogram) {
    planogramFb[selection] = safeDeepGet(planogram, selection);
  }

  wsResult = yield call(setPlanogram_Milano, id, idMap, nameMap, priceMap);
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    yield put(
      result(UPDATE_PLANOGRAM, {
        code: Result.FAILED,
        message: wsResult,
      })
    );
    return;
  }
  yield call(firebaseInstance.planogram.create, id, planogramFb, nameBp);

  yield put(
    result(UPDATE_PLANOGRAM, { code: Result.SUCCESS, message: wsResult })
  );
}

function* readproduct(action) {
  yield put(loading(READ_PRODUCT));
  const sku = safeDeepGet(action, ['payload', 'id']);
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.planogram.readproduct, sku);
  const ingredientsMaps = wsResult.body.ingredients;
  for (const id in ingredientsMaps) {
    var type = safeDeepGet(ingredientsMaps[id], ['type']);
    var amount = safeDeepGet(ingredientsMaps[id], ['amount']);
    safeDeepSet(wsResult.body, [id, 'type'], type);
    safeDeepSet(wsResult.body, [id, 'amount'], amount);
  }

  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ_PRODUCT, { code: Result.FAILED }));
    return;
  }

  yield put(
    result(READ_PRODUCT, {
      code: 0,
      body: wsResult.body,
    })
  );
}

function* delete_(payload) {
  yield put(loading(DELETE));
  const data = safeDeepGet(payload, 'payload', {})
  yield call(post_task_bp, data, deviceId);
  const firebaseInstance = getInstance();
  const deviceId = safeDeepGet(payload, ['payload', 'deviceId'], '');
  const wsResult = yield call(firebaseInstance.businessPoint.delete_, deviceId);
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(DELETE, { code }));
}

function* deletePlanogram(payload) {
  yield put(loading(DELETE));
  const firebaseInstance = getInstance();
  const deviceId = safeDeepGet(payload, ['payload', 'deviceId'], '');
  const wsResult = yield call(firebaseInstance.planogram.delete_, deviceId);
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(DELETE, { code }));
}

function* addPlanogram(payload) {
  yield put(loading(ADD_PLANOGRAM));
  const firebaseInstance = getInstance();
  const id = safeDeepGet(payload, ['payload', 'id'], '');
  const planogram = safeDeepGet(payload, ['payload', 'planogram'], '');
  const idMap = {};
  const priceMap = {};
  const nameMap = {};
  for (const id in planogram) {
    const code = Number(id);
    const number = ('0' + code).slice(-2);
    const name = safeDeepGet(planogram, [id, 'name'], '');
    const price = safeDeepGet(planogram, [id, 'price'], '');
    const sku = safeDeepGet(planogram, [id, 'sku'], '');
    if (id != 'null') {
      idMap[number] = sku;
      nameMap[number] = name;
      priceMap[number] = price;
    }
  }
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  const businessPointBody = wsResult.body;
  const businessPointMap = {};
  for (const id in businessPointBody) {
    const deviceId = safeDeepGet(businessPointBody, [id, 'id'], '');
    businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
  }
  const nameBp = safeDeepGet(businessPointMap, [id, 'name'], '-');
  const planogramFb = {};
  for (const selection in planogram) {
    planogramFb[selection] = safeDeepGet(planogram, selection);
  }

  wsResult = yield call(setPlanogram_Milano, id, idMap, nameMap, priceMap);
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    yield put(
      result(ADD_PLANOGRAM, { code: Result.FAILED, message: wsResult })
    );
    return;
  }
  yield call(firebaseInstance.planogram.create, id, planogramFb, nameBp);

  yield put(result(ADD_PLANOGRAM, { code: Result.SUCCESS, message: wsResult }));
}

function* testcsv(payload) {
  yield put(loading(TESTCSV));
  console.log(payload);
}

function* businessPointSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(LIST_VM, list_vm);
  yield takeEvery(LIST_PRODUCT, list_product);
  yield takeEvery(INIT_CREATE, initCreate);
  yield takeEvery(CREATE, create);
  yield takeEvery(READ_INFO, readInfo);
  yield takeEvery(UPDATE_INFO, updateInfo);
  yield takeEvery(UPDATE_CALIBRATION, updatecalibration);
  yield takeEvery(READ_PLANOGRAM, readPlanogram);
  yield takeEvery(READ_PRODUCT, readproduct);
  yield takeEvery(UPDATE_PLANOGRAM, updatePlanogram);
  yield takeEvery(ADD_PLANOGRAM, addPlanogram);
  yield takeEvery(DELETE, delete_);
  yield takeEvery(DELETE_PLANOGRAM, deletePlanogram);
  yield takeEvery(TESTCSV, testcsv);
  yield takeEvery(INPUT_CSV, inputCsv);
  yield takeEvery(HISTORY, list_history);
  yield takeEvery(SERI_VM, getSeriVM);
}

export default businessPointSaga;
