import firebase from 'firebase/app';
export default class BusinessPoint {
  constructor(firebase) {
    this.collection = firebase.firestore().collection('serialnumbers');
  }

  list = () => {
    return new Promise((resolve, reject) => {
      const map = {};
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            map[doc.id] = doc.data();
          });
          resolve({ code: 0, body: map });
        },
        error => {
          resolve({ code: -1, body: error });
        }
      );
    });
  };
}