/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import {create, list, delete_ } from '../../store/category/actions';
import Pages403 from 'Base/pages/pages-403';
import { submit } from 'redux-form';
import { NAME_PROJECT,  buildTitle } from 'Apps';
import{ NAVBAR, PERMISION } from 'Apps';
const navbar = NAVBAR
const permision = PERMISION
class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      CategoryListColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: 'id',
          text: 'ID',
          sort: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          text: 'Name Category',
          dataField: 'category',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.category}
                </Link>
              </h5>
            </>
          ),
        },
        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          text: 'Action',
          formatter: (cellContent, row) => (
            <div className="d-flex gap-3">
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => this.handleDeleteClick(row.id)}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.showToast = this.showToast.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  showToast(msg) {
    var toastType = 'success';
    var message = msg =='Create' ? "Berhasil Membuat Category":'Berhasil Delete Kategori';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  handleDeleteClick = row => {
    this.props.DeleteCategory(row);
  };
  handleValidSubmit = (e, values) => {
    const name = safeDeepGet(values, 'category', '');
    const underscore = name.replace(/ /g,"_");
    const id = underscore.toLowerCase()
    const submitValues = {id, ...values};
    this.props.CreateCategory(submitValues)
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if (this.props.createResult !== prevProps.createResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        this.showToast('Create');
        this.toggle();
        this.componentDidMount();
      }
    }
    if (this.props.deleteResult !== prevProps.deleteResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['deleteResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        this.showToast('Delete');
        this.componentDidMount();
      }
    }
  }
  componentDidMount() {
    if (safeDeepGet(navbar, ['Product', 'edit'], 'off') == 'off') {
      this.state.CategoryListColumns.pop();
    }
    // Load Product
    this.props.LoadCategoryList();
  }

  /* Handle click */
  handleAddClick = arg => {
    this.toggle();
  };
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    // const code = safeDeepGet(
    //   this.props,
    //   ['result', 'code'],
    //   Result.UNKNOWN_ERROR
    // );
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const { SearchBar } = Search;
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];

    // const selectRow = {
    //   mode: 'checkbox',
    // };

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Category List')}</title>
          </MetaTags>
          {!permision ||
          safeDeepGet(navbar, ['Product', 'view'], 'off') == 'off' ? (
            <Pages403 />
          ) : (
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Product" breadcrumbItem="List" />
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={this.state.CategoryListColumns}
                        data={list}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={this.state.CategoryListColumns}
                            data={list}
                            search
                          >
                            {toolkitprops => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitprops.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="8">
                                    {safeDeepGet(
                                      navbar,
                                      ['Product', 'edit'],
                                      'off'
                                    ) == 'off' ? null : (
                                      <div className="text-sm-end">
                                        <Button
                                          color="primary"
                                          className="font-16 btn-block btn btn-primary"
                                          onClick={this.handleAddClick}
                                        >
                                          <i className="mdi mdi-plus-circle-outline me-1" />
                                          Create New Category
                                        </Button>
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          'table align-middle table-nowrap table-hover'
                                        }
                                        bordered={false}
                                        striped={false}
                                        responsive
                                        ref={this.node}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Modal
                size="md"
                isOpen={this.state.modal}
                className={this.props.className}
                toggle={this.toggle}
              >
                <ModalHeader toggle={this.toggle} tag="h4">
                  {'Create Category for Product'}
                </ModalHeader>
                <ModalBody>
                  <AvForm onValidSubmit={this.handleValidSubmit}>
                    <Row form>
                      <Col className="col-12">
                        <div className="mb-3">
                          <AvField
                            name="category"
                            label="Nama Kategori"
                            type="text"
                            errorMessage="Invalid Text"
                            validate={{
                              required: { value: true },
                            }}
                            value={''}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-success save-user"
                            disabled={this.state.loading}
                          >
                            Submit
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </ModalBody>
              </Modal>
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['category', 'list'], {}),
  createResult: safeDeepGet(state,['category', 'create'], {}),
  deleteResult: safeDeepGet(state, ['category', 'delete'],{}),
  loading: safeDeepGet(state, ['category', 'loading'], true),
});

const mapDispatchToProps = dispatch => ({
  CreateCategory: payload => dispatch(create(payload)),
  LoadCategoryList: () => dispatch(list()),
  DeleteCategory: payload => dispatch(delete_(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
