/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import Base from './base';
import firebase from 'firebase/compat/app';
export default class CSV extends Base {
  constructor(firebase, applicationId) {
    super(firebase, applicationId, 'csv');
  }

  addCsv = payload => {
    return new Promise((resolve, reject) => {
      for (var i in payload) {
        var id = '';
        const _chars =
          'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890';
        for (var j = 0; j < 20; j++) {
          id += _chars.charAt(Math.floor(Math.random() * _chars.length));
        }
        payload[i]['id'] = payload[i]['DeviceId'];
        payload[i]['name'] = payload[i]['productId'];
        payload[i]['price'] = Number(payload[i]['price']);
        payload[i]['order'] = Number(payload[i]['order']);
        delete payload[i]['DeviceId'];
        delete payload[i]['amount 1'];
        delete payload[i]['amount 2'];
        delete payload[i]['amount 3'];
        delete payload[i]['amount 4'];
        delete payload[i]['ingredients 1'];
        delete payload[i]['ingredients 2'];
        delete payload[i]['ingredients 3'];
        delete payload[i]['ingredients 4'];
        this.collection
          .doc(id)
          .set(payload[i])
          .then(
            () => {
              resolve({ code: 0, Message: 'Berhasil Upload Csv Ke Database' });
            },
            error => {
              resolve({ code: -1, body: error });
            }
          );
      }
    });
  };
}
