/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import { safeDeepGet } from 'iotera-base/utility/json';
import Base from './base';

export default class Refund  extends Base {
  constructor(firebase, applicationId) {
    super(firebase, applicationId, 'refund_activity');
  }


  create = payload => {
    return new Promise((resolve, reject) => {
      const storage = JSON.parse(localStorage.getItem('authUser'));
      const authUser = safeDeepGet(storage, 'email', '-');
      const order_id = safeDeepGet(payload, ['payload', 'id']);
      const device_id = safeDeepGet(payload, ['payload', 'device_id']);
      const amount = safeDeepGet(payload, ['payload', 'amount']);
      const name = safeDeepGet(payload, ['payload', 'name']);
      const reason = safeDeepGet(payload, ['payload', 'reason'], '-');
      const timeStamp = new Date().getTime();
      const insertedPayload = {};
      insertedPayload['user'] = authUser;
      insertedPayload['device_id'] = device_id;
      insertedPayload['amount'] = amount;
      insertedPayload['time_stamp'] = timeStamp;
      insertedPayload['order_id'] = order_id;
      insertedPayload['name'] = name;
      insertedPayload['reason'] = reason;
      this.collection
        .doc(timeStamp.toString())
        .set(insertedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  list = payload => {
    return new Promise((resolve, reject) => {
      var filter_bp = safeDeepGet(payload, ['payload', 'businessPoints']);
      var filter_sd = safeDeepGet(payload, ['payload', 'dateRange'])[0];
      var filter_ed = safeDeepGet(payload, ['payload', 'dateRange'])[1];
      if (filter_bp == 'all' || filter_bp == null || filter_bp == undefined) {
        filter_bp = null;
      }
      if (filter_sd == 'all' || filter_sd == null || filter_sd == undefined) {
        filter_sd = null;
      }
      if (filter_ed == 'all' || filter_ed == null || filter_ed == undefined) {
        filter_ed = null;
      }
      let query = this.collection;
      if (filter_bp) {
        query = query.where('device_id', '==', filter_bp);
      }

      if (filter_sd) {
        query = query.where('time_stamp', '>=', filter_sd.getTime());
      }

      if (filter_ed) {
        const d = filter_ed;
        d.setDate(d.getDate() + 1);
        query = query.where('time_stamp', '<=', d.getTime());
      }
      const list = {};
      query.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          console.log(error);
          resolve({ code: -1, body: error });
        }
      );
    });
  };
}
