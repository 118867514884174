/* eslint-disable semi */

/* Import */
import React, { Component } from 'react';

import { connect } from 'react-redux';

import { init as initFirebase } from 'Apps/firebase';
import AppLayout from 'Apps/layout';
import AppRouter from 'Apps/routes';

import './Base/assets/scss/theme.scss';

/* Init Firebase */
initFirebase();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firebaseInitialized: false,
    };
  }

  async componentDidMount() {
    if(localStorage.getItem('project_handle') == null){
      setTimeout(() => {
        this.setState({ firebaseInitialized: true });
      }, 3000);
    } else {
      this.setState({ firebaseInitialized: true });
    }
    
    try {
      // await initFirebase();
    } catch (error) {
      console.error('Failed to initialize Firebase:', error);
    }
  }

  render() {
    if (!this.state.firebaseInitialized) {
      // You can show a loading spinner or message while Firebase is initializing.
      return (
        <div
          className="loading-spinner"
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            zIndex: '9999',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="150"
            height="150"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-ring"
          >
            <circle
              cx="50"
              cy="50"
              r="40"
              stroke="#007bff"
              strokeWidth="10"
              strokeDasharray="62.83185307179586 62.83185307179586"
              fill="none"
              strokeLinecap="round"
              transform="rotate(215.582 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>
      );
    }

    return (
      <React.Fragment>
        <AppRouter layout={AppLayout()} />
      </React.Fragment>
    );
  }
}

export default connect()(App);
