/* eslint-disable semi */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { Provider } from 'react-redux';
// import MultitenancyStore from './Multitenancy/store';
import AdminStore from './Admin/store';
import BaseStore from './Base/store';
import FrankeStore from './SvNestleFranke/store';
import MilanoStore from './SvNestleMilano/store';
import { APPLICATION_ID, MULTITENANCY_HANDLE } from 'Apps';
let multi = JSON.parse(localStorage.getItem('multitenancy'));
let store = null;
if (MULTITENANCY_HANDLE) {
  // if (multi) {
  //   console.log("IS MULTI STORE")
  //   store = MultitenancyStore;
  // } else {
  //   console.log("IS BASE STORE")
    store = BaseStore;
  // }
} else if (APPLICATION_ID == '1000000159') {
  store = MilanoStore;
} else if (APPLICATION_ID == '1000000211') {
  store = FrankeStore;
} else if (APPLICATION_ID == 'Admin') {
  store = AdminStore;
} else {
  store = BaseStore;
}
const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
