import {
  LOADING,
  RESULT,
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  LIST_VM,
  SUMMARY,
  IMPORT_PRODUCT
} from "./actionTypes";

const INIT_STATE = {
  loading: true,
};

const Refiller = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case RESULT:
      const newState = {
        ...state,
        loading: false,
      };
      switch (action.payload.actionType) {
        case LIST:
          newState["list"] = action.payload.result;
          break;
        case IMPORT_PRODUCT:
          newState["importProduct"] = action.payload.result;
          break;
        case SUMMARY:
          newState['summary'] = action.payload.result;
          break;
        case LIST_VM:
          newState["list_vm"] = action.payload.result;
          break;
        case INIT_CREATE:
          newState["initCreate"] = action.payload.result;
          break;
        case CREATE:
          newState["create"] = action.payload.result;
          break;
        case READ:
          newState["read"] = action.payload.result;
          break;
        case UPDATE:
          newState["update"] = action.payload.result;
          break;
        case STOCK_UPDATE:
          newState["stockUpdate"] = action.payload.result;
          break;
        case DELETE:
          newState["delete"] = action.payload.result;
          break;
      }
      return newState;

    default:
      return state;
  }
};

export default Refiller;
