import {
  LOADING,
  RESULT,
  LIST,
  LIST_VM,
  LIST_PRODUCT,
  INIT_CREATE,
  CREATE,
  READ_INFO,
  UPDATE_INFO,
  READ_PLANOGRAM,
  UPDATE_PLANOGRAM,
  ADD_PLANOGRAM,
  DELETE,
  READ_PRODUCT,
  UPDATE_CALIBRATION,
  DELETE_PLANOGRAM,
  HISTORY,
  TESTCSV,
  SERI_VM,
  INPUT_CSV
} from "./actionTypes";

export const getSeriVM = payload => ({
  type: SERI_VM,
  payload
});
export const list_history = payload => ({
  type: HISTORY,
  payload
});
export const loading = actionType => ({
  type: LOADING,
  payload: { actionType }
});

export const result = (actionType, result) => ({
  type: RESULT,
  payload: { actionType, result }
});

export const list = payload => ({
  type: LIST,
  payload
});
export const list_vm = () => ({
  type: LIST_VM,
});
export const list_product = () => ({
  type: LIST_PRODUCT,
});

export const initCreate = () => ({
  type: INIT_CREATE,
});

export const create = payload => ({
  type: CREATE,
  payload
});
export const inputCsv = payload => ({
  type: INPUT_CSV,
  payload
});
export const testcsv = payload => ({
  type: TESTCSV,
  payload
});

export const readInfo = id => ({
  type: READ_INFO,
  payload: { id }
});

export const updateInfo = payload => ({
  type: UPDATE_INFO,
  payload
});
export const updatecalibration = payload => ({
  type: UPDATE_CALIBRATION,
  payload
});

export const readPlanogram = id => ({
  type: READ_PLANOGRAM,
  payload: { id }
});

export const readproduct = id => ({
  type: READ_PRODUCT,
  payload: { id }
});

export const updatePlanogram = payload => ({
  type: UPDATE_PLANOGRAM,
  payload
});
export const addPlanogram = payload => ({
  type: ADD_PLANOGRAM,
  payload
});

export const delete_ = deviceId => ({
  type: DELETE,
  payload: { deviceId }
});
export const deletePlanogram = deviceId => ({
  type: DELETE_PLANOGRAM,
  payload: { deviceId }
});
