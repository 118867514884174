import { call, put, takeEvery } from 'redux-saga/effects'

import {
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  LIST_VM,
} from './actionTypes'

import { loading, result } from './actions'
import { getInstance } from '../../firebase/helper'
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json'
import Result from 'iotera-base/result'
import { createdUser, deletedUser } from 'Apps/device'

function* list() {
  yield put(loading(LIST))
  const firebaseInstance = getInstance()
  const wsResult = yield call(firebaseInstance.refiller.list)
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const refillerMap = wsResult.body
    const refillerList = []
    let i = 0
    for (const sku in refillerMap) {
      refillerMap[sku].id = sku
      safeDeepSet(refillerMap, [sku, 'id'], i++)
      refillerList.push(refillerMap[sku])
    }
    yield put(result(LIST, { code: Result.SUCCESS, body: refillerList }))
    return
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }))
}

function* initCreate() {
  yield put(result(INIT_CREATE, { code: Result.SUCCESS, body: {} }))
}
function* create(action) {
  yield put(loading(CREATE))
  const payload = safeDeepGet(action, 'payload')
  const id = safeDeepGet(action, ['payload', 'id'])
  const email = safeDeepGet(action, ['payload', 'email'])
  const pass = safeDeepGet(action, ['payload', 'pass'])
  const firebaseInstance = getInstance()

  // Add to Firebase Auth
  const wsResult = yield call(createdUser, email, pass)
  const code = safeDeepGet(wsResult, 'code')
  if (code === Result.SUCCESS || code === Result.HTTP.OK) {
    // Add to Role
    yield call(firebaseInstance.role.create, id, payload)

    // Add to Refiller
    yield call(firebaseInstance.refiller.create, id, payload)
  }

  yield put(result(CREATE, { code }))
}
function* list_vm() {
  yield put(loading(LIST_VM))
  const firebaseInstance = getInstance()
  let wsResult = yield call(firebaseInstance.refiller.list)
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const listMap = wsResult.body
    const refillerList = []
    for (const id in listMap) {
      let refillerName = safeDeepGet(listMap, [id, 'name'])
      let refillerId = safeDeepGet(listMap, [id, 'code'])
      refillerList.push({ label: refillerName, value: refillerId })
    }
    yield put(result(LIST_VM, { code: Result.SUCCESS, body: refillerList }))
    return
  }
  yield put(result(LIST_VM, { result: { code: Result.FAILED } }))
}
function* read(action) {
  yield put(loading(READ))
  const sku = safeDeepGet(action, ['payload', 'sku'])
  const firebaseInstance = getInstance()
  const wsResult = yield call(firebaseInstance.refiller.read, sku)
  wsResult.body.id = sku
  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ, { code: Result.FAILED }))
    return
  }

  yield put(
    result(READ, {
      code: 0,
      body: wsResult.body,
    })
  )
}

function* update(action) {
  yield put(loading(UPDATE))
  const sku = safeDeepGet(action, ['payload', 'id'])
  const payload = safeDeepGet(action, 'payload')
  const firebaseInstance = getInstance()
  const wsResult = yield call(firebaseInstance.refiller.update, sku, payload)
  const code = safeDeepGet(wsResult, 'code')

  yield put(result(UPDATE, { code }))
}

function* stockUpdate(action) {
  yield put(loading(STOCK_UPDATE))
  const sku = safeDeepGet(action, ['payload', 'sku'])
  const payload = safeDeepGet(action, 'payload')
  const firebaseInstance = getInstance()

  // Upload image to firebase storage
  const imageFile = safeDeepGet(action, ['payload', 'imageFile'])
  let imageUrl = null
  if (imageFile != null) {
    const imageExt = safeDeepGet(action, ['payload', 'imageExt'])
    const wsResult = yield call(
      firebaseInstance.productStorage.replace,
      imageFile,
      sku,
      imageExt
    )
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      imageUrl = safeDeepGet(wsResult, ['body', 'url'])
    }
  }
  if (imageUrl != null) {
    payload['img_url'] = imageUrl
  }

  const wsResult = yield call(
    firebaseInstance.product.updateStock,
    sku,
    payload
  )
  const code = safeDeepGet(wsResult, 'code')

  yield put(result(STOCK_UPDATE, { code }))
}

function* delete_(payload) {
  yield put(loading(DELETE))
  const firebaseInstance = getInstance()
  const userId = safeDeepGet(payload, ['payload', 'id'], '')
  const userUid = safeDeepGet(payload, ['payload', 'uid'], '')
  let wsResult = yield call(deletedUser, userUid)
  const code = safeDeepGet(wsResult, 'code')
  if(code == Result.SUCCESS || code == Result.HTTP.OK) {
    wsResult = yield call(firebaseInstance.refiller.delete_, userId)
    const code = safeDeepGet(wsResult, 'code')
    yield put(result(DELETE, { code: code }))
  } else {
    yield put(result(DELETE, { code: Result.FAILED }))

  }
}

function* productSaga() {
  yield takeEvery(LIST, list)
  yield takeEvery(INIT_CREATE, initCreate)
  yield takeEvery(CREATE, create)
  yield takeEvery(READ, read)
  yield takeEvery(UPDATE, update)
  yield takeEvery(STOCK_UPDATE, stockUpdate)
  yield takeEvery(DELETE, delete_)
  yield takeEvery(LIST_VM, list_vm)
}

export default productSaga
