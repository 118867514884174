import { call, put, takeEvery, all, fork } from 'redux-saga/effects';

// Product state
import {
  LIST,
  LIST_BUSINESS_POINTS,
  READ,
  REFUND,
  SUMMARY,
  SNAPSHOT,
  GET_SALES_ANALYTICS,
  UPDATE_REFUND,
  DOWNLOAD_SNAPSHOT,
} from './actionTypes';
import { PROJECT } from '../../../Project';
import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
import firebase from 'firebase/compat/app';

function* download_snapshot(payload) {
  yield put(loading(DOWNLOAD_SNAPSHOT));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPoint = wsResult.body;
    const businessPointMap = {};
    for (const id in businessPoint) {
      let deviceId = safeDeepGet(businessPoint, [id, 'id']);
      businessPointMap[deviceId] = safeDeepGet(businessPoint, id);
    }
    var distributorMap = {};
    wsResult = yield call(firebaseInstance.distributor.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const distributor = wsResult.body;
      for (const id in distributor) {
        let code = safeDeepGet(distributor, [id, 'code']);
        distributorMap[code] = safeDeepGet(distributor, id);
      }
    }
    wsResult = yield call(firebaseInstance.transaction.download, payload);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const transactionMap = wsResult.body;
      const transactionList = [];
      let i = 0;
      for (const orderId in transactionMap) {
        safeDeepSet(transactionMap, [orderId, 'id'], i++);
        const fillter = safeDeepGet(payload, ['fillter']);
        const role = safeDeepGet(payload, ['role']);
        var d_val = safeDeepGet(transactionMap[orderId], ['itemDetails']);
        var productName = safeDeepGet(d_val, [0]);
        safeDeepSet(transactionMap, [orderId, 'productDetails'], productName);
        let device_id = safeDeepGet(transactionMap[orderId], [
          'product',
          'device_id',
        ]);
        // Set BusinessPoint Name
        let bp_name = safeDeepGet(businessPointMap, [device_id, 'name'], '-');
        safeDeepSet(transactionMap, [orderId, 'name'], bp_name);
        // Set SerialNumber Milano
        let sn = safeDeepGet(businessPointMap, [device_id, 'sn']);
        safeDeepSet(transactionMap, [orderId, 'sn'], sn);
        // Set Distributor BusinessPoint
        let distributor = safeDeepGet(businessPointMap, [
          device_id,
          'distributor',
        ]);
        safeDeepSet(transactionMap, [orderId, 'distributor'], distributor);
        let nameDistributor = safeDeepGet(distributorMap, [
          distributor,
          'name',
        ]);
        safeDeepSet(
          transactionMap,
          [orderId, 'NameDistributor'],
          nameDistributor
        );
        let refiller = safeDeepGet(businessPointMap, [device_id, 'refiller']);
        safeDeepSet(transactionMap, [orderId, 'refiller'], refiller);
        // Set Channel Telegram BusinessPoint
        let channel = safeDeepGet(businessPointMap, [device_id, 'channel']);
        safeDeepSet(transactionMap, [orderId, 'channel'], channel);
          let badgeclass = 'info'
        if (
          safeDeepGet(transactionMap[orderId], [
            'detail',
            'transaction_status',
          ]) == 'settlement'
        ) {
          badgeclass = 'success';
        } else if (
          safeDeepGet(transactionMap[orderId], [
            'detail',
            'transaction_status',
          ]) == 'refund_pending'
        ) {
          badgeclass = 'warning';
        } else if (
          safeDeepGet(transactionMap[orderId], [
            'detail',
            'transaction_status',
          ]) == 'cancel'
        ) {
          badgeclass = 'danger';
        } else if (
          safeDeepGet(transactionMap[orderId], [
            'detail',
            'transaction_status',
          ]) == 'failure'
        ) {
          badgeclass = 'danger';
        }

        let badge = 'info';
          if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_vm_timeout'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_column_unresponsive'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_vm_error'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'success'
          ) {
            badge = 'success';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'pending'
          ) {
            badge = 'warning';
          }

        safeDeepSet(transactionMap, [orderId, 'badge'], badge);
        safeDeepSet(transactionMap, [orderId, 'badgeclass'], badgeclass);

        if (fillter == 'all' || fillter == null || fillter == undefined) {
          transactionList.push(transactionMap[orderId]);
        } else {
          if (role == 'refiller') {
            if ((transactionMap, [orderId, 'refiller'], refiller == fillter)) {
              transactionList.push(transactionMap[orderId]);
            }
          }
          if (role == 'distributor') {
            if (
              (transactionMap, [orderId, 'distributor'], distributor == fillter)
            ) {
              transactionList.push(transactionMap[orderId]);
            }
          }
        }
      }
      yield put(
        result(DOWNLOAD_SNAPSHOT, {
          code: Result.SUCCESS,
          loading: false,
          body: transactionList,
        })
      );
      return;
    }
    yield put(result(DOWNLOAD_SNAPSHOT, { result: { code: Result.FAILED } }));
  }
}

function* list_business_points(payload) {
  const data = safeDeepGet(payload, ['payload']);
  yield put(loading(LIST_BUSINESS_POINTS));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list, data);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointMap = wsResult.body;
    const bpFilterList = [{ label: 'All points', value: 'all' }];
    if (PROJECT == 'Redboks') {
      for (const deviceId in businessPointMap) {
        let bp_name = safeDeepGet(businessPointMap, [deviceId, 'name']);
        bpFilterList.push({ label: bp_name, value: deviceId });
      }
    } else {
      for (const deviceId in businessPointMap) {
        let bp_name = safeDeepGet(businessPointMap, [deviceId, 'name']);
        let id = safeDeepGet(businessPointMap, [deviceId, 'id']);
        bpFilterList.push({ label: bp_name, value: id });
      }
    }
    yield put(
      result(LIST_BUSINESS_POINTS, { code: Result.SUCCESS, body: bpFilterList })
    );
    return;
  }
  yield put(result(LIST_BUSINESS_POINTS, { result: { code: Result.FAILED } }));
}

function* list(payload) {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointMap = wsResult.body;
    wsResult = yield call(firebaseInstance.product.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const productMap = wsResult.body;
      wsResult = yield call(firebaseInstance.transactionrfid.list, payload);
      if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
        const transactionMap = wsResult.body;
        const transactionList = [];
        let i = 0;
        for (const orderId in transactionMap) {
          const fillter = safeDeepGet(payload, ['fillter']);
          const role = safeDeepGet(payload, ['role']);
          safeDeepSet(transactionMap, [orderId, 'id'], i++);
          let productSku = safeDeepGet(transactionMap[orderId], [
            'product',
            'sku',
          ]);
          let productName = safeDeepGet(productMap, [productSku, 'name']);
          safeDeepSet(transactionMap, [orderId, 'nameProduct'], productName);
          let device_id = safeDeepGet(transactionMap[orderId], [
            'product',
            'device_id',
          ]);
          let bp_name = safeDeepGet(businessPointMap, [device_id, 'name'], '-');
          safeDeepSet(transactionMap, [orderId, 'name'], bp_name);
          let badgeclass = 'info';
          if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'settlement'
          ) {
            badgeclass = 'success';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'refund_pending'
          ) {
            badgeclass = 'warning';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'cancel'
          ) {
            badgeclass = 'danger'; 
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'timeout'
          ) {
            badgeclass = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'failure'
          ) {
            badgeclass = 'danger';
          }

          let badge = 'info';
          if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_vm_timeout'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_column_unresponsive'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_vm_error'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'success'
          ) {
            badge = 'success';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'pending'
          ) {
            badge = 'warning';
          }

          safeDeepSet(transactionMap, [orderId, 'badge'], badge);
          safeDeepSet(transactionMap, [orderId, 'badgeclass'], badgeclass);

          // FILTERING
          if (fillter == 'all' || fillter == null || fillter == undefined) {
            transactionList.push(transactionMap[orderId]);
          } else {
            if (role == 'refiller') {
              if (
                (transactionMap, [orderId, 'refiller'], refiller == fillter)
              ) {
                transactionList.push(transactionMap[orderId]);
              }
            }
            if (role == 'distributor') {
              if (
                (transactionMap,
                [orderId, 'distributor'],
                distributor == fillter)
              ) {
                transactionList.push(transactionMap[orderId]);
              }
            }
          }
        }

        yield put(
          result(LIST, { code: Result.SUCCESS, body: transactionList })
        );
        return;
      }
      yield put(result(LIST, { result: { code: Result.FAILED } }));
    }
    yield put(result(LIST, { result: { code: Result.FAILED } }));
  }
}

function* read(payload) {}

function* refund(payload) {}

function* summary(payload) {
  yield put(loading(SUMMARY));
  const firebaseInstance = getInstance();
  let wsResult = yield call(
    firebaseInstance.transaction.nett_settlement,
    payload
  );

  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const summaryCurrentMap = wsResult.body;
    var payload_prev = payload;
    safeDeepSet(payload_prev, ['month'], safeDeepGet(payload, ['month']) - 1);
    wsResult = yield call(
      firebaseInstance.transaction.nett_settlement,
      payload_prev
    );

    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const summaryPrevMap = wsResult.body;
      wsResult = yield call(firebaseInstance.businessPoint.list);

      if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
        const bpInfo = wsResult.body;
        let landlord_current = sum_month_share(summaryCurrentMap, bpInfo);
        let landlord_prev = sum_month_share(summaryPrevMap, bpInfo);
        let landlord_change = calc_change(landlord_current, landlord_prev);

        let trx_current = sum_month(summaryCurrentMap);
        let trx_prev = sum_month(summaryPrevMap);
        let trx_change = calc_change(trx_current, trx_prev);

        let refund_current = sum_month_refund(summaryCurrentMap);
        let refund_prev = sum_month_refund(summaryPrevMap);
        let refund_change = calc_change(refund_current, refund_prev);

        let settlement_current = Object.keys(summaryCurrentMap).length;
        let settlement_prev = Object.keys(summaryPrevMap).length;
        let settlement_change = calc_change(
          settlement_current,
          settlement_prev
        );

        const ret_summary = {
          landlord_current: landlord_current,
          landlord_prev: landlord_prev,
          landlord_change: landlord_change,
          trx_current: trx_current,
          trx_prev: trx_prev,
          trx_change: trx_change,
          refund_current: refund_current,
          refund_prev: refund_prev,
          refund_change: refund_change,
          settlement_current: settlement_current,
          settlement_prev: settlement_prev,
          settlement_change: settlement_change,
        };
        yield put(result(SUMMARY, { code: Result.SUCCESS, body: ret_summary }));
        return;
      }
    }
  }
  yield put(result(SUMMARY, { result: { code: Result.FAILED } }));
}

function* snapshot() {
  // yield call(fetch, url, ...);
  yield put(loading(SNAPSHOT));
  const firebaseInstance = getInstance();
  // let wsResult = yield call(firebaseInstance.transaction.listSnapshot);
  const storageRef = firebase.storage().ref();
  const storage = firebase.storage();
  const dataList = [];
  const optionsList = [];
  var holder = {};
  var listRef = storageRef.child('report');
  let i = 0;
  listRef
    .listAll()
    .then(res => {
      res.prefixes.forEach(folderRef => {});

      res.items.forEach(itemRef => {
        itemRef.getDownloadURL().then(downloadURL => {
          var httpsReference = storage.refFromURL(downloadURL);
          dataList.push({
            url: downloadURL,
            id: i++,
            name: httpsReference.name,
          });
          optionsList.push({
            value: downloadURL,
            label: httpsReference.name,
          });
        });
      });
    })
    .catch(error => {
      console.log(error);
    });
  yield put(
    result(SNAPSHOT, {
      code: Result.SUCCESS,
      body: { data: dataList, options: optionsList },
    })
  );
  return;
}

function* get_sales_analytics(payload) {
  yield put(loading(GET_SALES_ANALYTICS));
  const firebaseInstance = getInstance();
  let wsResult = yield call(
    firebaseInstance.transaction.nett_settlement,
    payload
  );

  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const nsMap = wsResult.body;
    wsResult = yield call(firebaseInstance.product.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const productMap = wsResult.body;
      var holder = {};
      for (var d in nsMap) {
        var d_val = safeDeepGet(nsMap, [d]);
        let productSku = safeDeepGet(d_val, ['product', 'sku']);
        let productName = safeDeepGet(productMap, [productSku, 'name']);
        safeDeepSet(d_val, ['nameProduct'], productName);
        if (
          safeDeepGet(d_val, ['detail', 'transaction_status']) == 'settlement'
        ) {
          if (holder.hasOwnProperty(d_val.nameProduct)) {
            holder[d_val.nameProduct] =
              holder[d_val.nameProduct] + d_val.payment.amount;
          } else {
            holder[d_val.nameProduct] = d_val.payment.amount;
          }
        }
      }

      var ret_sales_analytics = [];
      for (var prop in holder) {
        ret_sales_analytics.push({ name: prop, value: holder[prop] });
      }

      yield put(
        result(GET_SALES_ANALYTICS, {
          code: Result.SUCCESS,
          body: ret_sales_analytics,
        })
      );
      return;
    }
  }
  yield put(result(GET_SALES_ANALYTICS, { result: { code: Result.FAILED } }));
}

function calc_change(sum_current, sum_prev) {
  var temp_change = 0.0;
  if (sum_prev == 0 && sum_current > 0) {
    temp_change = 100.0;
  } else if (sum_prev == 0 && sum_current == 0) {
    temp_change = 0.0;
  } else {
    temp_change = (sum_current / sum_prev - 1.0) * 100.0;
  }
  return temp_change;
}

function sum_month_share(nsMap, bpInfo) {
  var resultTotal = 0;
  var total = 0;
  var ppn = 0;
  var net = 0;
  var mdr = 0;
  const bpMap = {};
  for (const i in bpInfo) {
    var deviceid = safeDeepGet(bpInfo, [i, 'id']);
    bpMap[deviceid] = safeDeepGet(bpInfo, i);
  }
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    var device_id = safeDeepGet(val, ['deviceDetails', 'deviceId']);
    const share = Number(safeDeepGet(bpMap, [device_id, 'distributorMargin']));
    const persentase = share / 100;
    if (
      safeDeepGet(val, ['detail', 'transaction_status']) ==
      'settlement'
    ) {
      if (safeDeepGet(val, ['detail', 'dispense_status']) == true) {
        resultTotal += val.payment.amount;
      }
    }
  }
  mdr = (resultTotal * 7) / 1000;
  net = resultTotal - mdr;
  ppn = net * ((11 / 100) / (110 / 100));
  total = net - ppn;
  const share = (total * 5) / 100;
  return share;
}

function sum_month_refund(nsMap) {
  var resultTotal = 0;
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    if (safeDeepGet(val, ['detail', 'transaction_status']) == 'refund') {
      resultTotal += val.payment.amount;
    }
  }
  return resultTotal;
}

function sum_month(nsMap) {
  var resultTotal = 0;
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    if (safeDeepGet(val, ['detail', 'transaction_status']) == 'settlement') {
      resultTotal += val.payment.amount;
    }
  }
  return resultTotal;
}

function* updateRefund(action) {
  yield put(loading(UPDATE_REFUND));
  const id = safeDeepGet(action, ['payload', 'order_id']);
  const payload = safeDeepGet(action, 'payload');
  const firebaseInstance = getInstance();
  const wsResult = yield call(
    firebaseInstance.transaction.updateRefund,
    id,
    payload
  );
  const code = safeDeepGet(wsResult, 'code');

  yield put(result(UPDATE_REFUND, { code }));
}

function* transactionSaga() {
  yield takeEvery(LIST_BUSINESS_POINTS, list_business_points);
  yield takeEvery(LIST, list);
  yield takeEvery(READ, read);
  yield takeEvery(REFUND, refund);
  yield takeEvery(UPDATE_REFUND, updateRefund);
  yield takeEvery(SUMMARY, summary);
  yield takeEvery(SNAPSHOT, snapshot);
  yield takeEvery(GET_SALES_ANALYTICS, get_sales_analytics);
  yield takeEvery(DOWNLOAD_SNAPSHOT, download_snapshot);
}

export default transactionSaga;
