export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "STOCK_LIST";
export const INIT_CREATE = "STOCK_INIT_CREATE";
export const CREATE = "STOCK_CREATE";
export const READ = "STOCK_INFO";
export const UPDATE = "STOCK_UPDATE";
export const DELETE = "STOCK_DELETE";
export const REFILL_LIST = "REFILL_LIST";
export const LIST_INGREDIENT = "LIST_INGREDIENT";
