/* eslint-disable semi */
import {
  LOADING,
  RESULT,
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  LIST_BUSINESS_POINTS,
  LIST_ALERT_DEVICE,
  LIST_ALERT_HISTORICAL_BYDEVICE,
  LIST_ALERT_HISTORICAL,
  LIST_ALERT_CURRENT,
} from './actionTypes';

export const loading = actionType => ({
  type: LOADING,
  payload: { actionType },
});

export const result = (actionType, result) => ({
  type: RESULT,
  payload: { actionType, result },
});

export const list = payload => ({
  type: LIST,
  dateRange: payload.dateRange,
  businessPoints: payload.businessPoints,
});
export const list_business_points = payload => ({
  type: LIST_BUSINESS_POINTS,
  payload,
});
export const list_alert_device = payload => ({
  type: LIST_ALERT_DEVICE,
  payload,
});

export const list_alert_historical_device = (payload) => ({
  type: LIST_ALERT_HISTORICAL_BYDEVICE,
  payload
});

export const initCreate = () => ({
  type: INIT_CREATE,
});
export const list_alert_current = () => ({
  type: LIST_ALERT_CURRENT,
});
export const list_alert_historical = (payload) => ({
  payload,
  type: LIST_ALERT_HISTORICAL,
});
export const create = payload => ({
  type: CREATE,
  payload,
});

export const read = sku => ({
  type: READ,
  payload: { sku },
});

export const update = payload => ({
  type: UPDATE,
  payload,
});

export const stockUpdate = payload => ({
  type: STOCK_UPDATE,
  payload,
});

export const delete_ = sku => ({
  type: DELETE,
  payload: { sku },
});
