/* eslint-disable no-case-declarations */
/* eslint-disable semi */
import {
  LOADING,
  RESULT,
  INITCONFIG,
} from "./actionTypes";

const INIT_STATE = {
  loading: true,
};

const Role = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case RESULT:
      const newState = {
        ...state,
        loading: false,
      };
      switch (action.payload.actionType) {
        case INITCONFIG:
          newState["initConfig"] = action.payload.result;
          break;
      }
      return newState;

    default:
      return state;
  }
};

export default Role;
