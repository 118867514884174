/* eslint-disable semi */
export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "PRODUCT_LIST";
export const LIST_CATEGORY = "PRODUCT_LIST_CATEGORY";
export const INIT_CREATE = "PRODUCT_INIT_CREATE";
export const CREATE = "PRODUCT_CREATE";
export const READ = "PRODUCT_INFO";
export const UPDATE = "PRODUCT_UPDATE";
export const STOCK_UPDATE = "STOCK_PRODUCT_UPDATE";
export const DELETE = "PRODUCT_DELETE";
