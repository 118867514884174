export const LOADING = "LOADING"
export const RESULT = "RESULT"

export const LIST = "ACCOUNT_ROLE_LIST"
export const LIST_VM = "ACCOUNT_ROLE_LIST_VM"
export const INIT_CREATE = "ACCOUNT_ROLE_INIT_CREATE"
export const CREATE = "ACCOUNT_ROLE_CREATE"
export const READ = "ACCOUNT_ROLE_INFO"
export const UPDATE = "ACCOUNT_ROLE_UPDATE"
export const STOCK_UPDATE = "STOCK_ACCOUNT_ROLE_UPDATE"
export const DELETE = "ACCOUNT_ROLE_DELETE"
