import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';

// Availity
import { AvField, AvForm } from 'availity-reactstrap-validation';

// Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Iotera
import Result from "iotera-base/result";
import { safeDeepGet } from 'iotera-base/utility/json';
import { initCreate, create } from '../../store/distributor/actions';
// Redux
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
class CreateDistributor extends Component {
  constructor(props) {
    super(props);

    let isEdit = false;
    let sku = null;

    this.state = {
      isEdit,
      sku,
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {
    const { isEdit } = this.state;
    // Load product
  }
  handleValidSubmit(event, values) {
    const _chars =
      'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890';
    var id = '';
    for (var i = 0; i < 20; i++)
      id += _chars.charAt(Math.floor(Math.random() * _chars.length));
    // const { isEdit, imageUpdated, imageFile, imageExt } = this.state;
    const submittedValues = { ...values, id, role: 'distributor' };
    this.props.CreateDistributor(submittedValues);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      const body = safeDeepGet(
        this.props,
        ['createResult', 'body'],
        Result.UNKNOWN_ERROR
      );
      if(code != 999){
        if (code === Result.SUCCESS || code === Result.HTTP.OK) {
          this.showToast();
          this.props.history.replace('/distributor/list');
        } else {
          this.showToastFailed();
        }
      }
    }
  }
  showToastFailed() {
    const { isEdit } = this.state;

    if (isEdit) {
      var toastType = 'error';
      var message = "Gagal membuat akun distributor";
    } else {
      var toastType = 'error';
      var message = "Gagal membuat akun distributor";
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToast() {
    const { isEdit } = this.state;

    if (isEdit) {
      var toastType = 'success';
      var message = 'Berhasil Update Distributor';
    } else {
      var toastType = 'success';
      var message = 'Berhasil Tambah Distributor';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  render() {
    // const loading = safeDeepGet(this.props, "loading", true);
    const { isEdit, name, nik, nohp, email, pass } = this.state;

    return (
      <React.Fragment>
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <div className="page-content">
            <MetaTags>
              <title>Distributor | Smart Vending Machine</title>
            </MetaTags>
            <Container fluid={true}>
              <Breadcrumbs
                title="Distributor"
                breadcrumbItem={isEdit ? 'Edit' : 'Add'}
              />
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">
                        Distributor Information
                      </CardTitle>
                      <p className="card-title-desc">
                        Fill all information below
                      </p>
                      <Row>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <Label className="mt-1" htmlFor="code">
                              Code :
                            </Label>
                            <AvField
                              type="text"
                              id="code"
                              name="code"
                              placeholder="Code..."
                              className="form-control"
                              validate={{
                                required: { value: true, errorMessage: 'Please enter a Code' },
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="name">Name : </Label>
                            <AvField
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Name.."
                              className="form-control"
                              validate={{
                                required: { value: true, errorMessage: 'Please enter a Name' },
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label className="mt-1" htmlFor="telegram_group">
                              Telegram Group :
                            </Label>
                            <AvField
                              type="text"
                              id="telegram_group"
                              name="telegram_group"
                              placeholder="Telegram Group..."
                              className="form-control"
                              validate={{
                                required: { value: true, errorMessage: 'Please enter a Telegram Group' },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <Label className="mt-1" htmlFor="email">
                              Email :
                            </Label>
                            <AvField
                              type="email"
                              id="email"
                              name="email"
                              value={email}
                              placeholder="Masukan Email..."
                              className="form-control"
                              validate={{
                                required: { value: true, errorMessage: 'Please enter a Email' },
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="pass">Password : </Label>
                            <AvField
                              type="password"
                              id="pass"
                              name="pass"
                              value={pass}
                              placeholder="Masukan Password..."
                              className="form-control"
                              
                              validate={{
                                minLength: {value: 6, errorMessage: 'Your name must be between 6 and 16 characters'},
                                maxLength: {value: 16, errorMessage: 'Your name must be between 6 and 16 characters'},
                                required: { value: true,errorMessage: 'Please enter a Password'},
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <div className="d-flex flex-wrap gap-2 float-end mb-4">
                    <Link
                      className="btn btn-secondary"
                      role="button"
                      to="/distributor/list"
                    >
                      Batal
                    </Link>{' '}
                    <Button type="submit" color="primary">
                      Simpan
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </AvForm>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  initCreateResult: safeDeepGet(state, ['distributor', 'initCreate'], {}),
  createResult: safeDeepGet(state, ['distributor', 'create'], {}),
  loading: safeDeepGet(state, ['distributor', 'loading'], true),
});

const mapDispatchToProps = dispatch => ({
  InitCreateDistributor: () => dispatch(initCreate()),
  CreateDistributor: payload => dispatch(create(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateDistributor);
