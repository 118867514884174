/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import { safeDeepGet } from 'iotera-base/utility/json';
import Base from './base';

export default class Distributor extends Base {
  constructor(firebase, applicationId) {
    super(firebase, applicationId, 'invoice');
  }


  list = () => {
    return new Promise((resolve, reject) => {
      const list = {};
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          resolve({ code: -1, body: list });
        }
      );
    });
  };

  create = payload => {
    return new Promise((resolve, reject) => {
      delete payload.pass;
      const insertedPayload = {};
      insertedPayload['invoice'] = "";
      insertedPayload['bukti_transfer'] = "";
      insertedPayload['ts'] = new Date().getTime();
      insertedPayload['id'] = payload['invoice_no']
      const id = payload['invoice_no'];
      this.collection
        .doc(id)
        .set(insertedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  read = id => {
    return new Promise((resolve, reject) => {
      const invoice_id = safeDeepGet(id, 'sku', '')
      let map = {};
      this.collection
        .where('id', '==', invoice_id)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };

  update = payload => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      const id = payload['id']
      updatedPayload['id'] = safeDeepGet(payload,'id', '');
      updatedPayload['invoice'] = safeDeepGet(payload,'invoice_url', '');
      updatedPayload['bukti_transfer'] = safeDeepGet(payload,'bukti_url', '')
      updatedPayload['invoice_ext'] = safeDeepGet(payload,'invoice_ext','');
      updatedPayload['bukti_transfer_ext'] = safeDeepGet(payload,'bukti_transfer_ext','');
      this.collection
        .doc(id)
        .set(
          updatedPayload,
          { merge: true }
        )
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  delete_ = payload => {
    return new Promise((resolve, reject) => {
      const device_id = payload['device_id'];
      const sku = payload['sku'];
      const id = safeDeepGet(payload, 'id', device_id + '_' + sku ); 
      this.collection
        .doc(id)
        .delete()
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };
}
