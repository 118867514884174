export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "INGREDIENT_LIST";
export const LIST_OPTIONS = "INGREDIENT_OPTIONS_LIST";
export const LIST_BEAN = "INGREDIENT_OPTIONS_BEAN";
export const INIT_CREATE = "INGREDIENT_INIT_CREATE";
export const CREATE = "INGREDIENT_CREATE";
export const READ = "INGREDIENT_INFO";
export const UPDATE = "INGREDIENT_UPDATE";
export const STOCK_UPDATE = "STOCK_INGREDIENT_UPDATE";
export const DELETE = "INGREDIENT_DELETE";
