import {
  LOADING,
  RESULT,
  LIST_BUSINESS_POINTS,
  LIST,
  UPDATE_REFUND,
  READ,
  REFUND,
  SUMMARY,
  SNAPSHOT,
  DOWNLOAD_SNAPSHOT,
  GET_SALES_ANALYTICS,
} from './actionTypes';

const INIT_STATE = {
  loading: true,
};

const Transaction = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case RESULT:
      const newState = {
        ...state,
        loading: false,
      };
      switch (action.payload.actionType) {
        case LIST_BUSINESS_POINTS:
          newState['list_business_points'] = action.payload.result;
          break;
        case LIST:
          newState['list'] = action.payload.result;
          break;
        case READ:
          newState['read'] = action.payload.result;
          break;
        case REFUND:
          newState['refund'] = action.payload.result;
          break;
        case UPDATE_REFUND:
          newState['updateRefund'] = action.payload.result;
          break;
        case SUMMARY:
          newState['summary'] = action.payload.result;
          break;
        case SNAPSHOT:
          newState['snapshot'] = action.payload.result;
          break;
        case DOWNLOAD_SNAPSHOT:
          newState['download_snapshot'] = action.payload.result;
          break;
        case GET_SALES_ANALYTICS:
          newState['get_sales_analytics'] = action.payload.result;
          break;
      }
      return newState;

    default:
      return state;
  }
};

export default Transaction;
