/* eslint-disable semi */
import { JGET, JPOST } from 'iotera-base/webservice/wsrequest'
let headers = {'Authorization': 'Basic aW90ZXJhOklvdGVyYVNtYXJ0dmVuMjAyMQ==' }
import {APPLICATION_ID} from 'Apps'
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json'
import { FIREBASE_URL, PLATFORM_URL } from "Apps/index"

import { Buffer } from 'buffer'

export function refundTrx(payload) {
  const device_id = safeDeepGet(payload, 'device_id', '');
  const id = safeDeepGet(payload, 'id', '');
  const amount = safeDeepGet(payload, 'amount', '');
  const reason = safeDeepGet(payload, 'reason', '');
  const ts = new Date().getTime();
  const body = {
    application_id: APPLICATION_ID,
    device_id: device_id,
    transaction_id: id,
    amount: amount,
    ts: ts,
    reason: reason,
  };
    let result = id.includes('_');
    if (result) {
      let arr_key = id.split('_');
      const key = arr_key[1];
      safeDeepSet(body, 'refund_key','index-' + key)
    }
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');
  const param = {
    url: FIREBASE_URL + '/refundRequest',
    headers: {
      Authorization: 'apikey ajkhjkhqkjhwjkehkqwe:khkkjqhjkwhjkq',
      'Access-Control-Allow-Origin': '*',
    },
    body: {
      data: btoaBody,
    },
  };
  // const headers= {
  //   Authorization: 'apikey ajkhjkhqkjhwjkehkqwe:khkkjqhjkwhjkq',
  //   'Access-Control-Allow-Origin': '*',
  // };
  // const body = {
  //   application_id: APPLICATION_ID,
  //   device_id: device_id,
  //   transaction_id: id,
  //   amount: amount,
  //   ts: ts,
  // };
  // return axios.post(FIREBASE_URL + '/refundRequest',body, {headers: headers})
  // console.log(param)
  return JPOST(param);
}

export function device_list() {
  const param = {
    url: PLATFORM_URL + APPLICATION_ID + '/device-get',
    // url: 'https://nestle-touchless.iotera.io/device/list',
    // url: url + '1000000021/device',
    headers: headers
  }

  return JGET(param)
}
// export function device_list() {
//   const param = {
//     url: PLATFORM_URL + APPLICATION_ID + '/device',
//     headers: headers
//   };

//   return JGET(param);
// }

export function device_map() {
  return device_list()
}
export function createdUser(email, password) {
    const body = {
      email: email,
      password: password,
    };
    const btoaBody = Buffer.from(decodeURIComponent(encodeURIComponent(JSON.stringify(body)))).toString('base64');
    const param = {
      url: FIREBASE_URL+`/createdUser`,
      body: {
        data: btoaBody,
      },
    };
    return JPOST(param);
}

export function deletedUser(uid) {
    const body = { uid: uid };
    const btoaBody = Buffer.from(decodeURIComponent(encodeURIComponent(JSON.stringify(body)))).toString('base64');
    const param = {
      url: FIREBASE_URL+'/deletedUser',
      body: {
        data: btoaBody,
      },
    };
    return JPOST(param);
  }
export function getPlanogram(device_id) {
  const param = {
    url: PLATFORM_URL + APPLICATION_ID + '/planogram-coffee-milano-get',
    queryParams:{
      id: device_id
    }
  }
  return JGET(param)
}
// Belum Ganti URL
export function sensors(deviceId) {
  const param = {
    url: PLATFORM_URL + APPLICATION_ID + '/sensors-get',
    queryParams: {
      device_id: deviceId,
    },
  }

  return JGET(param)
}

export function setStock(deviceId, stocks) {
  const param = {
    url: PLATFORM_URL + APPLICATION_ID + '/setstock',
    body: {
      device_id: deviceId,
      stocks,
    },
  }

  return JPOST(param)
}

export function setConfig(deviceId, ids, names, prices, actives) {
  const param = {
    url: PLATFORM_URL + APPLICATION_ID + '/setconfig',
    body: {
      device_id: deviceId,
      ids,
      names,
      prices,
      actives,
    },
  }

  return JPOST(param)
}
// export function setPlanogram_Milano(deviceId, ids, names, prices, actives) {
//   const param = {
//     url: PLATFORM_URL + APPLICATION_ID + '/setconfig',
//     body: {
//       device_id: deviceId,
//       ids,
//       names,
//       prices,
//     },
//   };
//   // return JPOST(param);
// }
export function setPlanogram_Milano(deviceId, ids, names, prices) {
  const body =  {
    device_id: deviceId,
    ids,
    names,
    prices,
  }
  const btoaBody = Buffer.from(decodeURIComponent(encodeURIComponent(JSON.stringify(body)))).toString('base64');
  const param = {
    url: PLATFORM_URL + APPLICATION_ID +'/planogram-coffee-milano-set',
    // url: url + '1000000021/set_planogram_milano',
    body: {
      data: btoaBody
    },
     
  }
  return JPOST(param)
}

export function account_bank_list() {
  const param = {
    url: PLATFORM_URL+APPLICATION_ID+'/account-get',
  }

  return JGET(param)
}

export function bank_list() {
  const param = {
    url: PLATFORM_URL+APPLICATION_ID+'/bank-get',
  }

  return JGET(param)
}

export function payout_list(){
  const param = {
    url: PLATFORM_URL+APPLICATION_ID+`/payout-get`
  }

  return JGET(param)
}


export function getBalance(){
  const param = {
    url: FIREBASE_URL+`/getBalances`
  }
  return JGET(param)
}

export function createCoupon(amount, ts, type) {
  const param = {
    url: PLATFORM_URL + APPLICATION_ID + '/create-coupon',
    body: {
      discount: Number(amount),
      exp_ts: ts,
      type: type,
    },
  }
  return JPOST(param)
}
export function getCoupon() {
  const param = {
    url: 'https://pay.iotera.io/web/coupon/get',
    headers: {
      'Iotera-Payment-Application-Id': APPLICATION_ID,
      'Iotera-Payment-Admin-Token':
        '6bf868df24a4357dee20e6d3d6ef1d1944249ad44cb29687446d346f60fc0215',
    },
    body: {
      coupon_id: '',
      code: '2F2S1WY94B',
    },
  }
  return JPOST(param)
  
}
export function listCoupon() {
  const param = {
    url: PLATFORM_URL + APPLICATION_ID + '/get-coupon',
    body: {
      application_id: APPLICATION_ID,
    },
  }
  return JPOST(param)
}
export function cancelCoupon(code) {
  const param = {
    url: PLATFORM_URL + APPLICATION_ID + '/cancel-coupon',
    body: {
      coupon_id: '',
      code: code,
    },
  }
  return JPOST(param)
}
export function deleteCoupon(code) {
  const param = {
    url: PLATFORM_URL + APPLICATION_ID + '/delete-coupon',
    body: {
      coupon_id: '',
      code: code,
    },
  }
  return JPOST(param)
}
export function payoutRequest(payload){
  const email = safeDeepGet(payload, 'email', '')
  const id = safeDeepGet(payload, 'id', '')
  const amount = safeDeepGet(payload, 'amount', 0)
  const notes = safeDeepGet(payload, 'notes', '')
  const param = {
    url: PLATFORM_URL + APPLICATION_ID + '/payout-request',
    body: {
      "account_id": id,
      "amount": amount,
      "notes": notes,
      "email": email
    },
  }
  console.log(param)
  return {code: 200}

  // return JPOST(param)
}
export function payoutValidate(otp){
  const param = {
    url: PLATFORM_URL + APPLICATION_ID + '/payout-validate',
    body: {
      "otp" : otp
    },
  }
  console.log(param)
  return {code: 200}
  // return JPOST(param)
}

export function getTasks(){
  const param = {
    url : PLATFORM_URL + APPLICATION_ID + '/task-get',
  }
  return JGET(param)
}

export function post_task_bp(payload, id){
  const url =  PLATFORM_URL + APPLICATION_ID + "/task-business-point"
  const old_value = safeDeepGet(payload, 'old_value', {});
  const new_value = safeDeepGet(payload, 'new_value', {});
  const device_name = safeDeepGet(payload, 'name', '');
  const device_id = id
  const type_activity = safeDeepGet(payload, 'type_activity', 'delete');
  const param = {
    url: url,
    body: { 
      old_value: old_value,
      new_value: new_value,
      device_name: device_name,
      device_id: device_id,
      type_activity: type_activity,
    }
  }
  return JPOST(param)
}
export function update_business_point(payload) {
  const device_id = safeDeepGet(payload, 'device_id');
  const device_name = safeDeepGet(payload, 'device_name');
  const param = {
    url:  PLATFORM_URL + APPLICATION_ID + "/update-business-point",
    body: {
      device_id: device_id,
      device_name: device_name,
    },
  };
  return JPOST(param);
}
