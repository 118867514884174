export const LOADING = "LOADING"
export const RESULT = "RESULT"

export const LIST = "CATEGORY_LIST"
export const LIST_VM = "CATEGORY_LIST_VM"
export const INIT_CREATE = "CATEGORY_INIT_CREATE"
export const CREATE = "CATEGORY_CREATE"
export const READ = "CATEGORY_INFO"
export const UPDATE = "CATEGORY_UPDATE"
export const STOCK_UPDATE = "STOCK_CATEGORY_UPDATE"
export const DELETE = "CATEGORY_DELETE"
