/* eslint-disable semi */
import { call, put, takeEvery } from "redux-saga/effects";

// Product state
import { LIST, INIT_CREATE, CREATE, READ, UPDATE, DELETE, STOCK_UPDATE, LIST_CATEGORY } from "./actionTypes";

import { loading, result } from "./actions";
import { getInstance } from "../../firebase/helper";
import { safeDeepGet, safeDeepSet } from "iotera-base/utility/json";
import Result from "iotera-base/result";

function* list() {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.product.list);
  if (safeDeepGet(wsResult, "code") == Result.SUCCESS) {
    const productMap = wsResult.body;
    const productList = [];
    let i = 0;
    wsResult = yield call(firebaseInstance.category.list)
    const categoryMap = wsResult.body
    for (const sku in productMap) {
      const id_category = safeDeepGet(productMap, [sku, 'category'], '');
      const name_category = safeDeepGet(categoryMap, [id_category, 'category'], '')
      safeDeepSet(productMap, [sku, "category"], name_category)
      safeDeepSet(productMap, [sku, "id"], i++);
      productList.push(productMap[sku]);
    }
    yield put(result(LIST, { code: Result.SUCCESS, body: productList }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}
function* list_category() {
  yield put(loading(LIST_CATEGORY));
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.category.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const categoryBody = safeDeepGet(wsResult, 'body', {});
    const categoryList = [];
    for(const i in categoryBody){
      categoryList.push({value: i, label: safeDeepGet(categoryBody, [i, 'category'],'')});
    }
    yield put(result(LIST_CATEGORY, { code: Result.SUCCESS, body: categoryList }));
    return;
  }
  yield put(result(LIST_CATEGORY, { result: { code: Result.FAILED, body: [] } }));
}
function* initCreate() {
  yield put(result(INIT_CREATE, { code: Result.SUCCESS, body: {} }));
}

function* create(action) {
  yield put(loading(CREATE));
  const sku = safeDeepGet(action, ["payload", "sku"]);
  const payload = safeDeepGet(action, "payload");
  const firebaseInstance = getInstance();
  // Upload image to firebase storage
  const imageFile = safeDeepGet(action, ["payload", "imageFile"]);
  let imageUrl = null;
  if (imageFile != null) {
    const imageExt = safeDeepGet(action, ["payload", "imageExt"]);
    const wsResult = yield call(firebaseInstance.productStorage.replace, imageFile, sku, imageExt);
    if (safeDeepGet(wsResult, "code") == Result.SUCCESS) {
      imageUrl = safeDeepGet(wsResult, ["body", "url"]);
    }
  }
  if (imageUrl != null) {
    payload["img_url"] = imageUrl;
  }

  const wsResult = yield call(firebaseInstance.product.create, sku, payload);
  const code = safeDeepGet(wsResult, "code");

  yield put(result(CREATE, { code }));
}

function* read(action) {
  yield put(loading(READ));
  const sku = safeDeepGet(action, ["payload", "sku"]);
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.product.read, sku);
  if (safeDeepGet(wsResult, "code") !== Result.SUCCESS) {
    yield put(result(READ, { code: Result.FAILED }));
    return;
  }

  yield put(
    result(READ, {
      code: 0,
      body: wsResult.body,
    })
  );
}

function* update(action) {
  yield put(loading(UPDATE));
  const sku = safeDeepGet(action, ["payload", "sku"]);
  const payload = safeDeepGet(action, "payload");
  const firebaseInstance = getInstance();
  // Upload image to firebase storage
  const imageFile = safeDeepGet(action, ["payload", "imageFile"]);
  let imageUrl = null;
  if (imageFile != null) {
    const imageExt = safeDeepGet(action, ["payload", "imageExt"]);
    const wsResult = yield call(firebaseInstance.productStorage.replace, imageFile, sku, imageExt);
    if (safeDeepGet(wsResult, "code") == Result.SUCCESS) {
      imageUrl = safeDeepGet(wsResult, ["body", "url"]);
    }
  }
  if (imageUrl != null) {
    payload["img_url"] = imageUrl;
  }

  const wsResult = yield call(firebaseInstance.product.update, sku, payload);
  const code = safeDeepGet(wsResult, "code");

  yield put(result(UPDATE, { code }));
}

function* stockUpdate(action) {
  yield put(loading(STOCK_UPDATE));
  const sku = safeDeepGet(action, ["payload", "sku"]);
  const payload = safeDeepGet(action, "payload");
  const firebaseInstance = getInstance();

  // Upload image to firebase storage
  const imageFile = safeDeepGet(action, ["payload", "imageFile"]);
  let imageUrl = null;
  if (imageFile != null) {
    const imageExt = safeDeepGet(action, ["payload", "imageExt"]);
    const wsResult = yield call(firebaseInstance.productStorage.replace, imageFile, sku, imageExt);
    if (safeDeepGet(wsResult, "code") == Result.SUCCESS) {
      imageUrl = safeDeepGet(wsResult, ["body", "url"]);
    }
  }
  if (imageUrl != null) {
    payload["img_url"] = imageUrl;
  }

  const wsResult = yield call(firebaseInstance.product.updateStock, sku, payload);
  const code = safeDeepGet(wsResult, "code");

  yield put(result(STOCK_UPDATE, { code }));
}


function* delete_(payload) {
  yield put(loading(DELETE));

  const firebaseInstance = getInstance();
  const sku = safeDeepGet(payload, ['payload', 'sku'], "")
  const wsResult = yield call(firebaseInstance.product.delete_, sku);
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(DELETE, { code }));
}

function* productSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(LIST_CATEGORY, list_category);
  yield takeEvery(INIT_CREATE, initCreate);
  yield takeEvery(CREATE, create);
  yield takeEvery(READ, read);
  yield takeEvery(UPDATE, update);
  yield takeEvery(STOCK_UPDATE, stockUpdate);
  yield takeEvery(DELETE, delete_);
}

export default productSaga;
