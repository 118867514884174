/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import { call, delay, put, takeEvery } from 'redux-saga/effects';

// Product state
import {
  LIST,
  INIT_CREATE,
  LIST_BUSINESS_POINTS,
  LIST_ALERT_CURRENT,
  LIST_ALERT_HISTORICAL,
  LIST_ALERT_DEVICE,
  LIST_ALERT_HISTORICAL_BYDEVICE,
} from './actionTypes';
import { PROJECT } from '../../../Project';
import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet, parse } from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
import {
  get_notifications,
  alert_current,
  alert_historical,
  get_alert_device,
  get_historical_by_device,
} from 'Apps/device';

function* list() {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(get_notifications);
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    const alertList = safeDeepGet(wsResult, 'body', []);
    wsResult = yield call(firebaseInstance.businessPoint.list);
    const bpMap = safeDeepGet(wsResult, 'body', {});
    const OnlineType = [];
    const MachineType = [];
    for (const i in alertList) {
      const alertMap = alertList[i];
      const device_id = safeDeepGet(alertMap, 'id', '');
      const type = safeDeepGet(alertMap, 'type', '');
      if (type == 'machine') {
        MachineType.push(alertMap);
      } else {
        OnlineType.push(alertMap);
      }
      const bpName = safeDeepGet(bpMap, [device_id, 'name'], '');
      safeDeepSet(alertMap, 'name', bpName);
    }
    const MachineList = [];
    for (const i in MachineType) {
      const data = parse(MachineType[i].desc);
      const device_id = safeDeepGet(data, 'device_id', '');
      const bpName = safeDeepGet(bpMap, [device_id, 'name'], '');
      safeDeepSet(data, 'name', bpName);
      if (data != null) {
        MachineList.push(data);
      }
    }

    yield put(
      result(LIST, {
        code: Result.SUCCESS,
        body: alertList,
        machine: MachineList,
        online: OnlineType,
      })
    );
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}

function* list_alert_device(action) {
  yield put(loading(LIST_ALERT_DEVICE));
  const device_id = safeDeepGet(action, ['payload']);
  let wsResult = yield call(get_alert_device, device_id);
  let code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code != Result.HTTP.OK) {
    yield put(result(LIST_ALERT_DEVICE, { code: Result.FAILED, body: [] }));
    return;
  }
  const alertBody = safeDeepGet(wsResult, 'body', []);
  const firebaseInstance = getInstance();
  wsResult = yield call(firebaseInstance.errorEx.list);
  code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code != Result.SUCCESS) {
    yield put(result(LIST_ALERT_DEVICE, { code: Result.FAILED, body: [] }));
    return;
  }
  const listExplanation = safeDeepGet(wsResult, 'body');
  for (const i in alertBody) {
    const key = safeDeepGet(alertBody[i], 'key');
    const split_key = key.split('-');
    const type = safeDeepGet(split_key[1]);
    safeDeepSet(alertBody[i], 'type', type);
    const error_code = safeDeepGet(split_key[2]);
    safeDeepSet(alertBody[i], 'error_code', error_code);
    const description = safeDeepGet(listExplanation, [
      error_code,
      'description',
    ]);
    safeDeepSet(alertBody[i], 'desc', description);
    const handling = safeDeepGet(listExplanation, [error_code, 'handling']);
    safeDeepSet(alertBody[i], 'handling', handling);
  }
  yield put(
    result(LIST_ALERT_DEVICE, { code: Result.SUCCESS, body: alertBody })
  );
}
function* list_alert_historical_device(action) {
  yield delay(1000);
  yield put(loading(LIST_ALERT_HISTORICAL_BYDEVICE));
  const device_id = safeDeepGet(action, ['payload', 'device_id']);
  const key = safeDeepGet(action, ['payload', 'key']);
  const start = safeDeepGet(action, ['payload', 'start']);
  const end = safeDeepGet(action, ['payload', 'end']);
  let wsResult = yield call(
    get_historical_by_device,
    device_id,
    key,
    start,
    end
  );
  let code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code != Result.HTTP.OK) {
    yield put(
      result(LIST_ALERT_HISTORICAL_BYDEVICE, { code: Result.FAILED, body: [] })
    );
    return;
  }
  let alertBody = safeDeepGet(wsResult, 'body', []);
  const firebaseInstance = getInstance();
  wsResult = yield call(firebaseInstance.errorEx.list);
  code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code != Result.SUCCESS) {
    yield put(
      result(LIST_ALERT_HISTORICAL_BYDEVICE, { code: Result.FAILED, body: [] })
    );
    return;
  }
  const listExplanation = safeDeepGet(wsResult, 'body');
  for (const i in alertBody) {
    const key = safeDeepGet(alertBody[i], 'key');
    const split_key = key.split('-');
    const type = split_key[1];
    safeDeepSet(alertBody[i], 'type', type);
    const error_code = split_key[2];
    safeDeepSet(alertBody[i], 'error_code', error_code);
    const description = safeDeepGet(listExplanation, [
      error_code,
      'description',
    ]);
    safeDeepSet(alertBody[i], 'desc', description);
    const handling = safeDeepGet(listExplanation, [error_code, 'handling']);
    safeDeepSet(alertBody[i], 'handling', handling);
  }
  yield put(
    result(LIST_ALERT_HISTORICAL_BYDEVICE, {
      code: Result.SUCCESS,
      body: alertBody,
    })
  );
  alertBody = [];
}
function* list_alert_current() {
  yield put(loading(LIST_ALERT_CURRENT));
  let wsResult = yield call(alert_current);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.HTTP.OK) {
    const alertBody = safeDeepGet(wsResult, 'body');
    wsResult = yield call(get_notifications);
    const wsCode = safeDeepGet(wsResult, 'code', Result.FAILED);
    if (wsCode != Result.HTTP.OK) {
      yield put(result(LIST_ALERT_CURRENT, { code: Result.FAILED, body: [] }));
      return;
    }
    const notificationBody = safeDeepGet(wsResult, 'body', []);
    const alertResult = {};
    for (const i in notificationBody) {
      const type = safeDeepGet(notificationBody[i], 'type');
      const open_time = safeDeepGet(notificationBody[i], 'open_time');
      const desc = safeDeepGet(notificationBody[i], 'desc');
      const notification_open = safeDeepGet(
        notificationBody[i],
        'notification_open'
      );
      
      const ts = new Date(open_time).getTime();
      if (type != 'complaint' && type != 'refund' && type != 'business_point' && type != 'machine') {
        const device_name = safeDeepGet(alertBody[i], 'device_name',"");
        const device_id = safeDeepGet(alertBody[i], 'device_id',"");
        if(type == "online"){
          alertBody.push({
            type: type,
            key: type,
            initial_ts: ts,
            desc,
            notification_open,
          });
        } else {
          alertBody.push({
            device_name,
            device_id,
            type: type,
            key: type,
            initial_ts: ts,
            desc,
            notification_open,
          });
        }
        
      }
    }
    const firebaseInstance = getInstance();
    wsResult = yield call(firebaseInstance.errorEx.list);
    const code = safeDeepGet(wsResult, 'code', Result.FAILED);
    if (code != Result.SUCCESS) {
      yield put(result(LIST_ALERT_CURRENT, { code: Result.FAILED, body: [] }));
      return;
    }
    const initList = [];
    const bpList = [];
    const onlineList = [];
    const ntList = [];
    const machineList = [];
    const errorList = [];
    const stockList = [];
    const typeList = {};
    const listExplanation = safeDeepGet(wsResult, 'body');
    for (const i in listExplanation) {
      const data = {};
      const key = i.split('_');
      const type = key[0];
      const code_error = key[1];
      data[code_error] = listExplanation[i];
      typeList[type] = data;
    }
    for (const i in alertBody) {
      const key = safeDeepGet(alertBody[i], 'key', '-');
      // const key = arr_key[num++ % 2];
      if (
        key != 'online' &&
        key != 'business_point' &&
        key != 'no_transaction' &&
        // key != 'machine' && 
        key != '' && key != 'stock'
      ) {
        const split_key = key.split('-');
        const type = split_key[1];
        safeDeepSet(alertBody[i], 'type', type);
        const error_code = split_key[2];
        const description = safeDeepGet(
          typeList,
          [type, error_code, 'description'],
          '-'
        );
        const handling = safeDeepGet(
          typeList,
          [type, error_code, 'handling'],
          '-'
        );
        safeDeepSet(alertBody[i], 'handling', handling);
        safeDeepSet(alertBody[i], 'desc', description);
        safeDeepSet(alertBody[i], 'error_code', error_code);
        errorList.push(alertBody[i]);
      } else if (key == 'online') {
        onlineList.push(alertBody[i]);
      // } else if (key == 'machine') {
      //   machineList.push(alertBody[i]);
      } else if (key == 'no_transaction') {
        ntList.push(alertBody[i]);
      } else if (key == '') {
        stockList.push(alertBody[i]);
      } else if (key == 'stock') {
        stockList.push(alertBody[i]);
      }
    }
    const body = {
      errorList,
      onlineList,
      stockList,
      ntList,
      machineList,
      initList,
    };
    const length = {
      errorList: errorList.length,
      onlineList: onlineList.length,
      ntList: ntList.length,
      machineList: machineList.length,
      stockList: stockList.length,
      all: alertBody.length,
    };
    yield put(
      result(LIST_ALERT_CURRENT, { code: Result.SUCCESS, body: body, length })
    );
    return;
  } else {
    yield put(result(LIST_ALERT_CURRENT, { code: Result.FAILED, body: [] }));
  }
}
function* list_alert_historical(payload) {
  yield put(loading(LIST_ALERT_HISTORICAL));
  const id = safeDeepGet(payload, 'payload');
  let wsResult = yield call(alert_historical, id);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.HTTP.OK) {
    const alertBody = safeDeepGet(wsResult, 'body');
    const firebaseInstance = getInstance();
    wsResult = yield call(firebaseInstance.errorEx.list);
    const code = safeDeepGet(wsResult, 'code', Result.FAILED);
    if (code != Result.SUCCESS) {
      yield put(
        result(LIST_ALERT_HISTORICAL, { code: Result.FAILED, body: [] })
      );
      return;
    }
    const listExplanation = safeDeepGet(wsResult, 'body');
    for (const i in alertBody) {
      const key = safeDeepGet(alertBody[i], 'key');
      const split_key = key.split('-');
      const type = split_key[1];
      safeDeepSet(alertBody[i], 'type', type);
      const error_code = split_key[2];
      safeDeepSet(alertBody[i], 'error_code', error_code);
      const description = safeDeepGet(listExplanation, [
        error_code,
        'description',
      ]);
      safeDeepSet(alertBody[i], 'desc', description);
      const handling = safeDeepGet(listExplanation, [error_code, 'handling']);
      safeDeepSet(alertBody[i], 'handling', handling);
    }
    yield put(
      result(LIST_ALERT_HISTORICAL, { code: Result.SUCCESS, body: alertBody })
    );
    return;
  } else {
    yield put(result(LIST_ALERT_HISTORICAL, { code: Result.FAILED, body: [] }));
  }
}
function* initCreate() {
  yield put(result(INIT_CREATE, { code: Result.SUCCESS, body: {} }));
}

function* productSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(LIST_ALERT_DEVICE, list_alert_device);
  yield takeEvery(LIST_ALERT_HISTORICAL, list_alert_historical);
  yield takeEvery(LIST_ALERT_CURRENT, list_alert_current);
  yield takeEvery(LIST_ALERT_HISTORICAL_BYDEVICE, list_alert_historical_device);
}

export default productSaga;
