import React, { Component } from "react"
import MetaTags from 'react-meta-tags'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"

import Breadcrumbs from "Base/components/Common/Breadcrumb"

class FormRepeater extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      rows1: [],
    }
  }

  handleAddRow = () => {
    const item = {
      name: "",
    }
    this.setState({
      rows: [...this.state.rows, item],
    })
  }

  handleAddRowNested = () => {
    const item1 = {
      name1: "",
    }
    this.setState({
      rows1: [...this.state.rows1, item1],
    })
  }
  handleRemoveRow = (e, idx) => {
    if (idx === "01") {
      document.getElementById("addr" + idx).style.display = "block"
    } else if (typeof idx != "undefined") {
      document.getElementById("addr" + idx).style.display = "none"
    }

  }
  handleRemoveRowNested = (e, idx) => {
    document.getElementById("nested" + idx).style.display = "none"
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Create Delivery Order | Smart Vending Machine</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Delivery Order" breadcrumbItem="Stock" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Delivery Order Baru</CardTitle>
                    <Form className="outer-repeater">
                      <div data-repeater-list="outer-group" className="outer">
                        <div data-repeater-item className="outer">
                          <div className="mb-3">
                            <Label htmlFor="formid">Delivery Order Id : </Label>
                            <Input
                              type="text"
                              id="formid"
                              placeholder="Enter DO Id..."
                              className="form-control"
                            />
                          </div>

                          <div className="mb-3">
                            <Label htmlFor="formname">Penerima :</Label>
                            <Input
                              type="text"
                              id="formname"
                              placeholder="Enter receiver Name..."
                              className="form-control"
                            />
                          </div>

                          <div className="mb-3">
                            <Label htmlFor="formphone">Telp penerima :</Label>
                            <Input
                              type="number"
                              id="formphone"
                              placeholder="Enter receiver Phone number..."
                              className="form-control"
                            />
                          </div>

                          <div className="inner-repeater mb-4">
                            <Label>Product yang di-deliver :</Label>
                            <table style={{ width: "100%" }}>
                              <tbody>
                                <tr id="addrMain" key="">
                                  <td>
                                    <Row className="inner mb-3 ">
                                      <Col md="5" className="col-8">
                                        <Input
                                          type="text"
                                          className="inner form-control"
                                          placeholder="Pilih SKU"
                                        />
                                      </Col>
                                      <Col md="5" className="col-8">
                                        <Input
                                          type="text"
                                          className="inner form-control"
                                          placeholder="Masukkan jumlah yang akan di-deliver"
                                        />
                                      </Col>                                      
                                      <Col md="2" className="col-4">
                                        <Button
                                          disabled
                                          color="primary"
                                          className="btn-block inner"
                                          style={{ width: "100%" }}
                                        >
                                          {" "}
                                          Delete
                                        </Button>
                                      </Col>
                                    </Row>
                                  </td>
                                </tr>

                                {this.state.rows1.map((item1, idx) => (
                                  <tr id={"nested" + idx} key={idx}>
                                    <td>
                                    <Row className="inner mb-3 ">
                                      <Col md="5" className="col-8">
                                        <Input
                                          type="text"
                                          className="inner form-control"
                                          placeholder="Pilih SKU"
                                        />
                                      </Col>
                                      <Col md="5" className="col-8">
                                        <Input
                                          type="text"
                                          className="inner form-control"
                                          placeholder="Masukkan jumlah yang akan di-deliver"
                                        />
                                      </Col>                                      
                                      <Col md="2" className="col-4">
                                        <Button
                                          disabled
                                          color="primary"
                                          className="btn-block inner"
                                          style={{ width: "100%" }}
                                        >
                                          {" "}
                                          Delete
                                        </Button>
                                      </Col>
                                    </Row>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <Button
                              onClick={this.handleAddRowNested}
                              color="success"
                            >
                              Add Product ke DO
                            </Button>
                          </div>

                          <FormGroup className="mb-3">
                            <Label htmlFor="formmessage">Message :</Label>
                            <Input
                              type="textarea"
                              id="formmessage"
                              className="form-control"
                              rows="3"
                            />
                          </FormGroup>
                          <Button type="submit" color="primary">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default FormRepeater
