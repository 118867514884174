/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Availity
import {
  AvField,
  AvForm,
  AvRadioGroup,
  AvRadio,
} from 'availity-reactstrap-validation';
import Pages403 from 'Base/pages/pages-403';
import{ NAVBAR, PERMISION } from 'Apps'
const navbar = NAVBAR
const permision = PERMISION
// Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';
import Select from 'react-select';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, isEmpty } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { initCreate, create, read, update } from '../../store/product/actions';
import SweetAlert from 'react-bootstrap-sweetalert';
import { buildTitle, NAME_PROJECT } from 'Apps';
const instantsOptions = [
  { label: 'Sugar', value: 'sugar' },
  { label: 'Powder', value: 'powder' },
];
class Product extends Component {
  constructor(props) {
    super(props);

    let isEdit = false;
    let sku = null;

    const path = this.props.match.path;
    if (path.includes('/edit/')) {
      isEdit = true;
      sku = safeDeepGet(this.props.match.params, 'id');
    }

    this.state = {
      error_dlg: false,
      dynamic_title: 'Invalid Image Format',
      dynamic_description: 'Use Format JPG or PNG ',
      isEdit,
      sku,
      image: null,
      imageUpdated: false,
    };

    this.handleImageFile = this.handleImageFile.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleCancleClick = this.handleCancleClick.bind(this);
    this.showToast = this.showToast.bind(this);
    this.handleOnChangeRadio = this.handleOnChangeRadio.bind(this);
    this.handelChangeInstant1 = this.handelChangeInstant1.bind(this);
    this.handelChangeInstant2 = this.handelChangeInstant2.bind(this);
    this.handelChangeInstant3 = this.handelChangeInstant3.bind(this);
    this.handelChangeInstant4 = this.handelChangeInstant4.bind(this);
    this.handelChangeInstant5 = this.handelChangeInstant5.bind(this);
  }

  componentDidMount() {
    const { isEdit } = this.state;

    // Load product
    if (!isEdit) {
      this.props.InitCreateProduct();
    } else {
      this.props.ReadProduct(this.state.sku);
    }
  }
  handleCancleClick() {
    this.props.history.push(`/product/list/`);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isEdit } = this.state;

    if (!isEdit) {
      if (this.props.createResult !== prevProps.createResult) {
        // Success create
        const code = safeDeepGet(
          this.props,
          ['createResult', 'code'],
          Result.UNKNOWN_ERROR
        );

        if (code === Result.SUCCESS) {
          this.showToast();
          this.props.history.replace('/product/list');
        }
      }
    } else {
      if (this.props.readResult !== prevProps.readResult) {
        const code = safeDeepGet(
          this.props,
          ['readResult', 'code'],
          Result.UNKNOWN_ERROR
        );
        if (code == Result.SUCCESS) {
          const newState = {};
          const name = safeDeepGet(this.props, ['readResult', 'body', 'name']);
          newState['name'] = name;
          const desc = safeDeepGet(this.props, ['readResult', 'body', 'desc']);
          newState['desc'] = desc;
          const hot = safeDeepGet(this.props, ['readResult', 'body', 'hot']);
          newState['hot'] = hot;
          const water = safeDeepGet(this.props, ['readResult', 'body', 'water']);
          newState['water'] = water;
          const instant1 = safeDeepGet(this.props, ['readResult', 'body', 'instant1']);
          newState['instant1'] = instant1;
          const instant2 = safeDeepGet(this.props, ['readResult', 'body', 'instant2']);
          newState['instant2'] = instant2;
          const instant3 = safeDeepGet(this.props, ['readResult', 'body', 'instant3']);
          newState['instant3'] = instant3;
          const instant4 = safeDeepGet(this.props, ['readResult', 'body', 'instant4']);
          newState['instant4'] = instant4;
          const instant5 = safeDeepGet(this.props, ['readResult', 'body', 'instant5']);
          newState['instant5'] = instant5;
          const water_instant1 = safeDeepGet(this.props, ['readResult', 'body', 'water_instant1']);
          newState['water_instant1'] = water_instant1;
          const water_instant2 = safeDeepGet(this.props, ['readResult', 'body', 'water_instant2']);
          newState['water_instant2'] = water_instant2;
          const water_instant3 = safeDeepGet(this.props, ['readResult', 'body', 'water_instant3']);
          newState['water_instant3'] = water_instant3;
          const water_instant4 = safeDeepGet(this.props, ['readResult', 'body', 'water_instant4']);
          newState['water_instant4'] = water_instant4;
          const water_instant5 = safeDeepGet(this.props, ['readResult', 'body', 'water_instant5']);
          newState['water_instant5'] = water_instant5;
          const out_time_instant1 = safeDeepGet(this.props, ['readResult', 'body', 'out_time_instant1']);
          newState['out_time_instant1'] = out_time_instant1;
          const out_time_instant2 = safeDeepGet(this.props, ['readResult', 'body', 'out_time_instant2']);
          newState['out_time_instant2'] = out_time_instant2;
          const out_time_instant3 = safeDeepGet(this.props, ['readResult', 'body', 'out_time_instant3']);
          newState['out_time_instant3'] = out_time_instant3;
          const out_time_instant4 = safeDeepGet(this.props, ['readResult', 'body', 'out_time_instant4']);
          newState['out_time_instant4'] = out_time_instant4;
          const out_time_instant5 = safeDeepGet(this.props, ['readResult', 'body', 'out_time_instant5']);
          newState['out_time_instant5'] = out_time_instant5;
          const stir_time_instant1 = safeDeepGet(this.props, ['readResult', 'body', 'stir_time_instant1']);
          newState['stir_time_instant1'] = stir_time_instant1;
          const stir_time_instant2 = safeDeepGet(this.props, ['readResult', 'body', 'stir_time_instant2']);
          newState['stir_time_instant2'] = stir_time_instant2;
          const stir_time_instant3 = safeDeepGet(this.props, ['readResult', 'body', 'stir_time_instant3']);
          newState['stir_time_instant3'] = stir_time_instant3;
          const stir_time_instant4 = safeDeepGet(this.props, ['readResult', 'body', 'stir_time_instant4']);
          newState['stir_time_instant4'] = stir_time_instant4;
          const stir_time_instant5 = safeDeepGet(this.props, ['readResult', 'body', 'stir_time_instant5']);
          newState['stir_time_instant5'] = stir_time_instant5;
          const image = safeDeepGet(this.props, [
            'readResult',
            'body',
            'img_url',
          ]);
          newState['image'] = image;
          
          if (!isEmpty(newState)) {
            this.setState(newState);
          }
        }
      } else if (this.props.updateResult !== prevProps.updateResult) {
        // Success update info
        const code = safeDeepGet(
          this.props,
          ['updateResult', 'code'],
          Result.UNKNOWN_ERROR
        );

        if (code === Result.SUCCESS) {
          this.showToast();
          this.props.history.replace('/product/list');
        }
      }
    }
  }
  handelChangeInstant1(value) {
    const { instant2, instant3, instant4,instant5, isEdit } = this.state;
    if (instant2 == 'sugar') {
      if (value.value == 'sugar') {
        this.setState({ instant1: null });
        this.showWarning();
      } else {
        this.setState({ instant1: value.value });
      }
    } else {
      if (instant4 == 'sugar') {
        if (value.value == 'sugar') {
          this.setState({ instant1: null });
          this.showWarning();
        } else {
          this.setState({ instant1: value.value });
        }
      } else {
        if (instant3 == 'sugar') {
          if (value.value == 'sugar') {
            this.setState({ instant1: null });
            this.showWarning();
          } else {
            this.setState({ instant1: value.value });
          }
        } else {
          if (instant5 == 'sugar') {
            if (value.value == 'sugar') {
              this.setState({ instant1: null });
              this.showWarning();
            } else {
              this.setState({ instant1: value.value });
            }
          } else {
            this.setState({ instant1: value.value });
          }
        }
      }
    }
  }
  handelChangeInstant2(value) {
    const { instant1, instant3, instant4, instant5, isEdit } = this.state;
    if (instant1 == 'sugar') {
      if (value.value == 'sugar') {
        this.setState({ instant2: null });
        this.showWarning();
      } else {
        this.setState({ instant2: value.value });
      }
    } else {
      if (instant4 == 'sugar') {
        if (value.value == 'sugar') {
          this.setState({ instant2: null });
          this.showWarning();
        } else {
          this.setState({ instant2: value.value });
        }
      } else {
        if (instant3 == 'sugar') {
          if (value.value == 'sugar') {
            this.setState({ instant2: null });
            this.showWarning();
          } else {
            this.setState({ instant2: value.value });
          }
        } else {
          if (instant5 == 'sugar') {
            if (value.value == 'sugar') {
              this.setState({ instant2: null });
              this.showWarning();
            } else {
              this.setState({ instant2: value.value });
            }
          } else {
            this.setState({ instant2: value.value });
          }
        }
      }
    }
  }
  handelChangeInstant3(value) {
    const { instant1, instant2, instant4, instant5, isEdit } = this.state;
    if (instant1 == 'sugar') {
      if (value.value == 'sugar') {
        this.setState({ instant3: null });
        this.showWarning();
      } else {
        this.setState({ instant3: value.value });
      }
    } else {
      if (instant4 == 'sugar') {
        if (value.value == 'sugar') {
          this.setState({ instant3: null });
          this.showWarning();
        } else {
          this.setState({ instant3: value.value });
        }
      } else {
        if (instant2 == 'sugar') {
          if (value.value == 'sugar') {
            this.setState({ instant3: null });
            this.showWarning();
          } else {
            this.setState({ instant3: value.value });
          }
        } else {
          if (instant5 == 'sugar') {
            if (value.value == 'sugar') {
              this.setState({ instant3: null });
              this.showWarning();
            } else {
              this.setState({ instant3: value.value });
            }
          } else {
            this.setState({ instant3: value.value });
          }
        }
      }
    }
  }
  handelChangeInstant4(value) {
    const { instant1, instant2, instant3, instant5, isEdit } = this.state;
    if (instant1 == 'sugar') {
      if (value.value == 'sugar') {
        this.setState({ instant4: null });
        this.showWarning();
      } else {
        this.setState({ instant4: value.value });
      }
    } else {
      if (instant3 == 'sugar') {
        if (value.value == 'sugar') {
          this.setState({ instant4: null });
          this.showWarning();
        } else {
          this.setState({ instant4: value.value });
        }
      } else {
        if (instant2 == 'sugar') {
          if (value.value == 'sugar') {
            this.setState({ instant4: null });
            this.showWarning();
          } else {
            this.setState({ instant4: value.value });
          }
        } else {
          if (instant5 == 'sugar') {
            if (value.value == 'sugar') {
              this.setState({ instant4: null });
              this.showWarning();
            } else {
              this.setState({ instant4: value.value });
            }
          } else {
            this.setState({ instant4: value.value });
          }
        }
      }
    }
  }
  handelChangeInstant5(value) {
    const { instant1, instant2, instant3, instant4, isEdit } = this.state;
    if (instant1 == 'sugar') {
      if (value.value == 'sugar') {
        this.setState({ instant5: null });
        this.showWarning();
      } else {
        this.setState({ instant5: value.value });
      }
    } else {
      if (instant3 == 'sugar') {
        if (value.value == 'sugar') {
          this.setState({ instant5: null });
          this.showWarning();
        } else {
          this.setState({ instant5: value.value });
        }
      } else {
        if (instant2 == 'sugar') {
          if (value.value == 'sugar') {
            this.setState({ instant5: null });
            this.showWarning();
          } else {
            this.setState({ instant5: value.value });
          }
        } else {
          if (instant4 == 'sugar') {
            if (value.value == 'sugar') {
              this.setState({ instant5: null });
              this.showWarning();
            } else {
              this.setState({ instant5: value.value });
            }
          } else {
            this.setState({ instant5: value.value });
          }
        }
      }
    }
  }
  showWarning() {
    var toastType = 'error';
    var message = 'Bahan sudah Digunakan!';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };
    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  handleAlertImg() {
    var toastType = 'error';
    var message = 'Format Gambar Tidak Sesuai!';
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  handleImageFile(event) {
    const fakepath = event.target.value;
    const imageExt = fakepath.slice(
      ((fakepath.lastIndexOf('.') - 1) >>> 0) + 2
    );

    if (imageExt !== 'jpg' && imageExt !== 'png') {
      this.handleAlertImg();
      event.target.value = null;
      return;
    }

    this.setState({
      imageUpdated: true,
      image: URL.createObjectURL(event.target.files[0]),
      imageFile: event.target.files[0],
      imageExt,
    });
  }

  handleValidSubmit(event, values) {
    const {
      isEdit,
      imageUpdated,
      imageFile,
      imageExt,
      instant1,
      instant2,
      instant3,
      instant4,
      instant5,
      hot,
    } = this.state;

    const submittedValues = {
      ...values,
      instant1,
      instant2,
      instant3,
      instant4,
      instant5,
      hot,
    };
    if (imageUpdated) {
      submittedValues['imageFile'] = imageFile;
      submittedValues['imageExt'] = imageExt;
    }
    
    if (isEdit) {
      this.props.UpdateProduct(submittedValues);
    } else {
      this.props.CreateProduct(submittedValues);
    }
  }
  showToast() {
    const { isEdit } = this.state;
    var toastType = 'success';
    var message = 'Berhasil Tambah Product';
    if (isEdit) {
      toastType = 'success';
      message = 'Berhasil Update Product';
    }
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  handleOnChangeRadio(event) {
    this.setState({ hot: event.target.value });
  }

  render() {
    const loading = safeDeepGet(this.props, 'loading', true);
    const {
      isEdit,
      name,
      sku,
      desc,
      image,
      hot,
      out_time_instant1,
      out_time_instant2,
      out_time_instant3,
      out_time_instant4,
      out_time_instant5,
      stir_time_instant1,
      stir_time_instant2,
      stir_time_instant3,
      stir_time_instant4,
      stir_time_instant5,
      water,
      water_instant1,
      water_instant2,
      water_instant3,
      water_instant4,
      water_instant5,
      instant1,
      instant2,
      instant3,
      instant4,
      instant5,
    } = this.state;
    return (
      <React.Fragment>
        {safeDeepGet(navbar, ['Product', 'edit'], 'off') == 'off' ? (
          <Pages403 />
        ) : (
          <AvForm onValidSubmit={this.handleValidSubmit}>
            <div className="page-content">
              <MetaTags>
                <title>{buildTitle('Product')}</title>
              </MetaTags>
              <Container fluid>
                <Breadcrumbs
                  title="Product"
                  breadcrumbItem={isEdit ? 'Edit' : 'Add'}
                />
                <Row>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">
                          Product Information
                        </CardTitle>
                        <p className="card-title-desc">
                          Fill all information below
                        </p>
                        <Row>
                          <Col sm="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="name">Display Name</Label>
                              <AvField
                                id="name"
                                name="name"
                                value={name}
                                placeholder="Nama"
                                type="text"
                                errorMessage="Masukkan Nama"
                                className="form-control"
                                validate={{ required: { value: true } }}
                              />
                            </FormGroup>
                            <FormGroup className="mb-3">
                              <Label htmlFor="sku">SKU</Label>
                              <AvField
                                id="sku"
                                name="sku"
                                value={sku}
                                placeholder="SKU"
                                type="text"
                                errorMessage="Masukan SKU dengan benar"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  // Bisa Pakai Huruf Besar
                                  pattern: { value: '^[A-Za-z0-9_-]+$' },
                                  // Tidak bisa pakai Huruf Besar
                                  // pattern: {value: '^[a-z0-9_]+$'},
                                }}
                                disabled={isEdit}
                              />
                            </FormGroup>
                            <FormGroup className="mb-3">
                              <Label htmlFor="desc">Product Description</Label>
                              <AvField
                                id="desc"
                                name="desc"
                                value={desc}
                                rows="9"
                                type="textarea"
                                className="form-control"
                                validate={{
                                  maxLength: { value: 500 },
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <Label htmlFor="image" className="form-label">
                              Display Image
                            </Label>
                            <div className="dropzone">
                              {image != null ? (
                                <img
                                  className="mx-auto d-block"
                                  height={225}
                                  src={image}
                                />
                              ) : (
                                <div className="dz-message needsclick">
                                  <div className="mb-2">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Upload your image</h4>
                                </div>
                              )}
                            </div>
                            <p></p>
                            <p>
                              Format gambar .jpg atau .png dengan background
                              Transparant dan ukuran minimum 400 x 400px (Untuk
                              gambar optimal gunakan ukuran maksimal 600 x 600
                              px).{' '}
                            </p>
                            <Input
                              className="form-control mt-2"
                              type="file"
                              id="image"
                              onChange={this.handleImageFile}
                            />
                            {this.state.error_dlg ? (
                              <SweetAlert
                                error
                                title={this.state.dynamic_title}
                                onConfirm={() =>
                                  this.setState({ error_dlg: false })
                                }
                              >
                                {this.state.dynamic_description}
                              </SweetAlert>
                            ) : null}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">
                          Canister Information
                        </CardTitle>
                        <p className="card-title-desc">
                          Fill all information below
                        </p>
                        <Row>
                          <Col sm={6}>
                            <FormGroup className="mb-3">
                              <Row>
                                <Col sm={2}>
                                  <Label
                                    style={{
                                      position: 'absolute',
                                      //   top: '15%',
                                      left: '30%',
                                      //   transform: 'translate(-50%, -50%)',
                                    }}
                                    htmlFor="hot"
                                    className="form-label"
                                  >
                                    Hot :
                                  </Label>
                                </Col>
                                <Col sm={6}>
                                  <AvRadioGroup
                                    value={hot}
                                    inline
                                    name="hot"
                                    required
                                    errorMessage="Pilih salahsatu!"
                                    onChange={this.handleOnChangeRadio}
                                  >
                                    <AvRadio label="Ya" value="true" />{' '}
                                    <AvRadio label="Tidak" value="false" />
                                  </AvRadioGroup>
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup className="mb-3">
                              <Row>
                                <Col sm={2}>
                                <Label
                                    style={{
                                      position: 'absolute',
                                      top: '15%',
                                      left: '30%',
                                      //   transform: 'translate(-50%, -50%)',
                                    }}
                                    htmlFor="coffee"
                                    className="form-label"
                                  >
                                    Coffee :
                                  </Label>
                                </Col>
                                <Col sm={6}>
                                  <AvField
                                    id="water"
                                    name="water"
                                    value={water}
                                    placeholder="Jumlah Air"
                                    // style={{ width: '40%' }}
                                    type="number"
                                    errorMessage="Masukkan angka"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                  />
                                </Col>
                                <Col sm={4}>
                                  <p
                                    style={{
                                      position: 'absolute',
                                      top: '35%',
                                      transform: 'translate(-50%, -50%)',
                                    }}
                                  >
                                    {'ML'}
                                  </p>
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup className="mb-3">
                              <Row>
                                <Col sm={2}>
                                  <Label
                                    style={{
                                      position: 'absolute',
                                      top: '15%',
                                      left: '30%',
                                      //   transform: 'translate(-50%, -50%)',
                                    }}
                                    htmlFor="coffee"
                                    className="form-label"
                                  >
                                    Instant 1 :
                                  </Label>
                                </Col>
                                <Col sm={3}>
                                  <Select
                                    // defaultValue={bpfilterlist[0]}
                                    value={instantsOptions.filter(function (
                                      option
                                    ) {
                                      return option.value === instant1;
                                    })}
                                    // value={VmList.filter(function (option) {
                                    //   return option.value === selectedName;
                                    // })}
                                    onChange={this.handelChangeInstant1}
                                    options={instantsOptions}
                                    classNamePrefix="select2-selection"
                                    isLoading={false}
                                    placeholder="Select"
                                  />
                                </Col>
                                <Col sm={7}>
                                  {instant1 != 'powder' ? null : (
                                    <FormGroup className="mb-3">
                                      <Row>
                                        <Col sm={7}>
                                          <AvField
                                            id="out_time_instant1"
                                            name="out_time_instant1"
                                            value={out_time_instant1}
                                            placeholder="Waktu Keluar"
                                            type="number"
                                            errorMessage="Masukkan angka"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                        <Col sm={3}>
                                          <p
                                            style={{
                                              position: 'absolute',
                                              top: '35%',
                                              transform:
                                                'translate(-50%, -50%)',
                                            }}
                                          >
                                            {'Detik'}
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm={7}>
                                          <AvField
                                            id="water_instant1"
                                            name="water_instant1"
                                            value={water_instant1}
                                            placeholder="Jumlah Air"
                                            // style={{ width: '40%' }}
                                            type="number"
                                            errorMessage="Masukkan angka"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                        <Col sm={3}>
                                          <p
                                            style={{
                                              position: 'absolute',
                                              top: '35%',
                                              transform:
                                                'translate(-50%, -50%)',
                                            }}
                                          >
                                            {'ML'}
                                          </p>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm={7}>
                                          <AvField
                                            id="stir_time_instant1"
                                            name="stir_time_instant1"
                                            value={stir_time_instant1}
                                            placeholder="Waktu Mengaduk"
                                            // style={{ width: '40%' }}
                                            type="number"
                                            errorMessage="Masukkan angka"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                        <Col sm={3}>
                                          <p
                                            style={{
                                              position: 'absolute',
                                              top: '35%',
                                              transform:
                                                'translate(-50%, -50%)',
                                            }}
                                          >
                                            {'Detik'}
                                          </p>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                  )}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup className="mb-3">
                              <Row>
                                <Col sm={2}>
                                  <Label
                                    style={{
                                      position: 'absolute',
                                      top: '15%',
                                      left: '30%',
                                      //   transform: 'translate(-50%, -50%)',
                                    }}
                                    htmlFor="coffee"
                                    className="form-label"
                                  >
                                    Instant 2 :
                                  </Label>
                                </Col>
                                <Col sm={3}>
                                  <Select
                                    // defaultValue={bpfilterlist[0]}
                                    value={instantsOptions.filter(function (
                                      option
                                    ) {
                                      return option.value === instant2;
                                    })} // value={name}
                                    // value={VmList.filter(function (option) {
                                    //   return option.value === selectedName;
                                    // })}
                                    onChange={this.handelChangeInstant2}
                                    options={instantsOptions}
                                    classNamePrefix="select2-selection"
                                    isLoading={false}
                                    placeholder="Select"
                                  />
                                </Col>
                                <Col sm={7}>
                                  {instant2 != 'powder' ? null : (
                                    <FormGroup className="mb-3">
                                      <Row>
                                        <Col sm={7}>
                                          <AvField
                                            id="out_time_instant2"
                                            name="out_time_instant2"
                                            value={out_time_instant2}
                                            placeholder="Waktu Keluar"
                                            type="number"
                                            errorMessage="Masukkan angka"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                        <Col sm={3}>
                                          <p
                                            style={{
                                              position: 'absolute',
                                              top: '35%',
                                              transform:
                                                'translate(-50%, -50%)',
                                            }}
                                          >
                                            {'Detik'}
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm={7}>
                                          <AvField
                                            id="water_instant2"
                                            name="water_instant2"
                                            value={water_instant2}
                                            placeholder="Jumlah Air"
                                            // style={{ width: '40%' }}
                                            type="number"
                                            errorMessage="Masukkan angka"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                        <Col sm={3}>
                                          <p
                                            style={{
                                              position: 'absolute',
                                              top: '35%',
                                              transform:
                                                'translate(-50%, -50%)',
                                            }}
                                          >
                                            {'ML'}
                                          </p>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm={7}>
                                          <AvField
                                            id="stir_time_instant2"
                                            name="stir_time_instant2"
                                            value={stir_time_instant2}
                                            placeholder="Waktu Mengaduk"
                                            // style={{ width: '40%' }}
                                            type="number"
                                            errorMessage="Masukkan angka"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                        <Col sm={3}>
                                          <p
                                            style={{
                                              position: 'absolute',
                                              top: '35%',
                                              transform:
                                                'translate(-50%, -50%)',
                                            }}
                                          >
                                            {'Detik'}
                                          </p>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                  )}
                                </Col>
                              </Row>
                            </FormGroup>

                            <FormGroup className="mb-3">
                              <Row>
                                <Col sm={2}>
                                  <Label
                                    style={{
                                      position: 'absolute',
                                      top: '15%',
                                      left: '30%',
                                      //   transform: 'translate(-50%, -50%)',
                                    }}
                                    htmlFor="coffee"
                                    className="form-label"
                                  >
                                    Instant 3 :
                                  </Label>
                                </Col>
                                <Col sm={3}>
                                  <Select
                                    // defaultValue={bpfilterlist[0]}
                                    // value={name}
                                    // value={VmList.filter(function (option) {
                                    //   return option.value === selectedName;
                                    // })}
                                    value={instantsOptions.filter(function (
                                      option
                                    ) {
                                      return option.value === instant3;
                                    })}
                                    onChange={this.handelChangeInstant3}
                                    options={instantsOptions}
                                    classNamePrefix="select2-selection"
                                    isLoading={false}
                                    placeholder="Select"
                                  />
                                </Col>
                                <Col sm={7}>
                                  {instant3 != 'powder' ? null : (
                                    <FormGroup className="mb-3">
                                      <Row>
                                        <Col sm={7}>
                                          <AvField
                                            id="out_time_instant3"
                                            name="out_time_instant3"
                                            value={out_time_instant3}
                                            placeholder="Waktu Keluar"
                                            type="number"
                                            errorMessage="Masukkan angka"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                        <Col sm={3}>
                                          <p
                                            style={{
                                              position: 'absolute',
                                              top: '35%',
                                              transform:
                                                'translate(-50%, -50%)',
                                            }}
                                          >
                                            {'Detik'}
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm={7}>
                                          <AvField
                                            id="water_instant3"
                                            name="water_instant3"
                                            value={water_instant3}
                                            placeholder="Jumlah Air"
                                            // style={{ width: '40%' }}
                                            type="number"
                                            errorMessage="Masukkan angka"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                        <Col sm={3}>
                                          <p
                                            style={{
                                              position: 'absolute',
                                              top: '35%',
                                              transform:
                                                'translate(-50%, -50%)',
                                            }}
                                          >
                                            {'ML'}
                                          </p>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm={7}>
                                          <AvField
                                            id="stir_time_instant3"
                                            name="stir_time_instant3"
                                            value={stir_time_instant3}
                                            placeholder="Waktu Mengaduk"
                                            // style={{ width: '40%' }}
                                            type="number"
                                            errorMessage="Masukkan angka"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                        <Col sm={3}>
                                          <p
                                            style={{
                                              position: 'absolute',
                                              top: '35%',
                                              transform:
                                                'translate(-50%, -50%)',
                                            }}
                                          >
                                            {'Detik'}
                                          </p>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                  )}
                                </Col>
                              </Row>
                            </FormGroup>

                            <FormGroup className="mb-3">
                              <Row>
                                <Col sm={2}>
                                  <Label
                                    style={{
                                      position: 'absolute',
                                      top: '15%',
                                      left: '30%',
                                      //   transform: 'translate(-50%, -50%)',
                                    }}
                                    htmlFor="coffee"
                                    className="form-label"
                                  >
                                    Instant 4 :
                                  </Label>
                                </Col>
                                <Col sm={3}>
                                  <Select
                                    // value={instant4}

                                    // defaultValue={bpfilterlist[0]}
                                    // value={name}
                                    value={instantsOptions.filter(function (
                                      option
                                    ) {
                                      return option.value === instant4;
                                    })}
                                    onChange={this.handelChangeInstant4}
                                    options={instantsOptions}
                                    classNamePrefix="select2-selection"
                                    isLoading={false}
                                    placeholder="Select"
                                  />
                                </Col>
                                <Col sm={7}>
                                  {instant4 != 'powder' ? null : (
                                    <FormGroup className="mb-3">
                                      <Row>
                                        <Col sm={7}>
                                          <AvField
                                            id="out_time_instant4"
                                            name="out_time_instant4"
                                            value={out_time_instant4}
                                            placeholder="Waktu Keluar"
                                            type="number"
                                            errorMessage="Masukkan angka"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                        <Col sm={3}>
                                          <p
                                            style={{
                                              position: 'absolute',
                                              top: '35%',
                                              transform:
                                                'translate(-50%, -50%)',
                                            }}
                                          >
                                            {'Detik'}
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm={7}>
                                          <AvField
                                            id="water_instant4"
                                            name="water_instant4"
                                            value={water_instant4}
                                            placeholder="Jumlah Air"
                                            // style={{ width: '40%' }}
                                            type="number"
                                            errorMessage="Masukkan angka"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                        <Col sm={3}>
                                          <p
                                            style={{
                                              position: 'absolute',
                                              top: '35%',
                                              transform:
                                                'translate(-50%, -50%)',
                                            }}
                                          >
                                            {'ML'}
                                          </p>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm={7}>
                                          <AvField
                                            id="stir_time_instant4"
                                            name="stir_time_instant4"
                                            value={stir_time_instant4}
                                            placeholder="Waktu Mengaduk"
                                            // style={{ width: '40%' }}
                                            type="number"
                                            errorMessage="Masukkan angka"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                        <Col sm={3}>
                                          <p
                                            style={{
                                              position: 'absolute',
                                              top: '35%',
                                              transform:
                                                'translate(-50%, -50%)',
                                            }}
                                          >
                                            {'Detik'}
                                          </p>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                  )}
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup className="mb-3">
                              <Row>
                                <Col sm={2}>
                                  <Label
                                    style={{
                                      position: 'absolute',
                                      top: '15%',
                                      left: '30%',
                                      //   transform: 'translate(-50%, -50%)',
                                    }}
                                    htmlFor="coffee"
                                    className="form-label"
                                  >
                                    Instant 5 :
                                  </Label>
                                </Col>
                                <Col sm={3}>
                                  <Select
                                    // value={instant4}

                                    // defaultValue={bpfilterlist[0]}
                                    // value={name}
                                    value={instantsOptions.filter(function (
                                      option
                                    ) {
                                      return option.value === instant5;
                                    })}
                                    onChange={this.handelChangeInstant5}
                                    options={instantsOptions}
                                    classNamePrefix="select2-selection"
                                    isLoading={false}
                                    placeholder="Select"
                                  />
                                </Col>
                                <Col sm={7}>
                                  {instant5 != 'powder' ? null : (
                                    <FormGroup className="mb-3">
                                      <Row>
                                        <Col sm={7}>
                                          <AvField
                                            id="out_time_instant5"
                                            name="out_time_instant5"
                                            value={out_time_instant5}
                                            placeholder="Waktu Keluar"
                                            type="number"
                                            errorMessage="Masukkan angka"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                        <Col sm={3}>
                                          <p
                                            style={{
                                              position: 'absolute',
                                              top: '35%',
                                              transform:
                                                'translate(-50%, -50%)',
                                            }}
                                          >
                                            {'Detik'}
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm={7}>
                                          <AvField
                                            id="water_instant5"
                                            name="water_instant5"
                                            value={water_instant5}
                                            placeholder="Jumlah Air"
                                            // style={{ width: '50%' }}
                                            type="number"
                                            errorMessage="Masukkan angka"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                        <Col sm={3}>
                                          <p
                                            style={{
                                              position: 'absolute',
                                              top: '35%',
                                              transform:
                                                'translate(-50%, -50%)',
                                            }}
                                          >
                                            {'ML'}
                                          </p>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm={7}>
                                          <AvField
                                            id="stir_time_instant5"
                                            name="stir_time_instant5"
                                            value={stir_time_instant5}
                                            placeholder="Waktu Mengaduk"
                                            // style={{ width: '50%' }}
                                            type="number"
                                            errorMessage="Masukkan angka"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                        <Col sm={3}>
                                          <p
                                            style={{
                                              position: 'absolute',
                                              top: '35%',
                                              transform:
                                                'translate(-50%, -50%)',
                                            }}
                                          >
                                            {'Detik'}
                                          </p>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                  )}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <div className="d-flex flex-wrap gap-2 float-end mb-4">
                      <Button
                        onClick={this.handleCancleClick}
                        color="secondary"
                      >
                        Cancel
                      </Button>{' '}
                      <Button type="submit" color="primary" disabled={loading}>
                        Simpan
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </AvForm>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  initCreateResult: safeDeepGet(state, ['product', 'initCreate'], {}),
  readResult: safeDeepGet(state, ['product', 'read'], {}),
  createResult: safeDeepGet(state, ['product', 'create'], {}),
  updateResult: safeDeepGet(state, ['product', 'update'], {}),
  loading: safeDeepGet(state, ['product', 'loading'], true),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  InitCreateProduct: () => dispatch(initCreate()),
  ReadProduct: deviceId => dispatch(read(deviceId)),
  CreateProduct: payload => dispatch(create(payload)),
  UpdateProduct: payload => dispatch(update(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
