import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  FormGroup,
  Label,
  InputGroup,
} from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import ReactTooltip from 'react-tooltip';
//Import Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';
// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { list, list_business_points } from '../../store/alert/actions';
import Select from 'react-select';

import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class AlertList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.refDatePicker = React.createRef();

    this.state = {
      bpfilterlist: [],
      businessPoints: 'all',
      dateRange: [null, null],
      transactionStatus: 'all',
      dispenseStatus: 'all',
      productListColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },

        {
          text: 'Name',
          dataField: 'name',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.name}
                </Link>
              </h5>
            </>
          ),
        },
        {
          dataField: 'code',
          text: 'Code',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">{row.code}</p>
            </>
          ),
        },
        {
          dataField: 'actions',
          text: 'Actions',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">{row.actions}</p>
            </>
          ),
        },
        {
          dataField: 'desc',
          text: 'Descriptions',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">{row.description}</p>
            </>
          ),
        },
        {
          dataField: 'active',
          text: 'Active',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">
                <button
                  style={{ border: 'none' }}
                  className={
                    'font-size-12 badge-soft-' +
                    (row.active ? 'danger' : 'success')
                  }
                  color={row.active ? 'danger' : 'success'}
                >
                  {row.active ? 'Active' : 'Solve'}
                </button>
              </p>
            </>
          ),
        },
        {
          dataField: 'time',
          text: 'Time',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">
                {this.handleValidDate(row.firestoreTimestamp)}
              </p>
            </>
          ),
        },
      ],
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleBusinessPoints = this.handleBusinessPoints.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleClearDateRange = this.handleClearDateRange.bind(this);
  }
  handleBusinessPoints = businessPoints => {
    this.setState({ businessPoints });
    const payload = {
      businessPoints: businessPoints.value,
      dateRange: this.state.dateRange,
    };
    this.props.LoadAlertList(payload);
  };
  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };
  componentDidMount() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    let newDate = new Date(year, month, day - 1);
    let endDate = new Date();
    this.setState({ dateRange: [newDate, endDate] });
    // Load business point
    const payload = {
      businessPoints: 'all',
      dateRange: [newDate, endDate],
    };
    this.props.LoadAlertList(payload);
    let code = '';
    let type = '';
    const obj = JSON.parse(localStorage.getItem('role'));
    if (obj == null) {
      code = 'all';
      type = 'all';
    } else {
      if (obj.role == 'admin') {
        code = null;
        type = null;
      } else {
        code = obj.code;
        type = obj.role;
      }
    }
    const data = { code: code, type: type };

    this.props.LoadBpFilterList(data);
  }

  /* Handle click */
  handleAddClick = arg => {
    this.props.history.push('/product/create');
  };
  handleDateRange = dateRange => {
    if (dateRange.length < 2) {
      console.log('Ignoring.. only start date');
    } else {
      this.setState({ dateRange });
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: dateRange,
      };
      this.props.LoadAlertList(payload);
    }
  };

  handleClearDateRange = () => {
    console.log('dateRange is cleared');
    if (!this.refDatePicker.current?.flatpickr) {
      return;
    } else {
      this.setState({ dateRange:  [null, null]});
      this.refDatePicker.current.flatpickr.clear();
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: [null, null],
      };
      this.props.LoadAlertList(payload);
    }
  };
  handleEditClick = row => {
    this.props.history.push(
      `/product/edit/${safeDeepGet(row, 'name', 'default')}`
    );
  };

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };
  format_report = list => {
    const dataSet = [];
    let trx_data = {};
    if (list.length > 0) {
      for (const el in list) {
        trx_data = {
          actions: list[el].actions,
          active: list[el].active,
          code: list[el].code,
          description: list[el].description,
          device_id: list[el].device_id,
          business_point: list[el].name,
          date: this.handleValidDate(list[el].firestoreTimestamp),

        };
        dataSet.push(trx_data);
      }
      return dataSet;
    }
  };

  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };

  render() {
    // TODO Loading
    const bpfilterlist = safeDeepGet(
      this.props,
      ['bpfilterresult', 'body'],
      []
    );
    const code = safeDeepGet(
      this.props,
      ['result', 'code'],
      Result.UNKNOWN_ERROR
    );
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const report_list = this.format_report(list);
    const { SearchBar } = Search;
    const { businessPoints, dateRange } = this.state;
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];

    let report_filename = '';
    let bp_name = businessPoints.value ? businessPoints.label : businessPoints;
    if (dateRange[1] == null) {
      var endDate = new Date();
      var startDate = new Date();
      startDate.setDate(1);
      report_filename =
        'Alert ' +
        bp_name +
        ' '
      } else {
      report_filename =
        'Alert ' +
        bp_name +
        ' ' +
        dateRange[0].toLocaleDateString('en-GB') +
        ' ke ' +
        dateRange[1].toLocaleDateString('en-GB');
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Alert List | Smart Vending Machine</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Alert" breadcrumbItem="List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.productListColumns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.productListColumns}
                          data={list}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="3">
                                  <FormGroup className="mb-3 ajax-select select2-container">
                                    <Select
                                      defaultValue={bpfilterlist[0]}
                                      value={businessPoints}
                                      onChange={this.handleBusinessPoints}
                                      options={bpfilterlist}
                                      classNamePrefix="select2-selection"
                                      isLoading={false}
                                      placeholder="Business Point"
                                    />
                                  </FormGroup>
                                </Col>

                                <Col sm="7">
                                  <Row className="m-auto text-sm-end">
                                    <Col sm="6">
                                      <Label className="col-md-12 col-form-label text-sm-end">
                                        Date Range
                                      </Label>
                                    </Col>
                                    <Col sm="6">
                                      <InputGroup>
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="dd M,yyyy"
                                          options={{
                                            mode: 'range',
                                            maxDate: new Date(),
                                            dateFormat: 'Y-m-d',
                                          }}
                                          value={dateRange}
                                          onChange={this.handleDateRange}
                                          ref={this.refDatePicker}
                                        />
                                        <Button
                                          type="reset"
                                          color="secondary"
                                          onClick={this.handleClearDateRange}
                                        >
                                          Clear
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col
                                  sm="2"
                                  className="d-none d-lg-block"
                                  align="center"
                                >
                                  <div className="clearfix mt-4 mt-lg-0">
                                    <ExcelFile
                                      filename={report_filename}
                                      element={
                                        <button
                                          type="button"
                                          className="btn btn-franke white w-sm"
                                        >
                                          <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                                          Download Excel
                                        </button>
                                      }
                                    >
                                      <ExcelSheet
                                        data={report_list}
                                        name="Alert List"
                                      >
                                        <ExcelColumn
                                          label="Device Id"
                                          value="device_id"
                                        />
                                        <ExcelColumn
                                          label="Business Point"
                                          value="business_point"
                                        />
                                        <ExcelColumn
                                          label="Date"
                                          value="date"
                                        />
                                        <ExcelColumn
                                          label="Code Error"
                                          value="code"
                                        />
                                        <ExcelColumn
                                          label="Description"
                                          value="description"
                                        />
                                        <ExcelColumn
                                          label="Actions"
                                          value="actions"
                                        />
                                        <ExcelColumn
                                          label="Active"
                                          value="active"
                                        />
                                      </ExcelSheet>
                                    </ExcelFile>
                                  </div>
                                </Col>
                              </Row>
                              <div className="table-responsive">
                                <ReactTooltip place="top" effect="solid" />
                                <BootstrapTable    
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  defaultSorted={defaultSorted}
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    'table align-middle table-nowrap table-check'
                                  }
                                  headerWrapperClasses={'table-light'}
                                  ref={this.node}
                                />
                              </div>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['alert', 'list'], {}),
  loading: safeDeepGet(state, ['alert', 'loading'], true),
  bpfilterresult: safeDeepGet(state, ['alert', 'list_business_points'], {}),
});

const mapDispatchToProps = dispatch => ({
  LoadAlertList: (businessPoints, dateRange) =>
    dispatch(list(businessPoints, dateRange)),
  LoadBpFilterList: payload => dispatch(list_business_points(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertList);
