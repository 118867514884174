/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { APPLICATION_ID, NAVBAR, PERMISION, buildTitle, getTableBusinessPoint } from 'Apps';

// Strap
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import SweetAlert from 'react-bootstrap-sweetalert';

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
// Router
// Table
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

import { PROJECT_HANDLE } from 'Apps';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
// Redux
import { connect } from 'react-redux';
import { list, delete_ } from '../../store/business-point/actions';
// import BusinessPoint from "../../../Component/businessPoint"
const Category = [
  { label: 'All Category', value: 'all' },
  { label: 'Beverage', value: 'Beverage' },
  { label: 'Laundry', value: 'Laundry' },
];
let role = '';
const obj = JSON.parse(localStorage.getItem('role'));
if (obj != null) {
  role = obj.role;
}
import Pages403 from '../pages-403';
const navbar = NAVBAR
const permision = PERMISION
class BusinessPoint extends Component {
  constructor(props) {
    super(props);
    const isMobile= false
    const Columns = getTableBusinessPoint(role, isMobile,this.handleStockClick, this.handlePlanogramClick, this.handleInfoClick, this.handleCalibrationClick, this.handleAlertClick, this.handleDeleteConfirm, this.handleAccountClick, this.handleClearCashConfirm)
    this.node = React.createRef()
    this.state = {
      role: '',
      success_dlg: false,
      error_dlg: false,
      dynamic_title: '',
      device_id: '',
      dynamic_description: '',
      confirm_alert: false,
      Columns 
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleInfoClick = this.handleInfoClick.bind(this);
    this.handleCalibrationClick = this.handleCalibrationClick.bind(this);
    this.handlePlanogramClick = this.handlePlanogramClick.bind(this);
    this.handleAccountClick = this.handleAccountClick.bind(this);
    this.handleAlertClick = this.handleAlertClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleStockClick = this.handleStockClick.bind(this);
    this.showToast = this.showToast.bind(this);
    this.handleQrcodeClick = this.handleQrcodeClick.bind(this);
  }

  componentDidMount() {
    if (
      APPLICATION_ID == '1000000159' ||
      APPLICATION_ID == '1000000211' ||
      APPLICATION_ID == '1000000226' ||
      APPLICATION_ID == '1000000229' ||
      APPLICATION_ID == '1000000209'
    ) {
      // Load business point
      let code = '';
      let type = '';
      const obj = JSON.parse(localStorage.getItem('role'));
      if (obj == null) {
        code = 'all';
        type = 'all';
      } else {
        if (obj.role == 'admin') {
          code = null;
          type = null;
        } else {
          code = obj.code;
          type = obj.role;
          this.setState({ role: obj.role });
        }
      }
      const data = { code: code, type: type };
      this.props.LoadBusinessPointFillter(data);
      // setInterval(async () => {
      //   this.props.LoadBusinessPointFillter(data);
      // }, 3000);
    } else {
      // setInterval(async () => {
      //   this.props.LoadBusinessPointList();
      // }, 3000);
      this.props.LoadBusinessPointList();
    }
  }
  /* Handle click */
  handleAddClick = arg => {
    this.props.history.push('/business-point/create');
  };
  showToast() {
    var toastType = 'success';
    var message = 'Berhasil Delete Businnes Point';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  handleDeleteClick = (id, name) => {
    const row = {
      id: id,
      name: name,
    };
    this.props.DeleteBusinessPoint(row);
    this.props.LoadBusinessPointList();
    this.showToast();
    this.setState({
      confirm_alert: false,
      success_dlg: true,
      dynamic_title: 'Deleted',
      dynamic_description: 'Business Point has been deleted.',
    });
  };
  handleQrcodeClick = row => {
    {
      APPLICATION_ID == '1000000209'
        ? this.props.history.push(
            `/business-point/qrCode/${safeDeepGet(row, 'device_id', 'default')}`
          )
        : this.props.history.push(
            `/business-point/qrCode/${safeDeepGet(row, 'id', 'default')}`
          );
    }
  };
  handleInfoClick = row => {
    {
      APPLICATION_ID == '1000000209' || APPLICATION_ID == '1000000232' || APPLICATION_ID == '1000000226' 
        ? this.props.history.push(
            `/business-point/info/${safeDeepGet(row, 'device_id', 'default')}`
          )
        : APPLICATION_ID == '1000000211' || PROJECT_HANDLE == 'DSN'
        ? this.props.history.push(
            `/business-point/info/${safeDeepGet(row, 'id', 'default')}`
          )
        : this.props.history.push(
            `/business-point/info/${safeDeepGet(row, 'iddevice', 'default')}`
          );
    }
  };
  handleStockClick = row => {
    {
      APPLICATION_ID == '1000000209' || APPLICATION_ID == '1000000232'  || APPLICATION_ID == '1000000226' 
        ? this.props.history.push(
            `/business-point/stock/${safeDeepGet(row, 'device_id', 'default')}`
          )
        : this.props.history.push(
            `/business-point/stock/${safeDeepGet(row, 'id', 'default')}`
          );
    }
  };
  handleAlertClick = row => {
    {
      APPLICATION_ID == '1000000209' || APPLICATION_ID == '1000000232'  || APPLICATION_ID == '1000000226' 
        ? this.props.history.push(
            `/business-point/alert/${safeDeepGet(row, 'device_id', 'default')}`
          )
        : this.props.history.push(
            `/business-point/alert/${safeDeepGet(row, 'id', 'default')}`
          );
    }
  };

  handlePlanogramClick = row => {
    APPLICATION_ID == '1000000209' || APPLICATION_ID == '1000000232'  || APPLICATION_ID == '1000000226' 
      ? this.props.history.push(
          `/business-point/planogram/${safeDeepGet(
            row,
            'device_id',
            'default'
          )}`
        )
      : this.props.history.push(
          `/business-point/planogram/${safeDeepGet(row, 'id', 'default')}`
        );
  };

  handleAccountClick = row => {
    APPLICATION_ID == '1000000209' || APPLICATION_ID == '1000000232'  || APPLICATION_ID == '1000000226' 
      ? this.props.history.push(
          `/RFID/list/${safeDeepGet(row, 'device_id', 'default')}`
        )
      : this.props.history.push(
          `/RFID/list/${safeDeepGet(row, 'id', 'default')}`
        );
  };

  handleCalibrationClick = row => {
    APPLICATION_ID == '1000000209' || APPLICATION_ID == '1000000232'  || APPLICATION_ID == '1000000226' 
      ? this.props.history.push(
          `/business-point/options/${safeDeepGet(row, 'device_id', 'default')}`
        )
      : APPLICATION_ID == '1000000211' || PROJECT_HANDLE == 'DSN'
      ? this.props.history.push(
          `/business-point/options/${safeDeepGet(row, 'id', 'default')}`
        )
      : this.props.history.push(
          `/business-point/options/${safeDeepGet(row, 'iddevice', 'default')}`
        );
  };
  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    const code = safeDeepGet(
      this.props,
      ['result', 'code'],
      Result.UNKNOWN_ERROR
    );
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length,
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'name',
        order: 'asc',
      },
    ];

    const { role } = this.state;
    const { SearchBar } = Search;
    return (
      <React.Fragment>
        <div className="page-content">
        <MetaTags>
              <title>{buildTitle('Business Point')}</title>
            </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Business Point" breadcrumbItem="List" />
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.Columns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.Columns}
                          data={list}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              {role == 'distributor' ? (
                                ''
                              ) : role == 'refiller' ? (
                                ''
                              ) : (
                                <Row>
                                  <Col sm="3">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="2">
                                    {/* <div >
                                      <FormGroup className="mb-3 ajax-select select2-container">
                                        <Select
                                          defaultValue={Category[0]}
                                          // value={businessPoints}
                                          // onChange={this.handleBusinessPoints}
                                          options={Category}
                                          classNamePrefix="select2-selection"
                                          isLoading={false}
                                          placeholder="Business Point"
                                        />
                                      </FormGroup>
                                      </div> */}
                                  </Col>
                                  <Col sm="7">
                                    <div className="text-sm-end">
                                      <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded mb-2 me-2"
                                        href="/business-point/create"
                                      >
                                        <i className="mdi mdi-plus me-1" /> New
                                        Business Point
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              )}

                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={'id'}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={'table align-middle table-nowrap'}
                                  headerWrapperClasses={'thead-light'}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  ref={this.node}
                                />
                              </div>

                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                              {this.state.confirm_alert ? (
                                <SweetAlert
                                  title="Are you sure?"
                                  warning
                                  showCancel
                                  confirmButtonText="Yes, delete it!"
                                  confirmBtnBsStyle="success"
                                  cancelBtnBsStyle="danger"
                                  onConfirm={() =>
                                    this.handleDeleteClick(
                                      this.state.device_id,
                                      this.state.name
                                    )
                                  }
                                  onCancel={() =>
                                    this.setState({
                                      confirm_alert: false,
                                    })
                                  }
                                >
                                  You won&apos;t be able to revert this!
                                </SweetAlert>
                              ) : null}
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['businessPoint', 'list'], {}),
  loading: safeDeepGet(state, ['businessPoint', 'loading'], true),
});

const mapDispatchToProps = dispatch => ({
  LoadBusinessPointList: () => dispatch(list()),
  LoadBusinessPointFillter: code => dispatch(list(code)),
  DeleteBusinessPoint: payload => dispatch(delete_(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPoint);
