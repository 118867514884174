import React, { Component } from 'react';
import { isEmpty, size } from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import ReactExport from 'react-data-export';
import PropTypes from 'prop-types';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import ReactTooltip from 'react-tooltip';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Table,
  InputGroup,
} from 'reactstrap';
import Select from 'react-select';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import XlsxPopulate from 'xlsx-populate';
import { saveAs } from 'file-saver';
//Import Breadcrumb
// Iotera
import { safeDeepGet } from 'iotera-base/utility/json';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { Link } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import {
  list_business_points,
  list,
  read,
  updateRefund,
  refunded,
} from '../Base/store/transaction/actions';
import { PROJECT_HANDLE, REFUND_PERIOD } from 'Apps';
// import { Columns } from "../../../Map/"
const paymentOptions = [
  { label: 'All transactions', value: 'all' },
  { label: 'Settlement', value: 'settlement' },
  { label: 'Refund Pending', value: 'refund_pending' },
  { label: 'Cancel', value: 'cancel' },
  { label: 'Failure', value: 'failure' },
  { label: 'Pending', value: 'pending' },
  { label: 'Refunded', value: 'refunded' },
];

const dispenseOptions = [
  { label: 'All dispenses', value: 'all' },
  { label: 'No Dispense', value: 'no_dispense' },
  { label: 'Success', value: 'success' },
  { label: 'Pending', value: 'pending' },
];
const refundOptions = [
  { label: 'No Refund', value: 'all' },
  { label: 'Refund', value: 'refund' },
];
// const dispenseNestleOptions = [
//   { label: 'All dispenses', value: 'all' },
//   { label: 'No Dispense', value: false },
//   { label: 'Success', value: true },
// ];

const bankOptions = [
  { label: 'All', value: 'all' },
  { label: 'RFID', value: 'RFID' },
  // { label: 'QRIS-ESPAY', value: 'QRIS-ESPAY' },
  { label: 'QRIS', value: 'QRIS-MIDTRANS' },
];
class Transaction extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.refDatePicker = React.createRef();
    const date = new Date();
    const twoDay = new Date(date.setDate(date.getDate() - REFUND_PERIOD));
    var one_day = 1000 * 60;

    const date2 = new Date();
    const year = date2.getFullYear();
    const month = date2.getMonth();
    const day = date2.getDate();
    let newDate = new Date(year, month, day - 1);
    let endDate = new Date();
    this.state = {
      modal: false,
      modalDetail: false,
      products: [],
      trx: [],
      confirm_msg: false,
      confirm_alert: false,
      bpfilterlist: [],
      dateRange: [newDate, endDate],
      role: '',
      anchor: false,
      businessPoints: 'all',
      payment: 'all',
      transactionStatus: 'all',
      dispenseStatus: 'all',
      RedbokstransactionColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: 'detail.order_id',
          text: 'Order',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p
                style={{ fontWeight: 'bold', textDecorationLine: 'underline'  }}
                className="mb-1"
                onClick={() => this.handleDetail(row)}
              >
                {safeDeepGet(row, ['detail', 'order_id'])}
              </p>
              <p className="mb-0"></p>
              <p className="mb-1">
                {this.handleValidDate(row.time.firestore_timestamp)}
              </p>
            </>
          ),
        },
        {
          dataField: 'name',
          text: 'Business Point',
          sort: true,
        },
        {
          dataField: 'product.sku',
          text: 'Product',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <Tippy content={row.product.sku}>
                <button
                  style={{ border: 'none' }}
                  className={'font-size-12 btn btn-outline-light'}
                >
                  {row.nameProduct}
                </button>
              </Tippy>
            </>
          ),
        },
        {
          dataField: 'total',
          text: 'Total',
          formatter: (cellContent, row) => (
            <>
              {safeDeepGet(row, ['detail', 'transaction_status'], '-') ===
              'refunded' ? (
                <p style={{ color: 'red' }}>
                  {'-' + safeDeepGet(row, ['payment', 'amount'], '-')}
                </p>
              ) : (
                <p>{safeDeepGet(row, ['payment', 'amount'], '-')}</p>
              )}
            </>
          ),
        },
        {
          dataField: 'paymentStatus',
          text: 'Payment Status',
          formatter: (cellContent, row) => (
            <>
              {safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
              'cancel' ? (
                <Tippy content="Dibatalkan oleh pembeli, atau timeout dari sisi vending mesin">
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      safeDeepGet(row, 'badgeclass', 'info')
                    }
                    color={safeDeepGet(row, 'badgeclass', 'info')}
                  >
                    {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                  </span>
                </Tippy>
              ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
                'failure' ? (
                <Tippy content="Kegagalan Komunikasi dengan bank">
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      safeDeepGet(row, 'badgeclass', 'info')
                    }
                    color={safeDeepGet(row, 'badgeclass', 'info')}
                  >
                    {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                  </span>
                </Tippy>
              ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
                'refunded' ? (
                <Tippy content="Dana sudah dikembalikan ke pembeli">
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      safeDeepGet(row, 'badgeclass', 'info')
                    }
                    color={safeDeepGet(row, 'badgeclass', 'info')}
                  >
                    {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                  </span>
                </Tippy>
              ) : (
                <Tippy content={safeDeepGet(row, ['payment', 'method'], '-')}>
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      safeDeepGet(row, 'badgeclass', 'info')
                    }
                    color={safeDeepGet(row, 'badgeclass', 'info')}
                  >
                    {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                  </span>
                </Tippy>
              )}
            </>
          ),
        },
        {
          dataField: 'dispensestatus',
          text: 'Dispense Status',
          formatter: (cellContent, row) => (
            <button
              style={{ border: 'none' }}
              className={
                'font-size-12 badge-soft-' + safeDeepGet(row, 'badge', 'info')
              }
              color={safeDeepGet(row, 'badge', 'info')}
            >
              {safeDeepGet(row, ['detail', 'dispense_status'], '-')}
            </button>
          ),
        },
        {
          dataField: 'refund',
          isDummyField: true,
          text: 'Refund',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
              'settlement' ? (
                row.time.timestamp >= twoDay ? (
                  <Tippy
                    content={this.timeConvert(
                      Math.ceil((row.time.timestamp - twoDay) / one_day)
                    )}
                  >
                    <button
                      type="button"
                      color="success"
                      className="btn-sm btn-rounded btn-success"
                      onClick={() => this.handleRefundedMidTrans(row)}
                    >
                      Refund
                    </button>
                  </Tippy>
                ) : (
                  ' '
                )
              ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
                'refunded' ? (
                <>
                  <p className="mb-0">
                    {this.handleValidRefundDate(row.detail.refund_time)}
                  </p>
                  <p className="mb-1">
                    {this.handleValidTime(row.detail.refund_time)}
                  </p>
                </>
              ) : (
                ' '
              )}
            </>
          ),
        },
      ],
      DemotransactionColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: 'detail.order_id',
          text: 'Order',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p   style={{ fontWeight: 'bold', textDecorationLine: 'underline'  }}
                className="mb-1"
                onClick={() => this.handleDetail(row)}>
                {safeDeepGet(row, ['detail', 'order_id'])}
              </p>
              <p className="mb-0"></p>
              <p className="mb-1">
                {this.handleValidDate(row.time.firestore_timestamp)}
              </p>
              {/* <p className="mb-1">{row.name}</p> */}
            </>
          ),
        },
        {
          dataField: 'name',
          text: 'Business Point',
          sort: true,
        },
        {
          dataField: 'product.sku',
          text: 'Product',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <Tippy content={row.product.sku}>
                <button
                  style={{ border: 'none' }}
                  className={'font-size-12 btn btn-outline-light'}
                >
                  {row.nameProduct}
                </button>
              </Tippy>
            </>
          ),
        },
        {
          dataField: 'total',
          text: 'Total',
          formatter: (cellContent, row) => (
            <>
              {safeDeepGet(row, ['detail', 'transaction_status'], '-') ===
              'refunded' ? (
                <p style={{ color: 'red' }}>
                  {'-' + safeDeepGet(row, ['payment', 'amount'], '-')}
                </p>
              ) : (
                <p>{safeDeepGet(row, ['payment', 'amount'], '-')}</p>
              )}
            </>
          ),
        },
        {
          dataField: 'paymentStatus',
          text: 'Payment Status',
          formatter: (cellContent, row) => (
            <>
              {safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
              'cancel' ? (
                <Tippy content="Dibatalkan oleh pembeli, atau timeout dari sisi vending mesin">
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      safeDeepGet(row, 'badgeclass', 'info')
                    }
                    color={safeDeepGet(row, 'badgeclass', 'info')}
                  >
                    {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                  </span>
                </Tippy>
              ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
                'failure' ? (
                <Tippy content="Kegagalan Komunikasi dengan bank">
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      safeDeepGet(row, 'badgeclass', 'info')
                    }
                    color={safeDeepGet(row, 'badgeclass', 'info')}
                  >
                    {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                  </span>
                </Tippy>
              ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
                'refunded' ? (
                <Tippy content="Dana sudah dikembalikan ke pembeli">
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      safeDeepGet(row, 'badgeclass', 'info')
                    }
                    color={safeDeepGet(row, 'badgeclass', 'info')}
                  >
                    {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                  </span>
                </Tippy>
              ) : (
                <Tippy content={safeDeepGet(row, ['payment', 'method'], '-')}>
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      safeDeepGet(row, 'badgeclass', 'info')
                    }
                    color={safeDeepGet(row, 'badgeclass', 'info')}
                  >
                    {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                  </span>
                </Tippy>
              )}
            </>
          ),
        },
        {
          dataField: 'dispensestatus',
          text: 'Dispense Status',
          formatter: (cellContent, row) => (
            <button
              style={{ border: 'none' }}
              className={
                'font-size-12 badge-soft-' + safeDeepGet(row, 'badge', 'info')
              }
              color={safeDeepGet(row, 'badge', 'info')}
            >
              {safeDeepGet(row, ['detail', 'dispense_status'], '-')}
            </button>
          ),
        },
        {
          dataField: 'refund',
          isDummyField: true,
          text: 'Refund',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
              'settlement' ? (
                row.time.timestamp >= twoDay ? (
                  <Tippy
                    content={this.timeConvert(
                      Math.ceil((row.time.timestamp - twoDay) / one_day)
                    )}
                  >
                    <button
                      type="button"
                      color="success"
                      className="btn-sm btn-rounded btn-success"
                      onClick={() => this.handleRefundedMidTrans(row)}
                    >
                      Refund
                    </button>
                  </Tippy>
                ) : (
                  ' '
                )
              ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
                'refunded' ? (
                <>
                  <p className="mb-0">
                    {this.handleValidRefundDate(row.detail.refund_time)}
                  </p>
                  <p className="mb-1">
                    {this.handleValidTime(row.detail.refund_time)}
                  </p>
                </>
              ) : (
                ' '
              )}
            </>
          ),
        },
      ],
      NestletransactionColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: 'order_id',
          text: 'Order',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p   style={{ fontWeight: 'bold', textDecorationLine: 'underline'  }}
                className="mb-1"
                onClick={() => this.handleDetail(row)}>
                {safeDeepGet(row, ['transactionDetails', 'orderId'])}
              </p>
              <p className="mb-0"></p>
              <p className="mb-1">
                {this.handleValidDate(row.timeDetails.firestoreTimeStamp)}
              </p>
              {/* <p className="mb-1">{row.name}</p> */}
            </>
          ),
        },
        {
          dataField: 'name',
          text: 'Business Point',
          sort: true,
        },
        {
          dataField: 'product',
          text: 'Product',
          sort: true,
          formatter: (cellContent, row) => <>{row.productDetails.name}</>,
        },
        {
          dataField: 'total',
          text: 'Total',
          formatter: (cellContent, row) => <>{row.productDetails.price}</>,
        },
        {
          dataField: 'paymentStatus',
          text: 'Payment Status',
          formatter: (cellContent, row) => (
            <Tippy content={row.paymentDetails.paymentType}>
              <button
                style={{ border: 'none' }}
                className={
                  'font-size-12 badge-soft-' +
                  safeDeepGet(row, 'badgeclass', 'info')
                }
                color={safeDeepGet(row, 'badgeclass', 'info')}
              >
                {row.transactionDetails.transactionStatus}
              </button>
            </Tippy>
          ),
        },
        {
          dataField: 'dispensestatus',
          text: 'Dispense Status',
          formatter: (cellContent, row) => (
            <button
              style={{ border: 'none' }}
              className={
                'font-size-12 badge-soft-' + safeDeepGet(row, 'badge', 'info')
              }
              color={safeDeepGet(row, 'badge', 'info')}
            >
              {row.transactionDetails.isDispense ? 'success' : 'no_dispense'}
            </button>
          ),
        },
        {
          dataField: 'refund',
          isDummyField: true,
          text: 'Refund',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {row.transactionDetails.transactionStatus == 'settlement' ? (
                row.transactionDetails.isDispense == false ? (
                  <Button
                    type="button"
                    color="primary"
                    className="btn-sm btn-rounded"
                    onClick={() => this.handleRefund(row)}
                  >
                    Refund
                  </Button>
                ) : (
                  ' '
                )
              ) : row.transactionDetails.transactionStatus ==
                'refund_pending' ? (
                row.transactionDetails.isDispense == false ? (
                  <Button
                    type="button"
                    color="success"
                    className="btn-sm btn-rounded"
                    onClick={() => this.handleRefunded(row)}
                  >
                    Done
                  </Button>
                ) : (
                  ' '
                )
              ) : row.transactionDetails.transactionStatus == 'refunded' ? (
                row.transactionDetails.isDispense == false ? (
                  <>
                    <p className="mb-0">
                      Success
                      {/* {this.handleValidRefundDate(row.detail.refund_time)} */}
                    </p>
                    <p className="mb-1">
                      Success
                      {/* {this.handleValidTime(row.detail.refund_time)} */}
                    </p>
                  </>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </>
          ),
        },
      ],
      SvFranketransactionColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: 'detail.order_id',
          text: 'Order',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p
                style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}
                className="mb-1"
                onClick={() => this.handleDetail(row)}
              >
                {safeDeepGet(row, ['detail', 'order_id'], '-')}
              </p>
              <p className="mb-1">{safeDeepGet(row, 'name', '-')}</p>
              <p className="mb-1">
                {this.handleValidDate(
                  safeDeepGet(row, ['time', 'firestore_timestamp'], '-')
                )}
              </p>
              {/* <p className="mb-1">{row.name}</p> */}
            </>
          ),
        },
        // {
        //   dataField: 'name',
        //   text: 'Business Point',
        //   sort: true,
        // },
        {
          dataField: 'product.sku',
          text: 'Product',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p style={{ fontWeight: 'bold' }} className="mb-1">
                {safeDeepGet(row, ['product', 'sku'], '-')}
              </p>

              <p>
                {safeDeepGet(row, ['detail', 'transaction_status'], '-') ===
                'refunded' ? (
                  <p style={{ color: 'red' }}>
                    {'-' + safeDeepGet(row, ['payment', 'amount'], '-')}
                  </p>
                ) : (
                  <p>{safeDeepGet(row, ['payment', 'amount'], '-')}</p>
                )}
              </p>
            </>
          ),
        },
        {
          dataField: 'Method',
          text: 'Payment Method',
          formatter: (cellContent, row) => (
            <>
              <p>{safeDeepGet(row, ['payment', 'method'], '-')}</p>
            </>
          ),
        },
        {
          dataField: 'paymentStatus',
          text: 'Payment / Dispense Status',
          formatter: (cellContent, row) => (
            <>
              <p>
                {safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
                'cancel' ? (
                  <Tippy content="Dibatalkan oleh pembeli, atau timeout dari sisi vending mesin">
                    <span
                      style={{ border: 'none' }}
                      className={
                        'font-size-12 badge badge-pill badge-soft-' +
                        safeDeepGet(row, 'badgeclass', 'info')
                      }
                      color={safeDeepGet(row, 'badgeclass', 'info')}
                    >
                      {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                    </span>
                  </Tippy>
                ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
                  'failure' ? (
                  <Tippy content="Kegagalan Komunikasi dengan bank">
                    <span
                      style={{ border: 'none' }}
                      className={
                        'font-size-12 badge badge-pill badge-soft-' +
                        safeDeepGet(row, 'badgeclass', 'info')
                      }
                      color={safeDeepGet(row, 'badgeclass', 'info')}
                    >
                      {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                    </span>
                  </Tippy>
                ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
                  'refunded' ? (
                  <Tippy content="Dana sudah dikembalikan ke pembeli">
                    <span
                      style={{ border: 'none' }}
                      className={
                        'font-size-12 badge badge-pill badge-soft-' +
                        safeDeepGet(row, 'badgeclass', 'info')
                      }
                      color={safeDeepGet(row, 'badgeclass', 'info')}
                    >
                      {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                    </span>
                  </Tippy>
                ) : (
                  <Tippy
                    content={safeDeepGet(
                      row,
                      ['payment', 'detail', 'issuer'],
                      '-'
                    )}
                  >
                    <span
                      style={{ border: 'none' }}
                      className={
                        'font-size-12 badge badge-pill badge-soft-' +
                        safeDeepGet(row, 'badgeclass', 'info')
                      }
                      color={safeDeepGet(row, 'badgeclass', 'info')}
                    >
                      {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                    </span>
                  </Tippy>
                )}
              </p>
              <p>
                <button
                  style={{ border: 'none' }}
                  className={
                    'font-size-12 badge-soft-' +
                    safeDeepGet(row, 'badge', 'info')
                  }
                  color={safeDeepGet(row, 'badge', 'info')}
                >
                  {safeDeepGet(row, ['detail', 'dispense_status'], '-')}
                </button>
              </p>
            </>
          ),
        },
        // formatter: (cellContent, row) => (
        //   <>
        //     {safeDeepGet(row, ['detail', 'transaction_status'], '-') == 'cancel' ? (
        //       <Tippy content="Dibatalkan oleh pembeli, atau timeout dari sisi vending mesin">
        //         <span
        //           style={{ border: 'none' }}
        //           className={
        //             'font-size-12 badge badge-pill badge-soft-' +
        //             safeDeepGet(row, 'badgeclass', 'info')
        //           }
        //           color={safeDeepGet(row, 'badgeclass', 'info')}
        //         >
        //           {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
        //         </span>
        //       </Tippy>
        //     ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') == 'failure' ? (
        //       <Tippy content="Kegagalan Komunikasi dengan bank">
        //         <span
        //           style={{ border: 'none' }}
        //           className={
        //             'font-size-12 badge badge-pill badge-soft-' +
        //             safeDeepGet(row, 'badgeclass', 'info')
        //           }
        //           color={safeDeepGet(row, 'badgeclass', 'info')}
        //         >
        //           {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
        //         </span>
        //       </Tippy>
        //     ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') == 'refunded' ? (
        //       <Tippy content="Dana sudah dikembalikan ke pembeli">
        //         <span
        //           style={{ border: 'none' }}
        //           className={
        //             'font-size-12 badge badge-pill badge-soft-' +
        //             safeDeepGet(row, 'badgeclass', 'info')
        //           }
        //           color={safeDeepGet(row, 'badgeclass', 'info')}
        //         >
        //           {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
        //         </span>
        //       </Tippy>
        //     ) : (
        //       <Tippy content={safeDeepGet(row, ['payment', 'method'], '-')}>
        //         <span
        //           style={{ border: 'none' }}
        //           className={
        //             'font-size-12 badge badge-pill badge-soft-' +
        //             safeDeepGet(row, 'badgeclass', 'info')
        //           }
        //           color={safeDeepGet(row, 'badgeclass', 'info')}
        //         >
        //           {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
        //         </span>
        //       </Tippy>
        //     )}
        //   </>
        // ),
        // },
        // {
        //   dataField: 'dispensestatus',
        //   text: 'Dispense Status',
        //   formatter: (cellContent, row) => (
        //     <button
        //       style={{ border: 'none' }}
        //       className={'font-size-12 badge-soft-' + safeDeepGet(row, 'badge', 'info')}
        //       color={safeDeepGet(row, 'badge', 'info')}
        //     >
        //       {safeDeepGet(row, ['detail', 'dispense_status  '], '-')}
        //     </button>
        //   ),

        // },
      ],
      SvNestletransactionColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: 'detail.order_id',
          text: 'Order',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <Link to="#" style={{ fontWeight: 'bold', textDecorationLine: 'underline', color:'black' }}
                className="mb-1"
                onClick={() => this.handleDetail(row)}>
                {safeDeepGet(row, ['detail', 'order_id'])}
              </Link>
              <p className="mb-0"></p>
              <p className="mb-1">
                {this.handleValidDate(row.time.firestore_timestamp)}
              </p>
              {/* <p className="mb-1">{row.name}</p> */}
            </>
          ),
        },
        {
          dataField: 'name',
          text: 'Business Point',
          sort: true,
        },
        {
          dataField: 'product.sku',
          text: 'Product',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <Tippy content={row.product.sku}>
                <button
                  style={{ border: 'none' }}
                  className={'font-size-12 btn btn-outline-light'}
                >
                  {row.nameProduct}
                </button>
              </Tippy>
            </>
          ),
        },
        {
          dataField: 'total',
          text: 'Total',
          formatter: (cellContent, row) => (
            <>
              {safeDeepGet(row, ['detail', 'transaction_status'], '-') ===
              'refunded' ? (
                <p style={{ color: 'red' }}>
                  {'-' + safeDeepGet(row, ['payment', 'amount'], '-')}
                </p>
              ) : (
                <p>{safeDeepGet(row, ['payment', 'amount'], '-')}</p>
              )}
            </>
          ),
        },
        {
          dataField: 'paymentStatus',
          text: 'Payment Status',
          formatter: (cellContent, row) => (
            <>
              {safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
              'cancel' ? (
                <Tippy content="Dibatalkan oleh pembeli, atau timeout dari sisi vending mesin">
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      safeDeepGet(row, 'badgeclass', 'info')
                    }
                    color={safeDeepGet(row, 'badgeclass', 'info')}
                  >
                    {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                  </span>
                </Tippy>
              ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
                'failure' ? (
                <Tippy content="Kegagalan Komunikasi dengan bank">
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      safeDeepGet(row, 'badgeclass', 'info')
                    }
                    color={safeDeepGet(row, 'badgeclass', 'info')}
                  >
                    {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                  </span>
                </Tippy>
              ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
                'refunded' ? (
                <Tippy content="Dana sudah dikembalikan ke pembeli">
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      safeDeepGet(row, 'badgeclass', 'info')
                    }
                    color={safeDeepGet(row, 'badgeclass', 'info')}
                  >
                    {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                  </span>
                </Tippy>
              ) : (
                <Tippy content={safeDeepGet(row, ['payment', 'method'], '-')}>
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      safeDeepGet(row, 'badgeclass', 'info')
                    }
                    color={safeDeepGet(row, 'badgeclass', 'info')}
                  >
                    {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                  </span>
                </Tippy>
              )}
            </>
          ),
        },
        {
          dataField: 'dispensestatus',
          text: 'Dispense Status',
          formatter: (cellContent, row) => (
            <button
              style={{ border: 'none' }}
              className={
                'font-size-12 badge-soft-' + safeDeepGet(row, 'badge', 'info')
              }
              color={safeDeepGet(row, 'badge', 'info')}
            >
              {safeDeepGet(row, ['detail', 'dispense_status'], '-')}
            </button>
          ),
        },
        {
          dataField: 'refund',
          isDummyField: true,
          text: 'Refund',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
              'settlement' ? (
                row.time.timestamp >= twoDay ? (
                  <Tippy
                    content={this.timeConvert(
                      Math.ceil((row.time.timestamp - twoDay) / one_day)
                    )}
                  >
                    <button
                      type="button"
                      color="success"
                      className="btn-sm btn-rounded btn-success"
                      onClick={() => this.handleRefundedMidTrans(row)}
                    >
                      Refund
                    </button>
                  </Tippy>
                ) : (
                  ' '
                )
              ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
                'refunded' ? (
                <>
                  <p className="mb-0">
                    {this.handleValidRefundDate(row.detail.refund_time)}
                  </p>
                  <p className="mb-1">
                    {this.handleValidTime(row.detail.refund_time)}
                  </p>
                </>
              ) : (
                ' '
              )}
            </>
          ),
        },
      ],
      FranketransactionColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: 'order_id',
          text: 'Order',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p   style={{ fontWeight: 'bold', textDecorationLine: 'underline'  }}
                className="mb-1"
                onClick={() => this.handleDetail(row)}>
                {safeDeepGet(row, ['transactionDetails', 'orderId'])}
              </p>
              <p className="mb-0"></p>
              <p className="mb-1">
                {this.handleValidDate(row.timeDetails.firestoreTimeStamp)}
              </p>
              {/* <p className="mb-1">{row.name}</p> */}
            </>
          ),
        },
        {
          dataField: 'name',
          text: 'Business Point',
          sort: true,
        },
        {
          dataField: 'product',
          text: 'Product',
          sort: true,
          formatter: (cellContent, row) => <>{row.productDetails.name}</>,
        },
        {
          dataField: 'total',
          text: 'Total',
          formatter: (cellContent, row) => <>{row.productDetails.price}</>,
        },
        {
          dataField: 'paymentStatus',
          text: 'Payment Status',
          formatter: (cellContent, row) => (
            <>
              <Tippy content={row.paymentDetails.paymentType}>
                <button
                  style={{ border: 'none' }}
                  className={
                    'font-size-12 badge-soft-' +
                    safeDeepGet(row, 'badgeclass', 'info')
                  }
                  color={safeDeepGet(row, 'badgeclass', 'info')}
                >
                  {row.transactionDetails.transactionStatus}
                </button>
              </Tippy>
              <p className="mb-0"></p>
              <button
                style={{ border: 'none' }}
                className={
                  'font-size-12 badge-soft-' + safeDeepGet(row, 'badge', 'info')
                }
                color={safeDeepGet(row, 'badge', 'info')}
              >
                {row.transactionDetails.isDispense ? 'success' : 'no_dispense'}
              </button>
              {/* <p className="mb-1">{row.name}</p> */}
            </>
          ),
        },
        {
          dataField: 'dispensestatus',
          text: 'Dispense Status',
          formatter: (cellContent, row) => (
            <button
              style={{ border: 'none' }}
              className={
                'font-size-12 badge-soft-' + safeDeepGet(row, 'badge', 'info')
              }
              color={safeDeepGet(row, 'badge', 'info')}
            >
              {row.transactionDetails.isDispense ? 'success' : 'no_dispense'}
            </button>
          ),
        },
        {
          dataField: 'refund',
          isDummyField: true,
          text: 'Refund',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {row.transactionDetails.transactionStatus == 'settlement' ? (
                row.transactionDetails.isDispense == false ? (
                  <Button
                    type="button"
                    color="primary"
                    className="btn-sm btn-rounded"
                    // onClick={() => this.handleRefund(row)}
                    onClick={() => this.handleRefundedMidTrans(row)}
                  >
                    Refund
                  </Button>
                ) : (
                  <Button
                    type="button"
                    color="primary"
                    className="btn-sm btn-rounded"
                    // onClick={() => this.handleRefund(row)}
                    onClick={() => this.handleRefundedMidTrans(row)}
                  >
                    Refund
                  </Button>
                )
              ) : (
                ' '
              )}
            </>
          ),
        },
      ],
    };

    this.handleBusinessPoints = this.handleBusinessPoints.bind(this);
    this.handleTransactionStatus = this.handleTransactionStatus.bind(this);
    this.handlePaymentMethod = this.handlePaymentMethod.bind(this);
    this.handleDispenseStatus = this.handleDispenseStatus.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleRefund = this.handleRefund.bind(this);
    this.handleRefunded = this.handleRefunded.bind(this);
    this.handleRefundedMidTrans = this.handleRefundedMidTrans.bind(this);
    this.handleRefundDate = this.handleRefundDate.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleDetail = this.handleDetail.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleDetail = this.toggleDetail.bind(this);
  }

  timeConvert(n) {
    var num = n;
    var day = num / 60 / 24;
    var rday = Math.floor(day);
    var hours = (day - rday) * 24;
    var rhours = Math.round(hours);
    return rday + ' Days(s) and ' + rhours + ' hours(s) ';
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  toggleDetail() {
    this.setState(prevState => ({
      modalDetail: !prevState.modalDetail,
    }));
  }
  // untuk ke Payment Server
  handleRefundedMidTrans = arg => {
    const product = arg;
    this.setState({
      products: {
        id: product.detail.order_id,
        amount: product.payment.amount,
        device_id: product.product.device_id,
      },
    });
    this.toggle();
  };
  handleDetail = arg => {
    const trx = arg;
    this.setState({
      trx,
    });
    // this.toggleDetail();
    // const order_id = safeDeepGet(trx, ['detail', 'order_id'], 'default')
    // this.props.history.push(`/transaction/detail/${safeDeepGet(trx, 'device_id', 'default')}`)
  };
  handleClick() {
    this.setState({
      drp_secondary_sm: !this.state.drp_secondary_sm,
    });
  }
  handleClose = () => {
    this.setState(prevState => ({
      anchor: !prevState.anchor,
    }));
  };
  handleRefundDate = date => {
    if (date) {
      const d = new Date(0, 0, 0, 0, 0, 0, date).toLocaleDateString('en-GB');
      return d;
    }
  };
  // untuk ke Payment Server
  handleValidRefundSubmit = (e, values) => {
    const { onRefund } = this.props;
    const { products } = this.state;
    const refunded = {
      id: products.id,
      amount: products.amount,
      device_id: products.device_id,
    };
    onRefund(refunded);
    this.toggle();
    this.handleRefund(products);
    if (PROJECT_HANDLE == 'Nestle' || PROJECT_HANDLE == 'Franke') {
      let code = '';
      let type = '';
      const obj = JSON.parse(localStorage.getItem('role'));
      if (obj == null) {
        code = 'all';
        type = 'all';
      } else {
        if (obj.role == 'admin') {
          code = null;
          type = null;
        } else {
          code = obj.code;
          type = obj.role;
          this.setState({ role: obj.role });
        }
      }
      const data = { code: code, type: type };
      const payload1 = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        data: data,
        bank: this.state.payment.value,
      };
      this.props.LoadTransactionList(payload1);
    } else {
      const payload1 = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        bank: this.state.payment.value,
      };
      this.props.LoadTransactionList(payload1);
    }
  };

  //Untuk Ke  Payemnt Server
  handleRefund = row => {
    const payload = {
      order_id: safeDeepGet(row, 'id', ' '),
      transaction_status: 'refund_pending',
    };
    this.props.RefundTransaction(payload);
    if (PROJECT_HANDLE == 'Nestle' || PROJECT_HANDLE == 'Franke') {
      let code = '';
      let type = '';
      const obj = JSON.parse(localStorage.getItem('role'));
      if (obj == null) {
        code = 'all';
        type = 'all';
      } else {
        if (obj.role == 'admin') {
          code = null;
          type = null;
        } else {
          code = obj.code;
          type = obj.role;
          this.setState({ role: obj.role });
        }
      }
      const data = { code: code, type: type };
      const payload1 = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        data: data,
        bank: this.state.payment.value,
      };
      this.props.LoadTransactionList(payload1);
    } else {
      const payload1 = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        bank: this.state.payment.value,
      };
      this.props.LoadTransactionList(payload1);
    }
  };

  // Untuk Ke Firebase 
  handleRefunded = row => {
    if (confirm('Are you sure you want to complete the refund process?')) {
      var time = Date.now();
      const payload = {
        order_id: safeDeepGet(row, ['detail', 'order_id'], ' '),
        transaction_status: 'refunded',
        refund_time: time,
      };
      this.props.RefundTransaction(payload);
    } else {
      const payload1 = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        bank: this.state.payment.value,
      };
      this.props.LoadTransactionList(payload1);
    }
    const payload1 = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      bank: this.state.payment.value,
    };
    this.props.LoadTransactionList(payload1);
  };

  handleBusinessPoints = businessPoints => {
    this.setState({ businessPoints });
    if (PROJECT_HANDLE == 'Nestle' || PROJECT_HANDLE == 'Franke') {
      let code = '';
      let type = '';
      const obj = JSON.parse(localStorage.getItem('role'));
      if (obj == null) {
        code = 'all';
        type = 'all';
      } else {
        if (obj.role == 'admin') {
          code = null;
          type = null;
        } else {
          code = obj.code;
          type = obj.role;
          this.setState({ role: obj.role });
        }
      }
      const data = { code: code, type: type };
      const payload = {
        businessPoints: businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        data: data,
        bank: this.state.payment.value,
      };
      this.props.LoadTransactionList(payload);
    } else {
      const payload = {
        businessPoints: businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        bank: this.state.payment.value,
      };
      this.props.LoadTransactionList(payload);
    }
  };
  handlePaymentMethod = payment => {
    this.setState({ payment });
    if (PROJECT_HANDLE == 'Nestle' || PROJECT_HANDLE == 'Franke') {
      let code = '';
      let type = '';
      const obj = JSON.parse(localStorage.getItem('role'));
      if (obj == null) {
        code = 'all';
        type = 'all';
      } else {
        if (obj.role == 'admin') {
          code = null;
          type = null;
        } else {
          code = obj.code;
          type = obj.role;
          this.setState({ role: obj.role });
        }
      }
      const data = { code: code, type: type };
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        data: data,
        bank: payment.value,
      };
      this.props.LoadTransactionList(payload);
    } else {
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        bank: payment.value,
      };
      this.props.LoadTransactionList(payload);
    }
  };

  handleTransactionStatus = transactionStatus => {
    this.setState({ transactionStatus });
    if (PROJECT_HANDLE == 'Nestle' || PROJECT_HANDLE == 'Franke') {
      let code = '';
      let type = '';
      const obj = JSON.parse(localStorage.getItem('role'));
      if (obj == null) {
        code = 'all';
        type = 'all';
      } else {
        if (obj.role == 'admin') {
          code = null;
          type = null;
        } else {
          code = obj.code;
          type = obj.role;
          this.setState({ role: obj.role });
        }
      }
      const data = { code: code, type: type };
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        data: data,
        bank: this.state.payment.value,
      };
      this.props.LoadTransactionList(payload);
    } else {
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        bank: this.state.payment.value,
      };
      this.props.LoadTransactionList(payload);
    }
  };

  handleDispenseStatus = dispenseStatus => {
    this.setState({ dispenseStatus });
    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: dispenseStatus.value,
      bank: this.state.payment.value,
    };
    this.props.LoadTransactionList(payload);
  };

  handleDateRange = dateRange => {
    if (dateRange.length < 2) {
      console.log('Ignoring.. only start date');
    } else {
      this.setState({ dateRange });
      if (PROJECT_HANDLE == 'Nestle' || PROJECT_HANDLE == 'Franke') {
        let code = '';
        let type = '';
        const obj = JSON.parse(localStorage.getItem('role'));
        if (obj == null) {
          code = 'all';
          type = 'all';
        } else {
          if (obj.role == 'admin') {
            code = null;
            type = null;
          } else {
            code = obj.code;
            type = obj.role;
            this.setState({ role: obj.role });
          }
        }
        const data = { code: code, type: type };
        const payload = {
          businessPoints: this.state.businessPoints.value,
          dateRange: dateRange,
          transactionStatus: this.state.transactionStatus.value,
          dispenseStatus: this.state.dispenseStatus.value,
          data: data,
          bank: this.state.payment.value,
        };
        this.props.LoadTransactionList(payload);
      } else {
        const payload = {
          businessPoints: this.state.businessPoints.value,
          dateRange: dateRange,
          transactionStatus: this.state.transactionStatus.value,
          dispenseStatus: this.state.dispenseStatus.value,
          bank: this.state.payment.value,
        };
        this.props.LoadTransactionList(payload);
      }
    }
  };

  handleSwitch = e => {
    const value = e.value;
    this.setState({ refundStatus: e });
    if (value == 'refund') {
      if (this.state.SvFranketransactionColumns.length < 6) {
        const date = new Date();
        const twoDay = new Date(date.setDate(date.getDate() - REFUND_PERIOD));
        var one_day = 1000 * 60;
        this.state.SvFranketransactionColumns.push({
          dataField: 'refund',
          isDummyField: true,
          text: 'Refund',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
              'settlement' ? (
                row.time.timestamp >= twoDay ? (
                  <Tippy
                    content={this.timeConvert(
                      Math.ceil((row.time.timestamp - twoDay) / one_day)
                    )}
                  >
                    <button
                      type="button"
                      color="success"
                      className="btn-sm btn-rounded btn-success"
                      onClick={() => this.handleRefundedMidTrans(row)}
                    >
                      Refund
                    </button>
                  </Tippy>
                ) : (
                  ' '
                )
              ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
                'refunded' ? (
                <>
                  <p className="mb-0">
                    {this.handleValidRefundDate(row.detail.refund_time)}
                  </p>
                  <p className="mb-1">
                    {this.handleValidTime(row.detail.refund_time)}
                  </p>
                </>
              ) : (
                ' '
              )}
            </>
          ),
        });
      }
      // this.setState({
      //   toggleSwitch: true,
      // });
      // this.state.FranketransactionColumns.pop();
    } else {
      if (this.state.SvFranketransactionColumns.length > 5) {
        this.state.SvFranketransactionColumns.pop();
      }
    }

    //  setTimeout(window.location.reload(false), 5000)
    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      bank: this.state.payment.value,
    };
    this.props.LoadTransactionList(payload);
  };

  componentDidMount() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    let newDate = new Date(year, month, day - 1);
    let endDate = new Date();
    this.setState({ dateRange: [newDate, endDate] });

    var current_month = new Date();
    if (PROJECT_HANDLE == 'Nestle' || PROJECT_HANDLE == 'Franke') {
      let code = '';
      let type = '';
      const obj = JSON.parse(localStorage.getItem('role'));
      if (obj == null) {
        code = 'all';
        type = 'all';
      } else {
        if (obj.role == 'admin') {
          code = null;
          type = null;
        } else {
          code = obj.code;
          type = obj.role;
          this.setState({ role: obj.role });
        }
      }
      const data = { code: code, type: type };
      this.props.LoadBusinessPointFillter(data);
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        data: data,
        bank: this.state.payment.value,
      };
      this.props.LoadTransactionList(payload);
    } else {
      this.props.LoadBpFilterList();
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        bank: this.state.payment.value,
      };
      this.props.LoadTransactionList(payload);
    }
    // Load transactions
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { orders } = this.props;
    if (!isEmpty(orders) && size(prevProps.orders) !== size(orders)) {
      this.setState({ orders: {}, isEdit: false });
    }
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { orders } = this.props;
    this.setState({
      orders: orders.filter(order =>
        Object.keys(order).some(
          key =>
            typeof order[key] === 'string' &&
            order[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    });
  };

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }));
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  handleDeleteOrder = order => {
    const { onDeleteOrder } = this.props;
    if (order.id !== undefined) {
      onDeleteOrder(order);
      this.onPaginationPageChange(1);
    }
  };

  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };

  handleValidTime = date => {
    const time1 = new Date(date).toLocaleTimeString('en-GB');
    return time1;
  };
  handleValidRefundDate = date => {
    const date1 = new Date(date).toLocaleDateString('en-GB');
    return date1;
  };

  format_report = list => {
    const dataSet = [];
    let trx_data = {};
    if (list.length > 0) {
      if (PROJECT_HANDLE == 'Redboks') {
        for (const el in list) {
          trx_data = {
            order_id: safeDeepGet(list[el], ['detail', 'order_id'], '-'),
            business_point: safeDeepGet(list[el], 'name', '-'),
            date: this.handleValidDate(list[el].time.firestore_timestamp),
            sku: safeDeepGet(list[el], ['product', 'sku'], '-'),
            total: safeDeepGet(list[el], ['payment', 'amount'], 0),
            method: safeDeepGet(list[el], ['payment', 'method'], '-'),
            payment_status: safeDeepGet(
              list[el],
              ['detail', 'transaction_status'],
              '-'
            ),
            dispense_status: safeDeepGet(
              list[el],
              ['detail', 'dispense_status'],
              '-'
            ),
          };
          dataSet.push(trx_data);
        }
      } else if (PROJECT_HANDLE == 'Nestle' || PROJECT_HANDLE == 'Franke') {
        let i = 1;
        for (const el in list) {
          let dispense = safeDeepGet(
            list[el],
            ['detail', 'dispense_status'],
            '-'
          );
          const transactionStatus = safeDeepGet(
            list[el],
            ['detail', 'transaction_status'],
            '-'
          );
          const firebaseTimestamp = list[el].time.firestore_timestamp;
          var transactionTime = firebaseTimestamp.toDate();

          let finalStatus = null;
          if (transactionStatus == 'settlement' && dispense == 'success') {
            finalStatus = 'Transaksi Sukses';
          } else if (
            transactionStatus == 'settlement' &&
            dispense == 'no_dispense'
          ) {
            var twoDaysLater = transactionTime.setDate(
              transactionTime.getDate() + 2
            );
            var now = new Date();
            if (now < twoDaysLater) {
              finalStatus = 'Gagal Dispense (Potential Refund)';
            } else {
              // more than 2 * 24 hours
              finalStatus = 'Transaksi Sukses';
            }
          } else if (transactionStatus == 'pending') {
            finalStatus = 'Tidak Terjadi Transaksi';
          } else if (transactionStatus == 'refund') {
            finalStatus = 'Refund';
          }
          const provider = safeDeepGet(list[el], ['payment', 'method'], '');
          let paymentIssuer = '';
          if (provider == '') {
            paymentIssuer = safeDeepGet(list[el], ['payment', 'method'], '');
          } else {
            if (provider == 'AIRPAY SHOPEE') {
              paymentIssuer = 'SHOPEEPAY';
            } else {
              paymentIssuer = provider;
            }
          }
          const amount = list[el].payment.amount;

          const Persentase = 7 / 1000;
          const mdr = amount * Persentase;
          const net = amount - mdr;

          let sn = '';
          // if (list[el].sn.slice(0, 2) == 'VM') {
          //   sn = list[el].sn.slice(2, 10);
          // } else {
          //   sn = list[el].sn;
          // }

          var trxId = '';

          if (paymentIssuer == 'RFID') {
            trxId = this.handleValidDate(list[el].time.firestore_timestamp);
          } else {
            trxId = safeDeepGet(
              list[el],
              ['payment', 'detail', 'transaction_id'],
              '-'
            );
          }
          var firebaseRefundTimestamp = safeDeepGet(
            list[el],
            ['detail', 'refund_time'],
            null
          );
          let refund = '';

          if (
            firebaseRefundTimestamp != null ||
            firebaseRefundTimestamp != undefined
          ) {
            const time = firebaseRefundTimestamp;
            const timestirng = time.toString();
            const splitString = timestirng.slice(0, 13);

            var refundTime = this.handleRefundDate(Number(splitString));
            if (refundTime != null) {
              refund = refundTime;
            }
          }

          trx_data = {
            no: i++,
            date: this.handleValidDate(list[el].time.firestore_timestamp),
            business_point: safeDeepGet(list[el], 'name', '-'),
            sn: sn,
            distributor: safeDeepGet(list[el], 'NameDistributor', '-'),
            channel: safeDeepGet(list[el], 'channel', '-'),
            sku: safeDeepGet(list[el], ['product', 'sku'], '-'),
            total: amount,
            mdr: mdr,
            net: net,
            provider: paymentIssuer,
            order_id: safeDeepGet(list[el], ['detail', 'order_id'], ''),
            trx_id: trxId,
            payment_status: safeDeepGet(
              list[el],
              ['detail', 'transaction_status'],
              '-'
            ),
            refundTime: refund,
            dispense_status: safeDeepGet(list[el], [
              'detail',
              'dispense_status',
            ]),
            finalStatus: finalStatus,
          };
          dataSet.push(trx_data);
        }
      } else {
        for (const el in list) {
          trx_data = {
            order_id: safeDeepGet(list[el], ['detail', 'order_id'], '-'),
            business_point: safeDeepGet(list[el], ['name']),
            date: this.handleValidDate(list[el].time.firestore_timestamp),
            sku: safeDeepGet(list[el], ['product', 'sku']),
            total: safeDeepGet(list[el], ['payment', 'amount']),
            method: safeDeepGet(list[el], ['payment', 'method']),
            payment_status: safeDeepGet(list[el], [
              'detail',
              'transaction_status',
            ]),
            dispense_status: safeDeepGet(list[el], [
              'detail',
              'dispense_status',
            ]),
          };
          dataSet.push(trx_data);
        }
      }
      return dataSet;
    }
  };
  getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : '';
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  handleExport = list => {
    var data = list;
    let headerAdmin = [
      'No',
      'Time',
      'Business Point',
      'SN Milano',
      'Distributor',
      'Channel',
      'Product Name',
      'Price on Time (Include Promo)',
      'MDR',
      'Net Price',
      'Provider',
      'Order Id',
      'TRX ID',
      'Transaction',
      'Refund Time',
      'Dispense Status',
      'Final Status',
    ];
    let header = [
      'No',
      'Time',
      'Business Point',
      'SN Milano',
      'Distributor',
      'Channel',
      'Product Name',
      'Price on Time (Include Promo)',
      'MDR',
      'Net Price',
      'Provider',
      'Order Id',
      'TRX ID',
      'Refund Time',
      'Final Status',
    ];

    const obj = JSON.parse(localStorage.getItem('role'));
    const { selectedMonthYear, businessPoints } = this.state;
    let val = selectedMonthYear;
    let bp_name = businessPoints.value ? businessPoints.label : businessPoints;
    let date = 'all';
    let month = '';
    let year = '';
    let A2 = '';
    if (val == null) {
      month = 'all';
      year = '';
      A2 = 'ALL TRX REPORT';
    } else {
      date = new Date(
        parseInt(val.split('-')[0]),
        parseInt(val.split('-')[1]) - 1,
        1
      );
      month = date.toLocaleString('default', { month: 'long' });
      year = parseInt(val.split('-')[0]);
      A2 = 'TRX REPORT ' + month + ' ' + year;
    }
    XlsxPopulate.fromBlankAsync().then(async workbook => {
      const sheet1 = workbook.sheet(0);
      let sheetData;
      if (obj == null) {
        sheetData = this.getSheetData(data, headerAdmin);
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          sheetData = this.getSheetData(data, headerAdmin);
        } else {
          sheetData = this.getSheetData(data, header);
        }
      }

      const totalColumns = sheetData[0].length;
      sheet1.cell('A15').value(sheetData);
      sheet1.cell('A1').value(bp_name);
      sheet1.cell('A2').value(A2);

      // CUP Sales
      sheet1.cell('A3').value('TOTAL CUP SALES');
      sheet1
        .cell('A3')
        .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });

      if (obj == null) {
        sheet1
          .cell('B3')
          .formula(
            '=COUNTIFS($Q:$Q,"Transaksi Sukses")+COUNTIFS($Q:$Q,"Refund")'
          );
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          sheet1
            .cell('B3')
            .formula(
              '=COUNTIFS($Q:$Q,"Transaksi Sukses")+COUNTIFS($Q:$Q,"Refund")'
            );
        } else {
          sheet1
            .cell('B3')
            .formula(
              '=COUNTIFS($O:$O,"Transaksi Sukses")+COUNTIFS($O:$O,"Refund")'
            );
        }
      }
      sheet1
        .cell('B3')
        .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });

      // Total Sales
      sheet1.cell('A4').value('TOTAL SALES (VALUE)');
      sheet1
        .cell('A4')
        .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });
      if (obj == null) {
        sheet1
          .cell('B4')
          .formula(
            '=SUMIF($Q:$Q,"Transaksi Sukses",$H:$H)+SUMIF($Q:$Q,"Refund",$H:$H)'
          );
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          sheet1
            .cell('B4')
            .formula(
              '=SUMIF($Q:$Q,"Transaksi Sukses",$H:$H)+SUMIF($Q:$Q,"Refund",$H:$H)'
            );
        } else {
          sheet1
            .cell('B4')
            .formula(
              '=SUMIF($0:$0,"Transaksi Sukses",$H:$H)+SUMIF($0:$0,"Refund",$H:$H)'
            );
          sheet1
            .cell('B3')
            .formula(
              '=COUNTIFS($O:$O,"Transaksi Sukses")+COUNTIFS($O:$O,"Refund")'
            );
        }
      }
      sheet1.cell('B4').style({
        fontColor: '2172d7',
        fontSize: '11',
        fontFamily: 'Calibri',
        numberFormat: 'Rp #,###.0; -',
      });

      //Refund
      sheet1.cell('A5').value('REFUND');
      sheet1
        .cell('A5')
        .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });
      if (obj == null) {
        sheet1.cell('B5').formula('=SUMIF($Q:$Q,"Refund",$H:$H)');
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          sheet1.cell('B5').formula('=SUMIF($Q:$Q,"Refund",$H:$H)');
        } else {
          sheet1.cell('B5').formula('=SUMIF($O:$O,"Refund",$H:$H)');
        }
      }
      sheet1.cell('B5').style({
        fontColor: '2172d7',
        fontSize: '11',
        fontFamily: 'Calibri',
        numberFormat: 'Rp #,###.0; -',
      });

      // Total Sales (Actual)
      sheet1.cell('A6').value('TOTAL SALES (ACTUAL)');
      sheet1.cell('B6').formula('B4-B5');
      sheet1.cell('B6').style('numberFormat', 'Rp #,###.0; -');

      // MDR
      sheet1.cell('A7').value('MDR');
      sheet1
        .cell('A7')
        .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });
      if (obj == null) {
        sheet1.cell('B7').formula('=SUMIFS($I:$I,$Q:$Q,"Transaksi Sukses")');
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          sheet1.cell('B7').formula('=SUMIFS($I:$I,$Q:$Q,"Transaksi Sukses")');
        } else {
          sheet1.cell('B7').formula('=SUMIFS($I:$I,$O:$O,"Transaksi Sukses")');
        }
      }
      sheet1.cell('B7').style({
        fontColor: '2172d7',
        fontSize: '11',
        fontFamily: 'Calibri',
        numberFormat: 'Rp #,###.0; -',
      });

      sheet1.cell('C7').value('0.7%');

      //Net Tranfer
      sheet1.cell('A10').value('Net Transfer to Distributor');
      sheet1.cell('B10').formula('=B6-B7');
      sheet1.cell('B10').style('numberFormat', 'Rp #,###.0; -');

      sheet1.cell('A12').value('PPn');
      sheet1.cell('B12').formula('=B10*11%/110%');
      sheet1.cell('B12').style('numberFormat', 'Rp #,###.0; -');
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style('bold', true);
      sheet1.range('A15:' + endColumn + '15').style('fill', '2172d7');
      // range.style('border', true);

      var filename;
      if (obj == null) {
        filename = 'report_Report_Transaction_Ho_' + bp_name + '.xlsx';
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          filename = 'report_Report_Transaction_Ho_' + bp_name + '.xlsx';
        } else {
          filename = 'report_Report_Transaction_Dist_' + bp_name + '.xlsx';
        }
      }

      return workbook.outputAsync().then(res => {
        saveAs(res, filename);
      });
    });
  };
  render() {

    // TODO Loading
    const date = new Date();
    const twoDay = new Date(date.setDate(date.getDate() - REFUND_PERIOD));
    var one_day = 1000 * 60;
    const bpfilterlist = safeDeepGet(
      this.props,
      ['bpfilterresult', 'body'],
      []
    );
    const {
      businessPoints,
      transactionStatus,
      dispenseStatus,
      dateRange,
      payment,
      role,
      refundStatus,
    } = this.state;
    let list = [];

    {
      PROJECT_HANDLE == 'Nestle' || PROJECT_HANDLE == 'Franke'
        ? role == 'refiller'
          ? []
          : (list = safeDeepGet(this.props, ['result', 'body'], []))
        : (list = safeDeepGet(this.props, ['result', 'body'], []));
    }

    // const list = safeDeepGet(this.props, ['result', 'body'], []);
    const { SearchBar } = Search;

    //pagination customization
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(Order),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'id',
        order: 'desc',
      },
    ];

    const selectRow = {
      mode: 'checkbox',
    };

    const report_list = this.format_report(list);
    let report_filename = '';
    let bp_name = businessPoints.value ? businessPoints.label : businessPoints;
    if (dateRange[1] == null) {
      var endDate = new Date();
      var startDate = new Date();
      startDate.setDate(1);
      report_filename =
        'Trx ' +
        bp_name +
        ' ' +
        startDate.toLocaleDateString('en-GB') +
        ' ke ' +
        endDate.toLocaleDateString('en-GB');
    } else {
      report_filename =
        'Trx ' +
        bp_name +
        ' ' +
        dateRange[0].toLocaleDateString('en-GB') +
        ' ke ' +
        dateRange[1].toLocaleDateString('en-GB');
    }
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <PaginationProvider
              pagination={paginationFactory(pageOptions || [])}
              keyField="id"
              columns={
                PROJECT_HANDLE == 'Nestle'
                  ? this.state.SvNestletransactionColumns || []
                  : PROJECT_HANDLE == 'Franke'
                  ? this.state.SvFranketransactionColumns || []
                  : PROJECT_HANDLE == 'Demo'
                  ? this.state.DemotransactionColumns || []
                  : this.state.RedbokstransactionColumns || []
              }
              data={list}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  data={list}
                  columns={
                    PROJECT_HANDLE == 'Nestle'
                      ? this.state.SvNestletransactionColumns || []
                      : PROJECT_HANDLE == 'Franke'
                      ? this.state.SvFranketransactionColumns || []
                      : PROJECT_HANDLE == 'Demo'
                      ? this.state.DemotransactionColumns || []
                      : this.state.RedbokstransactionColumns || []
                  }
                  bootstrap4
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col sm="2">
                          <FormGroup className="mb-3 ajax-select select2-container">
                            <Select
                              defaultValue={bpfilterlist[0]}
                              value={businessPoints}
                              onChange={this.handleBusinessPoints}
                              options={bpfilterlist}
                              classNamePrefix="select2-selection"
                              isLoading={false}
                              placeholder="Business Point"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={paymentOptions[0]}
                            isSearchable={false}
                            onChange={this.handleTransactionStatus}
                            value={transactionStatus}
                            name="color"
                            options={paymentOptions}
                            placeholder="Payment Status"
                          />
                        </Col>
                        <Col sm="2">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={dispenseOptions[0]}
                            isSearchable={false}
                            onChange={this.handleDispenseStatus}
                            value={dispenseStatus}
                            name="color"
                            options={dispenseOptions}
                            placeholder="Dispense Status"
                          />
                        </Col>
                        <Col sm="4">
                          <Row>
                            <Col sm="4">
                              <Label className="col-md-12 col-form-label text-sm-end">
                                Date Range
                              </Label>
                            </Col>
                            <Col sm="8">
                              <InputGroup>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="dd M,yyyy"
                                  options={{
                                    maxDate: new Date(),
                                    mode: 'range',
                                    dateFormat: 'Y-m-d',
                                  }}
                                  value={dateRange}
                                  onChange={this.handleDateRange}
                                  ref={this.refDatePicker}
                                />
                                {/* <Button
                                  type="reset"
                                  color="secondary"
                                  onClick={this.handleClearDateRange}
                                >
                                  Clear
                                </Button> */}
                              </InputGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          sm="2"
                          className="d-none d-lg-block"
                          align="center"
                        >
                          {PROJECT_HANDLE == 'Redboks' ? (
                            <div className="clearfix mt-4 mt-lg-0">
                              <ExcelFile
                                filename={report_filename}
                                element={
                                  <button
                                    type="button"
                                    className="btn btn-primary w-sm "
                                    disabled={list.length == 0 ? true : false }
                                  >
                                    <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                                    Download Excel
                                  </button>
                                }
                              >
                                <ExcelSheet
                                  data={report_list}
                                  name="Transaction List"
                                >
                                  <ExcelColumn
                                    label="Order Id"
                                    value="order_id"
                                  />
                                  <ExcelColumn
                                    label="Business Point"
                                    value="business_point"
                                  />
                                  <ExcelColumn label="Date" value="date" />
                                  <ExcelColumn
                                    label="Product SKU"
                                    value="sku"
                                  />
                                  <ExcelColumn label="Total" value="total" />
                                  <ExcelColumn
                                    label="Payment Method"
                                    value="method"
                                  />
                                  <ExcelColumn
                                    label="Payment Status"
                                    value="payment_status"
                                  />
                                  <ExcelColumn
                                    label="Dispense Status"
                                    value="dispense_status"
                                  />
                                </ExcelSheet>
                              </ExcelFile>
                            </div>
                          ) : PROJECT_HANDLE == 'Demo' ? (
                            <div className="clearfix mt-4 mt-lg-0">
                              <ExcelFile
                                filename={report_filename}
                                element={
                                  <button
                                    type="button"
                                    className="btn btn-primary w-sm"
                                    disabled={list.length == 0 ? true : false }
                                  >
                                    <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                                    Download Excel
                                  </button>
                                }
                              >
                                <ExcelSheet
                                  data={report_list}
                                  name="Transaction List"
                                >
                                  <ExcelColumn
                                    label="Order Id"
                                    value="order_id"
                                  />
                                  <ExcelColumn
                                    label="Business Point"
                                    value="business_point"
                                  />
                                  <ExcelColumn label="Date" value="date" />
                                  <ExcelColumn
                                    label="Product SKU"
                                    value="sku"
                                  />
                                  <ExcelColumn label="Total" value="total" />
                                  <ExcelColumn
                                    label="Payment Method"
                                    value="method"
                                  />
                                  <ExcelColumn
                                    label="Payment Status"
                                    value="payment_status"
                                  />
                                  <ExcelColumn
                                    label="Dispense Status"
                                    value="dispense_status"
                                  />
                                </ExcelSheet>
                              </ExcelFile>
                            </div>
                          ) : PROJECT_HANDLE == 'Franke' ? (
                            <div className="clearfix mt-4 mt-lg-0">
                              <Button
                                type="button"
                                color="primary"
                                className="btn btn-franke w-sm"
                                disabled={list.length == 0 ? true : false }
                                onClick={() => this.handleExport(report_list)}
                              >
                                <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                                Download Excel
                              </Button>
                            </div>
                          ) : (
                            <div className="clearfix mt-4 mt-lg-0">
                              <Button
                                type="button"
                                color="primary"
                                className="btn btn-nestle w-sm"
                                disabled={list.length == 0 ? true : false }
                                onClick={() => this.handleExport(report_list)}
                              >
                                <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                                Download Excel
                              </Button>
                            </div>
                          )}
                        </Col>
                        {/* {PROJECT_HANDLE == 'Svfranke' ? (
                          <>
                            <Col sm="10">{'  '}</Col>
                            <Col sm="2">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                                className="form-check form-switch mt-3"
                                dir="ltr"
                              >
                                <select
                                  className="form-select form-select-sm"
                                  id="greet"
                                  onChange={this.handleSwitch}
                                >
                                  <option value="all">No Refund</option>
                                  <option value="refund">Refund</option>
                                </select>
                              </div>
                            </Col>
                          </>
                        ) : null} */}
                      </Row>
                      <Row className="mb-2">
                        <Col sm="2">
                          <FormGroup className="mb-3 ajax-select select2-container">
                            <Select
                              defaultValue={bankOptions[0]}
                              value={payment}
                              onChange={this.handlePaymentMethod}
                              options={bankOptions}
                              classNamePrefix="select2-selection"
                              isLoading={false}
                              placeholder="Payment Method"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                          {PROJECT_HANDLE == 'Franke' ? (
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isSearchable={false}
                              value={refundStatus}
                              name="color"
                              // placeholder="Refund"
                              onChange={this.handleSwitch}
                              options={refundOptions}
                            >
                              {/* <option value="all">No Refund</option>
                                    <option value="refund">Refund</option> */}
                            </Select>
                          ) : null}
                        </Col>
                        <Col sm="2"></Col>
                        <Col sm="4"></Col>
                        <Col
                          sm="2"
                          className="d-none d-lg-block"
                          align="center"
                        ></Col>
                        {/* {PROJECT_HANDLE == 'Svfranke' ? (
                          <>
                            <Col sm="10">{'  '}</Col>
                            <Col sm="2">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                                className="form-check form-switch mt-3"
                                dir="ltr"
                              >
                                <select
                                  className="form-select form-select-sm"
                                  id="greet"
                                  onChange={this.handleSwitch}
                                >
                                  <option value="all">No Refund</option>
                                  <option value="refund">Refund</option>
                                </select>
                              </div>
                            </Col>
                          </>
                        ) : null} */}
                      </Row>
                      <div className="table-responsive">
                        <ReactTooltip place="top" effect="solid" />
                        <BootstrapTable    
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                          responsive
                          defaultSorted={defaultSorted}
                          bordered={false}
                          striped={false}
                          classes={
                            'table align-middle table-nowrap table-check'
                          }
                          headerWrapperClasses={'table-light'}
                          ref={this.node}
                        />
                      </div>
                      <div className="pagination pagination-rounded justify-content-end mb-2">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
            <Modal
              size="xl"
              isOpen={this.state.modalDetail}
              className={this.props.className}
              toggle={this.toggleDetail}
            >
              <div className="modal-header">
                <h4
                  className="modal-title mt-0"
                  id="exampleModalFullscreenLabel"
                >
                  {this.state.trx.detail != undefined
                    ? 'Order ID ' + this.state.trx.detail.order_id
                    : null}
                </h4>
                <button
                  onClick={() => this.setState({ modalDetail: false })}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {this.state.trx.detail != undefined ? (
                <ModalBody>
                  {/* <h5 className="mb-2">
                    Product SKU:{' '}
                    <span className="text-primary">
                      {this.state.trx.product.sku}
                    </span>
                  </h5> */}
                  <h5 className="mb-4">
                    Business Point :{' '}
                    <span className="text-primary">{this.state.trx.name}</span>
                  </h5>
                  <h5 className="mb-4">
                    Date :{' '}
                    <span className="text-primary">
                      {' '}
                      {this.handleValidDate(
                        this.state.trx.time.firestore_timestamp
                      )}
                    </span>
                  </h5>

                  <div className="table-responsive mb-2">
                    <Table className="table align-middle table-nowrap">
                      <thead>
                        <tr>
                          <th scope="col">Product</th>
                          <th scope="col">Total</th>
                          <th scope="col">Payment Status</th>
                          <th scope="col">Dispense Status</th>
                          <th scope="col">Payment Method</th>
                          <th scope="col">
                            {' '}
                            {this.state.trx.payment.method == 'RFID'
                              ? 'RFID'
                              : 'Issuer'}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">
                            <div>
                              <h5 className="text-truncate font-size-14">
                                {PROJECT_HANDLE == 'Redboks'
                                  ? this.state.trx.nameProduct
                                  : this.state.trx.product.sku}
                              </h5>
                            </div>
                          </th>
                          <td>
                            <div>
                              <h5 className="text-truncate font-size-14">
                                {safeDeepGet(
                                  this.state.trx,
                                  ['detail', 'transaction_status'],
                                  '-'
                                ) === 'refunded' ? (
                                  <span style={{ color: 'red' }} className="mt-1">
                                    {'-' +
                                      safeDeepGet(
                                        this.state.trx,
                                        ['payment', 'amount'],
                                        '-'
                                      )}
                                  </span>
                                ) : (
                                  <span className="mt-1">
                                    {safeDeepGet(
                                      this.state.trx,
                                      ['payment', 'amount'],
                                      '-'
                                    )}
                                  </span>
                                )}
                              </h5>
                            </div>
                          </td>
                          <td>
                            <div>
                              <span
                                style={{ border: 'none' }}
                                className={
                                  'font-size-12 badge badge-pill badge-soft-' +
                                  this.state.trx.badgeclass
                                }
                                color={this.state.trx.badgeclass}
                              >
                                {this.state.trx.detail.transaction_status}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <span
                                style={{ border: 'none' }}
                                className={
                                  'font-size-12 badge badge-pill badge-soft-' +
                                  this.state.trx.badge
                                }
                                color={this.state.trx.badge}
                              >
                                {this.state.trx.detail.dispense_status}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <h5 className="text-truncate font-size-14">
                                {this.state.trx.payment.method}
                              </h5>
                            </div>
                          </td>
                          <td>
                            <div>
                              <h5 className="text-truncate font-size-14">
                                {this.state.trx.payment.method == 'RFID'
                                  ? this.state.trx.payment.detail.rfid
                                  : this.state.trx.payment.detail.issuer}
                              </h5>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="mb-4">
                    <Row>
                      <Col sm={1}>
                        <div className="text-center">
                          {' '}
                          {this.state.trx.detail.transaction_status ==
                          'settlement' ? (
                            this.state.trx.time.timestamp >= twoDay ? (
                              <Button
                                type="button"
                                color="success"
                                className="btn-sm btn-rounded btn-success"
                                onClick={() =>
                                  this.handleRefundedMidTrans(this.state.trx)
                                }
                              >
                                Refund
                              </Button>
                            ) : (
                              ' '
                            )
                          ) : this.state.trx.detail.transaction_status ==
                            'refunded' ? (
                            <p className="mb-0">Refunded on</p>
                          ) : (
                            ' '
                          )}
                        </div>
                      </Col>
                      <Col sm={11}>
                        <div className="text-start">
                          <p style={{ fontWeight: 'bold' }} className="mt-1">
                            {this.state.trx.detail.transaction_status ==
                            'settlement' ? (
                              this.state.trx.time.timestamp >= twoDay ? (
                                'Time remaining for Refund ' +
                                this.timeConvert(
                                  Math.ceil(
                                    (this.state.trx.time.timestamp - twoDay) /
                                      one_day
                                  )
                                )
                              ) : (
                                ' '
                              )
                            ) : this.state.trx.detail.transaction_status ==
                              'refunded' ? (
                              <>
                                <p className="mb-0">
                                  {this.handleValidRefundDate(
                                    this.state.trx.detail.refund_time
                                  ) + ' '}{' '}
                                  {this.handleValidTime(
                                    this.state.trx.detail.refund_time
                                  )}
                                </p>
                                <p className="mb-1"></p>
                              </>
                            ) : (
                              ' '
                            )}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
              ) : null}
            </Modal>
            <Modal
              size="lg"
              isOpen={this.state.modal}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle} tag="h4">
                {'Refund Order ID ' + this.state.products.id}
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={this.handleValidRefundSubmit}>
                  <Row form>
                    <Col className="col-12">
                      <div className="mb-3">
                        <AvField
                          name="orderId"
                          label="Masukan Jumlah Refund"
                          type="number"
                          errorMessage="Invalid amount"
                          validate={{
                            required: { value: true },
                            min: { value: 0.01 },
                            max: { value: this.state.products.amount },
                          }}
                          value={this.state.products.amount || ''}
                        />
                      </div>
                      <p>
                        {'Batas minimum refund Rp. 0.01.- dan Max Rp. ' +
                          this.state.products.amount +
                          '.-'}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                          disabled={this.state.products.button}
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  bpfilterresult: safeDeepGet(
    state,
    ['transaction', 'list_business_points'],
    {}
  ),
  result: safeDeepGet(state, ['transaction', 'list'], {}),
  loading: safeDeepGet(state, ['transaction', 'loading'], true),
});
Transaction.propTypes = {
  onRefund: PropTypes.func,
};
const mapDispatchToProps = dispatch => ({
  LoadBpFilterList: () => dispatch(list_business_points()),
  LoadBusinessPointFillter: payload => dispatch(list_business_points(payload)),
  LoadTransactionList: (
    businessPoints,
    transactionStatus,
    dispenseStatus,
    dateRange,
    data
  ) =>
    dispatch(
      list(businessPoints, transactionStatus, dispenseStatus, dateRange, data)
    ),
  ReadTransaction: transactionId => dispatch(read(transactionId)),
  RefundTransaction: payload => dispatch(updateRefund(payload)),
  onRefund: refund => dispatch(refunded(refund)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
