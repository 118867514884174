import {
  LOADING,
  RESULT,
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  LIST_INGREDIENT,
  DISCOUNT_PRODUCT,
  DELETE_DISCOUNT
} from './actionTypes';

const INIT_STATE = {
  loading: true,
};

const Product = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case RESULT:
      const newState = {
        ...state,
        loading: false,
      };
      switch (action.payload.actionType) {
        case LIST:
          newState['list'] = action.payload.result;
          break;
        case LIST_INGREDIENT:
          newState['list_ingredients'] = action.payload.result;
          break;
        case DISCOUNT_PRODUCT:
          newState['discount_product'] = action.payload.result;
          break;
        case INIT_CREATE:
          newState['initCreate'] = action.payload.result;
          break;
        case CREATE:
          newState['create'] = action.payload.result;
          break;
        case READ:
          newState['read'] = action.payload.result;
          break;
        case UPDATE:
          newState['update'] = action.payload.result;
          break;
        case STOCK_UPDATE:
          newState['stockUpdate'] = action.payload.result;
          break;
        case DELETE_DISCOUNT:
          newState['delete_discount'] = action.payload.result;
          break;
        case DELETE:
          newState['delete'] = action.payload.result;
          break;
      }
      return newState;

    default:
      return state;
  }
};

export default Product;
