/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import { call, put, takeEvery } from 'redux-saga/effects';
// Product state
import { LIST, DETAIL_CHAT, CREATE, CANCEL, DELETE } from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
import {
  listCoupon,
  createCoupon,
  cancelCoupon,
  deleteCoupon,
} from '../../webservice/device';
function* list() {
  yield put(loading(LIST));
  let wsResult = yield call(listCoupon);
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    const couponBody = safeDeepGet(wsResult, 'body', []);
    for (const id in couponBody) {
      safeDeepSet(couponBody, [id, 'coupon_id'], id);
    }
    yield put(result(LIST, { code: Result.SUCCESS, body: couponBody }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}

function* create(payload) {
  yield put(loading(CREATE));
  const data = safeDeepGet(payload, 'payload', {});
  const amount = safeDeepGet(data, 'discount', 0);
  const ts = safeDeepGet(data, 'exp_ts', 0);
  const type = 1;
  let wsResult = yield call(
    createCoupon,
    btoa(amount.toString()),
    btoa(ts.toString()),
    btoa(type.toString())
  );
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    yield put(result(CREATE, { code: Result.SUCCESS }));
    return;
  }
  yield put(result(CREATE, { code: Result.FAILED }));
}
function* cancel_(payload) {
  yield put(loading(CANCEL));
  const data = safeDeepGet(payload, 'payload', {});
  const code = safeDeepGet(data, 'code', '');
  let wsResult = yield call(cancelCoupon, btoa(code));
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    yield put(result(CANCEL, { code: Result.SUCCESS }));
    return;
  }
  yield put(result(CANCEL, { code: Result.FAILED }));
}
function* delete_(payload) {
  yield put(loading(DELETE));
  const data = safeDeepGet(payload, 'payload', {});
  const code = safeDeepGet(data, 'code', '');
  let wsResult = yield call(deleteCoupon, btoa(code));
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    yield put(result(DELETE, { code: Result.SUCCESS }));
    return;
  }
  yield put(result(DELETE, { code: Result.FAILED }));
}

function* payoutSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(CREATE, create);
  yield takeEvery(CANCEL, cancel_);
  yield takeEvery(DELETE, delete_);
}

export default payoutSaga;
