/* eslint-disable semi */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Collapse } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import classname from 'classnames';

//i18n
import { withTranslation } from 'react-i18next';
import '../../Base/assets/scss/themeNestle.scss';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import { NAVBAR } from 'Apps';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: '',
    };
  }

  componentDidMount() {
    const navbar = NAVBAR
    const obj = JSON.parse(localStorage.getItem('role'));
    if (obj != null) {
      this.setState({ role: obj.role });
    }
    const arr_nav = [];
    for (const i in navbar) {
      safeDeepSet(navbar, [i, 'name'], i);
      arr_nav.push(safeDeepGet(navbar, i, {}));
    }
    this.setState({ arr_nav, navbar });
    let matchingMenuItem = null;
    const ul = document.getElementById('navigation');
    const items = ul.getElementsByTagName('a');
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        if (obj != null) {
          if (
            obj.role == 'admin' ||
            obj.role == null ||
            obj.role == undefined
          ) {
            matchingMenuItem = items[i];
            break;
          }
        } else {
          matchingMenuItem = items[i];
          break;
        }
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    item.classList.add('active');
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add('active'); // li
      const parent2 = parent.parentElement;
      parent2.classList.add('active'); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add('active'); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add('active'); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add('active'); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add('active'); // li
            }
          }
        }
      }
    }
    return false;
  };

  render() {
    const { navbar } = this.state;
    return (
      <React.Fragment>
        <div className="topnavFranke">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnavFranke-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnavFranke-menu-content"
              >
                <ul className="navbar-nav">
                  {safeDeepGet(navbar, ['Overview', 'view'], 'off') == 'on' ? (
                    <li className="nav-item dropdown">
                      <Link
                        to="/dashboard"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        {' '}
                        {this.props.t('Overview')}
                      </Link>
                    </li>
                  ) : null}
                  {/* Business Point */}
                  {safeDeepGet(navbar, ['Business Point', 'view'], 'off') ==
                  'on' ? (
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ bpState: !this.state.bpState });
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-map me-2" />
                        {this.props.t('Business Point')}{' '}
                        <div className="arrow-down" />
                      </Link>
                      <div
                        className={classname('dropdown-menu', {
                          show: this.state.bpState,
                        })}
                      >
                        <Row>
                          <Col lg={2}>
                            <div>
                              <Link
                                to="/business-point/list"
                                className="dropdown-item"
                              >
                                {this.props.t('List Business Points')}
                              </Link>
                              {safeDeepGet(
                                navbar,
                                ['Business Point', 'edit'],
                                'off'
                              ) == 'on' ? (
                                <Link
                                  to="/business-point/create"
                                  className="dropdown-item"
                                >
                                  {this.props.t('Add Business Point')}
                                </Link>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </li>
                  ) : null}
                  {/* Tansaction */}
                  {safeDeepGet(navbar, ['Transaction', 'view'], 'off') ==
                  'on' ? (
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ trxState: !this.state.trxState });
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-file me-2" />
                        {this.props.t('Transaction')}{' '}
                        <div className="arrow-down" />
                      </Link>
                      <div
                        className={classname('dropdown-menu', {
                          show: this.state.trxState,
                        })}
                      >
                        <Row>
                          <Col lg={2}>
                            <div>
                              <Link
                                to="/transaction/list"
                                className="dropdown-item"
                              >
                                {this.props.t('List Transactions')}
                              </Link>
                              <Link
                                to="/transaction/report"
                                className="dropdown-item"
                              >
                                {this.props.t('Reports')}
                              </Link>
                              <Link
                                to="/transaction/chat"
                                className="dropdown-item"
                              >
                                {this.props.t('Refund Request')}
                              </Link>
                              {safeDeepGet(
                                navbar,
                                ['Transaction', 'edit'],
                                'off'
                              ) == 'on' ? (
                                <Link
                                  to="/coupon/list"
                                  className="dropdown-item"
                                >
                                  {this.props.t('Coupon')}
                                </Link>
                              ) : null}
                              {/* <Link
                                to="/transaction/snapshot"
                                className="dropdown-item"
                              >
                                {this.props.t('Snapshot')}
                              </Link> */}
                              {/* <Link
                                to="/snapshot/distributor"
                                className="dropdown-item"
                              >
                                {this.props.t('Snapshot Distributor')}
                              </Link>
                              <Link
                                to="/snapshot/refiller"
                                className="dropdown-item"
                              >
                                {this.props.t('Snapshot Refiller')}
                              </Link> */}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </li>
                  ) : null}
                  {/* Payout */}
                  {safeDeepGet(navbar, ['Payout', 'view'], 'off') == 'on' ? (
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({
                            payOutState: !this.state.payOutState,
                          });
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-money me-2" />
                        {this.props.t('Payout')} <div className="arrow-down" />
                      </Link>
                      <div
                        className={classname('dropdown-menu', {
                          show: this.state.payOutState,
                        })}
                      >
                        <Row>
                          <Col lg={2}>
                            <div>
                              <Link
                                to="/payout/bankaccount"
                                className="dropdown-item"
                              >
                                {this.props.t('Bank Account')}
                              </Link>
                              <Link to="/payout/list" className="dropdown-item">
                                {this.props.t('Payout')}
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </li>
                  ) : null}
                  {/* Management */}
                  {safeDeepGet(navbar, ['Account', 'view'], 'off') == 'on' ? (
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({
                            managementState: !this.state.managementState,
                          });
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx bx-user-circle me-2" />
                        {this.props.t('Management')}{' '}
                        <div className="arrow-down" />
                      </Link>
                      <div
                        className={classname('dropdown-menu', {
                          show: this.state.managementState,
                        })}
                      >
                        <Row>
                          <Col lg={2}>
                            <Link to="/role" className="dropdown-item">
                              {this.props.t('Role')}
                            </Link>
                            <Link to="/account/list" className="dropdown-item">
                              {this.props.t('Account')}
                            </Link>
                            <Link to="/error/code" className="dropdown-item">
                              {this.props.t('Error')}
                            </Link>
                            {/* <div>
                              <Link to="/refill/list" className="dropdown-item">
                                {this.props.t('Refiller')}
                              </Link>
                            </div>
                            
                            <Link to="/landlord" className="dropdown-item">
                              {this.props.t('Landlord')}
                            </Link>
                            <Link to="/finance" className="dropdown-item">
                              {this.props.t('Finance')}
                            </Link> */}
                          </Col>
                        </Row>
                      </div>
                    </li>
                  ) : null}

                  {/* Activity */}
                  {safeDeepGet(navbar, ['Activity', 'view'], 'off') == 'on' ? (
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({
                            activityState: !this.state.activityState,
                          });
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-user-circle me-2" />
                        {this.props.t('Activity')}{' '}
                        <div className="arrow-down" />
                      </Link>
                      <div
                        className={classname('dropdown-menu', {
                          show: this.state.activityState,
                        })}
                      >
                        <Row>
                          <Col lg={2}>
                            <div>
                              <Link to="/refund/list" className="dropdown-item">
                                {this.props.t('Refund History')}
                              </Link>
                              <Link
                                to="/business-point/history/planogram"
                                className="dropdown-item"
                              >
                                {this.props.t('Planogram History')}
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </li>
                  ) : null}

                  {/* Alert */}
                  {/* <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ alertState: !this.state.alertState });
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-message-alt-dots me-2" />
                        {this.props.t('Alert')} <div className="arrow-down" />
                      </Link>
                      <div
                        className={classname('dropdown-menu', {
                          show: this.state.alertState,
                        })}
                      >
                        <Row>
                          <Col lg={2}>
                            <div>
                              <Link
                                to="/alert/machine/list"
                                className="dropdown-item"
                              >
                                {this.props.t('Machine')}
                              </Link>
                              <Link to="/alert/list" className="dropdown-item">
                                {this.props.t('Connection')}
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </li> */}
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Navbar));
