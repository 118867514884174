/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

// Strap
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  FormFeedback,
  FormText,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import Select from 'react-select';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Availity
import { AvField, AvForm } from 'availity-reactstrap-validation';

// Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { list, deviceList, create } from '../../store/payment-link/actions';

class PaymentLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error_dlg: false,
      dynamic_title: 'Invalid Image Format',
      dynamic_description: 'Use Format JPG or PNG ',
      notes:
        'Thank you for your purchase. Please follow the instructions to pay.',
      image: null,
      imageUpdated: false,
      appList: [],
      listDevice: [],
      phone: '+62',
      url: 'https://example.com/some-url',
      modal: false,
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleCancleClick = this.handleCancleClick.bind(this);
    this.showToast = this.showToast.bind(this);
    this.showToastFailed = this.showToastFailed.bind(this);
    this.handleOnchange = this.handleOnchange.bind(this);
    this.handleOnDeviceChange = this.handleOnDeviceChange.bind(this);
    this.togglePopUp = this.togglePopUp.bind(this);
    this.togglewithCopy = this.togglewithCopy.bind(this);
  }

  copyToClipboard = () => {
    navigator.clipboard
      .writeText(this.state.url)
      .then(() => {
        this.setState({ copySuccess: 'Text copied!' });
        this.showToast('Text copied!');
      })
      .catch(err => {
        this.setState({ copyFailed: 'Failed to copy text.' });
        this.showToastFailed('Failed to copy text.');
        console.error('Failed to copy text: ', err);
      });
  };

  componentDidMount() {
    // Load product
    this.props.ListApplicationId();
  }
  handleCancleClick() {
    this.props.history.push(`/admin/transaction`);
  }

  handleValidSubmit(event, values) {
    const { selectedAppId, selectedDeviceId } = this.state;
    const submittedValues = { ...values, selectedAppId, selectedDeviceId };
    this.props.CreatePaymentLink(submittedValues);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.listResult !== prevProps.listResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['listResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        const body = safeDeepGet(this.props, ['listResult', 'body'], {});
        this.setState({ appList: body });
      } else if (code === Result.FAILED) {
        this.showToastFailed('Failed Get List Application');
      }
    }
    if (this.props.deviceListResult !== prevProps.deviceListResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['deviceListResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        const body = safeDeepGet(this.props, ['deviceListResult', 'body'], {});
        this.setState({ listDevice: body });
      } else if (code === Result.FAILED) {
        this.showToastFailed('Failed Get List Devices');
      }
    }
    if (this.props.createResult !== prevProps.createResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        const body = safeDeepGet(this.props, ['createResult', 'body'], {});
        const url = safeDeepGet(body, 'url', "");
        this.togglePopUp();
        this.setState({ url: url });
      } else if (code === Result.FAILED) {
        this.showToastFailed('Failed Create Payment Link');
      }
    }
  }
  togglePopUp() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  togglewithCopy() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
    this.copyToClipboard();
  }
  handleOnchange(value) {
    this.setState({ selectedAppId: value.value });
    // this.props.ListDeviceId(value.value);
  }
  handleOnDeviceChange(value) {
    // console.log(value)
    this.setState({ selectedDeviceId: value });
  }
  showToast(message) {
    var toastType = 'success';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastFailed(message) {
    var toastType = 'error';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  render() {
    const code = safeDeepGet(
      this.props,
      ['createResult', 'code'],
      Result.UNKNOWN_ERROR
    );
    const loading = safeDeepGet(this.props, 'loading', true);
    const {
      name,
      email,
      selectedAppId,
      appList,
      listDevice,
      firstName,
      lastname,
      phone,
      notes,
      price,
    } = this.state;
    const VmList = safeDeepGet(this.props, ['initCreateResult', 'body'], []);
    return (
      <React.Fragment>
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <div className="page-content">
            <MetaTags>
              <title>Create Payment Link | Admin</title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs
                title="Transaction"
                breadcrumbItem="Create Payment Link"
              />
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Payment Link Create</CardTitle>
                      <p className="card-title-desc">
                        Fill all information below
                      </p>
                      <Row>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="applicationId">Project Name:</Label>
                            <Select
                              id="applicationId"
                              name="applicationId"
                              options={appList}
                              className="mb-3"
                              maxMenuHeight={300}
                              placeholder="Select Project Name"
                              onChange={this.handleOnchange}
                              value={appList.filter(function (option) {
                                return option.value === selectedAppId;
                              })}
                            ></Select>
                          </FormGroup>
                          {/* <FormGroup className="mb-3">
                            <Label htmlFor="firstName">First Name</Label>
                            <AvField
                              id="firstName"
                              name="firstName"
                              value={firstName ?? ''}
                              placeholder="firstName"
                              type="text"
                              className="form-control"
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </FormGroup> */}
                          <FormGroup className="mb-3">
                            <Label htmlFor="email">Email</Label>
                            <AvField
                              id="email"
                              name="email"
                              value={email}
                              placeholder="Email"
                              type="email"
                              className="form-control"
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </FormGroup>
                         
                        </Col>
                        <Col sm={6}>
                          {/* <Label htmlFor="selectbank">Select DeviceID</Label> */}
                          {/* <Select
                            isMulti
                            closeMenuOnSelect={false}
                            id="deviceId"
                            name="deviceId"
                            options={listDevice}
                            className="mb-3"
                            maxMenuHeight={300}
                            placeholder="Select Project Name"
                            onChange={this.handleOnDeviceChange}
                          ></Select> */}

                          {/* <FormGroup className="mb-3">
                            <Label htmlFor="lastname">Last Name</Label>
                            <AvField
                              id="lastname"
                              name="lastname"
                              value={lastname ?? ''}
                              min={0}
                              placeholder="Last Name"
                              type="text"
                              className="form-control"
                            />
                          </FormGroup> */}
                          {/* <FormGroup className="mb-3">
                            <Label htmlFor="phone">Phone</Label>
                            <AvField
                              id="phone"
                              name="phone"
                              value={phone ?? ''}
                              min={0}
                              placeholder="+62......"
                              type="text"
                              className="form-control"
                            />
                          </FormGroup> */}
                          <FormGroup className="mb-3">
                            <Label htmlFor="price">Price</Label>
                            <AvField
                              id="price"
                              name="price"
                              value={price ?? 0}
                              min={0}
                              placeholder="Total harga dengan pajak dan mdr"
                              type="text"
                              className="form-control"
                            />
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label htmlFor="notes">Notes</Label>
                            <AvField
                              id="notes"
                              name="notes"
                              value={notes}
                              placeholder="notes"
                              type="text"
                              className="form-control"
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <div className="d-flex flex-wrap gap-2 float-end mb-4">
                    <Button onClick={this.handleCancleClick} color="secondary">
                      Cancel
                    </Button>{' '}
                    <Button type="submit" color="primary">
                      Simpan
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </AvForm>
        <Modal
          size="lg"
          isOpen={this.state.modal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.togglePopUp} tag="h4">
            {'Link URL Payment LInk'}
          </ModalHeader>
          <ModalBody>
            <AvForm>
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <Row className="">
                        <Col sm="10" className="">
                          <div className="">
                            <FormGroup>
                              <Label for="exampleEmail">Copy This link</Label>
                              <Input
                                style={{ width: '100%' }}
                                disabled
                                value={this.state.url}
                                valid={this.state.copySuccess != undefined}
                                invalid={this.state.copyFailed !== undefined}
                              />
                              <FormFeedback
                                valid={this.state.copySuccess != undefined}
                                invalid={this.state.copyFailed !== undefined}

                              >
                                {this.state.copySuccess? "Sweet! "+this.state.copySuccess : this.state.copyFailed? "Oh No!," + this.state.copyFailed : null}
                              </FormFeedback>
                              <FormText>Copy this link</FormText>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col sm="2">
                          <div>
                            <Button
                              className="mt-4"
                              color="primary"
                              onClick={this.copyToClipboard}
                            >
                              Copy
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <div className="d-flex flex-wrap gap-2 float-end mb-4">
                    <Link
                      className="btn btn-secondary"
                      role="button"
                      onClick={this.togglewithCopy}
                      to="#"
                    >
                      Batal
                    </Link>{' '}
                  </div>
                </Col>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  listResult: safeDeepGet(state, ['PaymentLink', 'list'], {}),
  createResult: safeDeepGet(state, ['PaymentLink', 'create'], {}),
  deviceListResult: safeDeepGet(state, ['PaymentLink', 'deviceList'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  ListApplicationId: () => dispatch(list()),
  ListDeviceId: payload => dispatch(deviceList(payload)),
  CreatePaymentLink: payload => dispatch(create(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentLink);
