/* eslint-disable no-redeclare */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import MetaTags from "react-meta-tags";

// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
// Availity
import {
  AvField,
  AvForm,
} from "availity-reactstrap-validation";

// Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';

// Iotera
import Result from "iotera-base/result";
import { safeDeepGet, isEmpty } from "iotera-base/utility/json";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { create, initCreate, read, update } from "../../store/device/actions";
class CreateDevice extends Component {
  constructor(props) {
    super(props);

    let isEdit = false;
    const path = this.props.match.path;
    if (path.includes("/edit/")) {
      isEdit = true;
    }
    let appid = safeDeepGet(this.props.match.params, "id");
    let deviceId = safeDeepGet(this.props.match.params, "deviceid");
    this.state = {
      isEdit,
      appid,
      deviceId,
      subscription: false,
      rfid: false,
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.showToast = this.showToast.bind(this);
  }
  handleChecked(e) {
    const value = e.target.value;
    const isChecked = e.target.checked;
    if (value == "subscription") {
      if (isChecked) {
        this.setState({ subscription: true });
      } else {
        this.setState({ subscription: false });
      }
    } else if (value == "rfid") {
      if (isChecked) {
        this.setState({ rfid: true });
      } else {
        this.setState({ rfid: false });
      }
    }
  }
  handleValidSubmit(event, values) {
    const { isEdit, subscription, rfid, appid } =
      this.state;

    const submittedValues = {
      ...values,
      rfid: rfid,
      subscription: subscription,
      appid,
    };

    // console.log(submittedValues);
    if (isEdit) {
      this.props.UpdateDevice(submittedValues);
    } else {
      this.props.CreateDevice(submittedValues);
    }
  }
  componentDidMount() {
    const { isEdit, appid, deviceId } = this.state;
    // Load Refiller
    // if (!isEdit) {
    //   this.props.InitCreateApp();
    // } else {
    //   this.props.ReadDevice(appid, deviceId);
    // }
  }
  showToast() {
    const { isEdit } = this.state;

    if (isEdit) {
      var toastType = "success";
      var message = "Berhasil Update Business Point";
    } else {
      var toastType = "success";
      var message = "Berhasil Tambah Business Point";
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: "toast-top-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };

    if (toastType === "info") toastr.info(message);
    else if (toastType === "warning") toastr.warning(message);
    else if (toastType === "error") toastr.error(message);
    else toastr.success(message);
  }
  showToastFailed() {
    const { isEdit } = this.state;

    if (isEdit) {
      var toastType = "error";
      var message = "Gagal Update Business Point";
    } else {
      var toastType = "error";
      var message = "Gagal Tambah Business Point";
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: "toast-top-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };

    if (toastType === "info") toastr.info(message);
    else if (toastType === "warning") toastr.warning(message);
    else if (toastType === "error") toastr.error(message);
    else toastr.success(message);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isEdit, appid } = this.state;

    if (!isEdit) {
      if (this.props.createResult !== prevProps.createResult) {
        // Success create
        const code = safeDeepGet(
          this.props,
          ["createResult", "code"],
          Result.UNKNOWN_ERROR
        );
        if (code === Result.SUCCESS) {
          this.showToast();
          this.props.history.replace("/devices/list/" + appid);
        } else if (code !== Result.UNKNOWN_ERROR) {
          this.showToastFailed();
        }
      }
    } else {
      if (this.props.readResult !== prevProps.readResult) {
        const code = safeDeepGet(
          this.props,
          ["readResult", "code"],
          Result.UNKNOWN_ERROR
        );
        if (code == Result.SUCCESS) {
          const newState = {};
          const plugin_arr = safeDeepGet(
            this.props,
            ["readResult", "plugin"],
            []
          );
          for (const i in plugin_arr) {
            if (plugin_arr[i] == "subscription") {
              const feeSubs = safeDeepGet(this.props, [
                "readResult",
                "body",
                "plugin",
                plugin_arr[i],
                "daily_subscription",
              ]);
              newState["feeSubs"] = feeSubs;
            } else if (plugin_arr[i] == "rfid") {
              const priceRfid = safeDeepGet(this.props, [
                "readResult",
                "body",
                "plugin",
                plugin_arr[i],
                "rfid",
              ]);
              newState["priceRfid"] = priceRfid;
            }
          }
          const data_plugin = safeDeepGet(
            this.props,
            ["readResult", "dataPlugin"],
            []
          );
          for (const i in data_plugin) {
            if (data_plugin[i] == "ecr") {
              newState["ecr"] = true;
            }
            newState[data_plugin[i]] = true;
          }

          const id = safeDeepGet(this.props, [
            "readResult",
            "body",
            "device_id",
          ]);
          newState["id"] = id;
          const name = safeDeepGet(this.props, ["readResult", "body", "name"]);
          newState["name"] = name;
          const plugin = safeDeepGet(this.props, [
            "readResult",
            "body",
            "plugin",
          ]);
          newState["plugin"] = plugin;

          if (!isEmpty(newState)) {
            this.setState(newState);
          }
        }
      } else if (this.props.updateResult !== prevProps.updateResult) {
        // Success update info
        const code = safeDeepGet(
          this.props,
          ["updateResult", "code"],
          Result.UNKNOWN_ERROR
        );

        if (code === Result.SUCCESS) {
          this.props.history.replace("/devices/list/" + appid);
          this.showToast();
        }
      }
    }
  }
  render() {
    // const loading = safeDeepGet(this.props, "loading", true);
    // for(const i in)
    const {
      isEdit,
      name,
      id,
      feeSubs,
     
      priceRfid,
    } = this.state;
    const { subscription, rfid } = this.state;
    return (
      <React.Fragment>
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <div className="page-content">
            <MetaTags>
              <title>Application | Payment Server</title>
            </MetaTags>
            <Container fluid={true}>
              <Breadcrumbs
                title="Application"
                breadcrumbItem={isEdit ? "Edit" : "Add"}
              />
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Business Points Information</CardTitle>
                      <p className="card-title-desc">
                        Fill all information below
                      </p>
                      <Row>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="id">Device ID :</Label>
                            <AvField
                              type="text"
                              id="id"
                              name="id"
                              disabled={isEdit}
                              value={id}
                              placeholder="Masukan Device ID..."
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please enter a Device id",
                                },
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="name">Name : </Label>
                            <AvField
                              type="text"
                              id="name"
                              name="name"
                              value={name}
                              placeholder="Masukan Nama..."
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please enter a Name",
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup
                            className="mb-3"
                            onchange="handleChange(event)"
                          >
                            <Label className="mt-1" htmlFor="description">
                              Fee :
                            </Label>
                            <div className="mt-1">
                              <div className="form-check mb-2" id="myCheckbox">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="subscription"
                                  id="defaultCheck1"
                                  checked={this.state.subscription}
                                  onClick={this.handleChecked}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  Daily Subscription
                                </label>
                              </div>
                              <div className="form-check mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="rfid"
                                  id="defaultCheck2"
                                  checked={this.state.rfid}
                                  onClick={this.handleChecked}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck2"
                                >
                                  RFID
                                </label>
                              </div>
                             
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  {subscription ? (
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">
                          Subscription Information
                        </CardTitle>
                        <p className="card-title-desc">
                          Fill all information below
                        </p>
                        <Row>
                          <Col sm="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="feeSubs">Fee Subscription :</Label>
                              <AvField
                                type="text"
                                id="feeSubs"
                                name="feeSubs"
                                value={feeSubs}
                                placeholder="Masukan harga Subscription..."
                                className="form-control"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter a feeSubs",
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  ) : null}
                  {rfid ? (
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">
                          RFID Information
                        </CardTitle>
                        <p className="card-title-desc">
                          Fill all information below
                        </p>
                        <Row>
                          <Col sm="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="priceRfid">Fee :</Label>
                              <AvField
                                type="number"
                                id="priceRfid"
                                name="priceRfid"
                                value={priceRfid}
                                placeholder="Masukan harga rfid..."
                                className="form-control"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter a price",
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="6">
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  ) : null}

                  <div className="d-flex flex-wrap gap-2 float-end mb-4">
                    <Link
                      className="btn btn-secondary"
                      role="button"
                      to="/admin/business-point"
                    >
                      Batal
                    </Link>{" "}
                    <Button type="submit" color="primary">
                      Simpan
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </AvForm>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  initCreateResult: safeDeepGet(state, ["Device", "initCreate"], {}),
  createResult: safeDeepGet(state, ["Device", "create"], {}),
  loading: safeDeepGet(state, ["Device", "loading"], true),
  readResult: safeDeepGet(state, ["Device", "read"], {}),
  updateResult: safeDeepGet(state, ["Device", "update"], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  // InitCreateApp: () => dispatch(initCreate()),
  // CreateDevice: payload => dispatch(create(payload)),
  // ReadDevice: (appid, deviceid) => dispatch(read(appid, deviceid)),
  // UpdateDevice: payload => dispatch(update(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateDevice);
