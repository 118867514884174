/* eslint-disable semi */
/* Import */
import { APPLICATION_ID, MULTITENANCY_HANDLE } from 'Apps';

import adminLayout from 'Admin/components';
import baseLayout from 'Base/components';
import svFrankeLayout from 'SvNestleFranke/components';
import svNestleLayout from 'SvNestleMilano/components';
export default () => {
  let layout = baseLayout;
  switch (APPLICATION_ID) {
    case '1000000159':
      layout = svNestleLayout;
      break;
    case '1000000211':
      layout = svFrankeLayout;
      break;
    case 'Admin':
      layout = adminLayout;
      break;
    default:
      layout = baseLayout;
      break;
  }
  return layout;
};
