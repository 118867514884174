export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "CHAT_LIST";
export const INIT_CREATE = "PAYOUT_INIT_CREATE";
export const CREATE = "BANK_CREATE";
export const READ = "PAYOUT_INFO";
export const UPDATE = "PAYOUT_UPDATE";
export const STOCK_UPDATE = "STOCK_PAYOUT_UPDATE";
export const DELETE = "PAYOUT_DELETE";
export const DETAIL_CHAT = "DETAIL_CHAT";
