/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Compressor from 'compressorjs';

// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Availity
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Pages403 from 'Base/pages/pages-403';
import{ NAVBAR, PERMISION } from 'Apps'
const navbar = NAVBAR
const permision = PERMISION
// Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, isEmpty } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import {read, update } from '../../store/invoice/actions';
import SweetAlert from 'react-bootstrap-sweetalert';
import { buildTitle } from 'Apps';

// Ganti jadi Tagihan dari Invoice
// 1 file jadi bebas
// Bukti jadi Gambar aja
// Bisa Download
// kkalo bisa Upload Dari table
class Invoice extends Component {
  constructor(props) {
    super(props);

    let isEdit = false;
    let id = null;

    const path = this.props.match.path;
    if (path.includes('/info')) {
      isEdit = true;
      id = safeDeepGet(this.props.match.params, 'id');
    }

    this.state = {
      error_dlg: false,
      dynamic_title: 'Invalid Image Format',
      dynamic_description: 'Use Format JPG or PNG ',
      isEdit,
      id,
      file: null,
      fileInvUpdated: false,
    };

    this.handleInvoiceFile = this.handleInvoiceFile.bind(this);
    this.handleBuktiFile = this.handleBuktiFile.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleCancleClick = this.handleCancleClick.bind(this);
    this.showToast = this.showToast.bind(this);
  }

  componentDidMount() {
    const { isEdit } = this.state;

    // Load product
    if (!isEdit) {
      this.props.InitCreateProduct();
    } else {
      this.props.ReadProduct(this.state.id);
    }
  }
  handleCancleClick() {
    this.props.history.push(`/product/list/`);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isEdit } = this.state;

    if (!isEdit) {
      if (this.props.createResult !== prevProps.createResult) {
        // Success create
        const code = safeDeepGet(
          this.props,
          ['createResult', 'code'],
          Result.UNKNOWN_ERROR
        );

        if (code === Result.SUCCESS) {
          this.showToast();
          this.props.history.replace('/product/list');
        }
      }
    } else {
      if (this.props.readResult !== prevProps.readResult) {
        const code = safeDeepGet(
          this.props,
          ['readResult', 'code'],
          Result.UNKNOWN_ERROR
        );
        if (code == Result.SUCCESS) {
          const newState = {};
          const name = safeDeepGet(this.props, ['readResult', 'body', 'name']);
          newState['name'] = name;
          const desc = safeDeepGet(this.props, ['readResult', 'body', 'desc']);
          newState['desc'] = desc;
          const image = safeDeepGet(this.props, [
            'readResult',
            'body',
            'img_url',
          ]);
          newState['image'] = image;

          if (!isEmpty(newState)) {
            this.setState(newState);
          }
        }
      } 
      if (this.props.updateResult !== prevProps.updateResult) {
        // Success update info
        const code = safeDeepGet(
          this.props,
          ['updateResult', 'code'],
          Result.UNKNOWN_ERROR
        );

        if (code === Result.SUCCESS) {
          this.showToast();
          this.props.history.replace('/invoice/list');
        }
      }
    }
  }

  handleAlertImg() {
    var toastType = 'error';
    var message = 'Format Gambar Tidak Sesuai!';
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  handleInvoiceFile(event) {
    const fileInv = event.target.files[0];
    let fileInvFile = '';
    fileInvFile = fileInv;
    const fakepath = event.target.value;
    const fileInvExt = fakepath.slice(
      ((fakepath.lastIndexOf('.') - 1) >>> 0) + 2
    );
  
    this.setState({
      fileInvUpdated: true,
      fileInv: URL.createObjectURL(fileInvFile),
      fileInvFile: fileInvFile,
      fileInvExt,
    });
  }
  handleBuktiFile(event) {
    const fileBukti = event.target.files[0];
    let fileBuktiFile = '';
    fileBuktiFile = fileBukti;
    const fakepath = event.target.value;
    const fileBuktiExt = fakepath.slice(
      ((fakepath.lastIndexOf('.') - 1) >>> 0) + 2
    );
    this.setState({
      fileBuktiUpdated: true,
      fileBukti: URL.createObjectURL(fileBuktiFile),
      fileBuktiFile: fileBuktiFile,
      fileBuktiExt,
    });
  }

  handleValidSubmit(event, values) {
    const { isEdit, fileBuktiUpdated, fileBuktiFile, fileBuktiExt, fileInvFile, fileInvUpdated, fileInvExt} = this.state;

    const submittedValues = { ...values };

    if (fileBuktiUpdated) {
      submittedValues['fileBuktiFile'] = fileBuktiFile;
      submittedValues['fileBuktiExt'] = fileBuktiExt;
    }

    if (fileInvUpdated) {
      submittedValues['fileInvFile'] = fileInvFile;
      submittedValues['fileInvExt'] = fileInvExt;
    }

    // if (isEdit) {
      this.props.UpdateProduct(submittedValues);
    // } else {
    //   this.props.CreateProduct(submittedValues);
    // }
  }
  showToast() {
    const { isEdit } = this.state;
    var toastType = 'success';
    var message = 'Berhasil Tambah Invoice';
    if (isEdit) {
      toastType = 'success';
      message = 'Berhasil Update Invoice';
    }
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  render() {
    const loading = safeDeepGet(this.props, 'loading', true);
    const { isEdit, name, id, desc, image } = this.state;
    return (
      <React.Fragment>
        {safeDeepGet(navbar, ['Product', 'edit'], 'off') == 'off' ? (
          <Pages403 />
        ) : (
          <AvForm onValidSubmit={this.handleValidSubmit}>
            <div className="page-content">
              <MetaTags>
                <title>{buildTitle('Invoice List')}</title>
              </MetaTags>
              <Container fluid>
                <Breadcrumbs
                  title="Product"
                  breadcrumbItem={isEdit ? 'Edit' : 'Add'}
                />
                <Row>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">
                          Invoice Information
                        </CardTitle>
                        <p className="card-title-desc">
                          Fill all information below
                        </p>
                        <Row>
                          <Col sm="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="id">Invoice No</Label>
                              <AvField
                                id="id"
                                name="id"
                                value={id}
                                placeholder="id"
                                type="text"
                                errorMessage="Masukan id dengan benar"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  // Bisa Pakai Huruf Besar
                                  pattern: { value: '^[A-Za-z0-9_-]+$' },
                                  // Tidak bisa pakai Huruf Besar
                                  // pattern: {value: '^[a-z0-9_]+$'},
                                }}
                                disabled={isEdit}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <Label htmlFor="file" className="form-label">
                              Upload Bukti Perhitungan
                            </Label>
                            <Input
                              className="form-control"
                              type="file"
                              id="file"
                              onChange={this.handleInvoiceFile}
                            />
                          </Col>
                          <Col sm={6}>
                            <Label htmlFor="image" className="form-label">
                              Upload File Bukti Transfer
                            </Label>
                            <Input
                              className="form-control"
                              type="file"
                              id="image"
                              onChange={this.handleBuktiFile}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <div className="d-flex flex-wrap gap-2 float-end mb-4">
                      <Button
                        onClick={this.handleCancleClick}
                        color="secondary"
                      >
                        Cancel
                      </Button>{' '}
                      <Button type="submit" color="primary" disabled={loading}>
                        Simpan
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </AvForm>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  readResult: safeDeepGet(state, ['invoice', 'read'], {}),
  updateResult: safeDeepGet(state, ['invoice', 'update'], {}),
  loading: safeDeepGet(state, ['invoice', 'loading'], true),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  ReadProduct: deviceId => dispatch(read(deviceId)),
  UpdateProduct: payload => dispatch(update(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
