import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Availity
import { AvField, AvForm } from 'availity-reactstrap-validation';

// Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, isEmpty } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { initCreate, create, read, update } from '../../store/payout/actions';
import SweetAlert from 'react-bootstrap-sweetalert';

class InfoBank extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error_dlg: false,
      dynamic_title: 'Invalid Image Format',
      dynamic_description: 'Use Format JPG or PNG ',
      image: null,
      imageUpdated: false,
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleCancleClick = this.handleCancleClick.bind(this);
    this.showToast = this.showToast.bind(this);
    this.showToastFailed = this.showToastFailed.bind(this);
    this.handleOnchange = this.handleOnchange.bind(this);
  }

  componentDidMount() {
    // Load product
    this.props.InitCreateProduct();
  }
  handleCancleClick() {
    this.props.history.push(`/payout/bankaccount`);
  }

  handleValidSubmit(event, values) {
    const { selectedBank } = this.state;

    const submittedValues = { ...values, selectedBank: selectedBank };
    this.props.CreateAccount(submittedValues);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if(code != Result.UNKNOWN_ERROR){
        if (code === Result.SUCCESS) {
          this.props.history.replace('/payout/bankaccount');
          this.showToast();
        } else {
          this.showToastFailed();
        }
      }
    } else if( this.props.createResult === prevProps.createResult){
      console.log('.')
    }
  }
  handleOnchange(value) {
    this.setState({ selectedBank: value.value });
  }
  showToast() {
    var toastType = 'success';
    var message = 'Berhasil Tambah Account';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastFailed() {
    var toastType = 'error';
    var message = 'Gagal Tambah Account';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  render() {
    const code = safeDeepGet(
      this.props,
      ['createResult', 'code'],
      Result.UNKNOWN_ERROR
    );
    const loading = safeDeepGet(this.props, 'loading', true);
    const { name, email, accountNo, selectedBank } = this.state;
    const VmList = safeDeepGet(this.props, ['initCreateResult', 'body'], []);
    return (
      <React.Fragment>
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <div className="page-content">
            <MetaTags>
              <title>Account Bank | Redboks Vending Machine</title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="Payout" breadcrumbItem="Add Account" />
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Account Information</CardTitle>
                      <p className="card-title-desc">
                        Fill all information below
                      </p>
                      <Row>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="name">Name</Label>
                            <AvField
                              id="name"
                              name="name"
                              value={name}
                              placeholder="Nama"
                              type="text"
                              errorMessage="Masukkan Nama"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="email">Email</Label>
                            <AvField
                              id="email"
                              name="email"
                              value={email}
                              placeholder="Email"
                              type="email"
                              className="form-control"
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <Label htmlFor="selectbank">Select Bank</Label>
                          <Select
                            id="selectbank"
                            name="selectbank"
                            options={VmList}
                            className="mb-3"
                            maxMenuHeight={150}
                            placeholder="Select Bank"
                            onChange={this.handleOnchange}
                            value={VmList.filter(function (option) {
                              return option.value === selectedBank;
                            })}
                          ></Select>

                          <FormGroup className="mb-3">
                            <Label htmlFor="accountNo">Account Number</Label>
                            <AvField
                              id="accountNo"
                              name="accountNo"
                              value={accountNo}
                              min={0}
                              placeholder="Account Number"
                              type="number"
                              errorMessage="Masukkan Angka"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <div className="d-flex flex-wrap gap-2 float-end mb-4">
                    <Button onClick={this.handleCancleClick} color="secondary">
                      Cancel
                    </Button>{' '}
                    <Button type="submit" color="primary" disabled={loading}>
                      Simpan
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </AvForm>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  initCreateResult: safeDeepGet(state, ['payout', 'initCreate'], {}),
  readResult: safeDeepGet(state, ['payout', 'read'], {}),
  createResult: safeDeepGet(state, ['payout', 'create'], {}),
  updateResult: safeDeepGet(state, ['payout', 'update'], {}),
  loading: safeDeepGet(state, ['payout', 'loading'], true),

  test: state,
});

const mapDispatchToProps = dispatch => ({
  InitCreateProduct: () => dispatch(initCreate()),
  ReadProduct: deviceId => dispatch(read(deviceId)),
  CreateAccount: payload => dispatch(create(payload)),
  UpdateProduct: payload => dispatch(update(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoBank);
