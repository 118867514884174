import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
// Dashboard 
import dashboard from "./dashboard/reducer";

// Business point
import businessPoint from "./business-point/reducer";

// Product
import product from "./product/reducer";
import ingredient from "./ingredient/reducer";
import distributor from "./distributor/reducer";
import refiller from "./refiller/reducer";
import alert from "./alert/reducer";
import payout from "./payout/reducer";
import stock from "./stock/reducer";
import coupon from "./coupon/reducer";

// Transaction
import transaction from "./transaction/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  dashboard,
  businessPoint,
  alert,
  payout,
  refiller,
  distributor,
  ingredient,
  product,
  transaction,
  coupon,
  stock
})

export default rootReducer
