import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"

import dashboardSaga from "./dashboard/saga";

import businessPointSaga from './business-point/saga'
import productSaga from './product/saga'
import ingredientSaga from './ingredient/saga'
import distributorSaga from './distributor/saga'
import transactionrfidSaga from './transactionrfid/saga'
import transactionSaga from './transaction/saga'
import refillerSaga from './refiller/saga'
import alertSaga from './alert/saga'
import rfidSaga from './rfid/saga'
import payoutSaga from './payout/saga'
import stockSaga from './stock/saga'
import couponSaga from './coupon/saga'

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(businessPointSaga),
    fork(distributorSaga),
    fork(ingredientSaga),
    fork(productSaga),
    fork(couponSaga),
    fork(stockSaga),
    fork(payoutSaga),
    fork(rfidSaga),
    fork(alertSaga),
    fork(refillerSaga),
    fork(transactionSaga),
    fork(transactionrfidSaga),
  ])
}
