import {
  LOADING,
  RESULT,
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  LIST_VM
} from "./actionTypes";

export const loading = actionType => ({
  type: LOADING,
  payload: { actionType },
});

export const result = (actionType, result) => ({
  type: RESULT,
  payload: { actionType, result },
});

export const list = () => ({
  type: LIST,
});
export const list_vm = () => ({
  type: LIST_VM,
});

export const initCreate = () => ({
    type: INIT_CREATE,
  });

export const create = payload => ({
  type: CREATE,
  payload,
});

export const read = sku => ({
  type: READ,
  payload: { sku },
});

export const update = payload => ({
  type: UPDATE,
  payload,
});

export const stockUpdate = payload => ({
  type: STOCK_UPDATE,
  payload,
});

export const delete_ = payload => ({
  type: DELETE,
  payload,
});
